import React, { useEffect, useState } from 'react';
import "@mdxeditor/editor/style.css";
import {
    InsertTable,
    linkDialogPlugin,
    MDXEditor,
    tablePlugin,
    headingsPlugin,
    listsPlugin,
    quotePlugin,
    thematicBreakPlugin,
    linkPlugin,
    toolbarPlugin,
    UndoRedo,
    BoldItalicUnderlineToggles,
    ListsToggle
} from "@mdxeditor/editor";

function Separator() {
    return (
    <div
        data-orientation="vertical"
        aria-orientation="vertical"
        role="separator"
    ></div>
    );
}

export const BaseEditor = ({editorValue, setEditorValue, showTable}) => {

    const handleContentChange = (updatedContent) => {
        setEditorValue(updatedContent);
    };

    return (
        <div>
            <MDXEditor markdown={editorValue} 
                    onChange={(value) => handleContentChange(value)}
                    plugins={[
                        headingsPlugin(),
                        listsPlugin(),
                        quotePlugin(),
                        thematicBreakPlugin(),
                        linkPlugin(),
                        linkDialogPlugin({}),
                        tablePlugin(),
                        toolbarPlugin({
                            toolbarContents: () => (
                            <div className="MarkDownToolBar">
                                <UndoRedo />
                                <Separator />
                                <BoldItalicUnderlineToggles />
                                <Separator />
                                {/* <InsertTable />
                                <Separator /> */}
                                <ListsToggle options={['bullet', 'number']}/>
                            </div>
                            ),
                        }),
                    ]}
                />
        </div>
    );
}

export const ReadOnlyEditor = ({editorValue, brand_color}) => {

    const [key, setKey] = useState(0);

    useEffect(() => {
        setKey((prevKey) => prevKey + 1); // Increment key to force re-render on editorValue update
    }, [editorValue]);

    return (
        <div>
            <MDXEditor 
                    key={key}
                    markdown={editorValue} 
                    className='MarkDownEditors1'
                    plugins={[
                        headingsPlugin(),
                        listsPlugin(),
                        quotePlugin(),
                        thematicBreakPlugin(),
                        linkPlugin(),
                        linkDialogPlugin({}),
                        tablePlugin(),
                    ]}
                    readOnly={true}
                />
        </div>
    );
}
