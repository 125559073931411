import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import {connect} from 'react-redux';
import {setSelectedInvoiceAccount, setInvoiceAccountsList} from '../actions/invoiceAccount';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import { logout } from '../actions/invoiceAuth';
import {trackGAEvent} from '../util/AppUtil';
import { addCustomFieldApi, editCustomFieldApi } from '../services/clientService';
import {useIsMount} from './useIsMount';

const drawerStyle = {
    flexShrink: 0
};

const drawerPaperStyle = {
    width: '33%',
    flexGrow: 1,
    top: '51px',
};

const appBarStyle = {
    background: '#F9F9F9',
    height: '45px',
    color: '#202020'
};

const customMarginTopStyle = {
    marginTop: "16px"
};

const muiCheckboxColorPrimaryStyle = {
    color: "#d3d3d3 !important",
};

const muiCheckedStyle = {
    color: '#202020 !important'
};

const customMarginBottomStyle = {
    marginBottom: '-4px'
};

const CustomFieldDrawer = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [customFieldName, setCustomFieldName] = useState('');
    const [defaultValue, setDefaultValue] = useState('none');
    const [showInvoice, setShowInvoice] = useState(false);
    const [errorNo, setErrorNo] = useState(undefined);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [defaultValueArr, setDefaultValueArr] = useState([
        {key: "Tax Id", value: "{{client.tax_id}}"},
        {key: "Address", value: "{{client.address}}"},
        {key: "State", value: "{{client.state}}"},
        {key: "Country", value: "{{client.country}}"},
        {key: "Name", value: "{{client.name}}"},
    ]);
    const [defaultType, setDefaultType] = useState('none');
    const [defaultTypeArr, setDefaultTypeArr] = useState([
        {key:'Text', value:'text'},
        {key:'Image / Document', value:'file'}
    ]);
    const isMount = useIsMount();
    const customFieldList = props.selectedAccount?.custom_fields;


    const onCreateOrUpdateCustomField = () => {
        const name = customFieldName;
        if(name === '') {
            setErrorNo(0);
            setErrorMsg('* Please enter custom field name');
            return;
        }

        if(defaultType === 'none') {
            setErrorNo(1);
            setErrorMsg('* Please select a type');
            return;
        }

        const default_value = defaultValue === 'none' ? '' : defaultValue;

        consoleToLog(name);
        consoleToLog(defaultValue);
        consoleToLog(showInvoice);

        const invoice_account_id = props.selectedAccount?.id;

        setLoading(true);
        if(props.selectedCustomField){
            editCustomFieldApi(props.selectedCustomField.id, name, default_value, showInvoice, defaultType)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response editCustomFieldApi:', res);
                setLoading(false);
                enqueueSnackbar('Custom Field updated successfully', { variant: 'success' });

                trackGAEvent(props.selectedAccount.name, 'Custom Field Updated', `${props.user.firstname} ${props.user.lastname}`);


                let updatedCustomFieldArr = customFieldList.map((cfo) => {
                        if(cfo.id === res.id) {
                            cfo = res;
                        }
                        return cfo
                });

                let account = {
                    ...props.selectedAccount,
                    custom_fields: updatedCustomFieldArr
                }

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === account.id) return account;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);

                setErrorMsg('');
                setErrorNo(undefined);
                onCancelClick();
            })
            .catch((e) => {
                consoleToLog('Error editCustomFieldApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });
        }
        else{
            addCustomFieldApi(invoice_account_id, name, default_value, showInvoice, defaultType)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addCustomFieldApi:', res);
                setLoading(false);
                enqueueSnackbar('Custom Field added successfully', { variant: 'success' });

                trackGAEvent(props.selectedAccount.name, 'Custom Field Added', `${props.user.firstname} ${props.user.lastname}`);


                let accountObj = props.selectedAccount;
                accountObj.custom_fields.push(res);

                let accountList = props.invoiceAccountsList.map((ia)=>{
                    if(ia.id === accountObj.id) return accountObj;
                    else return ia;
                });

                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(accountObj);
                if(props.fromCustomFieldComponent || props.fromAddEditClientComponent) { 
                    props.setSwitchFocus(true);
                    props.onUDFNameChanged('', res, props.currentIndex);
                    props.onItemClick(res, props.currentIndex);
                }

                setErrorMsg('');
                setErrorNo(undefined);
                onCancelClick();
            })
            .catch((e) => {
                consoleToLog('Error addCustomFieldApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });
        }
    }

    const onCancelClick = () => {
        setCustomFieldName('');
        setShowInvoice(false);
        setDefaultValue('none');
        setDefaultType('none');
        setErrorNo(undefined);
        setErrorMsg('');
        props.handleCloseFilterDrawer()
    }

    const onShowInvoiceClicked = () =>{
        setShowInvoice(!showInvoice);
    }

    const onDefaultValueChanged = (e) => {
        setDefaultValue(e.target.value);
    }

    const onDefaultTypeChanged = (e) => {
        setDefaultType(e.target.value);
        setErrorNo(undefined);
        setErrorMsg('');
    }

    useEffect(()=>{
        if(!isMount) {
            if(props.selectedCustomField) {
                setCustomFieldName(props.selectedCustomField.name);
                setShowInvoice(props.selectedCustomField.show_invoice);
                setDefaultValue(props.selectedCustomField.default_value);
                setDefaultType(props.selectedCustomField.type)
            }
        }
    }, [props.selectedCustomField])

    return(
        <RightToLeftSideDrawer title="Custom Field" 
            openDrawer={props.openFilterDrawer}
            closeDrawer={()=>onCancelClick()}>

            <Grid container style={{padding:'16px'}}>
                <Grid size={12}>
                    <Typography variant="subtitle1">
                        Custom Field Name
                    </Typography>

                    <TextField fullWidth 
                        id="tax_name" 
                        variant="outlined"
                        type="text"
                        value={customFieldName}
                        autoComplete="off"
                        placeholder="Enter custom field name"
                        slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={(e) => {
                            setCustomFieldName(e.target.value)
                            setErrorNo(undefined);
                            setErrorMsg('');
                        }}
                        error={errorNo === 0}
                        helperText={errorNo === 0 ? errorMsg : ''}
                        size='small'
                    />
                </Grid>   
                <Typography variant="subtitle1" style={{marginTop:"8px"}}>
                        Type
                </Typography>
                <Grid size={12}>
                    <TextField
                        style={{ width: "100%"}} 
                        id="def_type"
                        select
                        //label="Select Type"
                        variant="outlined"
                        value={defaultType}
                        onChange={onDefaultTypeChanged}
                        slotProps={{style: {fontSize: 13}}}
                        error={errorNo === 1}
                        helperText={errorNo === 1 ? errorMsg : ''}
                        size='small'
                    >
                        <MenuItem value='none'>None</MenuItem>
                        {
                            defaultTypeArr && defaultTypeArr.map((op) => {
                            return  <MenuItem key={op.key} value={op.value}>
                                        {op.key}
                                    </MenuItem>
                            })
                        }
                    </TextField>    
                </Grid>

                {defaultType === 'text' &&
                <Grid size={12}>
                    <Typography variant="subtitle1" style={{marginTop:"8px"}}>
                            Default value
                    </Typography>
                    <TextField
                        style={{ width: "100%"}} 
                        id="def_value"
                        select
                        //label="Default Value"
                        variant="outlined"
                        value={defaultValue}
                        onChange={onDefaultValueChanged}
                        slotProps={{style: {fontSize: 13}}}
                        size='small'
                    >
                        <MenuItem value='none'>None</MenuItem>
                        {
                            defaultValueArr && defaultValueArr.map((op) => {
                            return  <MenuItem key={op.key} value={op.value}>
                                        {op.key}
                                    </MenuItem>
                            })
                        }
                    </TextField> 
                </Grid>   
                }
            
                {defaultType === 'text' &&
                <Grid size={12}
                    style={{display:'flex', alignItems:'center', margin:"8px 0px 2px -10px"}}>
                    <Checkbox
                        onChange={(e) => onShowInvoiceClicked(e)}
                        checked={showInvoice}
                        name="invoiceCheck"
                        color="primary"
                        style={{color:"#ccc !important"}}
                        // classes={{
                        //     colorPrimary: classes.MuiCheckboxColorPrimary,
                        //     checked: classes.Muichecked
                        // }}
                    />
                    <Typography variant='subtitle1' sx={{position:'relative', left:'-4px', top:'1px'}}>
                        Show on invoice
                    </Typography>
                    
                </Grid>}

                <div className="modal__footer">
                    <Button variant="contained" color="primary" 
                        onClick={onCreateOrUpdateCustomField}
                        disabled={loading}
                        className="modal__button">
                        {loading && <CircularProgress size={15} className='loading__style'/>}
                        Save    
                    </Button>
                    <Button variant="outlined" style={{
                        minWidth: '130px',
                    }} onClick={()=>onCancelClick()}>
                        Cancel
                    </Button>
                </div> 
            </Grid>

        </RightToLeftSideDrawer>
        );
    }

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    user: state.invoiceAccounts.user
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
    logout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldDrawer);