import React, {useState} from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {BaseEditor} from './BaseEditor';
import BaseDrawer from './BaseDrawer';

const CustomDrawer = ({itemObj, drawerOpen, handleDrawerClose, callUpdateSectionApi, apiLoading, setDirtyFlag}) => {

    const {data} = itemObj;
    const {text, title} = data;

    const [introTitle, setIntroTitle] = useState(title ? title : "");
    const [introDescp, setIntroDescp] = useState(text ? text : "");

    const setUpdatedItemObj = () => {
        const dataObj = {
            text: introDescp,
            title: introTitle,
        };
        const updated_obj = {
            ...itemObj,
            data: {...dataObj}
        }

        return updated_obj;
    }

    const onSaveClick = () => {
        callUpdateSectionApi(itemObj, setUpdatedItemObj());
    }

    const handleTitleChange = (event) => {
        setIntroTitle(event.target.value);
        setDirtyFlag(true);
    };

    const handleDescriptionChange = (value) => {
        setIntroDescp(value);
        setDirtyFlag(true);
    };

    const onDrawerClose = () => {
        handleDrawerClose(setUpdatedItemObj());
    }

    return (
        <BaseDrawer open={drawerOpen} 
            onClose={onDrawerClose}
            title='Custom'
        >
            <div className='drawer_content_container'>

                <Grid size={12}>
                    <Typography className={'drawer_label_style'}>
                        Page Title
                    </Typography>

                    <TextField 
                        className={'drawer_textfield_margintop'}
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        value={introTitle}
                        onChange={handleTitleChange}
                        size='small'
                    
                    />
                </Grid>

                <Grid size={12}>

                    <Typography className={'drawer_label_style'}>
                        Description
                    </Typography>

                    <BaseEditor editorValue={introDescp}
                        setEditorValue={(value) => handleDescriptionChange(value)}/>
                </Grid>

            </div>

            <div className={'drawer_footer'}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid>
                        <Button 
                            onClick={onSaveClick}
                            className={`next_btn_style drawer_btn_width`}>
                            {apiLoading && <CircularProgress size={15} className='drawer_loading_margin'/>} Save
                        </Button>
                    </Grid>

                    <Grid>
                        <Button variant='outlined' color='primary'
                            onClick={onDrawerClose}
                            className={'drawer_btn_width'}>
                            Cancel 
                        </Button>
                    </Grid>
                </Grid>
            </div>

    </BaseDrawer>
    );
}

export default CustomDrawer;