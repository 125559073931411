import React, { useState, memo, useCallback } from 'react';
import TextValueComponent from './TextValueComponent';
import SignatureValueComponent from './SignatureValueComponent';
import SignatureModalComponent from './SignatureModalComponent';
import DateValueComponent from './DateValueComponent';
import { DroppedItemTypes } from '../../util/AppUtil';

const PdfDroppedItems = memo(({ droppedItems, setDroppedItems, accountMembersList, loggedInUser }) => {
    
    const [openSignDialog, setOpenSignDialog] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const handleSignDialogOpen = (index) => {
        setOpenSignDialog(true);
        setSelectedIndex(index);
    }

    const handleSignDialogClose = () => {
        setOpenSignDialog(false);
        setSelectedIndex(null);
    }

    // Render unique items based on component_label while preserving their original index
    const renderFormElements = useCallback(() => {
        const seenLabels = new Set();
        return droppedItems.map((element, index) => {
            const isUnique = element.component_label && !seenLabels.has(`${element.component_type}_${element.component_label}`);
            if (isUnique) {
                seenLabels.add(`${element.component_type}_${element.component_label}`);
                switch (element.component_type) {
                    case DroppedItemTypes.TEXT:
                        return (
                            <TextValueComponent 
                                key={element.id} 
                                element={element} 
                                index={index} 
                                droppedItems={droppedItems} 
                                setDroppedItems={setDroppedItems} />
                        );
                    case DroppedItemTypes.SIGNATURE:
                        return (
                            <SignatureValueComponent 
                                key={element.id} 
                                element={element} 
                                index={index} 
                                droppedItems={droppedItems} 
                                setDroppedItems={setDroppedItems} 
                                handleSignDialogOpen={handleSignDialogOpen} 
                                accountMembersList={accountMembersList} 
                                loggedInUser={loggedInUser} />
                        );
                    case DroppedItemTypes.DATE:
                        return (
                            <DateValueComponent 
                                key={element.id} 
                                element={element} 
                                index={index} 
                                droppedItems={droppedItems} 
                                setDroppedItems={setDroppedItems} />
                        );
                    default:
                        return null;
                }
            }
            return null;
        }).filter(Boolean);
    }, [droppedItems]);

    return (
        <div className="form_container">
            {renderFormElements()}

            {openSignDialog &&
                <SignatureModalComponent 
                    openSignDialog={openSignDialog}
                    handleSignDialogClose={handleSignDialogClose}
                    droppedItems={droppedItems}
                    setDroppedItems={setDroppedItems}
                    selectedIndex={selectedIndex}
                    fromDocumentValue={true}
                />
            }
        </div>
    );
});

export default PdfDroppedItems;
