import React, { useEffect, useLayoutEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';

const MuiDialogpaper = {
    '& .MuiDialog-paper' : {
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    }
};

const dialogTitle = {
    borderBottom:'1px solid #ccc', 
    padding:'14px 16px', 
    fontSize:'16px',
    fontWeight:'600',
}

const DocumentNameModal = ({openDocumentNameModal, handleDocumentNameModalClose, documentName, setDocumentName, callApi, templateObj, documentObj, 
    callTemplateApi, setCallTemplateApi, predefinedTemplateObj, callPredefinedTemplateApi, setCallPredefinedTemplateApi, ...props}) => {

    useEffect(() => {
        if(callPredefinedTemplateApi) {
            setDocumentName(predefinedTemplateObj?.name || '');
        } else if(callTemplateApi) {
            setDocumentName(templateObj?.name || '');
        } else {
            setDocumentName(documentObj?.name || '')
        }
    }, [callTemplateApi])

    const onCancelClick = () => {
        handleDocumentNameModalClose();
        callTemplateApi && setCallTemplateApi(false);
        callPredefinedTemplateApi && setCallPredefinedTemplateApi(false);
    }   

    const onSaveClick = () => {
        callApi();
        handleDocumentNameModalClose();
    }

    return (
        <Dialog
                open={openDocumentNameModal}
                //TransitionComponent={Transition}
                scroll="paper"
                sx={MuiDialogpaper}
                maxWidth="xs"
                fullWidth
                style={{scrollbarWidth:'none !important'}}
                onClose={handleDocumentNameModalClose}>

                    <Grid container justifyContent='space-between' alignItems='center' sx={dialogTitle}>

                        <Grid>        
                            <Typography style={{fontWeight:600}}>
                                <span style={{marginLeft:'9px'}}>
                                    {(callTemplateApi || callPredefinedTemplateApi) ? `Set Template Name` : `Set Document Name` }
                                </span>
                            </Typography>
                        </Grid>

                        <Grid>
                            <ClearIcon fontSize='small' onClick={onCancelClick}
                            style={{cursor:'pointer', verticalAlign:'inherit'}}/>
                        </Grid>

                    </Grid>

                    <Grid size={12} style={{padding:'32px 16px'}}>
                        <Typography variant='body2'>
                            {(callTemplateApi || callPredefinedTemplateApi) ? 'Template Name' : 'Document Name'}
                        </Typography>

                        <TextField fullWidth
                            variant='outlined'
                            value={documentName}
                            onChange={(e) => setDocumentName(e.target.value)}
                            margin='dense'
                            placeholder='Enter document name'
                            sx={{marginTop:'2px'}}
                            size='small'
                        />

                    </Grid>
                    
                    
                    {
                        
                        <Grid container justifyContent='center' spacing={1} sx={{marginBottom:'32px'}}>
                            <Grid>
                                <Button variant='contained' color='primary' 
                                    onClick={onSaveClick}
                                >
                                    Save
                                </Button>
                            </Grid>

                            <Grid>
                                <Button variant='outlined' color='primary' 
                                    onClick={onCancelClick}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    }
                
            </Dialog>
    );
}

export default DocumentNameModal;