import React from 'react';
import Grid from '@mui/material/Grid2';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import moment from 'moment';
import { enableDisableEmailReminderApi } from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';

const EnableDisableReminders = (props) => {
    const {enqueueSnackbar} = useSnackbar();

    const handleSendReminderChange = async(e) => {
        const value = e.target.checked;
        const flag = (value || props.reminderObj?.execution_date === null) ? 'enable' : 'disable';

        try {
            const response = await enableDisableEmailReminderApi(props.invoiceAccountId, props.invoiceObj?.entity.id, props.invoiceObj?.client.id, props.invoiceId, flag);
            const res = response.data;
            consoleToLog("Response enableDisableEmailReminderApi", res);
            enqueueSnackbar(`Reminders ${flag} successfully`, {variant:'success'});

            const invoiceObj = {
                ...props.invoiceObj,
                invoice_reminders: res
            }
            props.updateInvoiceFuncObj(invoiceObj);
            props.setUpdatedInvoiceObj(invoiceObj);

        } catch (e) {
            consoleToLog("Error enableDisableEmailReminderApi", e);
            e.response && e.response.data.message && enqueueSnackbar(e.response.data.message, {variant:'error'});
        }
            
    }

    return (
        <Grid container alignItems='center' 
                className="save_invoice"
                sx={{marginTop:'16px'}}
            >
                    <Grid container direction="column" sx={{position:'relative'}}>
                        
                        <Typography className='font_styles1'>
                            Reminders
                        </Typography>

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.reminderObj?.active && props.reminderObj.execution_date !== null}
                                    onChange={handleSendReminderChange}
                                    color="primary"
                                    name="checkedB"
                                    slotProps={{ input: {'aria-label': 'primary checkbox'} }}
                                />
                            }
                            label={props.reminderObj?.active && props.reminderObj.execution_date !== null ? 'Disable Reminder' : 'Enable Reminder'}
                        />
                        
                        {
                            props.reminderObj?.active  && props.reminderObj.execution_date !== null &&
                            <Typography variant='subtitle1' sx={{marginTop:'8px'}}>
                                <span style={{color:'#4a5568'}}>Next Reminder:</span>
                                {moment(props.reminderObj?.execution_date).format('DD-MMM-YYYY')}
                            </Typography>
                        }

                    </Grid> 

            </Grid>
    );
}

export default EnableDisableReminders;