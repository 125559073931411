import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import {updateProposalTitleApi} from '../../../services/proposalService';
import { consoleToLog } from '../../../util/AppUtil';
import { useSnackbar } from 'notistack';

const UpdateProposalTitle = ({ proposalObj, setProposalObj, invoice_account_id, fromPreviewProposal }) => {

    const {enqueueSnackbar} = useSnackbar();

    const [editTitle, setEditTitle] = useState(false);
    const [title, setTitle] = useState(proposalObj.name);
    const [loading, setLoading] = useState(false);

    const onEditTitleClick = () => {
        setEditTitle(true);
    }

    const onResetTitleClick = () => {
        setTitle(proposalObj.name);
        setEditTitle(false);
    }

    const onSaveTitleClick = async() => {

        setLoading(true);

        try {
            const response = await updateProposalTitleApi(invoice_account_id, proposalObj?.id, title);
            const res = response.data;
            consoleToLog("Response updateProposalTitleApi: ", res);
            enqueueSnackbar(res.message, {variant:'success'});
            setLoading(false);

            setEditTitle(false);
            let obj = {
                ...proposalObj,
                name: res.proposal_name
            }
            setProposalObj(obj);
        } catch(e) {
            consoleToLog("Error updateProposalTitleApi: ", e);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
            setLoading(false);
        }
    }

    return (
        <div>
            {
                !editTitle 
                ?
                <Grid container alignItems='center' spacing={2}>
                    <Grid>
                        <Typography className='prop_title'>
                            {title}
                        </Typography>
                    </Grid>

                    {!fromPreviewProposal && 
                    <Grid>
                        <IconButton onClick={onEditTitleClick}>
                            <img src='/images/stylus.svg'/>
                        </IconButton>
                    </Grid>}
                </Grid>
                :
                !fromPreviewProposal &&
                <Grid container alignItems='center' spacing={2}>
                    <Grid>
                        <TextField fullWidth
                            variant='outlined'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            margin='dense'
                            sxe={{width: '250px'}}
                            size='small'
                        />
                    </Grid>
                    
                    <Grid>
                        <Grid container alignItems='center'>
                            <Grid size={5}>
                                {loading 
                                ?
                                <CircularProgress size={15} style={{marginRight:'8px'}}/>
                                :
                                <IconButton onClick={onSaveTitleClick}>
                                    <img src='/images/check.svg'/>
                                </IconButton>}
                            </Grid>

                            <Grid size={5}>
                                <IconButton onClick={onResetTitleClick}>
                                    <img src='/images/close.svg'/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </div>
    );
}

export default UpdateProposalTitle;