import React, { useEffect, useState } from 'react';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';
import { logout } from '../actions/invoiceAuth';
import { consoleToLog } from '../util/AppUtil';
import { getActivityFeedsApi } from '../services/authService';
import ActivityFeed from './ActivityFeed';


const AllActivityFeedDrawer = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [activityFeeds, setActivityFeed]=useState([]);

    useEffect(() => {
        getActivityFeeds();
    }, []);
   
     const getActivityFeeds = () => {
        const invoice_account_id = props.selectedAccount?.id
        const id = props.obj?.id;
        getActivityFeedsApi(invoice_account_id, id, props.type)  
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updatePaymentTransactionApi: ', res);
                setActivityFeed(res);
            })
            .catch((e) => {
                consoleToLog('Error updatePaymentTransactionApi: ', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });
    }

    return (
            <RightToLeftSideDrawer title={"Activities" }
                openDrawer={props.openActivityDrawer}
                closeDrawer={props.handleCloseActivityDrawer}>

                <div className='modal__main_activity'>
                    {activityFeeds && 
                        activityFeeds.length>0 
                        && <ActivityFeed activityFeeds={activityFeeds} 
                                hideShowActivities={true}/>
                    }
                    
                </div>    
            </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AllActivityFeedDrawer);