import React from 'react';
import BaseSection from './BaseSection';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { ReadOnlyEditor } from './BaseEditor';

const ThanksMessage = ({item, handleDrawerOpen, onShowOrHidePage}) => {

    const {data} = item;
    const {title, text} = data;

    return (
        <BaseSection title="Thanks Message" item={item}
            handleDrawerOpen={handleDrawerOpen}
            onShowOrHidePage={onShowOrHidePage}>

                <Grid size={12} className='edit_section_container'>

                    <Grid size={12}>
                        <Typography className='edit_section_heading' 
                            dangerouslySetInnerHTML={{__html: title}} />
                    </Grid>

                    <Grid size={12}>
                        <ReadOnlyEditor editorValue={text}/>
                    </Grid>
                    
                    
                </Grid>

        </BaseSection>
    );
};

export default ThanksMessage;
