import React, {useState, memo, useEffect} from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import { formatAmount } from '../../util/currencyUtil';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { useIsMount } from '../useIsMount';
import { PeriodicityArr, CreateItemButton } from '../../util/AppUtil';
import Box from '@mui/material/Box';

const MuiAutocompleteInputRoot = {
    position: 'relative',
    '& .MuiOutlinedInput-root': {
        padding: '0px 3px',
        borderRadius: '4px',
        background: '#fff',
        '& .MuiAutocomplete-input': {
            padding: '7.5px 4px'
        }
    }
};

const customTextField = {
    "& input::placeholder": {
        paddingLeft: "8px",
    }
};

const itemOptionStyle = {
    width: "100%",
    height: '100%',
    color: '#777f8e',
    margin: '5px 0px'
};

const MuiTextFieldRoot = {
    background: '#fff',
    '& .MuiOutlinedInput-input': {
        paddingTop: '7px',
        paddingBottom: '7px'
    }
};

const checkIcon = {
    fontSize: '21px',
    color: '#00B13F'
};

const editIcon = {
    fontSize: '21px',
    color: "#555",
};

const deleteIcon = {
    fontSize: '21px',
    color: "red",
};

const MuiAutocompletePopper = {
    width: '250px !important',
};

const actionItems = {
    display: 'flex',
    alignItems: 'center'
};

const TableItemRow = memo(({item, index, onSave, onDelete, onEdit, isEditing, invoiceItems, currenciesObj, quantityUnitTerminology, 
    selectedCurrency, fromBillingsDrawer, show_quantity, onCancelClick, setEditingIndex, handleAddEditItemDrawerOpen, ...props}) => {
    const isMount = useIsMount();
    
    const [editedItem, setEditedItem] = useState(item);

    useEffect(() => {
        if(!isMount) {
            setEditedItem(item);
        }
    }, [item])

    const {errorType, setErrorType, showQuantity} = props;

    const  getSelectedItem = () => {
        const itemObj = invoiceItems?.find((item) => {
            if(item.title === editedItem.name) return item
        });
        //console.log('**********item', itemObj);

        return itemObj || {};
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updatedItem = { ...editedItem, [name]: value };
        if (name === 'quantity' || name === 'price') {
            updatedItem.amount = parseFloat(updatedItem.quantity) * parseFloat(updatedItem.price);
            updatedItem.price = parseFloat(updatedItem.price);
        }
        setEditedItem(updatedItem);
        setErrorType(0);
    };

    const onItemClick = (option, index) => {
        if(option) {
            const lineItem = {...editedItem};
            
            lineItem.item_id = option.id;
            lineItem.name = option.title;
            lineItem.price = parseFloat(option.cost);
            lineItem.quantity = parseFloat(1);
            lineItem.amount = parseFloat(option.cost * lineItem.quantity);
            setErrorType(0);
            setEditedItem(lineItem); 
        }
    }

    const onItemClearClick = (index) => {
        const lineItem = {...editedItem};
        lineItem.item_id = '';
        lineItem.name = '';
        lineItem.price = '';
        lineItem.amount = '';
        setEditedItem(lineItem); 
    }

    const onSaveClick = (index, editedItem) => {
        if (editedItem.name === '') {
            setErrorType(3);
            return;
        } else if (editedItem.quantity === '') {
            setErrorType(4);
            return;
        } else if (editedItem.price === '') {
            setErrorType(5);
            return;
        } else if (editedItem.amount === '') {
            setErrorType(6);
            return;
        } else if (editedItem.periodicity === '') {
            setErrorType(7);
            return;
        } else {
            setErrorType(0);
            onSave(index, editedItem)
        }
    }

    const handleCancel = () => {
        setEditedItem({...item});
        setEditingIndex(null);
        setErrorType(0);
        onCancelClick();
    };


    const renderOptions = (props, option, index) => {
        return (
            <Grid size={12} sx={itemOptionStyle} {...props}>
                <Grid container alignItems='center' style={{flexWrap:'wrap'}}>
                    <Grid size={9} >
                        <Typography variant='body2' >
                            <span style={{color:"#333"}}>{option.title}</span>
                            {option.tax && option.tax.length > 0 &&
                            <Typography style={{fontSize:'12px', maxWidth:'80%'}}>
                                {option.tax.map((taxItem) => `${taxItem.name} ${taxItem.rate}%`).join(', ')}
                            </Typography>}
                        </Typography>
                    </Grid> 
                    
                    <Grid size={2}>
                        <Typography variant='body2'>
                            {formatAmount(option.cost, currenciesObj)}
                        </Typography>
                    </Grid>
                </Grid>    

            </Grid>
            );
    }

    return (
        <TableRow key={index}>
            {isEditing ? (
                <>
                <TableCell className={`table_cell_style item_name_width`}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={invoiceItems}
                        name="name"
                        value={getSelectedItem()}
                        getOptionLabel={(option) => option.title || ''}
                        //PaperComponent={(props) => <CreateItemButton {...props} text='Add Item' onClickFunction={handleAddEditItemDrawerOpen} />}
                        slotProps={{
                            paper: <CreateItemButton {...props} text='Add Item' onClickFunction={handleAddEditItemDrawerOpen} />
                        }}
                        renderOption={(props, option) => renderOptions(props, option, index)}
                        onChange={(e, newValue) => {
                            onItemClick(newValue, index)
                        }}
                        renderInput={params => (
                            <TextField {...params} variant="outlined"
                                placeholder='Search items'
                                fullWidth
                                error={errorType === 3}
                                size='small'
                            />
                        )}
                        clearIcon={<CloseIcon fontSize='small' onClick={() => onItemClearClick(index)} />}
                        error={errorType === 3}
                        sx={{'& .MuiOutlinedInput-root.MuiInputBase-sizeSmall' : {padding: '4px'}}}
                        size='small'
                    />
                </TableCell>
                <TableCell className={`table_cell_style quantity_width`}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        name="quantity"
                        value={editedItem.quantity}
                        onChange={handleChange}
                        placeholder="quantity"
                        type="number"
                        error={errorType === 4}
                        disabled={fromBillingsDrawer ? !show_quantity : !showQuantity}
                        sx={MuiTextFieldRoot}
                        size='small'
                    />
                </TableCell>
                <TableCell className={`table_cell_style price_width`}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        name="price"
                        value={editedItem.price}
                        onChange={handleChange}
                        placeholder="price"
                        type="number"
                        sx={MuiTextFieldRoot}
                        size='small'
                        error={errorType === 5}
                    />
                </TableCell>
                <TableCell className={`table_cell_style price_width`}>
                    <TextField
                        fullWidth
                        variant='outlined'
                        name="amount"
                        value={editedItem.amount}
                        onChange={handleChange}
                        placeholder={`amount`}
                        type="number"
                        sx={MuiTextFieldRoot}
                        size='small'
                        error={errorType === 6}
                    />
                </TableCell>
                <TableCell className={`table_cell_style periodicity_width`}>
                    <TextField
                        select
                        fullWidth
                        variant='outlined'
                        name="periodicity"
                        value={editedItem.periodicity}
                        onChange={handleChange}
                        placeholder="Select periodicity"
                        sx={MuiTextFieldRoot}
                        size='small'
                        error={errorType === 7}
                    >
                        {
                            PeriodicityArr.map((option) => {
                                return <MenuItem key={option?.value} value={option.value}>
                                            {option.name}
                                        </MenuItem>
                            })
                        }

                    </TextField>
                </TableCell>
                <TableCell className={`table_cell_style action_items`}>
                    <Box component='div' sx={actionItems}>
                        <div>
                            <Tooltip title={'Save'} arrow>
                                <IconButton  className={'add_item_icon_btn'}
                                    onClick={() => onSaveClick(index, editedItem)}>
                                    <CheckIcon sx={checkIcon}/>
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div>
                            <Tooltip title={'Cancel'} arrow>
                                <IconButton onClick={() => handleCancel()} 
                                    className={'add_item_icon_btn'}>
                                    <ClearIcon sx={deleteIcon}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Box>
                </TableCell>
                </>
            ) : (
                <>
                <TableCell className={`table_cell_style1 item_name_width`}>
                    <Typography className='add_item_table_descp'>
                        {item.name}
                    </Typography>
                </TableCell>
                <TableCell className={`table_cell_style1 quantity_width`}>
                    <Typography className={`add_item_table_descp ${(fromBillingsDrawer && !show_quantity || !fromBillingsDrawer && !showQuantity) ? 'hide_page_style' : ''}`}>
                        {item.quantity}
                    </Typography>
                </TableCell>
                <TableCell className={`table_cell_style1 price_width`}>
                    <Typography className='add_item_table_descp'>
                        <strong>{formatAmount(item.price, selectedCurrency)}</strong>
                    </Typography>
                </TableCell>
                <TableCell className={`table_cell_style1 price_width`}>
                    <Typography className='add_item_table_descp'>
                        <strong>{formatAmount(item.amount, selectedCurrency)}</strong>
                    </Typography>
                </TableCell>
                <TableCell className={`table_cell_style1 periodicity_width`}>
                    <Typography className='add_item_table_descp'>
                        {PeriodicityArr.find(period => period.value === item.periodicity)?.name}
                    </Typography>
                </TableCell>
                <TableCell className={`table_cell_style1 table_cell_style action_items`}>
                    <Box component='div' sx={actionItems}>
                        <div>
                            <Tooltip title={'Edit'} arrow>
                                <IconButton size='small' onClick={() => onEdit(index)} 
                                    className={'add_item_icon_btn'}>
                                    <EditOutlinedIcon sx={editIcon}/>
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div>
                            <Tooltip title={'Delete'} arrow>
                                <IconButton size='small' onClick={() => onDelete(index)} 
                                    className={'add_item_icon_btn'}>
                                    <img src='/images/drawer_delete.svg' />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Box>
                </TableCell>
                </>
            )}
        </TableRow>
    );
})

export default memo(TableItemRow);
