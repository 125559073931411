import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Button from '@mui/material/Button';
import moment from "moment";
import { useSnackbar } from "notistack";

const btnStyle = {
    width: "242.06px", 
    fontWeight: 400, 
    boxShadow: "none !important", 
    color: "#000",  
    borderRadius: "0", 
    justifyContent: "left", 
    backgroundColor: "#FFF",
    padding: "6px 12px !important"
};

const calenderStyle = {
    border: 'none !important', 
    borderTop: '1px solid #aeaeae !important'
};

const Calender = (props) => {

    const {enqueueSnackbar} = useSnackbar(); 

    const onChange = (dates) => {
        console.log('date*********', props.issueDate);
        if(moment(dates).format('YYYY-MM-DD') < props.issueDate) {   
            enqueueSnackbar("Due date cannot be before Issue Date", {variant:'error'});
            return;
        } else {
            props.setDueDate(dates);
            props.handleDueDatePopoverClose();
        }
    };

    //console.log('********dueDate', props.dueDate);

  return (
    <div>
        <div>
        <Button sx={{...btnStyle, fontSize:'13px'}}
            variant="contained"
            onClick={()=>{
                const immediateDate = moment(props.issueDate).add(0,'days').toDate();
                props.setDueDate(immediateDate);
                props.handleDueDatePopoverClose();
            }}
        > 
            <img alt="edit" src="/images/tomorrow.svg" style={{marginRight:"6px"}}/>  
            Immediate
            {/* <span style={{ marginLeft:'auto', color: "gray", fontSize: "13px"}}>{todayDay}</span> */}
        </Button>
        </div>
        <div>
        <Button sx={{...btnStyle, fontSize:'13px'}}
            variant="contained"
            onClick={()=>{
                const after7Days = moment(props.issueDate).add(7,'days').toDate();
                props.setDueDate(after7Days);
                props.handleDueDatePopoverClose();
            }}
        >
            <img alt="edit" src="/images/today.svg" style={{marginRight:"6px"}}/>
            After 7 Days
            {/* <span style={{ marginLeft:'auto', color: "gray", fontSize: "13px"}}>{tomorrowDay}</span> */}
        </Button>
        </div>
        <div>
        <Button sx={{...btnStyle, fontSize:'13px'}}
            variant="contained"
            onClick={()=>{
                const after14Days = moment(props.issueDate).add(14,'days').toDate();
                props.setDueDate(after14Days);
                props.handleDueDatePopoverClose();
            }}
        >
            <img alt="edit" src="/images/today.svg" style={{marginRight:"6px"}}/>
            After 14 Days
            {/* <span style={{ marginLeft:'auto', color: "gray", fontSize: "13px"}}>{nextMonday}</span> */}
        </Button>
        </div>
        <div style={{borderBottom:'1px solid #aeaeae'}}>
        <Button sx={{...btnStyle, fontSize:'13px'}}
            variant="contained"
            onClick={()=>{
                const after30Days = moment(props.issueDate).add(30,'days').toDate();
                props.setDueDate(after30Days);
                props.handleDueDatePopoverClose();
            }}
        >
            <img alt="edit" src="/images/nextweek.svg" style={{marginRight:"6px"}}/>
            After 30 Days
        </Button>
        </div>
        <DatePicker
            selected={moment(props.dueDate).toDate()}
            onChange={onChange}
            //selectsRange
            inline
            
        />
    </div>
  );
}

export default Calender;