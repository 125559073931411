import React, {useEffect} from 'react';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {connect} from 'react-redux';  
import { useIsMount } from './useIsMount';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
import { trackGAEvent, shouldDisable, AccessLevel } from '../util/AppUtil';
import {AppRoutes} from '../util/AppUtil';
import { useLocation , useParams} from 'react-router-dom';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { clearSelectedTransactionFilters } from '../actions/transactionFilters';
import { setHeightForComponent } from '../util/AppUtil';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import CurrencyBitcoinOutlinedIcon from '@mui/icons-material/CurrencyBitcoinOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import { useNavigate } from 'react-router-dom';

const labels = {
    display:'flex',
    alignItems:'center',
    padding:'10px 18px',
    marginTop: '5px',
    marginBottom: '10px',
    cursor:'pointer'
}
    
const labels_left = {
    display:'flex',
    alignItems:'center',
    padding:'10px 18px 10px 35px',
    marginTop: '5px',
    marginBottom: '8px',
    cursor:'pointer'
}
    
const text = {
    color:'#202020',
    fontWeight: 600,
}
    
const listitem = {
    fontSize: '15px',
    fontWeight: 400,
    color:'#202020',
    '&:first-child' : {
        marginTop:'-8px'
    },
    '&:selected' : {
        background:'transparent'
    }    
}

const onHover = {
    '& .settingsIcon': {
        visibility:'hidden'
    },
    '&:hover' : {
        '& .settingsIcon' : {
            visibility:'visible'
        }
    }
}

const estimateSettingsIcon = {
    fontSize:'19px',
    color:'#555',
    lineHeight: 0
}

const materialIconsSettings = {
    fontSize:'23px',
    color:'#555',
    marginRight:'5px'
}

const materialIconsSettings1 = {
    fontSize:'21px',
    color:'#555',
    marginRight:'5px'
}
    
const menuSectionHeader = { 
    marginTop:'20px', 
    paddingLeft: "16px", 
    fontSize: '12px', 
    color: '#366171' 
}

const LeftMenuComponent = (props) => {
    const isMount = useIsMount();
    const location = useLocation();
    const pathname = location?.pathname;
    const navigate = useNavigate();

    useEffect(() => {
        switch (pathname) {
            case AppRoutes.DASHBOARD:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
                break;
            case AppRoutes.INVOICES:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_INVOICES);
                break;
            case AppRoutes.TRANSACTIONS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_TRANSACTIONS);
                break;
            case AppRoutes.CLIENTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_CLIENTS);
                break;
            case AppRoutes.ITEMS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_ITEMS);
                break;
            case AppRoutes.MEMBERS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_MEMBERS);
                break;
            case AppRoutes.SETTINGS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
                break;
            case AppRoutes.PROFILE:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_PROFILE);
                break;
            case AppRoutes.ONBOARDING:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_ONBOARDING);
                break;
            case AppRoutes.CLIENT_REPORTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_CLIENT_REPORTS);
                break;
            case AppRoutes.ITEM_REPORTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_ITEM_REPORTS);
                break;
            case AppRoutes.ESTIMATES:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_ESTIMATES);
                break;
            case AppRoutes.PROPOSALS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_PROPOSALS);
                break;
            case AppRoutes.VENDORS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_VENDORS);
                break;
            case AppRoutes.BILL_CATEGORIES:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_BILL_CATEGORIES);
                break;
            case AppRoutes.DOCUMENTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_DOCUMENTS);
                break;
            case AppRoutes.BILLS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_BILLS);
                break;
            case AppRoutes.PAYMENTS:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_PAYMENTS);
                break;
            case AppRoutes.EXPENSES:
                props.setSelectedDrawerState(SelectedDrawerState.SHOW_EXPENSES);
                break;
            // Add other cases as needed
            default:
                break;
        }
}, [pathname])

    const proformaEnabled = props.selectedAccount.general_settings?.proforma_enabled;
    const enableTDS = props.selectedAccount?.enable_tds;
    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const accessLevelOwner = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.OWNER) ? true : false;

    const clearAllStoredState = () => {
        props.invoicesComponentState && props.setInvoiceComponentState();
        props.transactionsComponentState && props.setTransactionComponentState();
        props.estimateComponentState && props.setEstimateComponentState();
        props.proposalsComponentState && props.setProposalsComponentState();
        props.documentsComponentState && props.setDocumentsComponentState();
        props.billComponentState && props.setBillComponentState();
        props.expensesComponentState && props.setExpensesComponentState();
    }   

    const onDashboardTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Dashboard tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.DASHBOARD);
    }

    const onInvoicesTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Invoice tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.INVOICES);
        props.clearSelectedInvoiceFilters();
    }

    const onBillTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Bills tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.BILLS);
        props.clearSelectedInvoiceFilters();
    }

    const onPaymentsClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Payments tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.PAYMENTS);
    }

    const onEstimatesTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Estimates tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.ESTIMATES);
        props.clearSelectedInvoiceFilters();
    }

    const onTransactionsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Transactions tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        props.clearSelectedTransactionFilters();
        clearAllStoredState();
        navigate(AppRoutes.TRANSACTIONS);
    }

    const onSettingsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Settings tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.SETTINGS);
    }

    const onClientsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Clients tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.CLIENTS);
    }

    const onVendorsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Vendors tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.VENDORS);
    }

    const onItemsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Items tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.ITEMS);
    }
    const onBillCategoriesTabClick=()=>{
        trackGAEvent(props.selectedAccount.name, 'Bill categories tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.BILL_CATEGORIES);
    }

    const onAccountMemberClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Members tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.MEMBERS);
    }

    const onOnboardingTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Onboarding tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.ONBOARDING);
    }

    const onRevenueByClientClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Client Reports tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.CLIENT_REPORTS);
    }

    const onRevenueByItemsClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Item Reports tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.ITEM_REPORTS);
    }

    const onEstimatesSettingsIconClick = (e) => {
        e.stopPropagation();
        props.handleEstimatesSettingsModalOpen();
    }

    const onProposalsTabClick = () => {
        trackGAEvent(props.selectedAccount.name, 'Proposals tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.PROPOSALS);
    }

    const onEsignClick = () => {
        trackGAEvent(props.selectedAccount.name, 'E-Sign tab clicked', `${props.user.firstname} ${props.user.lastname}`);
        clearAllStoredState();
        navigate(AppRoutes.DOCUMENTS);
    }

    // const onExpensesTabClick = () => {
    //     trackGAEvent(props.selectedAccount.name, 'Expenses tab clicked', `${props.user.firstname} ${props.user.lastname}`);
    //     clearAllStoredState();
    //     navigate(AppRoutes.EXPENSES);
    //     props.clearSelectedInvoiceFilters();
    // }

    return (
        <div className='left_menu'
                style={{height:setHeightForComponent(props.isInvoiceAccountPlanFree, props.entityLimitExceeded)}}
                >
                {accessLevelOwner && props.selectedAccount.onboarding &&
                <MenuItem sx={labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_ONBOARDING}   
                    onClick={onOnboardingTabClick}
                >
                    <HandshakeOutlinedIcon sx={materialIconsSettings} />
                    <Typography variant="body1" sx={text}>Onboarding</Typography>
                </MenuItem>}
                <MenuItem sx={labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_DASHBOARD}   
                    onClick={onDashboardTabClick}
                >
                    <DashboardOutlinedIcon sx={materialIconsSettings} />
                    <Typography variant="body1" sx={text}>Dashboard</Typography>
                </MenuItem>
                <Typography variant="body1" 
                    sx={menuSectionHeader}>
                    Receivables
                </Typography>
                <MenuItem sx={labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_INVOICES}   
                    onClick={onInvoicesTabClick}
                >
                    {<LocalAtmOutlinedIcon sx={materialIconsSettings} /> }
                    <Typography variant="body1" sx={text} >Invoices</Typography>
                </MenuItem>
                <MenuItem sx={{...labels_left, ...onHover}}
                    selected={props.drawerState === SelectedDrawerState.SHOW_ESTIMATES}   
                    onClick={onEstimatesTabClick}
                >
                    <FeedOutlinedIcon sx={materialIconsSettings}/>
                    <Typography variant="body1" sx={text}>{props.selectedAccount.estimate_terminology}</Typography>
                    {accessLevelOwner &&
                    <Button color='primary' className={'settingsIcon'} style={{marginLeft:'auto', minWidth:'20px', padding:0}}
                        onClick={onEstimatesSettingsIconClick}>
                        <SettingsOutlinedIcon sx={estimateSettingsIcon} />
                    </Button>}
                        
                </MenuItem>
                <MenuItem sx={labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_TRANSACTIONS}   
                    onClick={onTransactionsTabClick}
                >
                    <ReceiptLongOutlinedIcon sx={materialIconsSettings}/>
                    <Typography variant="body1" sx={text}>Receipts</Typography>  
                </MenuItem>
                <MenuItem sx={labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_CLIENTS}   
                    onClick={onClientsTabClick}
                >
                    <CorporateFareOutlinedIcon sx={materialIconsSettings}/>
                    <Typography variant="body1" sx={text}>Clients</Typography>  
                </MenuItem>
                <MenuItem sx={labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_ITEMS}   
                    onClick={onItemsTabClick}
                >
                    <ListOutlinedIcon sx={materialIconsSettings}/>
                    <Typography variant="body1" sx={text}>Items</Typography>  
                </MenuItem>

                <Typography variant="body1" 
                    sx={menuSectionHeader}>
                    Payables
                </Typography>
                <MenuItem sx={labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_BILLS}   
                    onClick={onBillTabClick}
                >
                    {<CurrencyBitcoinOutlinedIcon sx={materialIconsSettings} /> }
                    <Typography variant="body1" sx={text} >Bills</Typography>
                </MenuItem>
                {/* <MenuItem sx={labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_EXPENSES}   
                    onClick={onExpensesTabClick}
                >
                    {<CurrencyExchangeOutlinedIcon sx={materialIconsSettings1} /> }
                    <Typography variant="body1" sx={text}>Expenses</Typography>
                </MenuItem> */}
                <MenuItem sx={labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_PAYMENTS}   
                    onClick={onPaymentsClick}
                >
                    {<PaymentsOutlinedIcon sx={materialIconsSettings} /> }
                    <Typography variant="body1" sx={text} >Payments</Typography>
                </MenuItem>
                <MenuItem sx={labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_VENDORS}   
                    onClick={onVendorsTabClick}
                >
                    {<CorporateFareOutlinedIcon sx={materialIconsSettings} /> }
                    <Typography variant="body1" sx={text} >Vendors</Typography>
                </MenuItem>
                <MenuItem sx={labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_BILL_CATEGORIES}   
                    onClick={onBillCategoriesTabClick}
                >
                    {<CategoryOutlinedIcon sx={materialIconsSettings} /> }
                    <Typography variant="body1" sx={text}>Bill Categories</Typography>
                </MenuItem>
                <Typography variant="body1" 
                    sx={menuSectionHeader}>
                    Reports
                </Typography>
                <MenuItem sx={labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_CLIENT_REPORTS}   
                    onClick={onRevenueByClientClick}
                >
                    {<LocalAtmOutlinedIcon sx={materialIconsSettings} /> }
                    <Typography variant="body1" sx={text}>Revenue by Client</Typography>
                </MenuItem>
                <MenuItem sx={labels_left}
                    selected={props.drawerState === SelectedDrawerState.SHOW_ITEM_REPORTS}   
                    onClick={onRevenueByItemsClick}
                >
                    {<LocalAtmOutlinedIcon sx={materialIconsSettings} /> }
                    <Typography variant="body1" sx={text}>Revenue by Items</Typography>
                </MenuItem>
                
                {/* <Accordion elevation={0} defaultExpanded="false" 
                    classes={{
                        root: classes.MuiAccordionroot
                    }}
                >
                    <div>
                        <AccordionSummary
                            style={{flexDirection: 'row-reverse', display: "inline-flex", padding: 0,  minHeight: "0"}}
                            //className={classes.summary}
                            expandIcon={<ExpandMoreIcon className={`${classes.rightdownicon}`}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            classes = {{
                                root: classes.MuiAccordionSummaryroot,
                                expandIcon: classes.MuiAccordionSummaryexpandIcon
                            }}
                        >
                            <Typography className={classes.text} variant="body1">Reports</Typography>
                        </AccordionSummary>
                    </div>
                    <AccordionDetails  className={classes.detail}>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.listitem}
                                selected={props.drawerState === SelectedDrawerState.SHOW_CLIENT_REPORTS}   
                                onClick={onRevenueByClientClick}
                                >
                                    Revenue by Client
                            </ListItem>
                            <ListItem button className={classes.listitem}
                                selected={props.drawerState === SelectedDrawerState.SHOW_ITEM_REPORTS}   
                                onClick={onRevenueByItemsClick}
                                >
                                    Revenue by Items
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion> */}
                
                <MenuItem sx={labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_PROPOSALS}   
                    onClick={onProposalsTabClick}
                >
                    <BusinessCenterOutlinedIcon sx={materialIconsSettings} style={{position:'relative', top:'-1px'}}/>
                    <Typography variant="body1" sx={text}>Proposals</Typography>  
                </MenuItem>
                <MenuItem sx={labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_DOCUMENTS}   
                    onClick={onEsignClick}
                >
                    {/* <img src={'/images/digital_signature.png'} style={{width:'18px', height:'18px', position:'relative', top:'-2px'}}/> */}
                    <AppRegistrationOutlinedIcon sx={materialIconsSettings} />
                    <Typography variant="body1" sx={text}>E-Sign</Typography>  
                </MenuItem>
                {!accessLevelViewer &&
                <MenuItem sx={labels}
                        selected={props.drawerState === SelectedDrawerState.SHOW_MEMBERS}   
                        onClick={onAccountMemberClick}>
                            <PeopleOutlineIcon sx={materialIconsSettings}/>
                            <Typography variant="body1" sx={text}>Members</Typography>
                </MenuItem>}
                <MenuItem sx={labels}
                    selected={props.drawerState === SelectedDrawerState.SHOW_SETTINGS}   
                    onClick={onSettingsTabClick}
                >
                    <SettingsOutlinedIcon sx={materialIconsSettings}/>
                    <Typography variant="body1" sx={text}>Settings</Typography>  
                </MenuItem>
        </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    drawerState: state.drawerState.setDrawerState,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    showEstimateScreen: state.invoiceAccounts.showEstimateScreen
})

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuComponent);