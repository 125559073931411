import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
import ClearIcon from '@mui/icons-material/Clear';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { useSnackbar } from 'notistack';
import {getAllTemplatesApi} from '../../services/documentService';
import { consoleToLog } from '../../util/AppUtil';
import { useIsMount } from '../useIsMount';
import { useNavigate } from 'react-router-dom';

const MuiDialogpaper = {
    "& .MuiDialog-paper": {
        width:'800px',
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    }
};
const dialogTitle = {
    borderBottom:'1px solid #ccc', 
    padding:'14px 0px', 
    fontSize:'16px',
    fontWeight:'600',
};
const cardStyle = {
    height:'180px',
    padding:'18px',
    display:'flex',
    flexDirection:'column',
    justifyContent:"center",
    alignItems:"center",
    cursor:'pointer',
    '&:hover': {
        background: 'rgba(0,0,0,0.1)'
    }
};
const selectedBackgound = {
    background: 'rgba(0,0,0,0.1)'
};
const flatIcons = {
    fontSize:'25px',
    color:'#007bfb'
};
const docTextStyle = {
    fontSize:'13px',
    fontWeight:500,
    color: '#4a5668'
};
const footerStyle = {
    padding: '16px',
    borderTop:'1px solid rgba(0,0,0,0.1)'
};
const descpStyle = {
    fontSize: '11px',
    fontWeight:600,
    color: '#888',
    textAlign:'center',
    marginTop:'16px'
};
const positionStyle = {
    position:'relative',
    top:'4px'
};
const templateHeading = {
    padding:'8px 16px',
    color:'#5b51bf',
    fontWeight:600,
    pointerEvents:'none'
};


const SelectDocumentOrTemplateModal = ({ openSelectDocTempModal, handleSelectDocTempModalClose, handleDocumentModalOpen, selectedAccount }) => {
    const isMount = useIsMount();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [uploadedPdf, setUploadedPdf] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(-1);
    const [selectedItem, setSelectedItem] = useState(undefined);

    useEffect(() => {
        fetchDocumentOrTemplateList();
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(templateList && templateList.length === 0) {
                setSelectedItem('document');
            }
        }
    }, [templateList]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/pdf") {
            setUploadedPdf(e.target.files[0]);
        } else {
            enqueueSnackbar("Please select a valid PDF file.", {variant:'error'});
            return;
        }
    }

    const fetchDocumentOrTemplateList = async() => {
        const invoice_account_id = selectedAccount.id;

        try {
            const response = await getAllTemplatesApi(invoice_account_id)
            const res = response.data;
            consoleToLog("Response getAllTemplatesApi: ", res);

            setTemplateList(res);

        } catch(e) {
            consoleToLog("Error getAllTemplatesApi: ", e.response);
            if(e.response.data && e.response.data.message) {
                return;
            }
        }

    }

    const handleTemplateChange = (e) => {
        const value = e.target.value;
        setSelectedTemplate(value);
    }

    const onNextClick = () => {
        if(selectedItem === 'document') {
            if(uploadedPdf) {
                navigate('/document/create', {state: {documentCreate:true, uploadedPdf}});
                handleDocumentModalOpen();
                handleSelectDocTempModalClose();
            } else {
                enqueueSnackbar('Please upload a pdf to continue', {variant:'error'});
            }
        }

        if(selectedItem === 'template') {
            navigate('/document/create', {state: {documentFromTemplate:true, selectedTemplate}});
            handleDocumentModalOpen();
            handleSelectDocTempModalClose();
        }
    }

    return (
        <Dialog
            open={openSelectDocTempModal}
            //TransitionComponent={Transition}
            scroll="paper"
            sx={{...MuiDialogpaper, scrollbarWidth:'none !important'}}
            maxWidth="sm"
            fullWidth
            onClose={handleSelectDocTempModalClose}>

                    <Grid container   
                        justifyContent='space-between'
                        alignItems='center'sx={dialogTitle}>

                        <Grid size={11}>        
                            <Typography style={{fontWeight:600, textAlign:'center'}}>
                                Create New
                            </Typography>
                        </Grid>

                        <Grid size={1}>
                            <ClearIcon fontSize='small' onClick={handleSelectDocTempModalClose}
                            style={{cursor:'pointer', verticalAlign:'inherit'}}/>
                        </Grid>

                    </Grid>

                    <div style={{padding:'24px 16px'}}>

                        <Grid container justifyContent='center' 
                            alignItems='center'
                            spacing={2}>

                            <Grid size={5}>
                                <Card fullWidth sx={{...cardStyle, ...(selectedItem === 'document' &&  selectedBackgound)}} 
                                    onClick={() => setSelectedItem('document')}
                                    variant="outlined">
                                        
                                    <Grid>
                                        <AddOutlinedIcon sx={flatIcons} />
                                    </Grid>

                                    <Grid>
                                        <Typography sx={docTextStyle}>
                                            Document
                                        </Typography>
                                    </Grid>

                                    <Grid>
                                        <Typography sx={descpStyle}>
                                            Create a document from scratch
                                        </Typography>
                                    </Grid>

                                </Card>
                            </Grid>

                            {templateList && templateList.length > 0 &&
                            <Grid size={5}>
                                <Card fullWidth sx={{...cardStyle, ...(selectedItem === 'template' &&  selectedBackgound)}}
                                    onClick={() => setSelectedItem('template')}
                                    variant="outlined">

                                    <Grid>
                                        <LibraryAddOutlinedIcon sx={{...flatIcons, position:'relative', top:'5px'}} />
                                    </Grid>

                                    <Grid>
                                        <Typography sx={{...docTextStyle, position:'relative', top:'5px'}}>
                                            Document from template
                                        </Typography>
                                    </Grid>

                                    <Grid>
                                        <Typography sx={{...descpStyle, position:'relative', top:'5px'}}>
                                            Create a document from an existing template
                                        </Typography>
                                    </Grid>

                                </Card>
                            </Grid>}
                        </Grid>

                        <Grid style={{marginTop:'24px'}}>
                            {
                                selectedItem === 'document' &&
                                <Grid size={12}>

                                    <Typography variant='body1' style={{textAlign:'center'}}>
                                        Please upload a PDF to continue
                                    </Typography>
                                    <Grid container justifyContent='center' 
                                        alignItems='center' 
                                        spacing={1}
                                        style={{marginTop:'8px'}}
                                        >
                                        <Grid>
                                            <label>
                                                <Button size='large' component='div' variant='contained' color='secondary'>
                                                    Upload
                                                </Button>
                                                <input style={{display:'none'}}
                                                        type="file"
                                                        id="document_pdf"
                                                        accept=".pdf"
                                                        onChange={handleFileChange}
                                                    />
                                                </label>
                                        </Grid>
                                    </Grid>
                                    {
                                        uploadedPdf &&
                                        <Typography style={{fontSize:'13px', marginTop:'8px', color:'#888', textAlign:'center'}}>
                                            {uploadedPdf?.name}
                                        </Typography>
                                    }

                                </Grid>
                            }

                            {
                                selectedItem === 'template' && templateList && templateList.length > 0 &&
                                <Grid size={12}>

                                    <Grid container direction='column' alignItems='center' justifyContent='center'>

                                        <Typography variant='body1' sx={{textAlign:'center'}}>
                                            Please Select template to continue
                                        </Typography>

                                        <Grid size={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            select
                                            value={selectedTemplate}
                                            onChange={handleTemplateChange}
                                            style={{ width: "300px" }}
                                            size='small'
                                        >
                                            <MenuItem value={-1}>Select Template</MenuItem>

                                            {/* User Templates Section */}
                                            {templateList?.some(template => !template.is_predefined) &&
                                            <Typography sx={templateHeading}>Organization Templates</Typography>}
                                            {templateList?.some(template => !template.is_predefined) && (
                                                templateList
                                                    .filter(template => !template.is_predefined)
                                                    .map(template => (
                                                        <MenuItem key={template.id} value={template}>
                                                            <span style={{marginLeft:'16px'}}>{template.name}</span>
                                                        </MenuItem>
                                                    ))
                                            )}

                                            {/* Predefined Templates Section */}
                                            {templateList?.some(template => template.is_predefined) &&
                                            <Typography sx={templateHeading}>Predefined Templates</Typography>}
                                            {templateList?.some(template => template.is_predefined) && (
                                                    templateList
                                                        .filter(template => template.is_predefined)
                                                        .map(template => (
                                                            <MenuItem key={template.id} value={template}>
                                                                <span style={{marginLeft:'16px'}}>{template.name}</span>
                                                            </MenuItem>
                                                        ))
                                            )}
                                        </TextField>
                                        </Grid>

                                    </Grid>

                                </Grid>
                            }
                        </Grid>
                    </div>

                    <Grid sx={footerStyle}>
                        <Grid container alignItems='center' 
                            spacing={1}
                            justifyContent='flex-end'
                            >
                            <Grid>
                                <Button variant='outlined' color='primary'
                                    onClick={handleSelectDocTempModalClose}
                                    sx={{width:'100px'}}>
                                    Cancel
                                </Button>
                            </Grid>

                            <Grid>
                                <Button variant='contained' color='primary'
                                    onClick={onNextClick}
                                    sx={{width:'100px'}}>
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
            
        </Dialog>
    );
}

export default SelectDocumentOrTemplateModal;