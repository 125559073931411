import React from 'react';
import Grid from '@mui/material/Grid2';
import { ReadOnlyEditor } from '../edit/BaseEditor';
import BackgroundPreview from './BackgroundPreview';

const CustomPagePreview = ({item, index, normalUserPreview, brand_color}) => {

    const {data} = item;
    const {title, text} = data;

    const descpStyle = normalUserPreview ? 'preview_description_style1' : 'preview_description_style';

    return (
        <BackgroundPreview item={item} title={title} 
            brand_color={brand_color}
            index={index}
            normalUserPreview={normalUserPreview}
        >

                <Grid size={12} className={descpStyle}>
                    <ReadOnlyEditor editorValue={text}/>
                </Grid>

        </BackgroundPreview>
    );
}

export default CustomPagePreview;