import React, {useState} from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import BaseDrawer from './BaseDrawer';
import { SocialMediaList } from '../../../util/AppUtil';
import {linkValidator} from '../../../util/validator';
import Box from '@mui/material/Box';

// const AntSwitch = withStyles((theme) => ({
//     root: {
//         width: 28,
//         height: 16,
//         padding: 0,
//         display: 'flex',
//     },
//     switchBase: {
//         padding: 2,
//         color: theme.palette.grey[500],
//         '&$checked': {
//             transform: 'translateX(12px)',
//             color: theme.palette.common.white,
//             '& + $track': {
//                 opacity: 1,
//                 backgroundColor: theme.palette.primary.main,
//                 borderColor: theme.palette.primary.main,
//             },
//         },
//     },
//     thumb: {
//         width: 12,
//         height: 12,
//         boxShadow: 'none',
//     },
//     track: {
//         border: `1px solid ${theme.palette.grey[500]}`,
//         borderRadius: 16 / 2,
//         opacity: 1,
//         backgroundColor: theme.palette.common.white,
//     },
//     checked: {},
// }))(Switch);

const labelStyle = {
    fontWeight: 400,
    lineHeight: '24px',
    marginRight: '4px',
};

const logoContainer = {
    background: '#f9f9f9',
    border: '1px solid #DDDDDD',
    borderRadius: '8px',
    height: '74px'
};

const addNewButton = {
    position: 'relative',
    left: '86%',
    top: '16px'
};

const addNewIcon = {
    width: '15px',
    marginRight: '4px'
};

// const uploadImg = {
//     width: '100%',
//     height: '100%',
//     cursor: 'pointer',
//     zIndex: 10
// };

const customColumn1 = {
    flexBasis: '70%',
    maxWidth: '70%'
};

const customColumn2 = {
    flexBasis: '2%',
    maxWidth: '2%'
};

const MuiTooltipTooltip = {
    fontSize: '13px',
    cursor: 'pointer'
};

const errorText = {
    fontSize: '12px',
    marginTop: '-5px',
    letterSpacing: '0.2px',
    color: 'red'
};

const socialLinkStyles = {
    background: '#f7f7f7',
    padding: "10px 16px",
    borderRadius: '6px',
    textAlign: "center"
};

const CoverPageDrawer = ({itemObj, drawerOpen, handleDrawerClose, callUpdateSectionApi, entity_name, entity_logo, 
        apiLoading, setDirtyFlag}) => {
    const {data} = itemObj;
    const {show_company_logo, show_company_name, title, client_company_name, client_first_name, client_last_name, website, social_links} = data;

    const [showCompanyLogo, setShowCompanyLogo] = useState(show_company_logo);
    const [showCompanyName, setShowCompanyName] = useState(show_company_name);
    const [companyLogo, setCompanyLogo] = useState(entity_logo);
    const [companyName, setCompanyName] = useState(entity_name);
    const [clientCompanyName, setClientCompanyName] = useState(client_company_name);
    const [sectionTitle, setSectionTitle] = useState(title);
    const [clientFirstName, setClientFirstName] = useState(client_first_name); 
    const [clientLastName, setClientLastName] = useState(client_last_name);
    const [socialLinks, setSocialLinks] = useState([...social_links]);
    const [websiteUrl, setWebsiteUrl] = useState(website);
    const [attachment, setAttachment] = useState([]);
    const [showError, setShowError] = useState(false);


    const handleShowCompanyLogoChange = (event) => {
        setShowCompanyLogo(event.target.checked);
        setDirtyFlag(true);
    };

    const handleShowCompanyNameChange = (event) => {
        setShowCompanyName(event.target.checked);
        setDirtyFlag(true);
    };

    const onCompanyLogoChanged = (e) => {
        setAttachment (e.target.files[0])
        var oFReader = new FileReader();
        oFReader.readAsDataURL(e.target.files[0]);
        oFReader.onload = function (oFREvent) {
            document.getElementById("company_logo").src = oFREvent.target.result;
        };
    }

    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
        setDirtyFlag(true);
    } 
    
    const handleClientFirstNameChange = (e) => {
        setClientFirstName(e.target.value);
        setDirtyFlag(true);
    } 

    const handleClientLastNameChange = (e) => {
        setClientLastName(e.target.value);
        setDirtyFlag(true);
    } 

    const handleClientCompanyNameChange = (e) => {
        setClientCompanyName(e.target.value);
        setDirtyFlag(true);
    } 

    const handleChangeTitle = (e) => {
        if (e.target.value.length <= 30) {
            setSectionTitle(e.target.value);
            setDirtyFlag(true);
        }
    } 

    const setUpdatedItemObj = () => {
        const dataObj = {
            show_company_logo: showCompanyLogo,
            show_company_name: showCompanyName,
            title: sectionTitle,
            client_company_name: clientCompanyName,
            client_first_name: clientFirstName,
            client_last_name: clientLastName,
            social_links: socialLinks,
            website: websiteUrl
        };
        const updated_obj = {
            ...itemObj,
            data: {...dataObj}
        }

        return updated_obj;
    }

    const onSaveClick = () => {
        if(socialLinks && socialLinks.length > 0) {
            const emptySocialLinks = socialLinks.filter((item) => !item.type || !item.link || item.link && !linkValidator(item.link));
            if(emptySocialLinks && emptySocialLinks.length > 0) {
                setShowError(true);
                return;
            } else {
                setShowError(false);
                callUpdateSectionApi(itemObj, setUpdatedItemObj());
            }
        } else {
            callUpdateSectionApi(itemObj, setUpdatedItemObj());
        }

    }

    const onDrawerClose = () => {
        handleDrawerClose(setUpdatedItemObj());
    }

    const addSocialLink = () => {
        setSocialLinks([...socialLinks, { type: 'other', link: ''}]);
        setDirtyFlag(true);
    };

    const handleSocialMediaType = (index, value) => {
        const newSocialLinks = [...socialLinks];
        newSocialLinks[index] = {
            ...newSocialLinks[index],
            type: value
        };
        setSocialLinks(newSocialLinks);
        setDirtyFlag(true);
    };

    
    const handleSocialMediaLink = (index, text) => {
        const newSocialLinks = [...socialLinks];
        newSocialLinks[index] = {
            ...newSocialLinks[index],
            link: text
        };
        setSocialLinks(newSocialLinks);
        setDirtyFlag(true);
    };

    const onDeleteIconClick = (index) => {
        const newSocialLinks = socialLinks.filter((_, i) => i !== index);
        setSocialLinks(newSocialLinks);
        setDirtyFlag(true);
    }

    return (
        <BaseDrawer open={drawerOpen} 
            onClose={onDrawerClose}
            title='Cover Page'
            >
        <div className='drawer_content_container'>
            <Grid size={12}>
                <Grid container alignItems='center' justifyContent='space-between'> 
                    <Grid>
                        <Grid container alignItems='center'>
                            <Typography sx={labelStyle}>
                                Company Logo
                            </Typography>
                            <Tooltip sx={MuiTooltipTooltip}
                                title='Company Logo can be changed from Settings => Entities => Edit' arrow>
                                <img src='/images/info.svg' style={{cursor:"pointer"}} />
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Grid>
                        <Switch checked={showCompanyLogo} onChange={handleShowCompanyLogoChange} name="company_logo" />
                    </Grid>
                </Grid>

                <Grid>
                    {
                        showCompanyLogo &&
                        <Box component='div' sx={logoContainer} style={{display:'flex', justifyContent:'center', position:'relative'}}>
                            <Grid>
                                <img style={{
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 9
                                }}
                                    src={companyLogo ? companyLogo : "/images/entity_business-and-trade.png"}
                                    id="company_logo"
                                    alt="Logo"
                                />
                            </Grid>

                                {/* <label className={classes.uploadImg}>
                                    <input style={{display:'none'}}
                                        type="file"
                                        id="input_logo"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={onCompanyLogoChanged}
                                    />
                                </label> */}
                        </Box>  
                    }
                </Grid>
            </Grid>

            <Grid size={12}>
                <Grid container alignItems='center' justifyContent='space-between'> 
                    <Grid>
                        <Grid container alignItems='center'>
                            <Typography sx={labelStyle}>
                                Company Name
                            </Typography>
                            <Tooltip classes={MuiTooltipTooltip} 
                                title='Company Name can be changed from Settings => Entities => Edit' arrow>
                                <img src='/images/info.svg' style={{cursor:"pointer"}}/>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Grid>
                        <Switch checked={showCompanyName} onChange={handleShowCompanyNameChange} name="company_logo" />
                    </Grid>
                </Grid>

                <Grid size={12}>
                    {
                        showCompanyName &&
                        <TextField 
                            variant='outlined'
                            fullWidth
                            margin='dense'
                            value={companyName}
                            onChange={handleCompanyNameChange}
                            disabled={true}
                        />

                    }
                </Grid>
            </Grid>

            <Grid size={12}>
                <Grid container alignItems='center'>
                            <Typography className={'drawer_label_style'}>
                                Title
                            </Typography>
                            <Tooltip sx={MuiTooltipTooltip} 
                                title='Title cannot have more than 30 characters' arrow>
                                <img src='/images/info.svg' style={{cursor:"pointer"}}/>
                            </Tooltip>
                        </Grid>

                <TextField 
                    className={'drawer_textfield_margintop'}
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    value={sectionTitle}
                    onChange={handleChangeTitle}
                    //inputProps={{ maxLength: 30 }}
                    slotProps={{
                        input: {
                            maxLength:30
                        }
                    }}
                    helperText={`${sectionTitle.length}/30`}
                    size='small'
                />
            </Grid>

            <Grid size={12}>
                <Grid container alignItems='center' 
                    spacing={1}
                    justifyContent='space-between'>
                    <Grid size={6}>
                        <Typography className={'drawer_label_style'}>
                            Client first name
                        </Typography>

                        <TextField 
                            className={'drawer_textfield_margintop'}
                            variant='outlined'
                            fullWidth
                            margin='dense'
                            value={clientFirstName}
                            onChange={handleClientFirstNameChange}
                            size='small'
                        
                        />
                    </Grid>

                    <Grid size={6}>
                        <Typography className={'drawer_label_style'}>
                            Client last name
                        </Typography>

                        <TextField 
                            className={'drawer_textfield_margintop'}
                            variant='outlined'
                            fullWidth
                            margin='dense'
                            value={clientLastName}
                            onChange={handleClientLastNameChange}
                            size='small'
                        
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid size={12}>

                <Typography className={'drawer_label_style'}>
                    Client company name
                </Typography>

                <TextField 
                    className={'drawer_textfield_margintop'}
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    value={clientCompanyName}
                    onChange={handleClientCompanyNameChange}
                    size='small'
                
                />

            </Grid>

            <Grid size={12}>

                <Typography className={'drawer_label_style'}>
                    Website
                </Typography>

                <TextField 
                    className={'drawer_textfield_margintop'}
                    variant='outlined'
                    fullWidth
                    margin='dense'
                    value={websiteUrl}
                    onChange={(e) => setWebsiteUrl(e.target.value)}
                    size='small'
                />
                
            </Grid>

            <Grid size={12}>

                <Typography className={'drawer_label_style'}>
                    Social Media
                </Typography>

                {socialLinks && socialLinks.length > 0 ? socialLinks?.map((media, index) => (
                    <>
                    <Grid container
                        key={index}
                        alignItems='center' 
                        spacing={1}>
                        <Grid size={3}> 
                            <TextField variant='outlined'
                                fullWidth
                                margin='dense'
                                select
                                value={SocialMediaList.some(sm => sm.type === media?.type) ? media?.type : 'other'}
                                onChange={(e) => handleSocialMediaType(index, e.target.value)}
                                error={media.type === '' && showError}
                                size='small'
                            >   
                                {SocialMediaList && SocialMediaList.length > 0 && 
                                SocialMediaList?.map((socialMedia) => (
                                    <MenuItem key={socialMedia?.type} value={socialMedia?.type}>
                                        {socialMedia.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid size={8} sx={customColumn1}>
                            <TextField variant='outlined'
                                fullWidth
                                margin='dense'
                                value={media.link}
                                onChange={(e) => handleSocialMediaLink(index, e.target.value)}
                                error={(media.link === '' || !linkValidator(media.link)) && showError}
                                placeholder={'Enter you profile url'}
                                size='small'
                            />
                        </Grid>
                        <Grid size={1} sx={customColumn2}>
                            <IconButton onClick={() => onDeleteIconClick(index)}>
                                <img src='/images/close.svg' alt="Delete" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container
                        key={index}
                        alignItems='center' 
                        spacing={1}>
                        <Grid size={3}> 
                            <Typography variant='subtitle1' sx={errorText}>
                                {media.type === '' && showError && `*Please select type`}
                            </Typography>
                        </Grid>
                        <Grid size={8} sx={customColumn1}>
                            <Typography variant='subtitle1' sx={errorText}>
                                {(media.link === '') && showError ? `*Please enter link` : !linkValidator(media.link) && showError && '*Please enter a valid url'}
                            </Typography>
                        </Grid>
                        <Grid size={1} sx={customColumn2}>
                            
                        </Grid>
                    </Grid>
                    </>
                ))
                :
                <Grid container alignItems='center' justifyContent='center' 
                    sx={socialLinkStyles}>
                    <Typography >
                        No Social Links Added
                    </Typography>
                </Grid>
            }

                <Button sx={addNewButton}
                    onClick={addSocialLink}>
                    <img src='/images/add_circle.svg' sx={addNewIcon}/> Add New
                </Button>
            </Grid>
        </div>

        <div className={'drawer_footer'}>
            <Grid container alignItems='center' spacing={1}>
                <Grid>
                    <Button onClick={onSaveClick} 
                        className={`next_btn_style drawer_btn_width`}>
                        {apiLoading && <CircularProgress size={15} className='drawer_loading_margin'/>} Save
                    </Button>
                </Grid>

                <Grid>
                    <Button variant='outlined' color='primary'
                        onClick={onDrawerClose}
                        className={'drawer_btn_width'}>
                        Cancel 
                    </Button>
                </Grid>
            </Grid>
        </div>

    </BaseDrawer>
    );
}

export default CoverPageDrawer;