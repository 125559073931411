import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {connect} from 'react-redux';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import {setClearInvoiceAccounts} from '../actions/invoiceAccount';
import {addBillCategoryApi} from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {trackGAEvent} from '../util/AppUtil';

const labelTextStyles = {
    fontSize: '14px',
    marginTop: '8px',
    marginBottom: '-6px'
};

const AddEditBillCategoriesDrawer = (props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [categoryName, setCategoryName] = useState("");
    const [errorType, setErrorType] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(props?.itemObj && Object.keys(props?.itemObj).length !== 0) {
            setCategoryName(props.itemObj?.title);
        } 

    }, [props.itemObj]);


    const clearForm = ( ) => {
        setCategoryName('');
        setErrorType(undefined);
        setErrorMessage('');
        props?.itemObj && Object.keys(props?.itemObj).length !== 0 && props.setItemObj({})
    }

    const closeDrawer = () => {
        props.handleDrawerClose();
        clearForm();
    }

    const onAddEditItemClick = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const title = categoryName;
        if(title === '') {
            setErrorType(0);
            setErrorMessage('Bill category cannot be empty!');
            return;
        }

        setLoading(true);
        if(props?.itemObj && Object.keys(props?.itemObj).length !== 0) {
            const item_id = props.itemObj?.id;
            
        } else {

        addBillCategoryApi(invoice_account_id, title)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addBillCategoryApi: ', res);
                setLoading(false);
                
                enqueueSnackbar('Bill Category Added SuccessFully', {variant: 'success'});
                props.onAddItemsClick(res);
                
                props.handleDrawerClose();
                clearForm();

                trackGAEvent(props.selectedAccount.name, 'Bill Category created', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog('Error addItemApi', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
        } 
    }


  /*   const callUpdateApi = (invoice_account_id, item_id, title, description, cost, tax_preference, tax, hsn_code) => {
        updateItemApi(invoice_account_id, item_id, title, description, cost, tax_preference, tax, hsn_code)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateItemApi: ', res);
                setLoading(false);
                
                enqueueSnackbar('Item Updated SuccessFully', {variant: 'success'});
                props.onUpdateLocalList(res);
                props.handleDrawerClose();
                clearForm();

                trackGAEvent(props.selectedAccount.name, 'Invoice Items item updated', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog('Error updateItemApi', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
        } */



    return (
        <RightToLeftSideDrawer title={props?.itemObj && Object.keys(props?.itemObj).length !== 0  ? 'Update Bill Category' : "Add Bill Category"} 
            openDrawer={props.openDrawer}
            closeDrawer={closeDrawer}>

            <div style={{padding:'0px 20px 24px 20px', height:'calc(100vh - 180px)', overflow:'auto'}}>
                <Grid Size={12}>
                    <Typography sx={labelTextStyles}>
                        Name
                    </Typography>

                    <TextField fullWidth 
                        id="item_name" 
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={categoryName}
                        autoComplete="off"
                        placeholder="Enter category name"
                        slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={(e) => {
                            setCategoryName(e.target.value)
                            setErrorType(undefined);
                            setErrorMessage('');
                        }}
                        error={errorType === 0}
                        helperText={errorType === 0 ? errorMessage : ''}
                        size='small'
                    />
                </Grid>    

                <div className="modal__footer">
                    <Button variant="contained" color="primary" 
                        onClick={onAddEditItemClick}
                        className="modal__button">
                        {loading && <CircularProgress size={15} className='loading__style'/>}
                        {props?.itemObj && Object?.keys(props.itemObj)?.length !== 0 ? 'Update' : 'Save'}    
                    </Button>
                    <Button variant="outlined" style={{
                        minWidth: '130px',
                    }} onClick={closeDrawer}>
                        Cancel
                    </Button>
                </div>
            </div>
        </RightToLeftSideDrawer>
    );
}


const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user

});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditBillCategoriesDrawer);