import React,{useState} from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import "@mdxeditor/editor/style.css";
import {
    linkDialogPlugin,
    MDXEditor,
    tablePlugin,
    headingsPlugin,
    listsPlugin,
    quotePlugin,
    thematicBreakPlugin,
    linkPlugin,
    toolbarPlugin,
    UndoRedo,
    BoldItalicUnderlineToggles,
    ListsToggle,
    InsertTable
} from "@mdxeditor/editor";
import {BaseEditor} from './BaseEditor';
import BaseDrawer from './BaseDrawer';
import {parseMarkdownToHtml} from '../../../util/AppUtil';

function Separator() {
    return (
    <div
        data-orientation="vertical"
        aria-orientation="vertical"
        role="separator"
    ></div>
    );
}

const ReviewDrawer = ({itemObj, drawerOpen, handleDrawerClose, callUpdateSectionApi, apiLoading, setDirtyFlag}) => {

    const {data} = itemObj;
    const {description, title, reviews} = data;


    const [introTitle, setIntroTitle] = useState(title);
    const [shortDescription, setShortDescription] = useState(description);
    const [editDescription, setEditDescription] = useState(false);
    const [reviewList, setReviewList] = useState([...reviews]);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedReviewItem, setSelectedReviewItem] = useState(undefined);

    const handleEditClick = (index, reviewObj) => {
        setSelectedItemIndex(index);
        setSelectedReviewItem(reviewObj);
    };

    const handleReviewTextChange = (index, text) => {
        setReviewList((prevState) =>
            prevState.map((state, i) =>
            i === index ? { ...state, review_text: text } : state
            )
        );
        setDirtyFlag(true);
    };

    const handleReviewedByChange = (index, text) => {
        setReviewList((prevState) =>
            prevState.map((state, i) =>
            i === index ? { ...state, reviewed_by: text } : state
            )
        );
        setDirtyFlag(true);
    };

    const handleReviewCompanyNameChange = (index, text) => {
        setReviewList((prevState) =>
            prevState.map((state, i) =>
            i === index ? { ...state, review_company_name: text } : state
            )
        );
        setDirtyFlag(true);
    };

    const onShortDescriptionSaveClick = () => {
        setEditDescription(false)
    }

    const onShortDescriptionCancelClick = () => {
        setEditDescription(false);
        setShortDescription(description);
    }

    const saveChanges = () => {
        setSelectedItemIndex(null);
        setSelectedReviewItem(undefined); 
    };

    const cancelEdit = () => {
        setSelectedItemIndex(null);
        const updatedArray = reviewList;
        if((updatedArray[selectedItemIndex]?.review_text === '' && updatedArray[selectedItemIndex]?.reviewed_by === '' && updatedArray[selectedItemIndex]?.review_company_name === "") || !selectedReviewItem){
            setReviewList([...updatedArray].filter((_, i) => i !== selectedItemIndex));
            setSelectedReviewItem(undefined); 
        } else {
            updatedArray[selectedItemIndex] = selectedReviewItem;
            setReviewList([...updatedArray]);
            setSelectedReviewItem(undefined); 
        }
    };

    const addReview = () => {
        setReviewList([{ review_text: "", reviewed_by: "", review_company_name: ""}, ...reviewList]);
        setSelectedItemIndex(0);
        setDirtyFlag(true);
    
    };

    const onDeleteIconClick = (index) => {
        const newItems = reviewList.filter((_, i) => i !== index);
        setReviewList(newItems);
        setDirtyFlag(true);
    }

    const setUpdatedItemObj = () => {
        const dataObj = {
            title: introTitle,
            description: shortDescription,
            reviews: reviewList

        };
        const updated_obj = {
            ...itemObj,
            data: {...dataObj}
        }

        return updated_obj;
    }

    const handleTitleChange = (e) => {
        setIntroTitle(e.target.value);
        setDirtyFlag(true);
    }

    const onSaveClick = () => {
        callUpdateSectionApi(itemObj, setUpdatedItemObj());
    }

    const onDrawerClose = () => {
        handleDrawerClose(setUpdatedItemObj());
    }


    return (
        <BaseDrawer open={drawerOpen} 
            onClose={onDrawerClose}
            title='Reviews'
        >
            <div className='drawer_content_container' style={{padding:'16px 0px'}}>

                <Grid size={12} className='drawer_element_padding'>
                    <Typography className={'drawer_label_style'}>
                        Page Title
                    </Typography>

                    <TextField 
                        className={'drawer_textfield_margintop'}
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        value={introTitle}
                        onChange={handleTitleChange}
                    
                    />
                </Grid>

                <Grid size={12} className='drawer_element_padding'>

                    <Typography className={'drawer_label_style'}>
                        <b>Description</b>
                    </Typography>

                    {editDescription ?
                        <div className='drawer_edit_item_container'>
                            <BaseEditor editorValue={shortDescription}
                                setEditorValue={setShortDescription}
                                showTable={false}/>

                            <Grid container alignItems='center' spacing={1}>
                                <Grid>
                                    <Button onClick={onShortDescriptionSaveClick} className='drawer_list_save_btn'>
                                        Save
                                    </Button>
                                </Grid>

                                <Grid>
                                    <Button onClick={onShortDescriptionCancelClick} variant='outlined'>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        :
                        <Typography className={'drawer_label_style drawer_descp_cursor'}
                            onClick={() => setEditDescription(true)}
                            dangerouslySetInnerHTML={{ __html: shortDescription }} />
                    }
                </Grid>

                <Grid size={12} className='drawer_element_padding'>

                {reviewList.length === 3 ?
                    <Tooltip title={"You cannot add more than 3 reviews"} arrow>
                        <span>
                        <Button fullWidth variant='outlined' 
                            disabled={reviewList.length === 3}
                            className='edit_drawer_add_btn'>
                            <img src='/images/drawer_add.svg' style={{marginRight: '5px'}}/>
                            Add Review
                        </Button>
                        </span>
                    </Tooltip>
                    :
                    <Button fullWidth variant='outlined' 
                        disabled={reviewList.length === 3}
                        onClick={addReview}
                        className='edit_drawer_add_btn'>
                        <img src='/images/drawer_add.svg' style={{marginRight: '5px'}}/>
                        Add Review
                    </Button>}

                </Grid>

                <Grid size={12} className='drawer_list_items'>
                    {
                        reviewList && reviewList.length > 0 &&
                        reviewList.map((reviewObj, index) => {
                            return (
                                <Grid size={12} className='itemList'>
                                    {index === selectedItemIndex ? (
                                    <div className='drawer_edit_item_container edit_list_width'>

                                        <Grid size={12}>
                                            <MDXEditor markdown={reviewList[index]?.review_text} 
                                                className='MarkDownEditors'
                                                onChange={(value) => handleReviewTextChange(index, value)}
                                                plugins={[
                                                    headingsPlugin(),
                                                    listsPlugin(),
                                                    quotePlugin(),
                                                    thematicBreakPlugin(),
                                                    linkPlugin(),
                                                    linkDialogPlugin({}),
                                                    tablePlugin(),
                                                    toolbarPlugin({
                                                        
                                                        toolbarContents: () => (
                                                        <div className="MarkDownToolBar">
                                                            <UndoRedo />
                                                            <Separator />
                                                            <BoldItalicUnderlineToggles />
                                                            <Separator />
                                                            {/* <InsertTable />
                                                            <Separator /> */}
                                                            <ListsToggle options={['bullet', 'number']} />
                                                        </div>
                                                        ),
                                                    }),
                                                ]}
                                                placeholder='Type your review'
                                                autoFocus
                                            />
                                        </Grid>

                                        <Grid size={12}>

                                            <Grid container justifyContent='space-between'
                                                alignItems='center'>

                                                <Grid size={5}>
                                                    <TextField 
                                                        variant='outlined'
                                                        fullWidth
                                                        margin='dense'
                                                        value={reviewList[index].reviewed_by}
                                                        placeholder='Reviewed By'
                                                        onChange={(e) => handleReviewedByChange(index, e.target.value)}
                                                        className='edit_drawer_textfield'
                                                    
                                                    />
                                                </Grid>

                                                <Grid size={5}>
                                                    <TextField 
                                                        variant='outlined'
                                                        fullWidth
                                                        margin='dense'
                                                        value={reviewList[index].review_company_name}
                                                        placeholder='Review Company Name'
                                                        onChange={(e) => handleReviewCompanyNameChange(index, e.target.value)}
                                                        className='edit_drawer_textfield'
                                                    
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Grid>


                                        <Grid container alignItems='center' spacing={1}>
                                            <Grid>
                                                <Button onClick={saveChanges} className='drawer_list_save_btn'>
                                                    Save
                                                </Button>
                                            </Grid>

                                            <Grid>
                                                <Button onClick={cancelEdit} variant='outlined'>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        
                                        
                                    </div>
                                ) : (
                                    <div className='drawer_edit_item_container' 
                                        onClick={() => handleEditClick(index, reviewObj)}>
                                        <Grid>
                                            <Typography dangerouslySetInnerHTML={{ __html: parseMarkdownToHtml(reviewObj?.review_text) }}
                                                className='drawer_list_items_descp' />
                                                
                                        </Grid>

                                        <Grid size={12}>
                                            <Grid container justifyContent='space-between'
                                                alignItems='center'>
                                                <Grid>
                                                    <Typography className='drawer_list_items_descp'>
                                                        <b> {reviewObj?.reviewed_by} </b>
                                                    </Typography>
                                                </Grid>

                                                <Grid>
                                                    <Typography>
                                                        <b> {reviewObj?.review_company_name} </b>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )}
                                    
                                    <IconButton 
                                        onClick={() => onDeleteIconClick(index)}
                                        className={'edit_drawer_delete_icon'}>
                                        <img src='/images/drawer_delete.svg' />
                                    </IconButton>
                                </Grid>
                            )
                        })
                    }
                </Grid>

            </div>

            <div className={'drawer_footer'}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid>
                        <Button
                            onClick={onSaveClick} 
                            className={`next_btn_style drawer_btn_width`}>
                            {apiLoading && <CircularProgress size={15} className='drawer_loading_margin'/>} Save
                        </Button>
                    </Grid>

                    <Grid>
                        <Button variant='outlined' color='primary'
                            onClick={onDrawerClose}
                            className={'drawer_btn_width'}>
                            Cancel 
                        </Button>
                    </Grid>
                </Grid>
            </div>

    </BaseDrawer>
    );
}

export default ReviewDrawer;