import React, { useEffect, useState, memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import moment from 'moment';
import {connect} from 'react-redux';
import {addPaymentBillTransactionApi, updatePaymentBillApi} from '../services/authService';
import {consoleToLog, isInvoiceAccountCountryIndia} from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import { useIsMount } from './useIsMount';
import { SendEmailType } from '../actions/invoiceAccount';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import { setClearInvoiceAccounts } from '../actions/invoiceAccount';
import {trackGAEvent, InvoiceStatus} from '../util/AppUtil';
import { SelectedDrawerState } from '../actions/drawer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Autocomplete from "@mui/material/Autocomplete";
import { currencyFormatterForAmount, formatAmount } from '../util/currencyUtil';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';

const muiAutocompleteInputRootStyle = {
    '& .MuiOutlinedInput-root': {
        padding: '0.5px 1px 0.5px 1px',
        borderRadius: '4px',
        background: '#fff',
        marginTop: '4px'
    }
};

const createItemButtonStyle = {
    margin: '16px auto 4px 10px',
    width: '95%',
    color: '#1bac3b',
    borderColor: '#d4d8de'
};

const createItemIconStyle = {
    margin: '0px 6px 0px 0px',
    fontSize: '16px'
};

const itemOptionStyle = {
    width: "100%",
    height: '100%',
    color: '#777f8e',
    margin: '5px 0px'
};

const AddEditBillPaymentDrawer = memo((props) => {
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const [fullAmt,setFullAmount]=useState("");
    const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [paymentMethod, setPaymentMethod] = useState(-1);
    const [bankAccount,setBankAccount]=useState({});
    const [paymentNotes, setPaymentNotes] = useState('');
    const [sendEmail, setSendEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [convertToTax, setConvertToTax] = useState(true);
    const [loading, setLoading] = useState(false);
    
    const invoiceObj = props.transactionObj ? props.transactionObj?.purchase_invoice : props.invoiceObj;
    const total_amount = props.transactionObj ? props.transactionObj?.purchase_invoice.total : props.invoiceObj?.total;
    const due_amount = props.transactionObj ? props.transactionObj?.purchase_invoice.due : props.invoiceObj?.due;
    const amount_paid = props.transactionObj?.amount ? props.transactionObj?.amount : 0;
    const date_paid = props.transactionObj?.payment_date;
    const notes = props.transactionObj?.remarks;
    const method = props.transactionObj?.payment_method ? props.transactionObj?.payment_method : -1;
    const bank_acc=props.transactionObj?.bank_account;
    const tds_amount = props.transactionObj ? props.transactionObj?.purchase_invoice.tds_amount : props.invoiceObj?.tds_amount;
    const entity_id = (props.fromPreviewScreen) ?  invoiceObj?.entity.id : invoiceObj?.entity_id;
    const selectedEntity = props.selectedAccount.entities.find((entity) => Number(entity.id) === entity_id);
    const bankList = entity_id ? (selectedEntity?.bank_accounts && selectedEntity?.bank_accounts.length > 0 ? selectedEntity?.bank_accounts : []) : [];
    const currencyObj = props.transactionObj /* || props.fromPreviewScreen */ ? invoiceObj?.currency : invoiceObj?.currency;
    const [tdsChange, setOnTdsChange] = useState(tds_amount>0? true:false);
    const [tdsTypeObj, setTDSTypeObj] = useState(invoiceObj?.tds_type_object);
    const [tdsAmount, setTDSAmount] = useState(invoiceObj?.tds_amount);
    //const [paidInFullClicked, setPaidInfullClicked] = useState(false);
    const [dueAmount, setDueAmount] = useState(props.transactionObj ? props.transactionObj?.purchase_invoice.due : props.invoiceObj?.due);

    const invoiceAccountCountry = props.selectedAccount?.country;

     // handles when user changes input in date inputfield
     const handleChangeDate = e => {
        setDate(e.target.value);
     };

     const onSendEMailClicked = (e) => {
        setSendEmail(!sendEmail);
    }

    const onConvertToTax = (e) => {
        setConvertToTax(!convertToTax);
    }
    
    const onItemClearClick1 = () => {
        setTDSTypeObj(undefined);
        setTDSAmount(0);
    }
  
    useEffect(() => {
            if(props.transactionObj) {
                setFullAmount(amount_paid);
                setDate(date_paid);
                setPaymentMethod(method);
                setPaymentNotes(notes);
                setBankAccount(bank_acc);
            }
    }, [props.transactionObj]);

    useEffect(() => {
        if(!isMount) {
            if(fullAmt > dueAmount) {
                setErrorMessage(`The amount field cannot be greater than ${formatAmount(dueAmount, currencyObj)}.`)
            } 

            if(tdsAmount > 0 && fullAmt > total_amount - tdsAmount) {
                setErrorMessage(`The amount field cannot be greater than ${formatAmount(total_amount - tdsAmount, currencyObj)}.`)
            } 

            if(!props.transactionObj && fullAmt > dueAmount) {
                setErrorMessage(`The amount field cannot be greater than ${dueAmount}.`)
            }

            if(!props.transactionObj && tdsAmount > 0 && fullAmt > total_amount - tdsAmount) {
                setErrorMessage(`The amount field cannot be greater than ${formatAmount(total_amount - tdsAmount, currencyObj)}.`)
            }
    
            // if(!props.transactionObj && invoiceObj?.status === InvoiceStatus.PARTIAL && tds_amount > 0 && fullAmt > due_amount - tds_amount) {
            //     setErrorMessage(`The amount field cannot be greater than ${currencyFormatterForAmount(due_amount - tds_amount)}.`)
            // }
        }
    }, [fullAmt])

    useEffect(() => {
        if(!isMount) {
            if(props.bankAccObj) {
                setBankAccount(props.bankAccObj);
                props.setBankAccObj(undefined);

            }
            
        } 

    }, [props.bankAccObj]);


    const onAddUpdatePaymentClick = () => {
        const invoice_account_id = props.selectedAccount?.id
        const invoice_id = props.invoiceObj?.id;
        const transaction_id = props.transactionObj?.id;

        const amount = parseFloat(fullAmt);
        if(!amount) {
            enqueueSnackbar('Amount cannot be empty!', {variant:'error'});
            return;
        }

        const payment_method = paymentMethod;
        if(payment_method === -1 || payment_method === '-1') {
            enqueueSnackbar('Please Select Payment Method', {variant:'error'});
            return;
        }

        const remarks = paymentNotes;

        const payment_date = date;
        if(!payment_date) {
            enqueueSnackbar('Please enter todays date!', {variant:'error'});
            return;
        }

        
        const send_receipt = sendEmail;

        const bank_account = bankAccount ? bankAccount?.id : undefined;

        setLoading(true);
        if(props.transactionObj) {
            callUpdateTransactionApi(invoice_account_id, transaction_id, amount, payment_method, bank_account, remarks, payment_date);
        } else {
        
        const tds_amount=tdsAmount;
        const tds_type_object_id=tdsTypeObj?.id;
        addPaymentBillTransactionApi(invoice_account_id, invoice_id, amount, payment_method, bank_account,
            remarks, payment_date, send_receipt, convertToTax, tds_amount, tds_type_object_id)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addPaymentBillTransactionApi: ', res);
                setLoading(false);

                enqueueSnackbar('Payment added successfully', {variant:'success'});
                if(props.drawerState === SelectedDrawerState.SHOW_BILLS || props.drawerState === SelectedDrawerState.SHOW_VENDORS) {
                    props.updateBillItemInBillList && props.updateBillItemInBillList(res);
                    
                    if(props.setUpdatedBillObj) {
                        let invoiceObj = res?.purchase_invoice;
                        let obj = {
                            ...props.invoiceObj,
                            paid: invoiceObj.paid,
                            status: invoiceObj.status,
                            due: invoiceObj.due,
                             can_edit: invoiceObj.can_edit
                        }
                        props.setUpdatedBillObj(obj);
                    }
                }

                /* if(props.drawerState === SelectedDrawerState.SHOW_TRANSACTIONS || props.drawerState === SelectedDrawerState.SHOW_CLIENTS) {
                    props.setAddTransaction && props.setAddTransaction(true);
                    props.updateTranscItemInTranscList && props.updateTranscItemInTranscList(res);
                } */
                //props.openAddPaymentBillDrawer && props.handleCloseAddNewPaymentDrawer();
                if(props.fromPreviewScreen){ 
                    let invoiceObj = res.purchase_invoice;
                    let obj = {
                        ...props.invoiceObj,
                        paid: invoiceObj.paid,
                        status: invoiceObj.status,
                        due: invoiceObj.due,
                        can_edit: invoiceObj.can_edit
                    }
                    props.setPreviewBillObj({purchase_invoice: obj});
                }

                onCloseAddEditPaymentDrawer();

                trackGAEvent(props.selectedAccount.name, 'Bill Payment added', `${props.user.firstname} ${props.user.lastname}`);
                
            })
            .catch((e) => {
                consoleToLog('Error addPaymentBillTransactionApi: ', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            });

        }    
     }

    const callUpdateTransactionApi = (invoice_account_id, transaction_id, amount, payment_method,bank_account,
        remarks, payment_date) => {
            updatePaymentBillApi(invoice_account_id, transaction_id, amount, payment_method, bank_account,
                remarks, payment_date, tdsAmount)  
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response updatePaymentTransactionApi: ', res);
                    setLoading(false);

                    enqueueSnackbar('Bill Payment updated successfully', {variant:'success'});

                    if(props.drawerState === SelectedDrawerState.SHOW_PAYMENTS) props.updateTranscItemInTranscList(res);
                    onCloseAddEditPaymentDrawer();
                    trackGAEvent(props.selectedAccount.name, 'Bill Payment updated', `${props.user.firstname} ${props.user.lastname}`);
                })
                .catch((e) => {
                    consoleToLog('Error updatePaymentTransactionApi: ', e.response);
                    setLoading(false);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant: 'error'});
                        return;
                    }
                });
    }

    const onPaidInFullClick = () => {
        if(invoiceObj?.status === InvoiceStatus.PARTIAL) {
            setFullAmount(dueAmount.toFixed(2));
        } else {
            setFullAmount((total_amount - tdsAmount).toFixed(2));
        }
        //when changing Full amount, set TDS empty 
        //setTDSAmount(0);
    }
    
    const onCloseAddEditPaymentDrawer = () => {
        setFullAmount('');
        setDate(moment(new Date()).format("YYYY-MM-DD"));
        setPaymentMethod(-1);
        setPaymentNotes('');
        setBankAccount(-1);
        props.transactionObj && props.setTransactionObj(undefined);
        props.handleCloseAddPaymentDrawer();
    }

    const onAddBankAccountClick = (e) => {
        props.handleBankDrawerOpen();
        if(props.fromPreviewScreen) {
            props.setEntityObjForBankAcc(invoiceObj.entity);
        } else {
            let obj = {
                id: invoiceObj.entity_id
            }
            props.setEntityObjForBankAcc(obj);
        }
    }

    const CreateItemButton = ({ children, ...other }) => (
        <Paper {...other} 
            style={{marginTop:'6px',
                    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' 
                    }}>
        <Button fullWidth
            sx={createItemButtonStyle}
            variant="outlined"
            color="primary"
            onMouseDown={onAddBankAccountClick}
        >
        <AddCircleOutlineIcon fontSize='small' sx={createItemIconStyle}/>  
            Add Bank Account
        </Button>
        {children}
    </Paper>
    );

    const renderOptions1 = (option) => {
        return (
            <Box component='div' sx={itemOptionStyle}>
                <Grid container alignItems='center' justifyContent="space-between">
                    <Grid size={10}>
                        <Typography variant='body2'>
                            {option.tds_display}
                        </Typography> 
                    </Grid>

                    <Grid size={2} style={{textAlign:'right'}}>
                        <Typography variant='body2'>
                            {option.rate}
                        </Typography>
                    </Grid>
                </Grid>    

            </Box>
            );
    }

    const onTDSItemChanged = (e, newValue) => {
        if(newValue){
            setTDSTypeObj(newValue);
            // let calculateTDSAmount = parseFloat((newValue.rate * props.invoiceObj.subtotal) / 100);
            // calculateDueAmount(parseFloat(calculateTDSAmount.toFixed(2)));
            // setTDSAmount(parseFloat(calculateTDSAmount.toFixed(2)));
        }
        else{
            setTDSTypeObj(undefined);
            //setTDSAmount(0);
        }
    }

    const onCalculateTDS = () => {
        const finalTds=(dueAmount-fullAmt).toFixed(2);
        const due = (total_amount - finalTds).toFixed(2);
        setTDSAmount(parseFloat(finalTds));
        setDueAmount(parseFloat(due));
    }

    const calculateDueAmount = (input) => {
        if(invoiceObj?.status !== InvoiceStatus.PARTIAL && invoiceObj?.status !== InvoiceStatus.PAID) {
            input ? setDueAmount(total_amount - input) : setDueAmount(total_amount);
        } else {
            input ? setDueAmount(due_amount - input) : setDueAmount(due_amount);
        }
    }

    return (
            <RightToLeftSideDrawer title={props.transactionObj ? "Update Payment" :"Add Payment" }
                openDrawer={props.openAddPaymentDrawer}
                closeDrawer={onCloseAddEditPaymentDrawer}
                fromPreviewScreen={props.fromPreviewScreen ? true : false}>

                <div className='modal__main'>

                    <Grid container style={{ borderBottom: '1px solid rgba(0,0,0, .1)', paddingBottom:'4px'}}> 
                        <Grid size={4}>
                            <Typography variant="subtitle1" className='custom_margin_bottom' style={{ color: "#9198a6" }}>
                                Bill Total
                            </Typography>
                            <Typography variant="subtitle1" style={{ fontSize: "20px" }}>
                                {formatAmount(total_amount, currencyObj)}
                            </Typography>
                        </Grid> 

                        {isInvoiceAccountCountryIndia(invoiceAccountCountry) && (
                        <Grid size={4} style={{ textAlign: 'center' }}>
                            <Typography variant="subtitle1" className='custom_margin_bottom' style={{ color: "#9198a6" }}>
                                TDS Amount
                            </Typography>
                            <Typography variant="subtitle1" style={{ fontSize: "20px" }}>
                                {formatAmount(tdsAmount ? tdsAmount : 0, currencyObj)}
                            </Typography>
                        </Grid>)}

                        <Grid size={4} style={{ textAlign: 'right' }}>
                            <Typography variant="subtitle1" className='custom_margin_bottom' style={{ color: "#9198a6" }}>
                                Amount Due
                            </Typography>   
                            <Typography variant="subtitle1" style={{ fontSize: "20px" }}>
                                {formatAmount(dueAmount, currencyObj)}
                            </Typography> 
                        </Grid>
                    </Grid>


                    <Grid container spacing={1} style={{marginTop: "15px"}}> 
                        <Grid size={6}>
                            <div style={{ display: "flex" }} className='custom_margin_bottom'>
                                <Typography variant="subtitle1">
                                    Payment *
                                </Typography>
                                <Typography variant="subtitle1" 
                                    onClick={onPaidInFullClick} 
                                    style={{marginLeft: "5px", paddingRight: "10px", cursor: "pointer", fontWeight: "bold", color: "#4c51bf"}}>
                                        Paid in full
                                </Typography>
                            </div>
                            <TextField fullWidth 
                                id="payment" 
                                variant="outlined"
                                type="number"
                                margin="dense"
                                autoComplete="off"
                                value={fullAmt}
                                onChange={(e)=>{
                                    let input = e.target.value;
                                    // Regular expression to allow only numbers with up to 2 decimal places
                                    if (input === "" || /^\d+(\.\d{0,2})?$/.test(input)) {
                                        setFullAmount(input);
                                        //when changing Full amount, set TDS empty 
                                        //setTDSAmount(0);
                                    }
                                }}
                                slotProps={{
                                    style: {fontSize: 13, color: "#ccc"},
                                    //input : {min}
                                }}
                                error={(fullAmt > total_amount - tdsAmount) || (!props.transactionObj && fullAmt > dueAmount)}
                                helperText={(fullAmt > total_amount - tdsAmount) || (!props.transactionObj && fullAmt > dueAmount) ? errorMessage : ''}
                                
                                onKeyPress={(event) => {
                                    if (event?.key === '-' || event?.key === '+') {
                                        event.preventDefault();
                                    }
                                }}
                                size='small'
                            />
                        </Grid>    


                        <Grid size={6}>
                            <Typography variant="subtitle1" className='custom_margin_bottom'>
                               Payment Date *
                            </Typography>   

                            <TextField fullWidth 
                                id="payment_date"
                                variant="outlined"
                                type="date"
                                margin="dense"
                                autoComplete="off"
                                value={date}
                                onChange={handleChangeDate}
                                slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                                size='small'
                            />
                        </Grid>
                    </Grid> 
                    {!props.transactionObj && isInvoiceAccountCountryIndia(invoiceAccountCountry) && <Grid container item spacing={1}>
                        <Grid size={12}>
                            <Tooltip title={!fullAmt || fullAmt <= 0 ? "Enter Payment amount" : ''}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={tdsChange}
                                            disabled={!fullAmt || fullAmt<=0}
                                            onChange={() =>{
                                                if(tdsChange){
                                                    setTDSAmount(0);
                                                    calculateDueAmount(0);
                                                    setTDSTypeObj(null);
                                                }
                                                setOnTdsChange(!tdsChange)
                                            }
                                            }
                                        />
                                    }
                                    label="Deduct TDS?"
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>}
                    {!props.transactionObj && 
                    isInvoiceAccountCountryIndia(invoiceAccountCountry) 
                    && tdsChange &&  
                    <Grid container spacing={1} sx={{marginTop: "-7px"}}> 
                        <Grid size={7}>
                            <Typography variant="subtitle1" className='custom_margin_bottom'>
                                TDS
                            </Typography>   
                            <Autocomplete
                                style={{marginTop:'2px'}}
                                id="combo-box-demo"
                                disabled={!fullAmt || fullAmt<=0}
                                options={props.tdsTypeList}
                                sx={muiAutocompleteInputRootStyle}
                                getOptionLabel={(option) => option.tds_display || ''}
                                //onInputChange={(e, newInputvalue) => onInvoiceItemNameChanged(e, newInputvalue, index)}
                                renderOption={(option) => renderOptions1(option)}
                                onChange={(e, newValue) => {
                                    onTDSItemChanged(e, newValue);
                                }}
                                value={tdsTypeObj}
                                renderInput={params => (
                                    <TextField {...params} variant="outlined"
                                    placeholder='Search items'
                                    fullWidth
                                        />
                                )}
                                clearIcon={<CloseIcon fontSize='small' onClick={onItemClearClick1}/>}
                            />
                        </Grid>    

                        <Grid size={5}>
                            <div style={{ display: "flex" }}>
                                <Typography variant="subtitle1" 
                                    style={{ marginBottom:'-10px'}}>
                                TDS Amount
                                </Typography>
                                { //invoiceObj?.tds_amount<=0 && 
                                    fullAmt > 0 &&
                                    <Typography variant="subtitle1" 
                                        onClick={()=>onCalculateTDS()} 
                                        style={{marginBottom: "-10px", marginLeft: "5px", cursor: "pointer", fontWeight: "bold", color: "#4c51bf"}}
                                    >
                                        Calculate TDS
                                </Typography>} 
                            </div>  
                            <TextField id="tds-amount"
                                variant='outlined'
                                type='number'
                                disabled={!fullAmt || fullAmt<=0}
                                margin='dense'
                                style={{color:'#ccc'}}
                                placeholder='TDS Amount'
                                value={tdsAmount}
                                onChange={(e) => {
                                    let input=e.target.value;
                                    if (input === "" || /^\d+(\.\d{0,2})?$/.test(input)) {
                                        setTDSAmount(parseFloat(input));
                                        calculateDueAmount(parseFloat(input));
                                    }
                                }}
                                slotProps={{
                                // classes: { root: classes.labelRoot, shrink: classes.shrink },
                                    style: {fontSize: 14, color: "#ccc"}
                                }}
                                //sx={classes.input}
                                clearIcon={<CloseIcon fontSize='small' onClick={onItemClearClick1}/>}
                                size='small'
                            />
                            
                        </Grid>
                    </Grid> }
                    <Grid size={12} className='custom_margin_top'>
                        <Typography variant="subtitle1" className='custom_margin_bottom'>
                            Payment Method *
                        </Typography>   

                        <TextField fullWidth 
                            id="payment_method"
                            variant="outlined"
                            margin="dense"
                            autoComplete="off"
                            select
                            value={paymentMethod}
                            defaultValue={-1}
                            //label='Select Payment Method'
                            slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                            size='small'
                        >
                            <MenuItem value={-1}>Select Payment Method</MenuItem>
                            <MenuItem value="bank">Bank</MenuItem>
                            <MenuItem value="cheque">Cheque</MenuItem>
                            <MenuItem value="cash">Cash</MenuItem>    
                            <MenuItem value="upi">UPI</MenuItem>
                            <MenuItem value="credit_card">Credit Card</MenuItem>
                            <MenuItem value="external">External</MenuItem>
                        </TextField>    
                    </Grid>

                    <Grid size={12} className='custom_margin_top'>
                        <Typography variant="subtitle1" style={{marginBottom:'-4px'}}>
                            Bank Account
                        </Typography>

                        <Autocomplete
                            id="combo-box-demo"
                            options={bankList}
                            value={bankAccount}
                            getOptionLabel={(option) => option?.bank_name || ''}
                            onChange={(e, newValue) => {
                                setBankAccount(newValue);
                            }}
                            renderInput={params => (
                                <TextField {...params} 
                                    variant="outlined"
                                    placeholder='Select Bank Account'
                                    fullWidth
                                    size="small"
                                />
                            )}
                            PaperComponent={CreateItemButton}
                            size='small'
                            sx={muiAutocompleteInputRootStyle}
                            isOptionEqualToValue={(option, value) => 
                                option?.id === value?.id
                            }
                        />
                    
                        {/* <TextField fullWidth 
                            id="bank_account"
                            variant="outlined"
                            margin="dense"
                            autoComplete="off"
                            select
                            defaultValue={bankAccount}     
                            
                            disabled={paymentMethod==="cash"}
                            value={bankAccount}
                            onChange={(e) =>    { 
                                if(bankList && bankList.length > 0) {
                                    setBankAccount(e.target.value);
                                } else {
                                    setBankAccount('-1');
                                }
                            }}
                        >
                        {<MenuItem value="-1">Select Bank Account</MenuItem>}
                        {bankList && bankList.length === 0  && 
                            <MenuItem value={0}>
                                <Button 
                                    onClick={onAddBankAccountClick}
                                    color='secondary'
                                    variant='contained'>
                                        <AddCircleOutlineIcon fontSize='small' style={{marginRight:'4px'}}/>
                                        Add Bank Account
                                    </Button>
                            </MenuItem>}
                            {
                        bankList && bankList.length > 0  &&
                        bankList.map((account) => {
                            return <MenuItem key={account.id} value={account.id}>{account.bank_name}({account.account_number})</MenuItem>
                        
                        })
                    }
                        </TextField>     */}
                    
                    
                    </Grid>

                    <Grid size={12} className='custom_margin_top'>
                        <Typography variant="subtitle1" className='custom_margin_bottom'>
                            Payment Notes
                        </Typography>   

                        <TextField fullWidth 
                            id="payment_notes"
                            variant="outlined"
                            margin="dense"
                            multiline
                            rows={4}
                            placeholder='Enter payment note. (note will only be seen by you)'
                            value={paymentNotes}
                            onChange={(e) => setPaymentNotes(e.target.value)}
                            size='small'
                        />
                    </Grid>

                    {/* {!props.transactionObj &&
                    <Grid item md={12}
                        className={classes.customMarginTop}>
                        <FormControlLabel
                            classes={{
                                root: classes.MuiFormControlLabelroot
                            }}
                            control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => onSendEMailClicked(e)}
                                checked={sendEmail}
                            />
                            }
                            label={`Send ${client_name} a payment receipt email`}
                        />
                    </Grid>} */}
                    
                    {!props.transactionObj && (props.invoiceObj?.invoice_type === SendEmailType.PROFORMA && fullAmt > 0 || 
                    props.invoiceObj?.invoice_type === SendEmailType.PROFORMA 
                    && props.invoiceObj?.status !== InvoiceStatus.PAID && props.invoiceObj?.status !== InvoiceStatus.CANCELLED) &&
                    <Grid size={12}>
                        <FormControlLabel
                            control={
                            <Checkbox
                                name="checkedB"
                                color="primary"
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => onConvertToTax(e)}
                                checked={convertToTax}
                                disabled={Number(fullAmt) === props.invoiceObj.total || Number(fullAmt) === props.invoiceObj.due}
                            />
                            }
                            label={`Convert to Tax Invoice`}
                        />
                    </Grid>}

                    <div className="modal__footer">
                        <Button variant="contained" color="primary" 
                            onClick={onAddUpdatePaymentClick}
                            disabled={(fullAmt > total_amount ) || (!props.transactionObj && fullAmt > dueAmount) || loading || props.accessLevelViewer}
                            style={{opacity: (fullAmt > total_amount ) || (!props.transactionObj && fullAmt > dueAmount) ? 0.7 : 1}}
                            className="modal__button">
                            {loading && <CircularProgress size={15} className='loading__style'/>}
                            {props.transactionObj ? 'Update' : 'Save' }    
                        </Button>
                        <Button variant="outlined" style={{
                            minWidth: '130px',
                        }} onClick={onCloseAddEditPaymentDrawer}>
                            Cancel
                        </Button>
                    </div> 
                </div>    
            </RightToLeftSideDrawer>
    );
})

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    drawerState: state.drawerState.setDrawerState,
    tdsTypeList: state.invoiceAccounts.tdsTypeList,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(AddEditBillPaymentDrawer));