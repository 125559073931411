import React from 'react';
import BaseSection from './BaseSection';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { PeriodicityArr } from '../../../util/AppUtil';
import { formatAmount } from '../../../util/currencyUtil';

const BillingSchedule = ({item, handleDrawerOpen, proposalObj, onShowOrHidePage}) => {

    const {data} = item;
    const {title, quantity_terminology, show_quantity, items} = data;

    const currency = proposalObj?.currency;

    return (
        <BaseSection title="Billing" item={item}
            handleDrawerOpen={handleDrawerOpen}
            onShowOrHidePage={onShowOrHidePage}>

            <Grid size={12} className='edit_section_container'>

                <Grid size={12}>
                    <Typography className='edit_section_heading' 
                        dangerouslySetInnerHTML={{__html: title}} />
                </Grid>

                <Grid size={12}>
                <TableContainer component={Paper} variant='outlined' >
                        <Table>
                            <TableHead style={{padding:0, background:'#f4f4f4'}}>
                                <TableRow>
                                    <TableCell className="item_name_width add_item_table_heading">Item name</TableCell>
                                    <TableCell className="quantity_width add_item_table_heading">
                                        <span className={!show_quantity && 'hide_page_style'}>{quantity_terminology}</span>
                                    </TableCell>
                                    <TableCell className="price_width add_item_table_heading">Price</TableCell>
                                    <TableCell className="price_width add_item_table_heading">Amount</TableCell>
                                    <TableCell className="periodicity_width add_item_table_heading">Periodicity</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items && items.length > 0 && items.map((item, index) => (
                                    <TableRow>
                                        <TableCell className={`item_name_width`}>
                                            <Typography className='add_item_table_descp'>
                                                {item.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={`quantity_width`}>
                                            <Typography className= {`add_item_table_descp ${!show_quantity && 'hide_page_style'}`}>
                                                {item.quantity}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={`price_width`}>
                                            <Typography className='add_item_table_descp'>
                                                <strong>{formatAmount(item.price, currency)}</strong>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={`price_width`}>
                                            <Typography className='add_item_table_descp'>
                                                <strong>{formatAmount(item.amount, currency)}</strong>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={`periodicity_width`}>
                                            <Typography className='add_item_table_descp'>
                                                {PeriodicityArr.find(period => period.value === item.periodicity)?.name}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>

        </BaseSection>
    );
};

export default BillingSchedule;
