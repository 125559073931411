import React, {useState, useEffect, Suspense, lazy} from 'react';
import {connect} from 'react-redux';
// import PreviewAndDownloadInvoice from '../components/PreviewAndDownloadInvoice';
// import InvoiceAccountVerification from '../components/InvoiceAccountVerification';
// import RootPage from '../components/RootPage';
// import NotFoundPage from '../components/NotFoundPage';
// import VerifyEmailPage from '../components/VerifyEmailPage';
// import OnboardingComponent from '../components/onboarding/OnboardingComponent';
// import PaymentSuccessPages from '../components/PaymentSuccessPages';
import BaseComponent from '../components/Base/BaseComponent';
// import DashboardComponent from '../components/DashboardComponent';
// import InvoiceListComponent from '../components/InvoiceListComponent';
import { AccessLevel, AppRoutes, consoleToLog, InvoicePlans } from '../util/AppUtil';
import LeftMenuComponent from '../components/LeftMenuComponent';
import SnackbarComponent from '../components/SnackbarComponent';
// import TransactionsComponent from '../components/TransactionsComponent';
// import ClientsComponent from '../components/ClientsComponent';
// import AccountMembers from '../components/AccountMembers';
// import SettingsComponent from '../components/SettingsComponent';
// import MyProfile from '../components/MyProfile';
import { PropsProvider } from '../components/context';
import { trackGAEvent, setHeightForComponent } from '../util/AppUtil';
import AddEditClientDrawer from '../components/AddEditClientDrawer';
import ClientContactModal from '../components/modals/ClientContactModal';
import AddEditBankDrawer from '../components/AddEditBankDrawer';
import ShowInvoicesDrawer from '../components/ShowInvoicesDrawer';
import ShowInfoDialog from '../components/ShowInfoDialog';
import AddEditPaymentDrawer from '../components/AddEditPaymentDrawer';
import AddNewPaymentDrawer from '../components/AddNewPaymentDrawer';
import CreateEditPreviewInvoiceModal from '../components/modals/CreateEditPreviewInvoiceModal';
import InvoiceModalComponent from '../components/modals/InvoiceModalComponent';
//import OutsideOnboardingComponent from '../components/OutsideOnboardingComponent';
import { useIsMount } from '../components/useIsMount';
import { getInvoiceAccountsList, setUserObj } from '../actions/invoiceAccount';
import { getUserDetailsObjApi } from '../services/authService';
import { setLoading } from '../actions/loading';
import { SelectedDrawerState, setSelectedDrawerState } from '../actions/drawer';
//import RevenueByClientReport from '../components/RevenueByClientReport';
import ClientSummaryDialog from '../components/modals/ClientSummaryDialog';
//import RevenueByItemsReports from '../components/RevenueByItemsReports';
import { extractBasePath } from '../util/AppUtil';
//import Estimates from '../components/Estimates';
import EstimateSettingsModal from '../components/modals/EstimateSettingsModal';
import { setSelectedInvoiceAccount, setInvoiceAccountsList } from '../actions/invoiceAccount';
import CreateEditPreviewProposalModal from '../components/proposals/CreateEditPreviewProposalModal';
//import Proposals from '../components/proposals/Proposals';
//lientProposalPreview from '../components/proposals/ClientProposalPreview';
//import RazorpaySuccess from '../components/RazorpaySuccess';

//import InvoiceItemsComponent from '../components/InvoiceItemsComponent';
//import VendorsComponent from '../components/VendorsComponent';
//import BillCategoriesComponent from '../components/BillCategoriesComponent';
import BillUploadModal from '../components/modals/BillUploadModal';
import BillDetailModalComponent from '../components/modals/BillDetailModalComponent';
//import BillListComponent from '../components/BillListComponent';
import CreateEditPreviewBillModal from '../components/modals/CreateEditPreviewBillModal ';
import AddEditBillPaymentDrawer from '../components/AddEditBillPaymentDrawer';
//import BillTransactionsComponent from '../components/BillTransactionsComponent';

import DocumentModal from '../components/contract_document/DocumentModal';
//import ESignDocuments from '../components/contract_document/ESignDocuments';
import SelectDocumentOrTemplateModal from '../components/contract_document/SelectDocumentOrTemplateModal';
//import PreviewAndDocument from '../components/contract_document/PreviewAndDocument';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid2';
//import BillComponent from '../components/BillComponent';
import ExpenseBillDetailModal from '../components/modals/ExpenseBillDetailModal';
import { useTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Lazy-loaded components
const RootPage = lazy(() => import('../components/RootPage'));
const OnboardingComponent = lazy(() => import('../components/onboarding/OnboardingComponent'));
const PreviewAndDownloadInvoice = lazy(() => import('../components/PreviewAndDownloadInvoice'));
const InvoiceAccountVerification = lazy(() => import('../components/InvoiceAccountVerification'));
const VerifyEmailPage = lazy(() => import('../components/VerifyEmailPage'));
const PaymentSuccessPages = lazy(() => import('../components/PaymentSuccessPages'));
const RazorpaySuccess = lazy(() => import('../components/RazorpaySuccess'));
const ClientProposalPreview = lazy(() => import('../components/proposals/ClientProposalPreview'));
const PreviewAndDocument = lazy(() => import('../components/contract_document/PreviewAndDocument'));

const DashboardComponent = lazy(() => import('../components/DashboardComponent'));
const InvoiceListComponent = lazy(() => import('../components/InvoiceListComponent'));
const TransactionsComponent = lazy(() => import('../components/TransactionsComponent'));
const ClientsComponent = lazy(() => import('../components/ClientsComponent'));
const NotFoundPage = lazy(() => import('../components/NotFoundPage'));
const OutsideOnboardingComponent = lazy(() => import('../components/OutsideOnboardingComponent'));
const InvoiceItemsComponent = lazy(() => import('../components/InvoiceItemsComponent'));
const AccountMembers = lazy(() => import('../components/AccountMembers'));
const MyProfile = lazy(() => import('../components/MyProfile'));
const RevenueByClientReport = lazy(() => import('../components/RevenueByClientReport'));
const RevenueByItemsReports = lazy(() => import('../components/RevenueByItemsReports'));
const Estimates = lazy(() => import('../components/Estimates'));
const Proposals = lazy(() => import('../components/proposals/Proposals'));
const VendorsComponent = lazy(() => import('../components/VendorsComponent'));
const BillCategoriesComponent = lazy(() => import('../components/BillCategoriesComponent'));
const ESignDocuments = lazy(() => import('../components/contract_document/ESignDocuments'));
const BillListComponent = lazy(() => import('../components/BillListComponent'));
const BillTransactionsComponent = lazy(() => import('../components/BillTransactionsComponent'));
const SettingsComponent = lazy(() => import('../components/SettingsComponent'));
const BillComponent = lazy(() => import('../components/BillComponent'));

const AppRouter = (props) => {
    const isMount = useIsMount();
    const theme = useTheme();

    const[showDrawer, setShowDrawer] = useState(true);

    const [openAddPaymentDrawer, setOpenAddPamentDrawer] = useState(false);
    const [invoiceObj, setInvoiceObj] = useState(undefined);
    const [paymentInvoiceObj, setPaymentInvoiceObj] = useState(undefined);
    const [paymentBillObj, setPaymentBillObj] = useState(undefined);

    const [openDialog, setOpenDialog] = useState(false);
    const [openBillDialog, setOpenBillDialog]=useState(false);
    const [openBillDetailDialog, setOpenBillDetailDialog]=useState(false);
    const [billObj, setBillObj]=useState(undefined);
    const [selectedEntity, setSelectedEntity]=useState(undefined);
    const [isFromUnclaimedBill, setIsFromUnclaimedBill]=useState(false);
    const [billId,setBillId]=useState(undefined);

    const [openAddNewPaymentDrawer, setOpenAddNewPaymentDrawer] = useState(false);
    const [updateTranscItemFromParent, setUpdateTranscItemFromParent] = useState(undefined);
    const [transactionObj, setTransactionObj] = useState(undefined);
    const [addTransaction, setAddTransaction] = useState(undefined);

    const [openClientDrawer, setOpenClientDrawerDrawer] = useState(false);
    const [isFromVendors, setIsFromVendors] = useState(false);
    const [openClientContactDrawer, setOpenClientContactDrawer] = useState(false);
    const [newClientObj, setNewClientObj] = useState(undefined);
    const [clientObjFromParent, setClientObjFromParent] = useState(undefined);
    const [editClient, setEditClient] = useState(undefined);
    const [isAddClient, setIsAddClient] = useState(false);

    const [openBankDrawer, setOpenBankDrawer] = useState(false);
    const [bankAccObj, setBankAccObj] = useState(undefined);
    const [entityObjForBankAcc, setEntityObjForBankAcc] = useState(false);
    const [updatedInvoiceObj, setUpdatedInvoiceObj] = useState(undefined);

    const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
    const [openEditPreviewBillDialog, setOpenEditPreviewBillDialog] = useState(false);
    
    const [openInvoicesDrawer, setOpenInvoicesDrawer] = useState(false);
    const [dueInvoiceObj, setDueInvoiceObj] = useState(undefined);
    const [clientInvoiceObj, setClientInvoiceObj] = useState(undefined);

    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [newInvoiceClicked, setNewInvoiceClicked] = useState(false);

    const [transactionsComponentState, setTransactionsComponentState] = useState(undefined);
    const [invoicesComponentState, setInvoicesComponentState] = useState(undefined);
    const [scrollContainer, setScrollContainer] = useState(null);
    const [callgetInvoiceListApi, setCallGetInvoiceListApi] = useState(false);
    const [estimateComponentState, setEstimateComponentState] = useState(undefined);
    const [callgetEstimateListApi, setCallgetEstimateListApi] = useState(false);

    const [openClientSummaryModal, setOpenClientSummaryModal] = useState(false);
    const [clientStatementObj, setClientStatmentObj] = useState(undefined);

    const [openEstimatesSettingsModal, setOpenEstimatesSettingsModal] = useState(false);
    const [showEstimateClicked, setShowEstimateClicked] = useState(false);
    const [openProposalModal, setOpenProposalModal] = useState(false);

    const [proposalsComponentState, setProposalsComponentState] = useState(undefined);
    const [updatedProposalObj, setUpdateProposalObj] = useState(undefined);

    const [openDocumentModal, setOpenDocumentModal] = useState(false);
    const [openSelectDocTempModal, setOpenSelectDocTempModal] = useState(false);

    const [documentsComponentState, setDocumentsComponentState] = useState(undefined);
    const [updatedDocumentObj, setUpdatedDocumentObj] = useState(undefined);
    const [billComponentState, setBillComponentState] = useState(undefined);
    const [updatedBillObj, setUpdatedBillObj] = useState(undefined);
    const [openAddPaymentBillDrawer, setOpenAddPamentBillDrawer] = useState(false);
    const [fromPreviewScreen, setFromPreviewScreen] = useState(false);
    const [previewBillObj, setPreviewBillObj] = useState(undefined);
    const [updateBillList, setUpdateBillList] = useState([]);

    const [newExpense, setNewExpense] = useState(false);
    const [expensesComponentState, setExpensesComponentState] = useState(undefined);
    const [openExpenseBillDetailDialog, setOpenExpenseBillDetailDialog]=useState(false);
    const [expenseBillObj, setExpenseBillObj] = useState(undefined);


    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;

    const entityList = props.selectedAccount.entities;
    const unVerifiedEntityEmails = entityList && entityList.length > 0 ? entityList.filter((entityItem) => !entityItem.is_email_verified) : [];
    const is_entity_email_unverified = unVerifiedEntityEmails && unVerifiedEntityEmails.length > 0;
    const isInvoiceAccountPlanFree = props.selectedAccount?.plan?.toLowerCase() === InvoicePlans.FREE;
    const entityLimitExceeded = props.selectedAccount?.entity_limit_exceeded;
    const canCreateInvoice = props.selectedAccount?.can_create_invoice;
    const pathname = window.location.pathname;
    const basePath = extractBasePath(false);
    
    useEffect(() => {
        if(Object.keys(props.selectedAccount).length === 0 && !props.isLoading && pathname !== '/' && basePath !== '/client') {
            props.setLoading(true);
            props.getInvoiceAccountsList(props.selectedAccount);
        }
        if(Object.keys(props.user).length === 0 && !props.isLoading && pathname !== '/' && basePath !== '/client') {
            props.setLoading(true);
            const fetchData = async() => {
                try {
                    let response = await getUserDetailsObjApi();
                    let userObj = {
                        ...response.data,
                        first_login: false,
                    }
                    props.setUserObj(userObj);
                    props.setLoading(false);
                } catch(e) {
                    props.setLoading(false);
                    consoleToLog('Error getUserDetailsObjApi', e)
                }
            }
            fetchData();
        }
    }, [pathname, props.selectedAccount, props.user]);

    const drawerAction = () => {
        //consoleToLog("*****drawerAction"); 
        setShowDrawer(!showDrawer);
    }

    const handleOpenAddPaymentDrawer = (e, invoice) => {
        trackGAEvent(props.selectedAccount.name, 'Invoice list item - Add Payment button clicked', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        setInvoiceObj(invoice);
        setOpenAddPamentDrawer(true);
    };

    const handleOpenAddPaymentBillDrawer = (e, bill) => {
        trackGAEvent(props.selectedAccount.name, 'Bill list item - Add Payment button clicked', `${props.user.firstname} ${props.user.lastname}`);
        e.stopPropagation();
        setBillObj(bill);
        setOpenAddPamentBillDrawer(true);
    };

    const handleCloseAddPaymentBillDrawer = () => {
        setOpenAddPamentBillDrawer(false);
        setBillObj(undefined);
        setFromPreviewScreen(false)
    };

    const updateBillObj = (bill) => {
        setBillObj(bill);
    }

    const handleCloseAddPaymentDrawer = () => {
        setOpenAddPamentDrawer(false);
        setInvoiceObj(undefined);
    };

    const updateInvoiceItemInInvoiceList = (obj) => {
        setPaymentInvoiceObj(obj);
    }
    const updateBillItemInBillList = (obj) => {
        //console.log("hi,hi", obj);
        setPaymentBillObj(obj);
    }

    const handleClickDialogOpen = () => {
        //props.showEstimateScreen && props.setShowEstimateScreen(false);
        if(canCreateInvoice) {
            if(!entityLimitExceeded && !props.showEstimateScreen) {
                trackGAEvent(props.selectedAccount.name, 'Invoice list item - New Invoice button clicked', `${props.user.firstname} ${props.user.lastname}`);
                setOpenDialog(true);
            } else {
                handleInfoDialogOpen();
                setNewInvoiceClicked(true);
            }
        } else {
            handleInfoDialogOpen();
            setNewInvoiceClicked(true);
        }
    };

    const handleNewBillDialogOpen=(fromNewExpense)=>{
        setOpenBillDialog(true);
        fromNewExpense && setNewExpense(true);
    }
    
    const handleClickDialogClose = () => {
        setOpenDialog(false);
        showEstimateClicked && setShowEstimateClicked(false);
    };

    const handleBillDialogClose= (shouldOpenNextModal, finalBillObj, selectedEntity) => {
        setOpenBillDialog(false);
        
        //open bill detail modal
        if(shouldOpenNextModal){
            handleOpenBillDetailModal(finalBillObj, selectedEntity);
        }
    };

    const handleEditPreviewBillDialogOpen = (entity, unclaimedBill, bill_id) => {
        if(!openEditPreviewBillDialog){
            setOpenEditPreviewBillDialog(true);
            setSelectedEntity(entity);
            setIsFromUnclaimedBill(unclaimedBill? true : false);
            setBillId(bill_id?bill_id:undefined)
        }
    }

    const handleEditPreviewBillDialogClose= () => {
        setOpenEditPreviewBillDialog(false);
        setSelectedEntity(undefined);
    }

    const handleBillDetailClickDialogClose=(isEdit)=>{
        if(isEdit){
            setOpenEditPreviewBillDialog(false);
            setSelectedEntity(undefined);
        }
        else{
            setBillObj(undefined);
            setSelectedEntity(undefined);
            setOpenBillDetailDialog(false);
        }
        setBillId(undefined);
        setIsFromUnclaimedBill(false);
        setNewExpense(false);
    }

    const handleOpenBillDetailModal=(finalBillObj, selectedEntity)=>{
        if(!openBillDetailDialog){
            consoleToLog("***bill detail dialog opening while creating bill")
            setBillObj(finalBillObj);
            setSelectedEntity(selectedEntity);
            setOpenBillDetailDialog(true);
        }
    }

    const handleOpenAddNewPaymentDrawer = () => {
        setOpenAddNewPaymentDrawer(true);
    };

    const handleCloseAddNewPaymentDrawer = () => {
        setOpenAddNewPaymentDrawer(false);
    };

    const updateTranscItemInTranscList = (obj) => {
        setUpdateTranscItemFromParent(obj);
    }
    
    const handleVendorDrawerOpen = () => {
        //trackGAEvent(props.selectedOrganization.organization.name, 'Add Contact Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setIsFromVendors(true);
        setOpenClientDrawerDrawer(true);
    }


    const handleClientDrawerOpen = () => {
        //trackGAEvent(props.selectedOrganization.organization.name, 'Add Contact Clicked', `${props.auth.user.firstname} ${props.auth.user.lastname}`);
        setOpenClientDrawerDrawer(true);
    }


    const handleClientDrawerClose=()=>{
        setIsFromVendors(false);
        setOpenClientDrawerDrawer(false);
    }

    const handleOpenClientContactDrawer = () => {
        setOpenClientContactDrawer(true);
    }

    const handleCloseClientContactDrawer = () => { 
        setOpenClientContactDrawer(false);
    }

    const addClient = (clientObj) => {
        if((props.drawerState === SelectedDrawerState.SHOW_VENDORS || props.drawerState === SelectedDrawerState.SHOW_CLIENTS) || (props.drawerState === SelectedDrawerState.SHOW_ONBOARDING)) {
                setIsAddClient(true);
                setClientObjFromParent(clientObj);
        }
        setNewClientObj(clientObj);
        props.drawerState !== SelectedDrawerState.SHOW_VENDORS && props.drawerState !== SelectedDrawerState.SHOW_CLIENTS && props.drawerState !== SelectedDrawerState.SHOW_ONBOARDING && handleOpenClientContactDrawer();
    }

    const clientEdited = (clientObj) => {
        if((props.drawerState === SelectedDrawerState.SHOW_VENDORS)|| (props.drawerState === SelectedDrawerState.SHOW_CLIENTS) || (props.drawerState === SelectedDrawerState.SHOW_ONBOARDING)) {
            setClientObjFromParent(clientObj);
        }
    }

    const handleBankDrawerOpen = () => {
        setOpenBankDrawer(true);
    };

    const handleBankDrawerClose = () => {
        setOpenBankDrawer(false);
    };

    const handleInvoiceDialogOpen = () => {
        setOpenInvoiceDialog(true);
    }


    const handleInvoiceDialogClose = () => {
        setOpenInvoiceDialog(false);
    }

    const handleInvoicesDrawerOpen = (invoice, selectedEntity) => {
        const obj = {
            ...invoice,
            entity_id: selectedEntity === 'all' ? undefined : selectedEntity
        }
        setDueInvoiceObj(obj);
        setOpenInvoicesDrawer(true);
    }

    const handleInvoicesDrawerClose = () => {
        setOpenInvoicesDrawer(false);
        setDueInvoiceObj(undefined);
    }

    const updateInvoiceItemListInClients = (invoiceObj) => {
        setClientInvoiceObj(invoiceObj)
    }

    const handleInfoDialogOpen = () => {
        setOpenInfoDialog(true);
    }
    
    const handleInfoDialogClose = () => {
        setOpenInfoDialog(false);
    }

    const setInvoiceComponentState = (invoicesStateObj) => {
        setInvoicesComponentState(invoicesStateObj)
    }

    const setTransactionComponentState = (transactionStateObj) => {
        setTransactionsComponentState(transactionStateObj)
    }

    const handleClientSummaryDialogOpen = (clientInvoicesObj, navigate) => {
        navigate(`/report/client/${clientInvoicesObj?.client.id}`);
        setClientStatmentObj(clientInvoicesObj);
        setOpenClientSummaryModal(true);
    }

    const handleClientSummaryDialogClose = (navigate) => {
        navigate(AppRoutes.CLIENT_REPORTS);
        setOpenClientSummaryModal(false);
        setClientStatmentObj(undefined);
    }

    const handleEstimatesSettingsModalOpen = () => {
        setOpenEstimatesSettingsModal(true);
    }

    const handleEstimatesSettingsModalClose = () => {
        setOpenEstimatesSettingsModal(false);
    }

    const handleProposalModalOpen = () => {
        setOpenProposalModal(true);
    }

    const handleProposalModalClose = () => {
        setOpenProposalModal(false);
    }

    const handleDocumentModalOpen = () => {
        setOpenDocumentModal(true);
    }

    const handleDocumentModalClose = () => {
        setOpenDocumentModal(false);
    }

    const handleSelectDocTempModalOpen = () => {
        setOpenSelectDocTempModal(true);
    }

    const handleSelectDocTempModalClose = () => {
        setOpenSelectDocTempModal(false);
    }

    const handleExpenseBillDetailModalOpen = (finalExpenseBillObj, selectedEntity)=>{
        if(!openExpenseBillDetailDialog){
            consoleToLog("***Expense bill detail dialog opening while creating bill")
            setExpenseBillObj(finalExpenseBillObj);
            setSelectedEntity(selectedEntity);
            setOpenExpenseBillDetailDialog(true);
        }
    }

    const handleExpenseBillDetailDialogClose=(isEdit)=>{
        if(isEdit){
            setOpenEditPreviewBillDialog(false);
            setSelectedEntity(undefined);
        }
        else{
            setExpenseBillObj(undefined);
            setSelectedEntity(undefined);
            setOpenExpenseBillDetailDialog(false);
        }
        setBillId(undefined);
        setIsFromUnclaimedBill(false);
    }

    const onNewInvoiceClick = () => {
        invoicesComponentState && setInvoiceComponentState();
        handleClickDialogOpen();
    }

    const onNewEstimateClick = () => {
        estimateComponentState && setEstimateComponentState();
        setShowEstimateClicked(true);
        setOpenDialog(true);
    }

    const onNewProposalClick = (navigate) => {
        proposalsComponentState && setProposalsComponentState();
        handleProposalModalOpen();
        navigate('/proposal/create');
    }

    const onNewDcoumentClick = () => {
        documentsComponentState && setDocumentsComponentState();
        handleSelectDocTempModalOpen();
    }


    const contextValue = {
        showDrawer,
        fullName: `${props.user.firstname} ${props.user.lastname}`,
        is_entity_email_unverified,
        handleOpenAddPaymentDrawer,
        paymentInvoiceObj,
        setPaymentInvoiceObj,
        handleInvoiceDialogOpen,
        handleEditPreviewBillDialogOpen,
        openInvoiceDialog,
        updatedInvoiceObj,
        setUpdatedInvoiceObj,
        accessLevelViewer,
        clientObjFromParent,
        setClientObjFromParent,
        handleOpenClientContactDrawer,
        setEditClient,
        handleClientDrawerOpen,
        handleVendorDrawerOpen,
        isAddClient,
        setIsAddClient,
        handleInfoDialogOpen,
        handleInvoicesDrawerOpen,
        updateTranscItemFromParent,
        setUpdateTranscItemFromParent,
        setTransactionObj,
        setOpenAddPamentDrawer,
        setAddTransaction,
        addTransaction,
        setInvoiceComponentState,
        invoicesComponentState,
        openDialog,
        setTransactionComponentState,
        transactionsComponentState,
        callgetInvoiceListApi,
        setCallGetInvoiceListApi,
        handleClickDialogOpen,
        handleClientSummaryDialogOpen,
        setOpenClientSummaryModal,
        estimateComponentState,
        setEstimateComponentState,
        setShowEstimateClicked,
        callgetEstimateListApi,
        setCallgetEstimateListApi,
        handleProposalModalOpen,
        proposalsComponentState,
        setProposalsComponentState,
        handleProposalModalOpen,
        setUpdateProposalObj,
        updatedProposalObj,
        handleDocumentModalOpen,
        setDocumentsComponentState,
        documentsComponentState,
        updatedDocumentObj,
        setUpdatedDocumentObj,
        billComponentState, 
        setBillComponentState,
        handleOpenAddPaymentBillDrawer,
        setPaymentBillObj,
        paymentBillObj,
        updatedBillObj, 
        setUpdatedBillObj,
        setOpenAddPamentBillDrawer,
        updateBillList,
        setUpdateBillList,
        onNewInvoiceClick,
        onNewEstimateClick,
        onNewProposalClick,
        onNewDcoumentClick,
        expensesComponentState, 
        setExpensesComponentState
    };

    return (
        <Router>
            <Suspense fallback={<Grid> </Grid>}>
                <Routes>
                    <Route path='/' element={<RootPage />} exact={true}/>
                    <Route path='/setup' element={<OnboardingComponent />} exact={true}/>
                    <Route path='/client/invoice/:invoice_uuid/preview' element={<PreviewAndDownloadInvoice />} exact={true}/>
                    <Route path='/account-email-verification-success' element={<InvoiceAccountVerification />} exact={true}/>
                    <Route path='/account-email-verification-failure' element={<InvoiceAccountVerification />} exact={true}/>
                    <Route path="/verify-email/:slug?" element={<VerifyEmailPage />} exact={true}/>
                    <Route path="/payment/success" element={<PaymentSuccessPages />} exact={true}/>
                    <Route path="/razorpay/setup" element={<RazorpaySuccess />} exact={true}/>
                    <Route path='/client/estimate/:estimate_uuid/preview' element={<PreviewAndDownloadInvoice />} exact={true}/>
                    <Route path='/client/proposal/:proposal_uuid/preview' element={<ClientProposalPreview />} exact={true}/>
                    <Route path='/client/:encrypted_url/document/:document_uuid/preview' element={<PreviewAndDocument />} exact={true}/>
                    <Route path='/member/:encrypted_url/document/:document_uuid/preview' element={<PreviewAndDocument />} exact={true}/>
                </Routes>
            </Suspense>
                <div>
                    {
                        Object.keys(props.selectedAccount).length === 0 || props.isLoading 
                        ?
                        <Grid container justifyContent='center'>
                            <CircularProgress size={25} style={{marginTop:'32px'}}/>
                        </Grid>
                        :
                        <BaseComponent drawerAction={drawerAction}
                            handleClickDialogOpen={handleClickDialogOpen}
                            handleNewBillDialogOpen={handleNewBillDialogOpen}
                            handleOpenAddNewPaymentDrawer={handleOpenAddNewPaymentDrawer}
                            handleClientDrawerOpen={handleClientDrawerOpen}
                            setInvoiceComponentState={setInvoiceComponentState}
                            setTransactionComponentState={setTransactionComponentState}
                            invoicesComponentState={invoicesComponentState}
                            transactionsComponentState={transactionsComponentState}
                            handleInvoiceDialogOpen={handleInvoiceDialogOpen}
                            setOpenClientSummaryModal={setOpenClientSummaryModal}
                            accessLevelViewer={accessLevelViewer}
                            setOpenDialog={setOpenDialog}
                            setShowEstimateClicked={setShowEstimateClicked}
                            estimateComponentState={estimateComponentState}
                            setEstimateComponentState={setEstimateComponentState}
                            handleProposalModalOpen={handleProposalModalOpen}
                            proposalsComponentState={proposalsComponentState}
                            setProposalsComponentState={setProposalsComponentState}
                            handleDocumentModalOpen={handleDocumentModalOpen}

                            handleVendorDrawerOpen={handleVendorDrawerOpen}
                            handleSelectDocTempModalOpen={handleSelectDocTempModalOpen}

                            documentsComponentState={documentsComponentState}
                            setDocumentsComponentState={setDocumentsComponentState}
                            handleEditPreviewBillDialogOpen={handleEditPreviewBillDialogOpen}
                            billComponentState={billComponentState} 
                            setBillComponentState={setBillComponentState}
                            expensesComponentState={expensesComponentState}
                            setExpensesComponentState={setExpensesComponentState}
                            >

                                
                                    <div className='display_flex'>
                                        {/* <Route path='/reports/client/:client_id' element={ClientSummaryDialog} exact={true}/> */}
                                        {/* <Route path='/invoice/create' element={CreateEditPreviewInvoiceModal} exact={true}/> */}
                                        {/* <Route path='/invoice/:slug/edit' element={CreateEditPreviewInvoiceModal} exact={true}/>
                                        <Route path='/invoice/:slug/preview' element={CreateEditPreviewInvoiceModal} exact={true}/> */}
                                        {/* <Route path='/estimate/create' element={CreateEditPreviewInvoiceModal} exact={true}/>
                                        <Route path='/estimate/:slug/edit' element={CreateEditPreviewInvoiceModal} exact={true}/>
                                        <Route path='/estimate/:slug/preview' element={CreateEditPreviewInvoiceModal} exact={true}/> */}

                                        <Box width={showDrawer ? '19%' : '0%'}>
                                            <LeftMenuComponent 
                                                invoicesComponentState={invoicesComponentState}
                                                setInvoiceComponentState={setInvoiceComponentState}
                                                transactionsComponentState={transactionsComponentState}
                                                setTransactionComponentState={setTransactionComponentState}
                                                handleEstimatesSettingsModalOpen={handleEstimatesSettingsModalOpen}
                                                estimateComponentState={estimateComponentState}
                                                setEstimateComponentState={setEstimateComponentState}
                                                proposalsComponentState={proposalsComponentState}
                                                setProposalsComponentState={setProposalsComponentState}
                                                billComponentState={billComponentState} 
                                                setBillComponentState={setBillComponentState}
                                                documentsComponentState={documentsComponentState}
                                                setDocumentsComponentState={setDocumentsComponentState}
                                                isInvoiceAccountPlanFree={isInvoiceAccountPlanFree}
                                                entityLimitExceeded={entityLimitExceeded}
                                                expensesComponentState={expensesComponentState}
                                                setExpensesComponentState={setExpensesComponentState}
                                                />
                                        </Box>
                                            
                                            <Box width={showDrawer ? '83%' : '100%'} bgcolor={theme.palette.primary.light}
                                                height={setHeightForComponent(isInvoiceAccountPlanFree, entityLimitExceeded)}
                                                className='main_content'
                                            >
                                            
                                            {/* {unVerifiedEntityEmails && unVerifiedEntityEmails.length > 0 && !accessLevelViewer &&
                                                    <div>
                                                        <SnackbarComponent showDrawer={showDrawer}
                                                        unVerifiedEntityEmails={unVerifiedEntityEmails}/>
                                                    </div>
                                            } */}

                                                <PropsProvider value={contextValue}>
                                                    <Suspense fallback={<Grid></Grid>}>
                                                        <Routes>
                                                            <Route path={AppRoutes.ONBOARDING} element={<OutsideOnboardingComponent />} />
                                                            <Route path={AppRoutes.DASHBOARD} element={<DashboardComponent />} />
                                                            <Route path={AppRoutes.INVOICES} element={<InvoiceListComponent />} />
                                                            <Route path={AppRoutes.TRANSACTIONS} element={<TransactionsComponent />} />
                                                            <Route path={AppRoutes.CLIENTS} element={<ClientsComponent />} />
                                                            <Route path={AppRoutes.ITEMS} element={<InvoiceItemsComponent />} />
                                                            <Route path={AppRoutes.MEMBERS} element={<AccountMembers />} />
                                                            <Route path={AppRoutes.PROFILE} element={<MyProfile />} />
                                                            <Route path={AppRoutes.CLIENT_REPORTS} element={<RevenueByClientReport />} />
                                                            <Route path={AppRoutes.ITEM_REPORTS} element={<RevenueByItemsReports />} />
                                                            <Route path={AppRoutes.ESTIMATES} element={<Estimates />} />
                                                            <Route path={AppRoutes.PROPOSALS} element={<Proposals />} />
                                                            <Route path={AppRoutes.VENDORS} element={<VendorsComponent />} />
                                                            <Route path={AppRoutes.BILL_CATEGORIES} element={<BillCategoriesComponent />} />
                                                            <Route path={AppRoutes.DOCUMENTS} element={<ESignDocuments />} />
                                                            <Route path={AppRoutes.BILLS} element={<BillComponent />} />
                                                            <Route path={AppRoutes.PAYMENTS} element={<BillTransactionsComponent />} />
                                                            {/* <Route path={AppRoutes.SETTINGS} element={SettingsComponent} /> */}
                                                            <Route path={AppRoutes.EXPENSES} element={<BillComponent />} />
                                                            {/* <PrivateRoute path='/invoices/client/preview' element={DownloadInvoice}/> */}
                                                            <Route path={AppRoutes.SETTINGS} element={<SettingsComponent />}>
                                                                <Route path='payment_gateway' element={<SettingsComponent />} />
                                                                <Route path='employees' element={<SettingsComponent />} />
                                                                <Route path='billing' element={<SettingsComponent />} />
                                                            </Route> 
                                                        </Routes>
                                                    </Suspense>
                                                </PropsProvider>

                                        </Box>
                                    </div>
                                {
                                    openClientDrawer && 
                                    <AddEditClientDrawer openClientDrawer={openClientDrawer} 
                                            handleClientDrawerClose={handleClientDrawerClose}
                                            accessLevelViewer={accessLevelViewer}
                                            addClient={addClient}
                                            editClient={editClient}
                                            setEditClient={setEditClient}
                                            clientEdited={clientEdited}
                                            isFromVendors={isFromVendors}
                                        />
                                }

                                {   
                                    openClientContactDrawer &&
                                    <ClientContactModal openDrawer={openClientContactDrawer}
                                        setOpenClientContactDrawer={setOpenClientContactDrawer}
                                        newClientObj={newClientObj}
                                        setNewClientObj={setNewClientObj}
                                        handleCloseClientContactDrawer={handleCloseClientContactDrawer}
                                        clientEdited={clientEdited}
                                        //clientsList={clientsList}
                                        //setClientsList={setClientsList}
                                    
                                />}

                                { 
                                    openDialog && 
                                    <InvoiceModalComponent openDialog={openDialog}
                                        handleClickDialogClose={handleClickDialogClose}
                                        handleInvoiceDialogOpen={handleInvoiceDialogOpen}
                                        showEstimateClicked={showEstimateClicked}
                                        handleClientDrawerOpen={handleClientDrawerOpen}
                                        />
                                }
                                {openBillDialog && 
                                    <BillUploadModal openBillDialog={openBillDialog}
                                        handleBillDialogClose={handleBillDialogClose}
                                        setUpdateBillList={setUpdateBillList}
                                        newExpense={newExpense}
                                        setNewExpense={setNewExpense}
                                />}
                                
                                {
                                    openInvoiceDialog && 
                                    <CreateEditPreviewInvoiceModal 
                                        openInvoiceDialog={openInvoiceDialog}
                                        handleInvoiceDialogClose={handleInvoiceDialogClose}
                                        setUpdatedInvoiceObj={setUpdatedInvoiceObj}
                                        setAddTransaction={setAddTransaction}
                                        updateTranscItemInTranscList={updateTranscItemInTranscList}
                                        updateInvoiceItemListInClients={updateInvoiceItemListInClients}
                                        setCallGetInvoiceListApi={setCallGetInvoiceListApi}
                                        handleEditPreviewBillDialogOpen={handleEditPreviewBillDialogOpen}
                                    />
                                } 
                                

                                {
                                    openAddPaymentDrawer && 
                                    <AddEditPaymentDrawer openAddPaymentDrawer={openAddPaymentDrawer}
                                        handleCloseAddPaymentDrawer={handleCloseAddPaymentDrawer}
                                        invoiceObj={invoiceObj}
                                        updateInvoiceItemInInvoiceList={updateInvoiceItemInInvoiceList}
                                        updateTranscItemInTranscList={updateTranscItemInTranscList}
                                        transactionObj={transactionObj}
                                        setTransactionObj={setTransactionObj}
                                        accessLevelViewer={accessLevelViewer}
                                        openAddNewPaymentDrawer={openAddNewPaymentDrawer}
                                        handleCloseAddNewPaymentDrawer={handleCloseAddNewPaymentDrawer}
                                        setAddTransaction={setAddTransaction}
                                        handleBankDrawerOpen={handleBankDrawerOpen}
                                        handleBankDrawerClose={handleBankDrawerClose}
                                        bankAccObj={bankAccObj}
                                        setBankAccObj={setBankAccObj}
                                        setEntityObjForBankAcc={setEntityObjForBankAcc}
                                        setUpdatedBillObj={setUpdatedBillObj}
                                    />
                                }
                                    {
                                    openBillDetailDialog && 
                                    <BillDetailModalComponent openBillDetailDialog={openBillDetailDialog}
                                        handleBillDetailClickDialogClose={handleBillDetailClickDialogClose}
                                        billObj={billObj}
                                        selectedEntity={selectedEntity}
                                        isFromUnclaimedBill={isFromUnclaimedBill}
                                        billId={billId}
                                        setUpdatedBillObj={setUpdatedBillObj}
                                        newExpense={newExpense}
                                    />
                                }

                                {
                                    openEditPreviewBillDialog && 
                                    <BillDetailModalComponent openBillDetailDialog={openEditPreviewBillDialog}
                                        editPreview={true}
                                        handleBillDetailClickDialogClose={handleBillDetailClickDialogClose}
                                        selectedEntity={selectedEntity}
                                        setOpenAddPamentBillDrawer={setOpenAddPamentBillDrawer}
                                        handleCloseAddPaymentBillDrawer={handleCloseAddPaymentBillDrawer}
                                        updateBillObj={updateBillObj}
                                        setFromPreviewScreen={setFromPreviewScreen}
                                        previewBillObj={previewBillObj}
                                        isFromUnclaimedBill={isFromUnclaimedBill}
                                        billId={billId}
                                        setUpdatedBillObj={setUpdatedBillObj}
                                    />
                                }
                                    
                                {
                                    openAddPaymentBillDrawer && 
                                    <AddEditBillPaymentDrawer openAddPaymentDrawer={openAddPaymentBillDrawer}
                                        handleCloseAddPaymentDrawer={handleCloseAddPaymentBillDrawer}
                                        invoiceObj={billObj}
                                        updateBillItemInBillList={updateBillItemInBillList}
                                        updateTranscItemInTranscList={updateTranscItemInTranscList}
                                        transactionObj={transactionObj}
                                        setTransactionObj={setTransactionObj}
                                        accessLevelViewer={accessLevelViewer}
                                        //openAddNewPaymentDrawer={openAddNewPaymentDrawer}
                                        handleCloseAddNewPaymentDrawer={handleCloseAddNewPaymentDrawer}
                                        setAddTransaction={setAddTransaction}
                                        handleBankDrawerOpen={handleBankDrawerOpen}
                                        handleBankDrawerClose={handleBankDrawerClose}
                                        bankAccObj={bankAccObj}
                                        setBankAccObj={setBankAccObj}
                                        setEntityObjForBankAcc={setEntityObjForBankAcc}
                                        fromPreviewScreen={fromPreviewScreen}
                                        setPreviewBillObj={setPreviewBillObj}
                                    />
                                }

                                {
                                    openAddNewPaymentDrawer &&
                                    <AddNewPaymentDrawer openAddNewPaymentDrawer={openAddNewPaymentDrawer} 
                                        handleCloseAddNewPaymentDrawer={handleCloseAddNewPaymentDrawer}
                                        handleOpenAddPaymentDrawer={handleOpenAddPaymentDrawer}
                                    />
                                }

                                {
                                    openBankDrawer &&
                                    <AddEditBankDrawer openBankDrawer={openBankDrawer}
                                        handleBankDrawerClose={handleBankDrawerClose}
                                        bankAccountObj={{}}
                                        fromDashboardOrAddPayment={true}
                                        setBankAccObj={setBankAccObj}
                                        entityObjForBankAcc={entityObjForBankAcc}
                                        setEntityObjForBankAcc={setEntityObjForBankAcc}
                                    />
                                }

                                {
                                    openInvoicesDrawer && 
                                    <ShowInvoicesDrawer openInvoicesDrawer={openInvoicesDrawer}
                                        handleInvoicesDrawerClose={handleInvoicesDrawerClose}
                                        dueInvoiceObj={dueInvoiceObj}
                                        handleOpenAddPaymentDrawer={handleOpenAddPaymentDrawer}
                                        handleInvoiceDialogOpen={handleInvoiceDialogOpen}/>            
                                }

                                {
                                    openInfoDialog && 
                                    <ShowInfoDialog openInfoDialog={openInfoDialog}
                                        handleInfoDialogClose={handleInfoDialogClose}
                                        newInvoiceClicked={newInvoiceClicked}
                                        setNewInvoiceClicked={setNewInvoiceClicked}/>
                                }

                                {
                                    openClientSummaryModal &&
                                    <ClientSummaryDialog openClientSummaryModal={openClientSummaryModal}
                                        handleClientSummaryDialogClose={handleClientSummaryDialogClose}
                                        clientStatementObj={clientStatementObj}
                                    />
                                }

                                {
                                    openEstimatesSettingsModal &&
                                    <EstimateSettingsModal openEstimatesSettingsModal={openEstimatesSettingsModal}
                                        handleEstimatesSettingsModalClose={handleEstimatesSettingsModalClose}
                                        selectedAccount={props.selectedAccount}
                                        setInvoiceAccountsList={props.setInvoiceAccountsList}
                                        setSelectedInvoiceAccount={props.setSelectedInvoiceAccount}
                                        invoiceAccountsList={props.invoiceAccountsList}
                                    />
                                } 

                                {
                                    openProposalModal &&
                                    <CreateEditPreviewProposalModal openProposalModal={openProposalModal}
                                        handleProposalModalClose={handleProposalModalClose}
                                        setUpdateProposalObj={setUpdateProposalObj}
                                        handleClientDrawerOpen={handleClientDrawerOpen}
                                        newClientObj={newClientObj}
                                        setNewClientObj={setNewClientObj}
                                    />
                                }

                                {
                                    openDocumentModal &&
                                    <DocumentModal openDocumentModal={openDocumentModal}
                                        handleDocumentModalClose={handleDocumentModalClose}
                                        setUpdatedDocumentObj={setUpdatedDocumentObj}
                                    />
                                }

                                {
                                    openSelectDocTempModal &&
                                    <SelectDocumentOrTemplateModal openSelectDocTempModal={openSelectDocTempModal}
                                        handleSelectDocTempModalClose={handleSelectDocTempModalClose}
                                        handleDocumentModalOpen={handleDocumentModalOpen}
                                        selectedAccount={props.selectedAccount}
                                    />
                                }

                                {
                                    // openExpenseBillDetailDialog &&
                                    // <ExpenseBillDetailModal openExpenseBillDetailDialog={openExpenseBillDetailDialog}
                                    //     handleExpenseBillDetailDialogClose={handleExpenseBillDetailDialogClose}
                                    //     expenseBillObj={expenseBillObj}
                                    //     selectedEntity={selectedEntity}
                                    //     isFromUnclaimedBill={isFromUnclaimedBill}
                                    //     billId={billId}
                                    //     setUpdatedBillObj={setUpdatedBillObj}

                                    // />
                                }

                        </BaseComponent>
                    }
                </div>
                {/* <Routes>
                    {/* <Route path='*' element={<NotFoundPage />} exact={true}/> }
                </Routes> */}
        </Router>
    )
};

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    drawerState: state.drawerState.setDrawerState,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    invoiceObj: state.invoiceAccounts.invoiceObj,
    editInvoiceObj: state.invoiceAccounts.editInvoiceObj, 
    isLoading: state.loading.isLoading,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    showEstimateScreen: state.invoiceAccounts.showEstimateScreen
}); 

const mapDispatchToProps = (dispatch) => ({
    setUserObj: (user_obj) => dispatch(setUserObj(user_obj)),
    getInvoiceAccountsList: (selectedAccount) => dispatch(getInvoiceAccountsList(selectedAccount)),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),

    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),


    setLoading: (isLoading) => dispatch(setLoading(isLoading))
    
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);