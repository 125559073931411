import React, {useState, useRef, useEffect, useMemo} from 'react';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import UpdateProposalTitle from './UpdateProposalTitle';
import CoverPage from './CoverPage';
import Introduction from './Introduction';
import WhyUs from './WhyUs';
import Reviews from './Reviews';
import Team from './Team';
import ThanksMessage from './ThanksMessage';
import BillingSchedule from './BillingSchedule';
import PaymentTerms from './PaymentTerms';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CoverPageDrawer from './CoverPageDrawer';
import IntroductionDrawer from './IntroductionDrawer';
import WhyUsDrawer from './WhyUsDrawer';
import ReviewDrawer from './ReviewDrawer';
import TeamDrawer from './TeamDrawer';
import ThankyouDrawer from './ThankyouDrawer';
import PaymentTermsDrawer from './PaymentTermsDrawer';
import BillingsDrawer from './BillingsDrawer';
import CustomDrawer from './CustomDrawer';
import CustomPage from './CustomPage';
import {changeOrderOfProposalSectionApi} from '../../../services/proposalService';
import { consoleToLog } from '../../../util/AppUtil';
import { useSnackbar } from 'notistack';
import {showProposalSectionApi, updateProposalSectionApi, addProposalSectionApi} from '../../../services/proposalService';
import ShowConfirmationModal from './ShowConfirmationModal';
import {updateProposalBillingSectionApi, deleteCustomSectionApi} from '../../../services/proposalService';
import { SelectedDrawerState } from '../../../actions/drawer';
import { syncNavItemsOrder } from '../../../util/AppUtil';
import Box from '@mui/material/Box'

const root = {
    display: 'flex',
    position: "relative"
};

const menuItemContainer = {
    backgroundColor: '#fff',
    //borderRadius: theme.shape.borderRadius,
    //boxShadow: theme.shadows[1],
    position: 'sticky',
    top: 0,
    zIndex: 1000,
};

const listItem = {
    borderLeft: `3px solid transparent`,
    cursor:'pointer',
    '&:not(:last-child)': { 
        borderBottom: `1px solid rgba(0,0,0,0.1)`,
    },
};

const selectedItem = {
    borderLeft: `3px solid #005EB8`,
};

const container = {
    marginTop: '16px',
    display: 'flex',
};

const content = {
    flexGrow: 1,
};

const customColumn = {
    flexBasis: '4%',
    maxWidth: '4%'
};

const customColumn1 = {
    flexBasis: '96%',
    maxWidth: '96%',
    '& .addNewBtn': {
        border: '1px dashed #777',
        color: '#777',
        height: '30px',
        margin: '8px 0px',
        visibility: 'hidden'
    },
    '&:hover': {
        '& .addNewBtn': {
            visibility: 'visible'
        }
    }
};

const dragIcon = {
    padding: "10px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

const menuItems = [
    { id: '1', component: 'Cover Page', type: 'cover_page' },
    { id: '2', component: 'Introduction', type: 'introduction' },
    { id: '3', component: 'Why Us?', type: 'why_us' },
    { id: '4', component: 'Reviews', type: 'reviews' },
    { id: '5', component: 'Team', type: 'team' },
    { id: '6', component: 'Billing Schedule & Payment Terms', type: 'billing_schedule' },
    { id: '7', component: 'Thanks Message', type: 'greetings' }
];

const componentMap = {
    cover_page: CoverPage,
    introduction: Introduction,
    why_us: WhyUs,
    reviews: Reviews,
    team: Team,
    greetings: ThanksMessage,
    billing_schedule: BillingSchedule,
    payment_terms: PaymentTerms,
};

const EditProposal = ({onCloseProposalDialog, editProposalArr, setEditProposalArr, proposalObj, setProposalObj, setUpdateProposalObj, drawerState
}) => {
    const {enqueueSnackbar} = useSnackbar();

    const [selectedComponent, setSelectedComponent] = useState('cover_page'); 
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [itemObj, setItemObj] = useState(undefined);
    const [apiLoading, setApiLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [updatedItemObj, setUpdatedItemObj] = useState(undefined);
    const [dirtyFlag, setDirtyFlag] = useState(false);
    const [navItems, setNavItems] = useState([...menuItems]);


    const invoice_account_id =  proposalObj?.client?.invoice_account_id;
    const proposal_id = proposalObj.id;
    const entity_name = proposalObj?.entity.name;
    const entity_logo = proposalObj?.entity.logo_url;
    const currencyObj = proposalObj?.currency;

    useEffect(() => {
        if(editProposalArr && editProposalArr.length > 0) {
            const updatedNavItems = syncNavItemsOrder(navItems, editProposalArr);
            setNavItems(updatedNavItems);
        }
    },[editProposalArr])

    const sectionRefs = useRef(navItems.reduce((acc, item) => {
        acc[item.type] = React.createRef();
        return acc;
    }, {}));

    const handleMenuItemClick = (type) => {
        setSelectedComponent(type);
        sectionRefs.current[type].current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleDrawerOpen = (obj) => {
        setItemObj(obj);
        setDrawerOpen(true);
    };

    const handleDrawerClose = (obj) => {
        if(dirtyFlag && obj) {
            setUpdatedItemObj(obj);
            handleShowConfirmModalOpen();
        } else {
            setDrawerOpen(false);
            setItemObj(undefined);
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    
    const onEnd = (result) => {
        let source_index = result.source.index + 1;
        let orderNumber = result.destination.index + 1;
        let section_id = result.draggableId;

        if(source_index !== orderNumber) {
            callChangeOrderApi(section_id, orderNumber);

            if (!result.destination) {
                return;
            }

            const items = reorder(
                editProposalArr,
                result.source.index,
                result.destination.index
            );
            setEditProposalArr([...items]);
        }
    };

    const updateSectionArrAndObj = (section, fromBilling) => {
        const updatedArray = editProposalArr.map((proposal) => {
                if(proposal.id === section.id) {
                    proposal = section;
                }
                return proposal;
        });
        setEditProposalArr([...updatedArray]);
        const total_amount = fromBilling ? section.data.items.reduce((acc, obj) => acc + obj.amount, 0) : undefined;
        const currencyObj = fromBilling ? section.data.currency : undefined;
        let obj ={
            ...proposalObj,
            sections: updatedArray,
            total_amount: fromBilling ? total_amount : proposalObj.total_amount,
            currency: fromBilling ? currencyObj : proposalObj.currency

        }
        drawerState === SelectedDrawerState.SHOW_PROPOSALS && section.type === 'billing_schedule' &&  setUpdateProposalObj({...obj});
        setProposalObj({...obj});

    }

    const callChangeOrderApi = async(section_id, orderNumber) => {
        try {
            const response = await changeOrderOfProposalSectionApi(invoice_account_id, proposal_id, section_id, orderNumber);
            const res = response.data;
            consoleToLog("Response changeOrderOfProposalSectionApi: ", res);
            enqueueSnackbar('Section order changed successfully', {variant:'success'});

            handleReorder([...res]);
            let obj ={
                ...proposalObj,
                sections: res
            }
            setProposalObj(obj);
        } catch (e) {
            consoleToLog("Error changeOrderOfProposalSectionApi: ", e);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const onShowOrHidePage = async(section, show_page) => {
        try {
            const response = await showProposalSectionApi(invoice_account_id, proposal_id, section?.id, show_page);
            const res = response.data;
            consoleToLog("Response showProposalSectionApi: ", res);
            enqueueSnackbar("Section visibility updated!!!", {variant:'success'});
            updateSectionArrAndObj(res, false);

        } catch (e) {
            consoleToLog("Error showProposalSectionApi: ", e);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callUpdateSectionApi = async(section, dataObj) => {
        let section_id = section.id;
        let {type, show_page, new_page, order_number, data} = dataObj;

        if(dataObj.proposal_id) {
            
            setApiLoading(true);
            
            if(dataObj.type === 'billing_schedule') {
                callUpdateBillingSectionApi(section_id, dataObj);
            } else {
                try {
                    const response = await updateProposalSectionApi(invoice_account_id, proposal_id, section_id, type, show_page, new_page, order_number, data);
                    const res = response.data;
                    consoleToLog("Response showProposalSectionApi: ", res);
                    enqueueSnackbar('Section updated successfully', {variant:'success'});
                    handleDrawerClose();
                    updateSectionArrAndObj(res, false);
                    setApiLoading(false);
                    setDirtyFlag(false);
                    setUpdatedItemObj(undefined);

                } catch (e) {
                    consoleToLog("Error showProposalSectionApi: ", e);
                    setApiLoading(false);
                    if(e.response.data && e.response.data.message) {
                        enqueueSnackbar(e.response.data.message, {variant:'error'});
                        return;
                    }
                }
            }
        } else {
            setApiLoading(true);
            callAddSectionApi(type, show_page, new_page, order_number, data)
        }
    }

    const callUpdateBillingSectionApi = async(section_id, dataObj) => {
        let {section, proposal_items, currency_id} = dataObj;
        
            try {
                const response = await updateProposalBillingSectionApi(invoice_account_id, proposal_id, section_id, section, proposal_items, currency_id);
                const res = response.data;
                consoleToLog("Response updateProposalBillingSectionApi: ", res);
                enqueueSnackbar('Section updated successfully', {variant:'success'});
                updateSectionArrAndObj(res, true);
                setApiLoading(false);
                handleDrawerClose();
                setDirtyFlag(false);
                setUpdatedItemObj(undefined);

            } catch (e) {
                consoleToLog("Error updateProposalBillingSectionApi: ", e);
                setApiLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            }
    }

    const onAddSectionClick = (item) => {
        let itemObj={
            order_number: item.order_number + 1,
            type: "custom",
            new_page: true,
            show_page: true,
            data: {
                "text": "",
                "title": ""
            },
        }
        handleDrawerOpen(itemObj);
    }

    const callAddSectionApi = async(type, show_page, new_page, order_number, data) => {
        try {
            const response = await addProposalSectionApi(invoice_account_id, proposal_id, type, show_page, new_page, order_number, data);
            const res = response.data;
            consoleToLog("Response showProposalSectionApi: ", res);
            enqueueSnackbar('Custom section added successfully', {variant:'success'});

            setEditProposalArr([...res]);
            setDirtyFlag(false);
            handleDrawerClose();

        } catch (e) {
            consoleToLog("Error showProposalSectionApi: ", e);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const callDeleteCustomSectionApi = async(section_id) => {
        try {
            const response = await deleteCustomSectionApi(invoice_account_id, proposal_id, section_id);
            const res = response.data;
            consoleToLog("Response deleteCustomSectionApi: ", res);
            enqueueSnackbar('Custom section deleted successfully!', {variant:'success'});
            setEditProposalArr([...res]);
            handleDrawerClose();

        } catch (e) {
            consoleToLog("Error deleteCustomSectionApi: ", e);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const handleShowConfirmModalOpen = () => {
        setShowConfirmModal(true);
    }

    const handleShowConfirmModalClose = () => {
        setShowConfirmModal(false);
    }

    const renderProposalSections = useMemo(() => {
        return editProposalArr.map((item, index) => {
            const DynamicComponent = componentMap[item.type] || (() => (
                <CustomPage 
                    item={item}
                    handleDrawerOpen={handleDrawerOpen}
                    onShowOrHidePage={onShowOrHidePage}
                    proposalObj={proposalObj}
                    callDeleteCustomSectionApi={callDeleteCustomSectionApi}
                />
            ));
            const {show_page} = item;
            
            return (
                <Draggable 
                    key={item.id} 
                    draggableId={show_page ? item.id : ''} 
                    index={index}
                > 
                    {(provided) => (
                        <div   
                            key={item.id} 
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            id={index+"_"+item.title}
                        >
                            <div ref={sectionRefs.current[item.type]}>
                                <Grid container alignItem='flex-start' justifyContent='space-between'>
                                    <Grid size={1} sx={customColumn} 
                                        {...provided.dragHandleProps}
                                    > 
                                        {item.type !== 'cover_page' &&
                                            <Box component='div' sx={dragIcon}>
                                                <img src='/images/drag_indicator.svg' />
                                            </Box>
                                        }
                                    </Grid>

                                    <Grid size={10} sx={customColumn1} key={item.id} >
                                        {DynamicComponent ? 
                                            <DynamicComponent 
                                                item={item}
                                                handleDrawerOpen={handleDrawerOpen}
                                                onShowOrHidePage={onShowOrHidePage}
                                                proposalObj={proposalObj}
                                                key={item.id}
                                            /> : null}

                                        <Button 
                                            fullWidth 
                                            variant='outlined' 
                                            onClick={() => onAddSectionClick(item)}
                                            className={'addNewBtn'}
                                        >
                                            <img 
                                                src='/images/drawer_add.svg' 
                                                style={{
                                                    marginRight:"8px", 
                                                    filter: "invert(52%) sepia(0%) saturate(0%) hue-rotate(352deg) brightness(89%) contrast(85%)"
                                                }}
                                            />
                                            Add New Page
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    )}
                </Draggable>  
            );
        });
    }, [editProposalArr, componentMap, proposalObj]); // Only re-render when these dependencies change

    const handleReorder = (reorderedProposal) => {
        setEditProposalArr(reorderedProposal);
        
        const updatedNavItems = syncNavItemsOrder(navItems, reorderedProposal);
        setNavItems(updatedNavItems);
    };

    return (
        <div className='edit_proposal_container'>
            <Grid container alignItems='center' spacing={2}>
                <Grid>
                    <div onClick={onCloseProposalDialog} className='edit_prop_icon_btn'>
                        <img src='/images/back_arrow_background.svg' />
                    </div>
                </Grid>

                <Grid>
                    <UpdateProposalTitle proposalObj={proposalObj}
                        invoice_account_id={invoice_account_id}
                        setProposalObj={setProposalObj}/>
                </Grid>
            </Grid>

            <Container maxWidth='lg' disableGutters sx={container}>
                <Grid container>
                    <Grid size={3}>
                        <Paper sx={menuItemContainer}>
                            <List disablePadding>
                                {navItems.map((item) => (
                                    item &&
                                    <ListItem
                                        button
                                        key={item.id}
                                        sx={{...listItem, ...(selectedComponent === item.type ? selectedItem : {})}}
                                        onClick={() => handleMenuItemClick(item.type)}
                                    >
                                        <ListItemText primary={item.component} />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>

                    <Grid size={8} sx={content}>
                        <DragDropContext onDragEnd={onEnd}>
                            <Droppable droppableId="droppable" direction="vertical">
                                {(provided) => (
                                    <div {...provided.droppableProps}
                                        ref={provided.innerRef} style={{width:'100%', marginBottom:'8px'}}>

                                        {renderProposalSections}
                                        {provided.placeholder}

                                </div>
                                )
                            }

                            </Droppable>  
                        </DragDropContext>
                    </Grid>
                </Grid>
            </Container>

            
                {itemObj?.type === 'cover_page' && <CoverPageDrawer itemObj={itemObj} 
                                                        handleDrawerClose={handleDrawerClose}
                                                        callUpdateSectionApi={callUpdateSectionApi}
                                                        entity_name={entity_name}
                                                        entity_logo={entity_logo}
                                                        apiLoading={apiLoading}
                                                        drawerOpen={drawerOpen}
                                                        setDirtyFlag={setDirtyFlag}
                                                    />}

                {itemObj?.type === 'introduction' && <IntroductionDrawer itemObj={itemObj} 
                                                        handleDrawerClose={handleDrawerClose}
                                                        callUpdateSectionApi={callUpdateSectionApi}
                                                        apiLoading={apiLoading}
                                                        drawerOpen={drawerOpen}
                                                        setDirtyFlag={setDirtyFlag}
                                                    />}

                {itemObj?.type === 'why_us' && <WhyUsDrawer itemObj={itemObj} 
                                                    handleDrawerClose={handleDrawerClose}
                                                    callUpdateSectionApi={callUpdateSectionApi}
                                                    apiLoading={apiLoading}
                                                    drawerOpen={drawerOpen}
                                                    setDirtyFlag={setDirtyFlag}
                                                    />}
            
                {itemObj?.type === 'reviews' && <ReviewDrawer itemObj={itemObj} 
                                                    handleDrawerClose={handleDrawerClose}
                                                    callUpdateSectionApi={callUpdateSectionApi}
                                                    apiLoading={apiLoading}
                                                    drawerOpen={drawerOpen}
                                                    setDirtyFlag={setDirtyFlag}
                                                    />}

                {itemObj?.type === 'team' && <TeamDrawer itemObj={itemObj} 
                                                handleDrawerClose={handleDrawerClose}
                                                callUpdateSectionApi={callUpdateSectionApi}
                                                apiLoading={apiLoading}
                                                drawerOpen={drawerOpen}
                                                setDirtyFlag={setDirtyFlag}
                                                invoice_account_id={invoice_account_id}
                                                proposal_id={proposal_id}
                                                />}

                {itemObj?.type === 'greetings' && <ThankyouDrawer itemObj={itemObj} 
                                                    handleDrawerClose={handleDrawerClose}
                                                    callUpdateSectionApi={callUpdateSectionApi}
                                                    apiLoading={apiLoading}
                                                    drawerOpen={drawerOpen}
                                                    setDirtyFlag={setDirtyFlag}
                                                    />}

                {itemObj?.type === 'payment_terms' && <PaymentTermsDrawer itemObj={itemObj} 
                                                        handleDrawerClose={handleDrawerClose}
                                                        callUpdateSectionApi={callUpdateSectionApi}
                                                        apiLoading={apiLoading}
                                                        drawerOpen={drawerOpen}
                                                        setDirtyFlag={setDirtyFlag}
                                                        />}

                {itemObj?.type === 'billing_schedule' && <BillingsDrawer itemObj={itemObj} 
                                                            handleDrawerClose={handleDrawerClose}
                                                            callUpdateSectionApi={callUpdateSectionApi}
                                                            apiLoading={apiLoading}
                                                            drawerOpen={drawerOpen}
                                                            setDirtyFlag={setDirtyFlag}
                                                            invoice_account_id={invoice_account_id}
                                                            proposalObj={proposalObj}
                                                            setProposalObj={setProposalObj}
                                                            editProposalArr={editProposalArr}
                                                            setEditProposalArr={setEditProposalArr}
                                                            setItemObj={setItemObj}
                                                        />}

                {itemObj?.type === 'custom' && <CustomDrawer itemObj={itemObj} 
                                                    handleDrawerClose={handleDrawerClose}
                                                    callUpdateSectionApi={callUpdateSectionApi}
                                                    apiLoading={apiLoading}
                                                    drawerOpen={drawerOpen}
                                                    setDirtyFlag={setDirtyFlag}
                                                />}
        
            {showConfirmModal && <ShowConfirmationModal showConfirmModal={showConfirmModal}
                                    handleShowConfirmModalClose={handleShowConfirmModalClose}
                                    itemObj={itemObj}
                                    updatedItemObj={updatedItemObj}
                                    callUpdateSectionApi={callUpdateSectionApi}
                                    setDirtyFlag={setDirtyFlag}
                                    handleDrawerClose={handleDrawerClose}
                                    />
                                    
                                    }
        </div>
    );
}

export default EditProposal;
