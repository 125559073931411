import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { getResponsiveFontSize, DroppedItemTypes } from '../../util/AppUtil';
import Box from '@mui/material/Box';

const itemContainer = {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    pointerEvents: 'none',
};
// const textItem = {
//     width: '100%',
//     margin: 0,
//     padding: '0px',
//     lineHeight: '1.2',
//     color: '#000',
//     border: '1px solid transparent',
// };
// const signatureItem = {
//     width: '100%',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// };
// const dateItem = {
//     width: '100%',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     border: '1px solid transparent',
// };
// const signImageContainer = {
//     width: '100%',
//     height: '100%',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// };
const signItemContainer = {
    position:'relative',
    width:'100%',
    height:'100%', 
    background:'yellow',
    borderRadius:'5px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
};
const setLabelStyle = {
    fontSize: '11px',
    position: 'absolute',
    top: 5,
    right: '8px',
    color: '#007bfb',
    cursor: 'pointer',
    zIndex: 100,
    fontWeight: 600
};
const setLabelStyle1 = {
    fontSize: '7.5px',
    position: 'absolute',
    top: 2,
    right: '8px',
    color: '#007bfb',
    cursor: 'pointer',
    zIndex: 100,
};

const DroppedItemsPreview = ({ item, pdfWidth, pdfHeight, originalDimension, windowWidth}) => {
    const [fontSize, setFontSize] = useState(0);
    const [styles, setStyles] = useState(undefined);

    useEffect(() => {
        if(item.id) {
            if (pdfHeight > 0 && originalDimension?.width > 0 && pdfWidth > 0) {
                const canvasRectArr = document.querySelectorAll('.react-pdf__Page__canvas');
                const canvasEl = canvasRectArr[item.page - 1];
                const pageRect = canvasEl?.getBoundingClientRect();
                // Calculate font size based on PDF dimensions
                const newFontSize = getResponsiveFontSize(12, pageRect?.width, pageRect?.height, originalDimension);
                const styleObj = getItemStyle();
                setStyles({...styleObj});
                setFontSize(newFontSize);
            }
        }
    }, [pdfHeight, originalDimension, pdfWidth]);

    const getItemStyle = () => {
        if (!item.coordinates?.topLeft || !item.coordinates?.bottomRight || !item.id) return {};
    
        const { topLeft, bottomRight } = item.coordinates;
    
        // Parse coordinates as absolute values
        const left = topLeft.x; // Use absolute value directly
        const top = topLeft.y; // Use absolute value directly
        const right = bottomRight.x; // Use absolute value directly
        const bottom = bottomRight.y; // Use absolute value directly
    
        // Ensure values are valid numbers
        if (isNaN(left) || isNaN(top) || isNaN(right) || isNaN(bottom)) return {};
    
        // Calculate width and height
        const width = right - left;
        const height = bottom - top;
    
        // Ensure width and height are valid
        if (width <= 0 || height <= 0) return {};

    
        // Scale dimensions
        // const scaledDimensions = getDroppedComponentWidthAndHeight(
        //     width,
        //     height,
        //     pageRect.width,
        //     pageRect.width,
        //     originalDimension
        // );

        // Return computed style
        return {
            position: 'absolute',
            left: `${left}px`, // Use absolute pixel value
            top: `${top}px`, // Use absolute pixel value
            width: `${width}px`, // Use absolute pixel value
            height: `${height}px`,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: (item.component_type === DroppedItemTypes.TEXT || item.component_type === DroppedItemTypes.DATE) ? 'flex-start' : 'center',
            overflow: 'visible', // Optional for multiline text
        };
    };

    const isSigned = item.value_json.is_signed;

    const renderContent = () => {
        switch (item.component_type) {
            // case DroppedItemTypes.TEXT:
            //     return (
            //         <Typography 
            //             className={classes.textItem} 
            //             style={{ fontSize: `${fontSize}px`}}
            //         >
            //             {item.value_json?.text || ''}
            //         </Typography>
            //     );
            case DroppedItemTypes.SIGNATURE:
                return !isSigned && (
                    <Box component='div' sx={{...signItemContainer, fontSize: windowWidth && windowWidth < 700 ? '8px' : `${fontSize}px`}}>
                        Sign

                        <div>
                            <Typography sx={{...(windowWidth && windowWidth < 700 ? setLabelStyle1 : setLabelStyle)}}>
                                {item.component_label || 'Set Label'}
                            </Typography>
                        </div>
                    </Box>
                );
            // case DroppedItemTypes.DATE:
            //     return (
            //         <Typography 
            //             className={classes.textItem} 
            //             style={{ fontSize: `${fontSize}px` }}>
            //             {item.value_json?.date || 'Set Date'}
            //         </Typography>
            //     );
            default:
                return null;
        }
    };

    return (
        <Box component='div' sx={{...itemContainer, ...styles}}>
            {renderContent()}
        </Box>
    );
};

export default DroppedItemsPreview;