import React, {useState, useEffect} from 'react';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Avatar from '@mui/material/Avatar';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import { connect } from 'react-redux';
import {consoleToLog, boldString} from '../../util/AppUtil';
import {autoCompleteClientListApi, getClientDetailsObjApi} from '../../services/clientService';
import { useSnackbar } from 'notistack';
import { setSelectedDrawerState } from '../../actions/drawer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

const dialogTitle = {
    borderBottom: '1px solid #ccc', 
    padding: '16px 24px', 
    display: 'flex',  
    justifyContent: 'space-between', 
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '500'
};

const avatarStyle = {
    width: "28px",
    height: "28px",
    fontSize: '12px',
    fontWeight: '600',
    color: '#2B2B2B',
    marginRight: '-8px'
};

const MuiTextFieldroot = {
    background: '#f0f3f7',
    border: 'none'
};

const list = {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    padding: '0px 8px 0px 16px',
    '&:hover': {
        background: '#F9F9F9'
    }
};

const listContainer = {
    width: '100% !important',
    '&:last-child .dropdownDivider': {
        display: 'none'
    }
};

const dividerStyle = {
    background: '#000', 
    opacity: '0.1'
};

const MuiDialogpaper = {
    '& .MuiDialog-paper' : {
        scrollbarWidth: 'none',
        overflow: 'visible',
        paddingBottom: '5px',
        "&::-webkit-scrollbar": {
            display: "none"
        }   
    }
};

const createItemButton = {
    marginTop: '8px',
    marginBottom: '8px',
    width: '96%', 
    color: '#1bac3b', 
    borderColor: '#d4d8de'
};

const createItemIcon = {
    margin: '0px 6px 0px 0px', 
    fontSize: '16px'
};

const InvoiceModalComponent = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const [searchCustomer, setSearchCustomer] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(-1);

    const entityList = props.selectedAccount?.entities;

    useEffect(() => {
        if(entityList && entityList.length === 1 && !props.editInvoiceObj) {
            setSelectedEntity(entityList[0]);
        }

    }, [])

    const getClientsList = (text) => {
        const invoice_account_id = props.selectedAccount.id;

        if(!text) return;
        
        autoCompleteClientListApi(invoice_account_id, text)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response autoCompleteClientListApi: ', res);
            setCustomerList(res.companies);
        })
        .catch((e) => {
            consoleToLog('Response autoCompleteClientListApi ', e);
            if (e.response.data && e.response.data.message) {
                return false;
            }
        })
    }  
    
    const getClientsDetailsObj = (client) => {
        const client_id = client.id;
        
        getClientDetailsObjApi(client_id)
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getClientDetailsObjApi: ', res);

            if(props.openChangeCustomerModal) {
                const client_obj = res.client;
                
                let clientObj = {
                    id: client_obj.id,
                    name: client_obj.name,
                    address: client_obj.address,
                    country: client_obj.country,
                    state: client_obj.state,
                    tax_id: client_obj.tax_id,
                    email: client_obj.email,
                    invoice_account_id: client.invoice_account_id,
                    user_defined_field: client_obj.user_defined_field,
                    contacts: client_obj.contacts

                }

                navigate(`${window.location.pathname}`, {state: {clientObj: clientObj}});
                props.handleClickDialogClose();
            } else {
            
                navigate(props.showEstimateClicked ? '/estimate/create' : '/invoice/create',{state: {clientObj: res.client, entityObj: selectedEntity}});
                props.handleInvoiceDialogOpen();
                setSearchCustomer('');
                props.handleClickDialogClose();
            }
        })
        .catch((e) => {
            consoleToLog('Response getClientDetailsObjApi ', e);
            if (e.response.data && e.response.data.message) {
                return false;
            }
        })
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });

    // const callChangeCustomerApi = (clientObj) => {
    //     const invoice_account_id = props.selectedAccount.id;
    //     const invoice_id = props.editInvoiceObj?.id;
    //     const client_id = clientObj?.id;

    //     // changeInvoiceCustomerApi(access_token, invoice_account_id, invoice_id, client_id)
    //     // .then((response) => {
    //     //     const res = response.data;
    //     //     consoleToLog('Response changeInvoiceCustomerApi: ', res);

    //     //     props.setEditInvoiceObj(res);
    //     // })
    //     // .catch((e) => {
    //     //     consoleToLog('Response changeInvoiceCustomerApi ', e);
    //     //     if (e.response.data && e.response.data.message) {
    //     //         return false;
    //     //     }
    //     // })

    // }

    const onCustomerItemClick = (clientObj) => {
            if(selectedEntity === -1) {
                enqueueSnackbar('Please select entity to create Invoice', {variant:'error'});
                return;
            } else {
                getClientsDetailsObj(clientObj);
            }
    }

    const onEntityClick = (e) => {
        const value = e.target.value;
        setSelectedEntity(value);
    }

    const onEntityClickFromEditInvoice = (e) => {
        const value = e.target.value;
        navigate(`${window.location.pathname}`, {state: {entityObj: value}});
        props.handleClickDialogClose();
    }

    const onCustomerClickFromEditInvoice = (clientObj) => {
        getClientsDetailsObj(clientObj);
    }


    const onClose = () => {
        props.handleClickDialogClose();
        selectedEntity !== -1 && setSelectedEntity(-1);
        
    }

    const showProperTitle = () => {
        if(!props?.editInvoiceObj) {
            return props.showEstimateClicked ? 'Create Estimate' : 'Create Invoice';
        } else {
            if(props.openChangeEntityModal) {
                return 'Change Entity';
            }
            if(props.openChangeCustomerModal) {
                return 'Change Client';
            }
        }
    }

    return (
            <div>
                <Dialog
                    open={props.openDialog}
                    //TransitionComponent={Transition}
                    sx={MuiDialogpaper}
                    fullWidth
                    onClose={onClose}>
                    <Typography sx={dialogTitle}>
                        <span>{showProperTitle()}</span>

                        <ClearIcon fontSize='xs' onClick={onClose}
                            style={{cursor:'pointer', verticalAlign:'inherit'}}/>
                        </Typography>

                    {!props?.editInvoiceObj ?
                    <Grid container direction="column" style={{padding:'8px 16px', position:'relative'}}> 

                        {entityList && entityList.length > 1 &&
                        <Grid size={12}>
                            <Typography variant='subtitle1' style={{marginBottom:'-6px'}}>
                                Select Entity
                            </Typography>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                sx={MuiTextFieldroot}
                                select
                                value={selectedEntity}
                                onChange={onEntityClick}
                                size='small'
                            >
                                <MenuItem value={-1}>Select Entity</MenuItem>
                                {
                                    entityList.map((entityItem) => {
                                        return <MenuItem key={entityItem?.id} value={entityItem}>
                                                    {entityItem.name}
                                                </MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>}
                            
                        
                        <Grid size={12} style={{marginTop:entityList && entityList.length > 1 ? '8px' : '0px', position:'relative'}}>
                            <Typography variant='subtitle1' style={{marginBottom:'-6px'}}>
                                Select Client
                            </Typography>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                sx={MuiTextFieldroot}
                                slotProps={{
                                    style: {fontSize: 14, color: "#ccc"},
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {/* <SearchIcon className={classes.searchicon}/> */}
                                                <i className="flaticon-search"></i>
                                            </InputAdornment>
                                        )
                                    }
                                }}
                                placeholder="Find Customer"
                                onChange={(e) => {
                                    setSearchCustomer(e.target.value);
                                    getClientsList(e.target.value);
                                }}
                                size='small'
                            />
                            {customerList && 
                            <ClickAwayListener onClickAway={() => {
                                setSearchCustomer('');
                                setCustomerList([]);
                            }}> 
                            <Grid size={12} sx={{flexBasis:'94.8%', maxWidth:'94.8%'}}
                                className={`company__menu ${(searchCustomer !== '')  ? "active" : "inactive"}`}
                            >   
                                <Grid container justifyContent='center'>
                                    <Button fullWidth
                                        sx={createItemButton}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            props.handleClientDrawerOpen();
                                            onClose();
                                        }}
                                    >
                                        <AddCircleOutlineIcon fontSize='small' sx={createItemIcon}/>  
                                        Add Client
                                    </Button>
                                </Grid>
                                <Divider className={`dropdownDivider`} sx={dividerStyle}/>
                                {customerList.length > 0 ? customerList.map((customer) => {
                                        const customer_name = customer.name;
                                        const compArr = customer.name.split(" ");
                                        let compStr1 = compArr[0];
                                        let compStr2 = compArr[1] ? compArr[1] : '';
                                        const finalStr = compStr1.charAt(0).toUpperCase() + compStr2.charAt(0).toUpperCase();

                                        return <Box component='ul' key={customer.id} sx={listContainer}>
                                                        <Box component='li' sx={list} 
                                                        onClick={() => onCustomerItemClick(customer)}
                                                        >
                                                        <Avatar
                                                            style={{background:'white', color:'#2B2B2B', border:'1px solid #2B2B2B'}}  
                                                            sx={avatarStyle}>
                                                            {finalStr}
                                                        </Avatar>
                                                        <span dangerouslySetInnerHTML={{__html: boldString(customer_name, searchCustomer)}}></span>
                                                    </Box>
                                                    <Divider className={`dropdownDivider`}  sx={dividerStyle}/>
                                                </Box>
                                    }) : <Grid container justifyContent='center'>
                                            <Box component='li' sx={list} style={{textAlign:'center'}}>
                                                <span style={{fontSize:'14px', color:'#555'}}>
                                                    No Clients Found
                                                </span>
                                            </Box>
                                        </Grid>
                                }
                            </Grid>
                            </ClickAwayListener>}
                        </Grid>
                    </Grid> 
                    :
                    <Grid container direction="column" style={{padding:'8px 16px', position:'relative'}}> 

                        {entityList && entityList.length > 1 && props.openChangeEntityModal && 
                        <Grid size={12}>
                            <Typography variant='subtitle1' sx={{marginBottom:'-6px'}}>
                                Select Entity
                            </Typography>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                sx={MuiTextFieldroot}
                                select
                                value={selectedEntity}
                                onChange={onEntityClickFromEditInvoice}
                                size='small'
                            >
                                <MenuItem value={-1}>Select Entity</MenuItem>
                                {
                                    entityList.map((entityItem) => {
                                        return <MenuItem key={entityItem?.id} value={entityItem}>
                                                    {entityItem.name}
                                                </MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>}
                            
                        
                        {props.openChangeCustomerModal &&
                        <Grid size={12} style={{marginTop:entityList && entityList.length > 1 ? '8px' : '0px'}}>
                            <Typography variant='subtitle1' style={{marginBottom:'-6px'}}>
                                Select Client
                            </Typography>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                sx={MuiTextFieldroot}
                                slotProps={{
                                    style: {fontSize: 14, color: "#ccc"},
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {/* <SearchIcon className={classes.searchicon}/> */}
                                                <i className="flaticon-search"></i>
                                            </InputAdornment>
                                        )
                                    }
                                }}
                                placeholder="Find Customer"
                                onChange={(e) => {
                                    setSearchCustomer(e.target.value);
                                    getClientsList(e.target.value);
                                }}
                                size='small'
                            />
                            {customerList && 
                            <ClickAwayListener onClickAway={() => {
                                setSearchCustomer('');
                                setCustomerList([]);
                            }}> 
                            <Grid size={12} sx={{flexBasis:'94.8%', maxWidth:'94.8%'}}
                                className={`company__menu ${(searchCustomer !== '')  ? "active" : "inactive"}`}
                            >   
                                
                                {customerList.length > 0 ? customerList.map((customer) => {
                                        const customer_name = customer.name;
                                        const compArr = customer.name.split(" ");
                                        let compStr1 = compArr[0];
                                        let compStr2 = compArr[1] ? compArr[1] : '';
                                        const finalStr = compStr1.charAt(0).toUpperCase() + compStr2.charAt(0).toUpperCase();

                                        return <Box component='ul' key={customer.id} sx={listContainer}>
                                                        <Box component='li' sx={list} 
                                                            onClick={() => onCustomerClickFromEditInvoice(customer)}
                                                        >
                                                        <Avatar
                                                            style={{background:'white', color:'#2B2B2B', border:'1px solid #2B2B2B'}}  
                                                            sx={avatarStyle}>
                                                            {finalStr}
                                                        </Avatar>
                                                        <span dangerouslySetInnerHTML={{__html: boldString(customer_name, searchCustomer)}}></span>
                                                        </Box>
                                                    <Divider className={`dropdownDivider`} sx={dividerStyle}/>
                                                </Box>
                                    }) : <Typography style={{fontSize:'14px', color:'#555', display:'flex', alignItems:'center'}}>
                                            No Client Found
                                        </Typography>
                                }
                            </Grid>
                            </ClickAwayListener>}
                        </Grid>}
                    </Grid> 
                    }
                </Dialog>
            </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceModalComponent);
