import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import { AccessLevel, consoleToLog, extractFlagCode, interStateTaxesArr, intraStateTaxesArr, isInvoiceAccountCountryIndia, ItemTypes, otherTaxesTypes, trackGAEvent } from "../util/AppUtil";
import { getClientByTextApi, getVendorsByTextApi } from "../services/clientService";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { callCreateBillSubmitApi, callUpdateBillSubmitApi, getBillCategoryListApi, getCurrencyObjApi, getEmployeeListApi } from "../services/authService";
import { formatAmount } from "../util/currencyUtil";
import { useSnackbar } from 'notistack';
import CreateTaxPresetDrawer from "./CreateTaxPresetDrawer";
import AddEditBillCategoriesDrawer from "./AddEditBillCategoriesDrawer";
import AddEditEmployeeDrawer from "./AddEditEmployeeDrawer";
import AddEditClientDrawer from "./AddEditClientDrawer";
import Flags from "country-flag-icons/react/3x2";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DatePicker from "react-datepicker";
import Calender from "./Calender";
import BillLineItem from "./BillLineItem";
import AddIcon from '@mui/icons-material/Add';
import { useIsMount } from './useIsMount';
import {ExpenseType, numberValidate} from '../util/AppUtil';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import {ITCValues} from '../util/AppUtil';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Divider from '@mui/material/Divider';
import MenuItem from "@mui/material/MenuItem";

// const customMarginTop = {
//     marginTop: "16px",
//     paddingLeft: "8px",
//     paddingRight: "8px"
// };

const MuiFormControlroot = {
    width:'100%',
    background:'#fff'
    // "& .MuiOutlinedInput-input": {
    //     paddingLeft: "16px",
    //     paddingTop:'8px',
    //     paddingBottom:'8px',
    //     background:'#fff',
    // },
    // "& .MuiSelect-select.Mui-disabled": {
    //     cursor: "default !important",
    //     background: "#EEEEEE !important",
    // },
    // "& .MuiInputBase-input.Mui-disabled": {
    //     background: "#EEEEEE !important",
    //     color: "#555555 !important",
    // },
    // "& .MuiInputBase-adornedEnd" : {
    //     backgroundColor: '#fff !important',
    // }
};

const itemOptionStyle = {
    width: "100%", 
    height:'100%',
    color:'#777f8e',
    margin:'5px 0px'
};
const MuiAutocompleteinputRoot = {
    '& .MuiOutlinedInput-root' : {
    padding:'0px',
    borderRadius: '4px',
    }
};
const customTextField = {
    "& input::placeholder": {
        paddingLeft: "8px",
    }
};
const taxItemStyle = {
    padding: "8px 8px", 
    cursor: 'pointer',
    '&:hover': {
        background: '#f5f5f5'
    }
};
const MuiTextFieldroot = {
    background:'#fff',
    // '& .MuiOutlinedInput-input': {
    //     paddingTop: '8px',
    //     paddingBottom: '8px'
    // }
};
const dateBtn = {
    background:'#fff',
    padding:'7px 8px',
    alignItems:'center',
    justifyContent:'space-between'
};
const addLineButton = {
    borderRadius:'6px',
    fontWeight:'500 !important'
};
const addIconStyle = {
    margin: '-0.8px -5px 0px 0px',
    fontSize:'16px !important'
};
const createItemButton = {
    margin:'8px auto 0px auto',
    width:'95%', 
    color:'#1bac3b', 
    borderColor:'#d4d8de'
};
const createItemIcon = {
    margin:'0px 6px 0px 0px', 
    fontSize:'16px'
};

const tagSaveBtn = {
    background:'#1A73E8',
    color:'#fff',
    width:'70px',
    '&:hover':{
        background:'blue',
    }
}

const tagCancelBtn = {
    border:'1px solid #ddd',
    background:'#fff',
    color:'#333'
}

const tagIconStyle = {
    position:'relative',
    top:'2px',
    fontSize:'16px',
    transform:'scaleX(-1)'
}

const subtotalContainer = {
    display: 'flex',
    padding: '15px',
    flexDirection: 'column',
    borderRadius: '5px',
    background: '#FFF',
    //boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.23)',
    marginTop: '16px',
    marginBottom:'16px'
};

const valueStyles = {
    color: '#000',
    leadingTrim: 'both',
    textEdge: 'cap',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
};

const sidebarFooter = {
    width:'100%',
    padding: '12px',
    position: 'absolute',
    bottom: 0,
    background: '#1A5BAD',
    zIndex:100
};

const saveDraftBtn = {
    height: '40px',
    padding: "12px 22px",
    borderRadius: '5px',
    background: '#1A5BAD',
    color: '#fff',
    border:'2px solid #fff',
    '&:hover' : {
        color: '#fff',
        border:'2px solid #fff'
    }
};

const saveBillBtn = {
    height: '40px',
    padding: "12px 22px",
    borderRadius: '5px',
    background: '#FFF',
    color: '#1A5BAD',
    '&:hover' : {
        background:'#f7f7f7',
        color: '#1A5BAD'
    }
};

const templateHeading = {
    padding:'8px 16px',
    color:'#5b51bf',
    fontWeight:600,
    pointerEvents:'none'
}

const CreateItemButton = ({ children, text, onClick, ...other }) => {
    return (
    <Paper {...other} 
        style={{
            marginTop:'6px',
            boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' 
        }}>
        <Grid container justifyContent="center">
            <Button fullWidth
                sx={createItemButton}
                variant="outlined"
                color="primary"
                onMouseDown={onClick}
                >
                <AddCircleOutlineIcon fontSize='small' sx={createItemIcon}/>  
                    Add New {`${text}`}
            </Button>
        </Grid>
        {children}
    </Paper>
    )
}

const CreateUpdateBillDetailComponent=(props)=>{
    const {enqueueSnackbar} = useSnackbar();

    const [billType, setBillType] = useState(props.newExpense ? ExpenseType.EXPENSE : ExpenseType.VENDOR_BILL);
    const [billObj, setBillObj]=useState();
    const [vendors, setVendors]=useState([]);
    const [currencyObj, setCurrencyObj]=useState(props.billObj ? props.billObj?.purchase_invoice?.currency : props.selectedAccount?.currencies);
    const [clients, setClients]=useState([]);
    const [billDate, setBillDate] = useState(null);
    const [billDueDate, setBillDueDate] = useState(null);
    const [isNoVendor, setIsNoVendor]= useState(true);
    const [isEmpReimburse, setIsEmpReimburse]= useState(true);
    const [isClaimExpense, setIsClaimExpense]= useState(true);
    const [loading, setLoading]= useState(false);

    const [tdsTypeObj, setTDSTypeObj] = useState(null);
    const [tdsAmount, setTDSAmount] = useState(0);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [selectedEntity, setSelectedEntity]=useState(props.selectedEntity);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [showAddTaxMenu, setShowAddTaxMenu] = useState(false);
    const [taxPresetsArr, setTaxPresetsArr] = useState([...props.selectedAccount?.tax_presets]);
    let other_taxes = otherTaxesTypes(props.selectedAccount?.tax_presets);
    let inter_state_taxes = interStateTaxesArr(props.selectedAccount?.tax_presets);
    let intra_state_taxes = intraStateTaxesArr(props.selectedAccount?.tax_presets);
    const [selectedTaxes, setSelectedTaxes]=useState([]);
    
    const [billCategoryList, setBillCategoryList]=useState([]);

    const [deductTds, setDeductTds]=useState(false);
    const [billNumber, setBillNumber]=useState("");
    const [description, setDescription]=useState();
    const [selectedBillCategory, setSelectedBillCategory] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openBillCategoryDrawer, setOpenBillCategoryDrawer] = useState(false);
    const [openBillEmployeeDrawer, setOpenBillEmployeeDrawer] = useState(false);
    const [openBillVendorDrawer, setOpenBillVendorDrawer] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [inputText, setInputText] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [billLineItems, setBillLineItems]=useState([]);

    const [reverseCharge, setReverseCharge] = useState(false);
    const [roundoffValue, setRoundoffValue]=useState(0.0);
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');

    const [paymentMethod, setPaymentMethod] = useState(-1);
    const [employeeList, setEmployeeList]=useState([]);

    const [popover_exchange_rate, setPopOverExchangeRate]= useState('');
    const [shouldShowExchangeRate, setShouldShowExchangeRate]= useState(false);
    const [exchangeRatePopover, setExchangeRatePopover] = useState(null);
    const [exchange_rate, setExchangeRate]= useState('');
    const [exchangeCurrency, setExchangeCurrency] = useState(props.selectedAccount.currencies?.code);
    const [symbolStr, setSymbol] = useState('INR');

    const currenciesObj = props.selectedAccount?.currencies;

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = (AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER) ? true : false;
    const invoiceAccountCountry = props.selectedAccount?.country;
    const bankList = (selectedEntity?.bank_accounts && selectedEntity?.bank_accounts.length > 0) ? selectedEntity?.bank_accounts : [];
    const selectedAccountCurrency = props.selectedAccount.currencies;
    

    const getBillFrom = (highlights) => {
        let billFrom = highlights.find((h)=> h?.type === ItemTypes.VENDOR_NAME);
        //consoleToLog("billFrom", billFrom)
        return billFrom;
    }
    const getBillNum = (highlights) => {
        let billNumber = highlights.find((h)=> h?.type === ItemTypes.INVOICE_RECEIPT_ID);
        //consoleToLog("billNumber", billNumber)
        return billNumber;
    }

    const getBillDate = (highlights) => {
        let billDate = highlights.find((h)=> h?.type === ItemTypes.INVOICE_RECEIPT_DATE);
        return billDate;
    }

    const getBillDueDate = (highlights) => {
        let billDueDate = highlights.find((h)=> h?.type === ItemTypes.DUE_DATE);
        //consoleToLog("billDueDate", billDueDate)
        return billDueDate;
    }

    const billNumObj = getBillNum(props.highlights);
    const billFromObj = getBillFrom(props.highlights);
    const billDateObj = getBillDate(props.highlights);
    const billDueDateObj = getBillDueDate(props.highlights);

    const updateHash = (highlight) => document.location.hash = highlight ? `highlight-${highlight.id}` : '';
    const isMount = useIsMount();

    useEffect(() => {
        console.log("**useEffect right side : billObj", props.billObj);
         //initialize subtotal first time when no bill attached
        let newObj = {
            amount: 0.0,
            description: "",
            category: null,
            tax:[],
            itc_eligibility: ITCValues.ELIGIBLE,
            tags:[],
            hsn_or_sac:''
        }
        setBillLineItems([newObj]);
        //get employee list // get category list 
        getList();
        getCurrencies(currenciesObj?.name, true);
    }, []);

    useEffect(() => {
        //if(!isMount){
            if(props.billObj && Object.keys(props.billObj).length>0){
                console.log("**right side useeffect of billObj", props.billObj);
                setBillObj(props.billObj);
                //if suggested vendors found use this 
                if(props.billObj.vendors && props.billObj.vendors.length > 0 && props.billObj.purchase_invoice.status==='draft') {
                    setIsNoVendor(false);
                    setVendors([...props.billObj.vendors]);
                    setSelectedVendor({...props.billObj.vendors[0]});
                }
                else{//if no suggested vendor check this
                    if(props.billObj?.purchase_invoice?.vendor) {
                        setIsNoVendor(false);
                    } else {
                        setIsNoVendor(true);
                    }
                    setSelectedVendor(props.billObj?.purchase_invoice?.vendor)
                }
                setCurrencyObj(props.billObj ? props.billObj?.purchase_invoice?.currency : props.selectedAccount?.currencies);
                prefillFormFields(props.billObj);

            }
        //}
    }, [props.billObj]);

    useEffect(() => {
        if(selectedVendor){
            consoleToLog("**Vendor state", selectedVendor?.state);
            consoleToLog("**selectedEntity state", selectedEntity?.state);
            if(selectedEntity?.state === selectedVendor?.state){
                setTaxPresetsArr([...intra_state_taxes, ...other_taxes]);
            }
            if(selectedEntity?.state !== selectedVendor?.state){
                setTaxPresetsArr([...inter_state_taxes, ...other_taxes]);
            }
        }
        else{
            setTaxPresetsArr([...props.selectedAccount?.tax_presets])
        }
    }, [selectedVendor]);

    useEffect(() => {
        if(!isMount){
            setTaxPresetsArr([...props.selectedAccount?.tax_presets])
        }
    }, [props.selectedAccount?.tax_presets]);

    const prefillFormFields=(bill)=>{
        setSelectedCurrency(bill?.purchase_invoice?.currency);
        setSelectedEntity(bill?.purchase_invoice?.entity);
        setBillNumber(bill?.purchase_invoice?.bill_number);
        //setSubtotal(bill?.purchase_invoice?.subtotal || 0);
        console.log("prefill bill?.purchase_invoice: ", bill?.purchase_invoice)
        if(bill?.purchase_invoice?.purchase_items 
            && bill?.purchase_invoice?.purchase_items.length>0){
            setBillLineItems(bill?.purchase_invoice.purchase_items)
        }
        else{
            let category=null
            //captured category setting to line item
            if(bill?.purchase_invoice?.ocr_json?.category_id){
                category=billCategoryList.find(cat=> cat.id===bill?.purchase_invoice?.ocr_json?.category_id)
            }
            //initialize subtotal first time
            let newObj = {
                amount: bill?.purchase_invoice.subtotal,
                description: "",
                category: category,
                tax: (bill?.purchase_invoice?.total_tax && bill?.purchase_invoice?.total_tax.length>0)? bill?.purchase_invoice?.total_tax : []
            }
            //console.log("newObj: ", newObj);
            setBillLineItems((prevItems) => [...prevItems, newObj]);
        }
        setBillDate(bill?.purchase_invoice?.bill_date);
        setBillDueDate(bill?.purchase_invoice?.due_date);
        setSelectedBillCategory(bill?.purchase_invoice?.category);
        setDescription(bill?.purchase_invoice?.description);
        //setSelectedTaxes(bill?.purchase_invoice?.taxes || []);

        if(bill.purchase_invoice?.purchase_invoice_type === 'expense'){
                setBillType(ExpenseType.EXPENSE);
                
                //get 0th object and extract bank account
                const obj=bill.purchase_invoice?.purchase_transactions[0]?.bank_account;
                if(obj && Object.keys(obj).length>0){
                    //set bank account id
                    setPaymentMethod(obj.id)
                }
                else{
                    //set cash
                    setPaymentMethod(bill.purchase_invoice?.purchase_transactions[0]?.payment_method)
                }
        }

        if(bill.purchase_invoice?.purchase_invoice_type === 'expense' && bill?.purchase_invoice?.employee 
            && Object.keys(bill?.purchase_invoice?.employee).length>0){
            setBillType(ExpenseType.REIMBURSEMENT)
            setSelectedEmployee(bill?.purchase_invoice?.employee);
        }

        if(bill?.purchase_invoice?.tds_type_object 
            && Object.keys(bill?.purchase_invoice?.tds_type_object).length>0)
        {
            setDeductTds(true);
            setTDSAmount(bill?.purchase_invoice?.tds_amount);
            setTDSTypeObj(bill?.purchase_invoice?.tds_type_object);
        }
        if(bill?.purchase_invoice?.employee 
            && Object.keys(bill?.purchase_invoice?.employee).length>0){
            setIsEmpReimburse(false);
            setSelectedEmployee(bill?.purchase_invoice?.employee);
        }

        if(bill?.purchase_invoice?.client 
            && Object.keys(bill?.purchase_invoice?.client).length>0){
            setIsClaimExpense(false);
            setSelectedClient(bill?.purchase_invoice?.client);
        }
    }

    const getCurrencies = (query, setCurrencyObj) => {
        const invoice_account_id = props.selectedAccount?.id;
        getCurrencyObjApi(invoice_account_id, query)
        .then((respose) => {
            const res = respose.data;
            consoleToLog('Response getCurrencyObjApi: ', res);

            setCurrencies(res);
            setCurrencyObj && setSelectedCurrency(currenciesObj);
        })
        .catch((e) => {
            consoleToLog('Error getCurrencyObjApi', e);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        })

    }

    const getList = async() => {
        const invoice_account_id = props.selectedAccount?.id;
        try{
            const res1=await getBillCategoryListApi(invoice_account_id);
            consoleToLog("Response getBillCategoryListApi List",res1.data)
            setBillCategoryList(res1.data);
            
            const res=await getEmployeeListApi(invoice_account_id);
            consoleToLog("Response Employee List",res.data)
            setEmployeeList(res.data);
        }
        catch(e){
            console.log("failed to get employee list", e);
            setEmployeeList([]);
        }
    }

    const handleChangeDate = (date) => {
        setBillDate(moment(date).format('YYYY-MM-DD'));
        handleBillDatePopoverClose();
    };

    const handleBillDatePopoverClick = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleBillDatePopoverClose = () => {
        setAnchorEl1(null);
    }

    const handleBillDatePopOver = () => {
        return (<Popover
            id={'bill-date-popover'}
            open={Boolean(anchorEl1)}
            anchorEl={anchorEl1}
            onClose={handleBillDatePopoverClose}
            className='date_popover_style'
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <DatePicker
                selected={billDate ? moment(billDate).toDate() : moment().toDate()}
                onChange={(date) => handleChangeDate(date)}
                //selectsRange
                inline
            />
        </Popover>)
    }
    
    
    /* const onSubtotalChange= (e) => {
        let inputSubtotal = e.target.value;
        // Regular expression to allow only numbers with up to 2 decimal places
        if (inputSubtotal === "" || /^\d+(\.\d{0,2})?$/.test(inputSubtotal)) {
            setSubtotal(inputSubtotal);

            //on subtotal change update selected taxes as well
            let localSelectedTaxes=selectedTaxes;
            if(localSelectedTaxes && localSelectedTaxes.length>0){
                let updatedTaxes= localSelectedTaxes.map((t)=>{
                    const finalSubtotal=(inputSubtotal*t.rate)/100;
                    t.amount=finalSubtotal.toFixed(2);
                    return t;
                })
                setSelectedTaxes([...updatedTaxes]);
            }
        }
    }; */

    const onDescriptionChange=(e)=>{
        let input = e.target.value;
        setDescription(input);
    }

    const handleChangeDueDate = (date) => {
    setBillDueDate(moment(date).format('YYYY-MM-DD'));
    };

    const handleDueDatePopoverClick = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleDueDatePopoverClose = () => {
        setAnchorEl2(null);
    }

    const handleDueDatePopOver = () => {
        return (<Popover
            id={'due-date-popover'}
            open={Boolean(anchorEl2)}
            anchorEl={anchorEl2}
            onClose={handleDueDatePopoverClose}
            className='date_popover_style'
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
            }}
        >
            <Calender issueDate={billDate ? moment(billDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')} 
                dueDate={billDueDate ? moment(billDueDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                setDueDate={handleChangeDueDate}
                handleDueDatePopoverClose={handleDueDatePopoverClose}
                />
        </Popover>)
    }

    const onItemClearClick1 = () => {
        setTDSTypeObj(undefined);
        setTDSAmount(0);
    }

    const handleVendorDrawerOpen=()=>{
        trackGAEvent(props.selectedAccount.name, 'Bill Vendor - Add button clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenBillVendorDrawer(true);
    }

    const handleVendorDrawerClose=()=>{
        setOpenBillVendorDrawer(false);
    }

    const handleEmployeeDrawerOpen=()=>{
        trackGAEvent(props.selectedAccount.name, 'Bill Employee - Add button clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenBillEmployeeDrawer(true);
    }

    const handleEmployeeDrawerClose=()=>{
        setOpenBillEmployeeDrawer(false);
    }

    const handleCategoryDrawerOpen = () => {
        trackGAEvent(props.selectedAccount.name, 'Bill Category - Add button clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenBillCategoryDrawer(true);
    }

    const handleCategoryDrawerClose = () => {
        setOpenBillCategoryDrawer(false);
    }

    const handleDrawerOpen = (e) => {
        trackGAEvent(props.selectedAccount.name, 'Bill Edit - Add Tax preset button clicked', `${props.user.firstname} ${props.user.lastname}`);
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const onAddBillCategoryClick = (category) => {
        setBillCategoryList(billCategoryList => [...billCategoryList, category]);
    }

    const onAddBillEmployeeClick = (category) => {
        setEmployeeList(employeeList => [...employeeList, category]);
    }

    const addClient=(vendor)=>{
        setVendors(vendors => [...vendors, vendor])
    }

    const renderOptions1 = (props, option) => {
        return (
            <Box component='div'  {...props}>
                <Grid container alignItems='center' justifyContent="space-between" sx={itemOptionStyle}>
                    <Grid size={10}>
                        <Typography variant='body2'>
                            {option.tds_display}
                        </Typography> 
                    </Grid>

                    <Grid size={2} style={{textAlign:'right'}}>
                        <Typography variant='body2'>
                            {option.rate}
                        </Typography>
                    </Grid>
                </Grid>    

            </Box>
            );
    }

    const onVendorNameChange=(e, newValue, reason)=>{
        if(newValue){
            if(newValue.id !=='add_new'){
                setSelectedVendor(newValue);
            }
        }
        else{
            setSelectedVendor(null);
        }
    }

    const onClientInputChange= (e, inputValue) => {
        const invoice_account_id = props.selectedAccount?.id
        const text=inputValue;
        const company_type='customer';

        if(text && text !== selectedVendor?.name){
            getClientByTextApi(invoice_account_id, text, company_type)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response getClientByTextApi: ', res);
                    setClients(res.companies);
                })
                .catch((e) => {
                    consoleToLog('Error getClientByTextApi', e.response);
                    if(e.response.data && e.response.data.message) {
                        return;
                    }
            });
        }
        else{
            setClients([]);
        }
    };

    const onVendorInputChange = (e, inputValue) => {
        const invoice_account_id = props.selectedAccount?.id
        const text=inputValue;
        const company_type='vendor';

        if(text && text !== selectedVendor?.name){
            getVendorsByTextApi(invoice_account_id, text, company_type)
                .then((response) => {
                    const res = response.data;
                    consoleToLog('Response getVendorsByTextApi: ', res);
                    setVendors(res.companies);
                })
                .catch((e) => {
                    consoleToLog('Error getVendorsByTextApi', e.response);
                    if(e.response.data && e.response.data.message) {
                        return;
                    }
            });
        }
        else{
            setVendors([]);
        }
    };
    
    const onAddTaxClick = (event) => {
        //console.log("Apply tax click", showAddTaxMenu);
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setShowAddTaxMenu(!showAddTaxMenu);
        /* if(dropdownIndex !== undefined && anchorEl) {
            setDropDownIndex(undefined);
            setAnchorEl(null);
        } */
    }

    /* const onTaxItemClick=(tax)=>{
        //console.log("selected tax: ", tax);
        const finalSubtotal=(subtotal*tax.rate)/100;
        tax.amount=finalSubtotal.toFixed(2);
        setSelectedTaxes([...selectedTaxes, tax]);
        setShowAddTaxMenu(false);
        setAnchorEl(null);
    }

    const onTaxDelete = (tax)=>{
        let taxArr=selectedTaxes.filter(t=>t.id!==tax.id);
        setSelectedTaxes(taxArr)
    } */

    const onBillNumberChange =(e)=>{
        const bill_number=e.target.value;
        setBillNumber(bill_number);
    }

    const getTotal = () => {
        let taxTotal = getTaxDetails().map(item => parseFloat(item.amount.toFixed(2))).reduce((prev, curr) => prev + curr, 0);
        let total=getSubtotal()+parseFloat(taxTotal)+parseFloat(roundoffValue);
        return total
    }

    const getSubtotal = () => {
        const totalPrice = billLineItems.map(item => item.amount!==""?item.amount:0).reduce((prev, curr) => prev + curr, 0);
        return totalPrice ? totalPrice : 0
    }

    const onBillCategoryChange = (e, newValue, reason) => {
        if(newValue){
            if(newValue.id !=='add_new'){
                setSelectedBillCategory(newValue);
            }
        }
        else{
            setSelectedBillCategory(null);
        }
    }

    const onEmployeeChange= (e, newValue, reason) => {
        if(newValue){
            if(newValue.id !=='add_new'){
                setSelectedEmployee(newValue);
            }
        }
        else{
            setSelectedEmployee(null);
        }
    }

    const onClientChange= (e, newValue, reason) => {
        if(newValue){
            setSelectedClient(newValue);
        }
        else{
            setSelectedClient(null);
        }
    }

    const onTDSItemChanged = (e, newValue) => {
        if(newValue){
            setTDSTypeObj(newValue);
            let calculateTDSAmount = parseFloat((newValue.rate * getSubtotal()) / 100);
            setTDSAmount(parseFloat(calculateTDSAmount.toFixed(2)));
        }
        else{
            setTDSTypeObj(undefined);
            setTDSAmount(0);
        }
    }
    
   const onSubmitHandler = async() => {
        const currency_id=selectedCurrency?.id || null;

        if(!billNumber){
            enqueueSnackbar('Bill number can not be empty!', {variant:'error'});
            return;
        }

        if(!billDate){
            enqueueSnackbar('Bill date can not be empty!', {variant:'error'});
            return;
        }

        if(!billDueDate){
            enqueueSnackbar('Bill due date can not be empty!', {variant:'error'});
            return;
        }

        if(billDueDate<billDate){
            enqueueSnackbar('Bill date should be greater than issue date!', {variant:'error'});
            return;
        }

        /* if(!subtotal || subtotal<0){
            enqueueSnackbar("Subtotal can not be empty or should be greter than 0", {variant:'error'});
            return;
        } */

        if(!currency_id){
            enqueueSnackbar('Kindly select currency!', {variant:'error'});
            return;
        }

        if(billLineItems && billLineItems.length===0){
            enqueueSnackbar('Kindly add bill line item!', { variant: 'error' });
            return;
        }

        //if category id 
        for (let item of billLineItems) {
            if (!item?.amount || item?.amount===0) {
                enqueueSnackbar('Kindly enter amount in bill line item!', { variant: 'error' });
                return;
            }
            if (!item?.category?.id) {
                enqueueSnackbar('Kindly select bill category in line item', { variant: 'error' });
                return;
            }
        }

        try{
            const entity_id=selectedEntity.id;
            const invoice_account_id=props.selectedAccount.id;
            const subtotal=getSubtotal()
            let taxes=getTaxDetails();/* selectedTaxes && selectedTaxes.length>0 &&
                selectedTaxes.map((tax)=>{
                  return {
                    name: tax.name,
                    rate: tax.rate,
                    amount: parseFloat(tax.amount),
                    tax_preset_id: tax.id
                  }  
                }); */

            const items=billLineItems.map((item)=>{
                return{
                    amount: item?.amount,
                    description: item?.description,
                    category_id: item?.category?.id,
                    tax: item?.tax,
                    itc_eligibility: ITCValues.ELIGIBLE
                }
            });

            const claim_client_id=!isClaimExpense && selectedClient ? selectedClient?.id: null;
            const employee_id=!isEmpReimburse && selectedEmployee ? selectedEmployee?.id: null;
            const category_id=selectedBillCategory ? selectedBillCategory?.id : null;
            const vendor_id = !isNoVendor && selectedVendor ? selectedVendor?.id : undefined;
            let tds_type_id=tdsTypeObj?tdsTypeObj.id: null;
            let tds_amount;
            if(!deductTds){
                tds_type_id=null;
                tds_amount=0;
            }
            else{
                tds_amount=tdsAmount;
            }
            
            setLoading(true);
            if(billObj){
                 //if bilobj available then update api
                const res=await callUpdateBillSubmitApi(entity_id, invoice_account_id, billNumber, 
                    billDate, billDueDate, subtotal, tds_amount, taxes, 
                    vendor_id, claim_client_id, employee_id, 
                    category_id, props.billObj?.purchase_invoice?.id, 
                    description, tds_type_id, currency_id, items)
                
                consoleToLog("Response callUpdateBillSubmitApi: ", res.data);

                setLoading(false);
                enqueueSnackbar('Bill updated successfully!', {variant:'success'});
                props.setUpdatedBillObj(res.data);
                
                //close modal
                props.handleBillDetailClickDialogClose();
            }
            else{
                consoleToLog("Creating bill manually!");
                //if bill obj not available then create api
                const res=await callCreateBillSubmitApi(entity_id, invoice_account_id,  billNumber, 
                    billDate, billDueDate, subtotal, tds_amount, taxes,
                    vendor_id, claim_client_id, employee_id, 
                    category_id, description, tds_type_id, currency_id, items);
                
                consoleToLog("Response callCreateBillSubmitApi: ", res.data);

                setLoading(false);
                enqueueSnackbar('Bill submitted successfully!', {variant:'success'});
                
                //close modal
                props.handleBillDetailClickDialogClose();
            }
        }
        catch(e){
            console.log("Error submit: ", e);
            setLoading(false);
            if(e?.response?.data && e?.response?.data?.message){
                enqueueSnackbar(e.response.data.message, {variant:'error'});
            }
        }
    } 

    const onSelectedCurrencyChange = (e, newValue) => {
        setSelectedCurrency(newValue);
        setExchangeCurrency(newValue?.code);
        //console.log('selected currency', newValue);
    }

    const handleInputChangeForCurrency = (e, newValue) => {
        const value = e?.target.value;
        value && getCurrencies(newValue, false);

        setInputText(newValue);
        if (!newValue) {
            setShowDropdown(false);
        }
    }

    const renderCurrencyOptions = (props, option) => {
        const flagCode =  extractFlagCode(option.flag);
        const Flag = Flags[flagCode];
        return (
            <Grid size={12} {...props}>
                <Grid container alignItems='center' sx={itemOptionStyle}> 
                    {Flag && 
                    <Grid size={1}>
                        <div style={{position:'relative', top:'1px'}}>
                            <Flag width='28px' height='22px'/>
                        </div>
                    </Grid>}
                    <Grid size={8}>
                        <Typography variant='body1' sx={{marginLeft:'8px'}}>
                            {option?.name}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            );
    }

    const showFlagStartAdornment = () => {
        const flagCode =  extractFlagCode(selectedCurrency?.flag);
        const Flag = Flags[flagCode];

        if (!Flag) {
            console.error(`Flag component for code ${selectedCurrency?.code} not found.`);
            return null; // Return null if the Flag is undefined
        }
    

        return ( 
                <InputAdornment position="start">
                    <Grid container alignItems='center'>
                        <Grid style={{paddingLeft:'6px', position:"relative", top:'3px'}}>
                            <Flag width='25px'/>
                        </Grid>
                    </Grid>
                </InputAdornment>
            )
    }

    const onDeleteLine = useCallback((index) => {
        let localBillLineItems = billLineItems;
        localBillLineItems = localBillLineItems.filter((item, i) => {
            return i !== index;
        });
        setBillLineItems([...localBillLineItems]);
    }, [billLineItems, setBillLineItems]);

    const onUpdateLineItemsArr = useCallback((lineItem, index) => {
        setBillLineItems((prevItems) => {
            const newItems = [...prevItems];
            newItems[index] = lineItem;
            return newItems;
        });
    }, []);

    // Memoize the list rendering
    const renderedInvoiceItemList = useMemo(
        () =>
        billLineItems.map((lineItem, index) => (
            <BillLineItem key={lineItem.id} 
                lineItem={lineItem}
                index={index}
                onDeleteLine={onDeleteLine}
                billCategoryList={billCategoryList}
                setBillCategoryList={setBillCategoryList}
                onUpdateLineItemsArr={onUpdateLineItemsArr}
                currencyObj={currencyObj}
                taxPresetsArr={taxPresetsArr}
                CreateItemButton={CreateItemButton}
                reverseCharge={reverseCharge}
                />
        )),
        [billLineItems, billCategoryList, onDeleteLine, onUpdateLineItemsArr, currencyObj, taxPresetsArr, reverseCharge]
    );

    const onAddBillLineItemClick = () => {
        let newObj = {
            amount: 0.0,
            description: "",
            category: null,
            tax:[],
            itc_eligibility: ITCValues.ELIGIBLE,
            tags:[],
            hsn_or_sac:''
        }
        setBillLineItems([...billLineItems, newObj]);
    }

     const getTaxDetailsUPdatedArray = (arr, taxItem) => {
        if(taxItem !== undefined) {
            arr.push({
                tax_preset_id: taxItem.tax_preset_id,
                name: taxItem.name,
                rate: taxItem.rate,
                amount: parseFloat(taxItem.amount)
            })
        }
        return arr;
    }

    const getTaxDetails = () => {
        let taxDetailsArray = [];
        //consoleToLog('*billLineItems', billLineItems);
        for(var i=0; i < billLineItems.length; i++) {
            for(var j = 0; j < billLineItems[i].tax?.length; j++) {
                var taxItem = billLineItems[i].tax[j];
                if(taxDetailsArray && taxDetailsArray.length > 0) {
                    let index = taxDetailsArray.findIndex(x => x.tax_preset_id === taxItem.tax_preset_id);
                     //console.log('index', index);
                    if(index === -1) {
                        taxDetailsArray=getTaxDetailsUPdatedArray(taxDetailsArray, taxItem);

                    } else {
                        taxDetailsArray[index].amount = parseFloat(taxDetailsArray[index].amount) + parseFloat(taxItem.amount);
                        //console.log('add amount', total_amount);
                    } 
                } else {
                    taxDetailsArray=getTaxDetailsUPdatedArray(taxDetailsArray, taxItem);
                }
            }
        }
        return taxDetailsArray;
    }

    const showTotalTaxUi = () => {
        return ( 
            <>
            {
                getTaxDetails().map((taxItem) => {
                return <Grid container justifyContent='space-between'
                            key={taxItem.tax_preset_id}
                            className="item_marginTop">
                            <Typography variant="body1">
                                {taxItem?.name}
                            </Typography>

                            <Typography sx={valueStyles}>
                                {`${isNaN(parseFloat(taxItem?.amount).toFixed(2))? `₹` + 0.00.toFixed(2) : 
                                    formatAmount(taxItem?.amount, selectedCurrency)}`}
                            </Typography>
                        </Grid>
                })
            }
            </>
        )
    }

    const handleBillTypeChange = (event) => {
        setBillType(event.target.value);

        //reset selected values
        // if(event.target.value===ExpenseType.EXPENSE){
        //     setSelectedEmployee(null);
        // }
        // else{
        //     setPaymentMethod(-1);
        //     setSelectedClient(null);
        //     setIsClaimExpense(false);
        // }
    };

    const handleReverseChange = (event) => {
        setReverseCharge(!reverseCharge);
        const localLineItems=[...billLineItems];
        const updatedLineItems = localLineItems.map((item) => {
            return {
                ...item,
                //tax_id: '',
                //tax_group: null,
                //reverse_charge_tax_group: null,
                tax: [],
            }
        });
        setBillLineItems(updatedLineItems);
        //setBillLineItems(true);
    }

    const handleExchangeRatePopoverOpen = (event) => {
        setExchangeRatePopover(event.currentTarget);
    }

    const handleExchangeRatePopoverClose = () => {
        setExchangeRatePopover(null);
    }

    const onExchangeSaveClick=()=>{
        setExchangeRate(popover_exchange_rate);
        setPopOverExchangeRate(popover_exchange_rate);
        handleExchangeRatePopoverClose();
    }

    const showExchangeRate = () =>{
        return (
            <Popover open={Boolean(exchangeRatePopover)} 
                anchorEl={exchangeRatePopover}
                onClose={handleExchangeRatePopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }} 
                >
                    <Paper sx={{width:'500px'}}>
                        <Typography sx={{ p: 1, background:'#f9f9fb', fontSize:'14px' }}>
                        Edit Exchange Rate
                        </Typography>
                        <Box sx={{p:1, display:'flex', flexDirection:'column', gap:'16px', paddingBottom:'16px'}}>
                            <Grid size={12}>
                                <Grid container justifyContent='center' 
                                    alignItems='center' sx={{padding:"13px 8px 8px 8px"}}>
                                    <Grid size={6}>
                                        <Typography variant="body2" sx={{marginBottom:'2px', color: 'red'}}>
                                            Exchange Rate (in INR)*
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="exchange"
                                            color="secondary"
                                            type="text"
                                            autoComplete="off"
                                            sx={MuiFormControlroot}
                                            value={popover_exchange_rate}
                                            onChange={(e) => {
                                                setPopOverExchangeRate(e.target.value)
                                            }}
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid size={12}>
                                <Grid container alignItems='center' 
                                    style={{padding:'0px 8px'}}
                                    spacing={1}>
                                    <Grid>
                                        <Button sx={tagSaveBtn} onClick={onExchangeSaveClick}>
                                            Save
                                        </Button>
                                    </Grid>

                                    <Grid>
                                        <Button 
                                            onClick={handleExchangeRatePopoverClose}
                                            sx={tagCancelBtn}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
            </Popover>
        )
    }

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value)
    }


    return(
        <>
        <div>
        {
            props.loading ?
            <Grid container justifyContent="center">
                <CircularProgress style={{marginTop:'16px'}}/>
            </Grid>
            :
            <div className='sidebar' style={{paddingTop:'8px'}}>
                <Grid size={12}>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="gender" 
                            name="bill_type" 
                            value={billType} 
                            onChange={handleBillTypeChange}>
                            <Grid item container>
                                <FormControlLabel value={ExpenseType.VENDOR_BILL} control={<Radio />} label="Vendor Bill" />
                                <FormControlLabel value={ExpenseType.EXPENSE} control={<Radio />} label="Expense" />
                                <FormControlLabel value={ExpenseType.REIMBURSEMENT} control={<Radio />} label="Reimbursement" />
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid container spacing={1}>
                    <Grid size={6}>
                        <Typography variant="subtitle1" className='textfield_label mandatory_fields'>
                            Bill Number*
                        </Typography>
                        <TextField
                            size="small"
                            placeholder="Bill Number"
                            variant="outlined"
                            fullWidth
                            value={billNumber}
                            onChange={onBillNumberChange}
                            onClick={(e) => updateHash(billNumObj)}
                            sx={MuiTextFieldroot}
                        />
                    </Grid>
                    <Grid size={6}>
                        <Typography variant="subtitle1" className='textfield_label mandatory_fields'>
                            Currency*
                        </Typography>
                        <Autocomplete
                            id="combo-box-demo"
                            options={currencies}
                            sx={MuiAutocompleteinputRoot}
                            style={MuiFormControlroot}
                            open={showDropdown}
                            onOpen={() =>  {
                                if(inputText) {
                                    setShowDropdown(true)
                                }
                            }}
                            onClose={() => setShowDropdown(false)}
                            value={selectedCurrency}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, option) => renderCurrencyOptions(props, option)}
                            onChange={(e, newValue) => onSelectedCurrencyChange(e, newValue)}
                            onInputChange = {(e, newValue) => handleInputChangeForCurrency(e, newValue)}
                            renderInput={params => (
                                <TextField {...params} variant="outlined" 
                                placeholder='Search Currency' 
                                fullWidth
                                slotProps={{
                                    input: {
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                            {showFlagStartAdornment()}
                                            {params.InputProps.startAdornment}
                                            </>
                                        )
                                    }
                                }}
                                
                                />
                            )}
                            size="small"
                        />
                    </Grid>
                </Grid>        
                <Grid container alignItems="center" spacing={1} style={{ marginTop: '12px'}}>
                    <Grid size={6}>
                        <Typography variant="subtitle1" 
                            className='textfield_label mandatory_fields'>
                            Bill Date*
                        </Typography>
                        <Button variant="outlined" color="primary" fullWidth
                            sx={dateBtn}
                            onClick={handleBillDatePopoverClick}>
                                <Typography variant="subtitle1" sx={{position:'relative', top:'1px'}}>
                                    {billDate ? moment(billDate).format('DD/MM/YYYY') : 'dd/mm/yyyy'}
                                </Typography>

                                <DateRangeIcon fontSize='small' sx={{color:'#555'}}/> 
                        </Button>
                        {handleBillDatePopOver()}
                        {/* <Typography variant="subtitle1" 
                            className='textfield_label mandatory_fields'
                            style={{marginBottom: "-8px"}}>
                            Bill Date*
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="date"
                            margin="dense"
                            autoComplete="off"
                            value={billDate}
                            onChange={handleChangeDate}
                            onClick={(e) => {
                                updateHash(billDateObj);
                            }}
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            classes={{
                                root: classes.MuiTextFieldroot
                            }}
                        /> */}
                    </Grid>

                    <Grid size={6}>
                        {billType === ExpenseType.VENDOR_BILL && 
                        <>
                            <Typography variant="subtitle1" 
                                className='textfield_label mandatory_fields'>
                                Due Date*
                            </Typography>
                            <Button variant="outlined" color="primary" fullWidth
                                sx={dateBtn}
                                onClick={handleDueDatePopoverClick}>
                                    <Typography variant="subtitle1" sx={{position:'relative', top:'1px'}}>
                                        {billDueDate ? moment(billDueDate).format('DD/MM/YYYY') : 'dd/mm/yyyy'}
                                    </Typography>
                                    
                                    <DateRangeIcon fontSize='small' sx={{color:'#555'}}/> 
                            </Button>
                            {handleDueDatePopOver()}
                        </>
                        }
                        {/* <TextField
                            fullWidth
                            variant="outlined"
                            type="date"
                            margin="dense"
                            autoComplete="off"
                            value={billDueDate}
                            onChange={handleChangeDueDate}
                            onClick={(e) => {
                                updateHash(billDueDateObj);
                            }}
                            InputLabelProps={{style: {fontSize: 13, color: "#ccc"}}}
                            classes={{
                                root: classes.MuiTextFieldroot
                            }}
                        /> */}
                    </Grid>
                </Grid>

                
                {billType === ExpenseType.VENDOR_BILL ?
                <Grid size={12} style={{ marginTop: '12px'}}>
                    <Grid container alignItems='center' spacing={1}>
                        <Grid size={6}>
                            <Typography variant="subtitle1" 
                                className='textfield_label mandatory_fields'>
                                Select Vendor*
                            </Typography>
                            <Autocomplete
                                size="small"
                                id="bill_from"
                                getOptionLabel={(option) => option.name || ''}
                                value={selectedVendor}
                                options={vendors}
                                onChange={(e, newValue, reason) => onVendorNameChange(e, newValue, reason)}
                                style={MuiFormControlroot}
                                renderInput={(params) => 
                                    <TextField {...params} color="secondary" variant="outlined"  placeholder="Select Vendor"
                                        onClick={(e) => updateHash(billFromObj)}
                                    />}
                                onInputChange={(e, newValue) => onVendorInputChange(e, newValue)}
                                getOptionSelected={(option, value) => option?.id === value?.id}
                                //PaperComponent={(props) => <CreateItemButton {...props} text='Vendor' onClick={handleVendorDrawerOpen} />}
                                slots={{
                                    paper: (props) => <CreateItemButton {...props} text='Vendor' onClick={handleVendorDrawerOpen} />
                                }}
                            />
                        </Grid>

                        <Grid size={6}>
                            <Typography variant="subtitle1" className='textfield_label'>
                                Order Number
                            </Typography>
                            <TextField
                                fullWidth
                                id="bill_date"
                                color="secondary"
                                type="text"
                                autoComplete="off"
                                placeholder="Order Number"
                                sx={MuiFormControlroot}
                                value={purchaseOrderNumber}
                                onChange={(e) => setPurchaseOrderNumber(e.target.value)}
                                size="small"
                                //onClick={(e) => updateHash(billDateObj)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid size={12} style={{ marginTop: '12px'}}>
                    <Typography variant="subtitle1" 
                        className='textfield_label mandatory_fields'>
                        Select Vendor*
                    </Typography>
                    <Autocomplete
                        size="small"
                        id="bill_from"
                        getOptionLabel={(option) => option.name || ''}
                        value={selectedVendor}
                        options={vendors}
                        onChange={(e, newValue, reason) => onVendorNameChange(e, newValue, reason)}
                        style={MuiFormControlroot}
                        renderInput={(params) => 
                            <TextField {...params} color="secondary" variant="outlined"  placeholder="Select Vendor"
                                onClick={(e) => updateHash(billFromObj)}
                            />}
                        onInputChange={(e, newValue) => onVendorInputChange(e, newValue)}
                        getOptionSelected={(option, value) => option?.id === value?.id}
                        //PaperComponent={(props) => <CreateItemButton {...props} text='Vendor' onClick={handleVendorDrawerOpen} />}
                        slots={{
                            paper: (props) => <CreateItemButton {...props} text='Vendor' onClick={handleVendorDrawerOpen} />
                        }}
                    />
                </Grid>}
                
                { billType === ExpenseType.EXPENSE && 
                    <Grid size={12} sx={{marginTop:'12px'}}>
                        <Typography variant="subtitle1" className='textfield_label mandatory_fields'>
                                Payment Method *
                        </Typography>  
                        <TextField
                            fullWidth
                            variant="outlined"
                            select
                            value={paymentMethod}
                            onChange={handlePaymentMethodChange}
                            size="small"
                            sx={MuiFormControlroot}
                        >
                            <MenuItem value={-1}>Select Payment Method</MenuItem>
                            <MenuItem value="cash" style={{ marginLeft: "10px" }}>Cash</MenuItem>
                            {/* Bank Section */}
                            {bankList && bankList.length>0 && <Typography sx={templateHeading}>Banks</Typography>}
                            {bankList && bankList.length>0 && bankList.map(bank => (
                                <MenuItem key={bank.id} value={bank.id}>
                                    <span style={{ marginLeft: "10px" }}>{bank.bank_name}</span>
                                </MenuItem>
                                    )
                            )}
                        </TextField>
                    </Grid>}
                    { billType === ExpenseType.REIMBURSEMENT && 
                    <Grid container alignItems="center" spacing={1}>
                        <Grid size={12} sx={{marginTop:'12px'}}>
                            <Typography variant="subtitle1" className='textfield_label'>
                                Select Employee:
                            </Typography>
                            <Autocomplete
                                size="small"
                                id="is_emp"
                                getOptionLabel={(option) => option.name || ''}
                                value={selectedEmployee}
                                options={employeeList}
                                onChange={(e, newValue, reason) => onEmployeeChange(e, newValue, reason)}
                                style={MuiFormControlroot}
                                renderInput={(params) => 
                                    <TextField {...params} color="secondary" variant="outlined"  placeholder="Select Employee"
                                        //onClick={(e) => updateHash(billFromObj)}
                                    />}
                                //onInputChange={(e, newValue) => onEmployeeInputChange(e, newValue)}
                                getOptionSelected={(option, value) => option.id === value.id}
                                slots={{
                                    paper: (props) => <CreateItemButton {...props} text='Employee' onClick={handleEmployeeDrawerOpen} />
                                }}
                            />
                        </Grid>
                        {/*  <Grid item md={6}>
                            <Typography variant="subtitle1" className='textfield_label'>
                                Manager Approved?
                            </Typography>
                            <Autocomplete
                                size="small"
                                id="mgr_status"
                                getOptionLabel={(option) => option.name || ''}
                                value={selectedManagerApprovedStatus}
                                options={approvedList}
                                onChange={(e, newValue, reason) => setSelectedManagerApprovedStatus(newValue)}
                                style={MuiFormControlroot}
                                renderInput={(params) => 
                                    <TextField {...params} 
                                        color="secondary" 
                                        variant="outlined"  
                                        placeholder="Select"
                                        //onClick={(e) => updateHash(billFromObj)}
                                    />}
                                getOptionSelected={(option, value) => option.name === value.name}
                                renderOption={(option) =>  option.name}
                            />
                        </Grid> */}
                    </Grid>}
                {exchangeCurrency !== selectedAccountCurrency?.code && exchangeCurrency !== 'INR' && billDate &&
                <Grid container 
                    style={{display: "flex", justifyContent: 'end', marginTop: "10px"}}
                    sx={{cursor:'pointer'}}
                    onClick={(e) => handleExchangeRatePopoverOpen(e)}
                >
                    <Grid>
                        <Typography variant="body2">
                            (As on {billDate})  1 {exchangeCurrency} = {exchange_rate} {selectedAccountCurrency?.code}
                        </Typography>
                    </Grid>
                    <Grid sx={{marginLeft: "8px"}}>
                        <EditOutlinedIcon sx={tagIconStyle}/>
                    </Grid>    
                </Grid>}
                {exchangeRatePopover && showExchangeRate()}
                <Grid size={12} style={{marginTop:'8px'}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="secondary"
                                checked={reverseCharge}
                                onChange={(e)=>handleReverseChange(e)}
                            />
                        }
                        label="This transaction is applicable for reverse charge"/>
                    {/* {reverseCharge && taxArr && taxArr.length>0 && taxArr[0].rcmLedgersList?.length===0 && <Typography variant="subtitle1"
                        className='textfield_label mandatory_fields'>
                            No RCM Ledgers found in tally. Create ledgers automatically by clicking <span style={{cursor:"pointer", color:"blue", textDecoration:"underline"}} onClick={()=>createRcmLedgersAuto()}>here</span>
                    </Typography>} */}
                </Grid>
                <Grid container style={{ marginTop: '12px' }}>
                    <Typography variant="subtitle1" className='textfield_label'>
                        Line Items:
                    </Typography>
                </Grid>
                {renderedInvoiceItemList}
                <Grid style={{marginTop:'8px'}}>
                    <Button variant='outlined' color="secondary"
                        startIcon={<AddIcon sx={addIconStyle}/>}
                        sx={addLineButton}
                        onClick={() => onAddBillLineItemClick()}
                    >
                        
                        Add Line
                    </Button>
                </Grid>

                <Grid size={12} sx={subtotalContainer}>
                    <Grid size={12}>
                        <Grid container justifyContent='space-between'>
                            <Typography variant="body1">
                                Subtotal
                            </Typography>
                            <Typography sx={valueStyles}>
                                {formatAmount(getSubtotal(), selectedCurrency)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction='column'>
                        {showTotalTaxUi()}
                    </Grid>
                    <Grid size={12} style={{ marginTop: '15px' }}>
                        <Grid container justifyContent="space-between">
                            <Typography variant="body1">
                                Total
                            </Typography>
                            <Typography sx={valueStyles}>
                                {formatAmount(getTotal(), selectedCurrency)}
                            </Typography>
                        </Grid>
                    </Grid>
                    {isInvoiceAccountCountryIndia(invoiceAccountCountry) && billType === ExpenseType.VENDOR_BILL && 
                    <Grid container  style={{ marginTop: '10px' }}>
                        <FormControlLabel
                            style={{marginBottom: "-4px"}}
                            control={
                                <Checkbox
                                    checked={deductTds}
                                    onChange={() =>{
                                        setDeductTds(!deductTds);
                                    }}
                                />
                            }
                            label="Deduct TDS"
                        />
                    </Grid>}
                    {
                    isInvoiceAccountCountryIndia(invoiceAccountCountry) 
                    && billType === ExpenseType.VENDOR_BILL && 
                    <Grid container spacing={1}> 
                        <Grid size={7}>
                            <Typography variant="subtitle1" className='textfield_label'>
                                TDS
                            </Typography>   
                            <Autocomplete
                                size="small"
                                id="combo-box-demo"
                                disabled={!deductTds}
                                options={props.tdsTypeList}
                                sx={MuiAutocompleteinputRoot}
                                style={MuiFormControlroot}
                                value={tdsTypeObj}
                                getOptionLabel={(option) => option.tds_display}
                                getOptionSelected={(option, value) => option?.id === value?.id}
                                renderOption={(props, option) => renderOptions1(props, option)}
                                onChange={(e, newValue) => {
                                    onTDSItemChanged(e, newValue);
                                }}
                                renderInput={params => (
                                    <TextField {...params} variant="outlined"
                                        placeholder='Search items'
                                        fullWidth
                                    />
                                )}
                                clearIcon={<CloseIcon fontSize='small' onClick={()=> onItemClearClick1()}/>}
                                //getOptionSelected={(option, value) => option.id === value.id}
                            />
                        </Grid>    

                        <Grid size={5}>
                            <div style={{ display: "flex" }}>
                                <Typography variant="subtitle1" className='textfield_label' 
                                    style={{ marginBottom:'-8px'}}>
                                TDS Amount
                                </Typography>
                            </div>  
                            <TextField id="tds-amount"
                                size="small"
                                variant='outlined'
                                type='number'
                                disabled={!deductTds}
                                margin='dense'
                                style={{color:'#ccc'}}
                                placeholder='TDS Amount'
                                value={tdsAmount}
                                onChange={(e) => {
                                    let input=e.target.value;
                                    if (input === "" || /^\d+(\.\d{0,2})?$/.test(input)) {
                                        setTDSAmount(parseFloat(input));
                                    }
                                }}
                                slotProps={{
                                    input: {
                                        style: {fontSize: 14, color: "#ccc"}
                                    }
                                }}
                                // InputLabelProps={{
                                // // classes: { root: classes.labelRoot, shrink: classes.shrink },
                                //     style: {fontSize: 14, color: "#ccc"}
                                // }}
                                sx={MuiTextFieldroot}
                                //closeIcon={<CloseIcon fontSize='small' onClick={onItemClearClick1}/>}
                            />
                            
                        </Grid>
                    </Grid> 
                    }

                    <Grid size={12} style={{ marginTop: '16px' }}>
                        <Grid container justifyContent="space-between" alignItems='center'>
                            <Grid>
                                <Grid container alignItems='center'>
                                    <Typography>
                                        Round Off
                                    </Typography>

                                    <TextField
                                        size="small"
                                        style={{marginLeft:'16px'}}
                                        placeholder="0.0"
                                        value={roundoffValue}
                                        onKeyPress={numberValidate}
                                        onChange={(e) => {
                                                e.stopPropagation();
                                                //let value=Number(e.target.value);
                                                setRoundoffValue(e.target.value);
                                            }}
                                        sx={{...MuiFormControlroot, width:'250px'}}
                                    />
                                </Grid>
                            </Grid>

                            <Grid>
                                <Typography sx={valueStyles}>
                                    {formatAmount(roundoffValue, selectedCurrency)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider orientation="horizontal" style={{margin:'16px 0px 0px 0px', height:'4px'}}/>                        
                    
                    <Grid size={12} style={{ marginTop: '15px' }}>
                        <Grid container justifyContent="space-between">
                            <Typography variant="body1">
                                Net Payable
                            </Typography>
                            <Typography sx={valueStyles}>
                                {formatAmount(getTotal()-(deductTds?tdsAmount:0), selectedCurrency)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item container 
                    alignItems="center" 
                    style={{ marginTop: '12px'}}>
                    <Grid item md={12}>
                        <FormControlLabel
                            style={{marginBottom: "-4px"}}
                            control={
                                <Checkbox
                                    checked={!isEmpReimburse}
                                    onChange={() =>{
                                        setIsEmpReimburse(!isEmpReimburse);
                                    }}
                                />
                            }
                            label="Employee Reimbursement"
                        />
                        <Autocomplete
                            size="small"
                            id="is_emp"
                            disabled={isEmpReimburse}
                            getOptionLabel={(option) => option.name || ''}
                            value={selectedEmployee}
                            options={employeeList}
                            onChange={(e, newValue, reason) => onEmployeeChange(e, newValue, reason)}
                            style={MuiFormControlroot}
                            renderInput={(params) => 
                                <TextField {...params} color="secondary" variant="outlined"  placeholder="Select Employee"
                                    //onClick={(e) => updateHash(billFromObj)}
                                />}
                            //onInputChange={(e, newValue) => onEmployeeInputChange(e, newValue)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderOption={(option) =>  option.name}
                            PaperComponent={(props) => <CreateItemButton {...props} text='Employee' onClick={handleEmployeeDrawerOpen} />}
                            // renderOption={(option) => 
                            //     option.id === "add_new" ? (
                            //         <Button variant="text" color="primary" style={{ width: '100%' }}
                            //             onClick={()=>handleEmployeeDrawerOpen()}>
                            //             <AddOutlinedIcon fontSize='small'
                            //                 style={{
                            //                     marginRight:'8px',
                            //                         fontSize: '16px'
                            //                         }}
                            //             />
                            //             Add Employee
                            //         </Button>
                            //     ) : (
                            //         option.name
                            //     )
                            // }
                        />
                    </Grid>
                </Grid> */}
                {/* <Grid item container 
                    alignItems="center" 
                    style={{ marginTop: '12px'}}>
                    <Grid item md={12}>
                        <FormControlLabel
                            style={{marginBottom: "-4px"}}
                            control={
                                <Checkbox
                                    checked={!isClaimExpense}
                                    onChange={() =>{
                                        setIsClaimExpense(!isClaimExpense);
                                    }}
                                />
                            }
                            label="Claim Expense"
                        />
                        <Autocomplete
                            size="small"
                            id="is_emp"
                            disabled={isClaimExpense}
                            getOptionLabel={(option) => option.name || ''}
                            value={selectedClient}
                            options={clients}
                            onChange={(e, newValue, reason) => onClientChange(e, newValue, reason)}
                            style={MuiFormControlroot}
                            renderInput={(params) => 
                                <TextField {...params} 
                                    color="secondary" 
                                    variant="outlined"  
                                    placeholder="Select client"
                                    //onClick={(e) => updateHash(billFromObj)}
                                />}
                            onInputChange={(e, newValue) => onClientInputChange(e, newValue)}
                            getOptionSelected={(option, value) => option.id === value.id}
                        />
                    </Grid>
                </Grid> */}

                {/* <Grid container style={{ marginTop: '24px', marginBottom: "20px" }} spacing={1}>
                    <Button variant="outlined" color="primary" 
                        onClick={()=>onSubmitHandler()}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Save As Draft'}
                    </Button>

                    <Button variant="contained" color="primary" 
                        onClick={()=>onSubmitHandler()}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Post'}
                    </Button>
                </Grid> */}
                
            </div>
        }
        </div>
        <div style={sidebarFooter}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                {
                <Grid>
                    <Button variant="outlined" 
                        color="secondary"
                        sx={saveDraftBtn}
                        //disabled={props.invoiceObj.invoice_status ==='posted'}
                        // onClick={()=> {
                                //handle api call
                        //     createTallyBill(true);
                        // }}
                        >
                        {/* {apiLoading && <CircularProgress size={20} sx={{color:'#fff', marginRight:'8px'}}/>} */}
                        Save As Draft
                    </Button>
                </Grid>}
                {<Grid>
                    <Button variant="outlined" 
                        color="secondary"
                        sx={saveBillBtn}
                        // disabled={props.invoiceObj.invoice_status ==='posted'}
                        // onClick={()=> {
                            //handle api call
                        //     createTallyBill(false);
                        // }}
                        >
                        {/* {apiLoading && <CircularProgress size={20} sx={{color:'#fff', marginRight:'8px'}}/>} */}
                        Post
                    </Button>
                </Grid>}
            </Grid>
        </div>
        {openDrawer && <CreateTaxPresetDrawer openDrawer={openDrawer}
            handleDrawerClose={handleDrawerClose}
        />
    }
        {openBillCategoryDrawer && <AddEditBillCategoriesDrawer 
            openDrawer={openBillCategoryDrawer} 
            handleDrawerClose={handleCategoryDrawerClose}
            onAddItemsClick={onAddBillCategoryClick}
        />
        }
        {openBillEmployeeDrawer && <AddEditEmployeeDrawer
            openDrawer={openBillEmployeeDrawer} 
            handleDrawerClose={handleEmployeeDrawerClose}
            onAddItemsClick={onAddBillEmployeeClick}
        />}
        {
            openBillVendorDrawer && 
            <AddEditClientDrawer openClientDrawer={openBillVendorDrawer} 
                handleClientDrawerClose={handleVendorDrawerClose}
                accessLevelViewer={accessLevelViewer}
                addClient={addClient}
                isFromVendors={true}
            />
        }
        </>
    )
}


const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    tdsTypeList: state.invoiceAccounts.tdsTypeList,
});

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateBillDetailComponent);