export const SelectedDrawerState  = {
    SHOW_DASHBOARD  : 'dashboard',
    SHOW_INVOICES : 'invoices',
    SHOW_TRANSACTIONS : 'transactions',
    SHOW_SETTINGS : 'settings',
    SHOW_CLIENTS : 'clients',
    SHOW_PROFILE : 'profile',
    SHOW_INVOICE_ACCOUNTS : 'invoice_accounts',
    SHOW_MEMBERS: 'members',
    SHOW_ONBOARDING: 'onboarding',
    SHOW_CLIENT_REPORTS: 'client_report',
    SHOW_ITEM_REPORTS: 'item_report',
    SHOW_ESTIMATES: 'estimates',
    SHOW_PROPOSALS: 'proposals',
    SHOW_ITEMS : 'items',
    SHOW_VENDORS : 'vendors',
    SHOW_BILL_CATEGORIES : 'bill_categories',
    SHOW_DOCUMENTS : 'documents',
    SHOW_BILLS : 'bills',
    SHOW_PAYMENTS : 'payments',
    SHOW_EXPENSES : 'expenses'
};

export const setSelectedDrawerState = (drawerState) =>  ({
    type: 'SET_SELECTED_DRAWER_STATE',
    drawerState
})