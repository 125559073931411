import React from 'react';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

const drawerPaper1 = {
    height: '100vh',
    flexGrow:1,
    top:'0px',
    overflow:'auto'
}
    
const appBar = {
    background:'#F9F9F9',
    height:'45px',
    color:'#202020'
}

const RightToLeftSideDrawer = (props) => {
    let {drawerWidth} = props;

    return(
        <Drawer variant="temporary"
            anchor="right"
            open={props.openDrawer}
            onClose={props.closeDrawer}
            sx={{
                '& .MuiDrawer-paper': {
                    ...drawerPaper1,
                    width: drawerWidth ? '70%' : '36%',
                },
                zIndex: props.fromDueInvoicesDrawer ? 99 : 101
            }}
            >
            <AppBar position="static" sx={appBar} elevation={0}>
                <Toolbar style={{minHeight:'40px', padding:'16px 16px', }}>
                    <Typography variant="body1" 
                        style={{fontWeight:'600', marginRight:'auto'}}>
                        {props.title}
                    </Typography>
                    <IconButton style={{color:'#202020', marginRight:'-12px'}} 
                        onClick={props.closeDrawer}>
                        <ClearIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div>
                {props.children}
            </div>
            </Drawer>
        );
}

export default RightToLeftSideDrawer;