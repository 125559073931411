import React, {useState, memo} from 'react';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import {connect} from 'react-redux';
import {createUpdateInvoiceAccountEntityApi} from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import {setSelectedInvoiceAccount, setInvoiceAccountsList} from '../actions/invoiceAccount';


const SetUPIDrawer = memo((props) => {

    const [upiIdText, setUpiIdText] = useState('');
    const [loading, setLoading] = useState(false);

    const onSaveClick = async() => {
        setLoading(true);

        const invoice_account_id = props.selectedAccount?.id;
        console.log('')

        const {name, address, country, state, email, mobile, tax_id, default_client_notes, default_payment_terms, image, id} = props.entityObj;

        try {
            let response = await createUpdateInvoiceAccountEntityApi(invoice_account_id, name, address, country, state, email, mobile || '', tax_id || '', 
                default_client_notes || '', default_payment_terms || '', undefined, id, upiIdText);
                setLoading(false);

            const res = response.data;
            consoleToLog('Response createUpdateInvoiceAccountEntityApi: ', res);

            let account = {
                ...props.selectedAccount,
                entities: props.selectedAccount?.entities.map((item) => {
                    if(item.id === res.id) {
                        item = res;
                    }
                    return item
                })
            };

            let accountList = props.invoiceAccountsList.map((ia) => {
            if (ia.id === account.id) return account;
                else return ia;
            });

            if(Object.keys(props.editInvoiceObj).length > 0) {
                let obj = {
                    ...props.editInvoiceObj,
                    entity: res
                }
                props.setDontCallApi(true);
                props.setEditInvoiceObj(obj);
                props.setUpdatedInvoiceObj(obj);
            } else {
                props.setEntityObj(res);
            }

            props.setSelectedInvoiceAccount(account);
            props.setInvoiceAccountsList(accountList);
            props.setCallGetInvoiceListApi(true);
            closeDrawer();

        } catch(e) {
            consoleToLog('Error createUpdateInvoiceAccountEntityApi: ', e);
            setLoading(false);
        }
    }

    const closeDrawer = () => {
        setUpiIdText('');
        props.handleShowUpiDrawerClose();
    }

    return (
        <RightToLeftSideDrawer openDrawer={props.showUpiDrawer}
            title={'Set Entity UPI ID'}
            closeDrawer={closeDrawer}
            >
            
            <Grid container style={{ padding: "16px 20px" }}>
                <Grid size={12}>
                    <Typography sx={{
                        fontSize: "14px",
                        marginBottom: "-8px",
                    }}>
                        UPI ID
                    </Typography>

                    <TextField fullWidth
                        id="upi_id"
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={upiIdText}
                        placeholder="Enter UPI ID"
                       // InputLabelProps={{style: {fontSize: 13, marginTop:'3px'}}}
                        onChange={(e) => setUpiIdText(e.target.value)}
                        size='small'
                    />
                        
                </Grid>

                <div className="modal__footer">
                    <Button variant="contained" color="primary" className="modal__button"
                        onClick={onSaveClick}>
                        {loading && (<CircularProgress size={15} className="loading__style" />)}
                        Save
                    </Button>
                    <Button variant="outlined" style={{minWidth: "130px",}} 
                        onClick={closeDrawer}>
                        
                        Cancel
                    </Button>
                </div>
            </Grid>

        </RightToLeftSideDrawer>
    );
});

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
    //editInvoiceObj: state.invoiceAccounts.editInvoiceObj
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
   // setEditInvoiceObj: (obj) => dispatch(setEditInvoiceObj(obj)),
   // setEntityObj: (entity) => dispatch(setEntityObj(entity))
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(SetUPIDrawer));