import React, {useState,} from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {BaseEditor} from './BaseEditor';
import BaseDrawer from './BaseDrawer';
import { Grid4x4 } from '@mui/icons-material';

const PaymentTermsDrawer = ({itemObj, drawerOpen, handleDrawerClose, callUpdateSectionApi, apiLoading, setDirtyFlag}) => {
    const {data} = itemObj;
    const {terms, title} = data;

    const [paymentTermsTitle, setPaymentTermsTitle] = useState(title);
    const [paymentTermsDescription, setPaymentTermsDescription] = useState(terms);

    const setUpdatedItemObj = () => {
        const dataObj = {
            terms: paymentTermsDescription,
            title: paymentTermsTitle,
        };
        const updated_obj = {
            ...itemObj,
            data: {...dataObj}
        }

        return updated_obj;
    }

    const onSaveClick = () => {
        callUpdateSectionApi(itemObj, setUpdatedItemObj());
    }

    const onDrawerClose = () => {
        handleDrawerClose(setUpdatedItemObj());
    }

    const handlePaymentTermsTitleChange = (event) => {
        setPaymentTermsTitle(event.target.value);
        setDirtyFlag(true);
    };

    const handlePaymentTermsDescpChange = (value) => {
        setPaymentTermsDescription(value);
        setDirtyFlag(true);
    };

    return (
        <BaseDrawer open={drawerOpen} 
            onClose={onDrawerClose}
            title='Payment Terms'
        >
            <div className='drawer_content_container'>

                <Grid size={12}>
                    <Typography className={'drawer_label_style'}>
                        Page Title
                    </Typography>

                    <TextField 
                        className={'drawer_textfield_margintop'}
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        value={paymentTermsTitle}
                        onChange={handlePaymentTermsTitleChange}
                    />
                </Grid>

                <Grid size={12}>

                    <Typography className={'drawer_label_style'}>
                        Description
                    </Typography>

                    <BaseEditor editorValue={paymentTermsDescription}
                        setEditorValue={handlePaymentTermsDescpChange}/>
                </Grid>

            </div>

            <div className={'drawer_footer'}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid4x4>
                        <Button 
                            onClick={onSaveClick}
                            className={`next_btn_style drawer_btn_width`}>
                            {apiLoading && <CircularProgress size={15} className='drawer_loading_margin'/>} Save
                        </Button>
                    </Grid4x4>

                    <Grid>
                        <Button variant='outlined' color='primary'
                            onClick={onDrawerClose}
                            className={'drawer_btn_width'}>
                            Cancel 
                        </Button>
                    </Grid>
                </Grid>
            </div>

    </BaseDrawer>
    );
}

export default PaymentTermsDrawer;
