import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {connect} from 'react-redux';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import {setClearInvoiceAccounts} from '../actions/invoiceAccount';
import {addEmployeeApi} from '../services/authService';
import { consoleToLog, emailValidator } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {trackGAEvent} from '../util/AppUtil';
import Autocomplete from '@mui/material/Autocomplete';

const MuiFormControlroot = {
    width:'100%',
    background:'#fff'
    // "& .MuiOutlinedInput-input": {
    //     paddingLeft: "16px",
    //     paddingTop:'8px',
    //     paddingBottom:'8px',
    //     background:'#fff',
    // },
    // "& .MuiSelect-select.Mui-disabled": {
    //     cursor: "default !important",
    //     background: "#EEEEEE !important",
    // },
    // "& .MuiInputBase-input.Mui-disabled": {
    //     background: "#EEEEEE !important",
    //     color: "#555555 !important",
    // },
    // "& .MuiInputBase-adornedEnd" : {
    //     backgroundColor: '#fff !important',
    // }
};
const labelTextStyles = {
    fontSize: '14px',
    marginTop: '8px',
    marginBottom: '-6px'
};

const customMarginTop = {
    marginTop: '8px'
};

const customColumn1 = {
    flexBasis: '46.5%',
    maxWidth: '46.5%'
};

const AddEditEmployeeDrawer = (props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedManager, setSelectedManager] = useState(null);

    useEffect(() => {
        if(props?.itemObj && Object.keys(props?.itemObj).length !== 0) {
            setName(props.itemObj?.name);
            setEmail(props.itemObj?.email);
            setPhone(props.itemObj?.phone_number);
            setTitle(props.itemObj?.title);
        } 

    }, [props.itemObj]);


    const clearForm = ( ) => {
        setName('');
        setEmail('');
        setPhone('');
        setTitle('');

        props?.itemObj && Object.keys(props?.itemObj).length !== 0 && props.setItemObj({})
    }

    const closeDrawer = () => {
        props.handleDrawerClose();
        clearForm();
    }

    const onAddEditItemClick = () => {
        const invoice_account_id = props.selectedAccount?.id;
        if(name === '') {
            enqueueSnackbar("Kindly Enter Name", {variant: 'error'});
            return;
        }
        if(email === '') {
            enqueueSnackbar("Kindly Enter email", {variant: 'error'});
            return;
        }

        const isEmailValid = emailValidator(email);
            if (!isEmailValid) {
            enqueueSnackbar('Email is not valid', {
                variant: 'error'
            });
                return;
        }

        setLoading(true);
        if(props?.itemObj && Object.keys(props?.itemObj).length !== 0) {
            const item_id = props?.itemObj?.id;
            //callUpdateBillCategoryApi(invoice_account_id, item_id, title);

        } else {

            addEmployeeApi(invoice_account_id, name, email, phone, title, selectedManager)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addEmployeeApi: ', res);
                setLoading(false);
                
                enqueueSnackbar('Employee Added SuccessFully', {variant: 'success'});
                props.onAddItemsClick(res);
                
                props.handleDrawerClose();
                clearForm();

                trackGAEvent(props.selectedAccount.name, 'Employee created', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog('Error addEmployeeApi', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
        } 
    }


    /* const callUpdateApi = (invoice_account_id, item_id, title, description, cost, tax_preference, tax, hsn_code) => {
        updateItemApi(invoice_account_id, item_id, title, description, cost, tax_preference, tax, hsn_code)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateItemApi: ', res);
                setLoading(false);
                
                enqueueSnackbar('Item Updated SuccessFully', {variant: 'success'});
                props.onUpdateLocalList(res);
                props.handleDrawerClose();
                clearForm();

                trackGAEvent(props.selectedAccount.name, 'Invoice Items  updated', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog('Error updateItemApi', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
        } */



    return (
        <RightToLeftSideDrawer title={ props?.itemObj && Object.keys(props?.itemObj).length !== 0  ? 'Update Employee' : "Add Employee"} 
            openDrawer={props.openDrawer}
            closeDrawer={closeDrawer}>

            <div style={{padding:'0px 20px 24px 20px', height:'calc(100vh - 180px)', overflow:'auto'}}>
                <Grid size={12}>
                    <Typography sx={labelTextStyles}>
                        Name*
                    </Typography>

                    <TextField fullWidth 
                        id="item_name" 
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={name}
                        autoComplete="off"
                        placeholder="Enter Employee name"
                        slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        size='small'
                    />
                </Grid>    
                <Grid  size={12}>
                    <Typography sx={labelTextStyles}>
                        Email*
                    </Typography>

                    <TextField fullWidth 
                        id="email" 
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={email}
                        autoComplete="off"
                        placeholder="Enter email"
                        slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        size='small'
                    />
                </Grid>
                <Grid  size={12}>
                    <Typography sx={labelTextStyles}>
                        Phone number
                    </Typography>

                    <TextField fullWidth 
                        id="phone" 
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={phone}
                        autoComplete="off"
                        placeholder="Enter phone number"
                        slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={(e) => {
                            setPhone(e.target.value)
                        }}
                        size='small'
                    />
                </Grid>
                <Grid  size={12}>
                    <Typography sx={labelTextStyles}>
                        Title
                    </Typography>

                    <TextField fullWidth 
                        id="title" 
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={title}
                        autoComplete="off"
                        placeholder="Enter title"
                        slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                        size='small'
                    />
                </Grid>
                <Grid  container 
                    alignItems="center" 
                    spacing={1}
                    style={{ marginTop: '12px'}}>
                    <Grid  size={12}>
                        <Typography variant="subtitle1" className='textfield_label'>
                            Select Manager
                        </Typography>
                        <Autocomplete
                            size="small"
                            id="mgr_status"
                            getOptionLabel={(option) => option.name || ''}
                            value={selectedManager}
                            options={props.employeeList}
                            onChange={(e, newValue, reason) => setSelectedManager(newValue)}
                            style={MuiFormControlroot}
                            renderInput={(params) => 
                                <TextField {...params} 
                                    color="secondary" 
                                    variant="outlined"  
                                    placeholder="Select"
                                    //onClick={(e) => updateHash(billFromObj)}
                                />}
                            getOptionSelected={(option, value) => option.name === value.name}
                            renderOption={(option) =>  option.name}
                        />
                    </Grid>
                </Grid>
                <div className="modal__footer">
                    <Button variant="contained" color="primary" 
                        onClick={onAddEditItemClick}
                        className="modal__button">
                        {loading && <CircularProgress size={15} className='loading__style'/>}
                        {props?.itemObj && Object?.keys(props?.itemObj)?.length !== 0 ? 'Update' : 'Save'}    
                    </Button>
                    <Button variant="outlined" style={{
                        minWidth: '130px',
                    }} onClick={closeDrawer}>
                        Cancel
                    </Button>
                </div>
            </div>
        </RightToLeftSideDrawer>
    );
}


const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user

});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEmployeeDrawer);