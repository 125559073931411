import moment from "moment";
import {SendEmailType} from "../actions/invoiceAccount";
import { SelectedDrawerState } from '../actions/drawer';
import ReactGA from "react-ga4";
import country from "../data/country.json";
import state from "../data/state.json";
import MenuItem from '@mui/material/MenuItem';
import { authenticateApi } from "../services/authService";
import currencyCountries from '../data/countries.json';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import md5 from 'md5';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


export const randomString = (anysize) =>{
    const charset='BCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var res = '';
    while (anysize--) res += charset[Math.random() * charset.length | 0];
    return res;
}

export const isDebug = () => {
    return process.env.REACT_APP_DEBUG !== 'false';
};

export const consoleToLog = (msg, value) => {
    if (isDebug()) console.log(msg, value);
};

export const emailValidator = (email) => {
    const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (reg.test(email)) {
        return true;
    } else {
        //toast.error('Email is not valid!');
        return false;
    }
};

export const FileExtenions = {
    PNG: "image/png",
    JPEG: "image/jpeg",
    JPG: "image/jpg",
    PDF: "application/pdf"
}

export const boldString = (client_name, searchClient) => {
    let boldStringText = new RegExp(searchClient, 'igm');
    let result =  client_name.replace(boldStringText, '<strong>' + searchClient + '</strong>');
    return result;
}  

export const showDate = (date) => {
     return moment(date, 'YYYY-MM-DD').format('MMMM Do, YYYY');
}  

export const showListItemDate = (date) => {
    return moment(date, 'YYYY-MM-DD').format('MMM Do, YYYY');
}  

export const showPaymentMethodString = (str) => {
    if(str === 'bank') return 'Bank';
    if(str === 'cash') return 'Cash';
    if(str === 'cheque') return 'Cheque';
    if(str === 'upi') return 'UPI';
    if(str === 'credit_card') return 'Credit Card';
    if(str === 'external') return 'external';
}

export const parseString = (str) => {
    //console.log('str', str);
    return str ? str.replace(/<br ?\/?>/ig,"\n") : '';
};

export const showStatusImages  = (status) => {
    if(status === 'draft') return '/images/eprocessify_draft.png';
    if(status === 'viewed') return '/images/eprocessify_viewed.png';
    if(status === 'partial') return '/images/eprocessify_partial.png';
    if(status === 'paid') return '/images/eprocessify_paid.png';
    if(status === 'sent') return '/images/eprocessify_sent.png';
    if(status === 'downloaded') return '/images/eprocessify_downloaded.png';
    if(status === 'cancelled') return '/images/eprocessify_cancelled.png';
}

export const estimateStatusImage = (status) => {
    if(status === 'draft') return '/images/eprocessify_draft.png';
    if(status === 'closed') return '/images/est_closed.png';
    if(status === 'approved') return '/images/est_approved.png';
    if(status === 'rejected') return '/images/est_rejected.png';
    if(status === 'cancelled') return '/images/eprocessify_cancelled.png';
    if(status === 'sent') return '/images/eprocessify_sent.png';
    if(status === 'viewed') return '/images/eprocessify_viewed.png';
    if(status === 'downloaded') return '/images/eprocessify_downloaded.png';
}

export const getPaymentGatewayLabel = (key)=>{
    if(key === 'razorpay') return 'Razorpay';
    if(key === 'stripe') return 'Stripe';
    if(key === 'paypal') return 'Paypal';
}

export const claimStatusColor = (status) => {   
    if(status === 'not_a_claim') {
        return '#828c98'
    } else if(status === 'unclaimed') {
        return '#f47b3b'
    } else if(status === 'claimed') {
        return '#007bc3'
    } else if(status === 'paid') {
        return '#006644'
    } 
}
export const statusColor = (status) => {   
    if(status === 'paid') {
        return 'green'
    } 
    else if(status === 'pending') {
        return '#f47b3b'
    }
    else if(status === 'viewed') {
        return '#cca20e'
    } else if(status === 'partial') {
        return '#006644'
    } else if(status === 'draft') {
        return '#828c98'
    } else if(status === 'sent') {
        return '#4c51bf'
    } else if(status === 'downloaded') {
        return '#007bc3'
    } else if(status === 'cancelled') {
        return '#f44336'
    } else if(status === 'approved') {
        return 'green'
    } else if(status === 'rejected') {
        return 'red'
    }
    else if(status === 'open') {
        return '#4c51bf'
    }
}

export const displayInitials = (name) => {   
    const firstLetters = name
    .split(' ').filter((element) => element.replace(/[^a-zA-Z ]/, ''))
    .map(word => word[0])
    .slice(0, 2)
    .join('');

  return firstLetters.toUpperCase();
} 

export const displayInvoiceType = (invoiceType) => {
    if(invoiceType === SendEmailType.PROFORMA) {
        return 'Proforma';
    } else if(invoiceType === SendEmailType.TAX) {
        return 'Tax';
    } else {
        return '';
    }
}

export const showErrowAndClearReduxOn401 = (props, e, enqueueSnackbar) => {
    if(e.response && e.response.status === 401 && e.response.data && e.response.data.detail) {
        enqueueSnackbar(e.response.data.detail, { variant: 'error' });
        props.logout();
        props.setClearInvoiceAccounts();
        props.clearSelectedInvoiceFilters();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
        props.clearSelectedTransactionFilters();
        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/logout?service=invoices`, '_self')
    }
    if(e.response && e.response.status !== 401 && e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.message, {variant: 'error'});
        return;
    }

}

export const show401ErrorAndClearRedux = (props, e, enqueueSnackbar) => {
    if(e.response && e.response.status === 401 && e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.detail, { variant: 'error' });
        props.logout();
        props.setClearInvoiceAccounts();
        props.clearSelectedInvoiceFilters();
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_DASHBOARD);
        props.clearSelectedTransactionFilters();
        window.open(`${process.env.REACT_APP_AUTH_APP_DOMAIN_NAME}/logout?service=invoices`, '_self')
    }
    if(e.response && e.response.status !== 401 && e.response.data && e.response.data.message) {
        enqueueSnackbar(e.response.data.message, {variant: 'error'});
        return;
    }

}

export const trackGAPageView= (page) => {
    ReactGA.pageview(page);
}

export const trackGAEvent= (category, action, label) => {
    if(!isDebug()) {
        ReactGA.event({
            category: category,
            action: action,
            label: label
        });
    }
}

export const udfTypes = {
    Text: { displayName: "Text", value: "text" },
    Password: { displayName: "Password", value: 'password' },
    ImageOrDocument: { displayName: "Image / Document", value: "file" }
};

export const SettingsTabValue = {
    ENTITIES: 0,
    PREFERENCES: 1,
    TAX_PRESETS: 2,
    PAYMENT_GATEWAY: 3,
    EMPLOYEES: 4,
    BILLING: 5,
};

export const ClientsTabValue = {
    CLIENTS: 0,
    ARCHIEVED_CLIENTS: 1,
    CUSTOM_FIELDS: 2,
    CONTACTS: 3
};

export const isPredefinedName = (name) => {
    if (name !== '') {
        return true;
    }
};

//return if valid, return error if not valid
export const isValidUserDefinedFields = (udfArray) => {
    for (var i = 0; i < udfArray.length; i++) {
        for (var j = i + 1; j < udfArray.length; j++) {//repeat
            if (udfArray[i].name === udfArray[j].name) {
                return `User defined field name can not be repeated: ${udfArray[i].name}`;
            }
        }
        //value validations
        if (!udfArray[i].value) {//empty
            return `Value can not be empty at row:${i + 1}`;
        }
    }
}

const {countries} = country;

export const showAllCountries = () => (
    countries.map((country)=>{
        return(
            <MenuItem value={country.code}>{country.name}</MenuItem>
        )
    })
)

const {states} = state;
export const showAllStates = () => (
    states.map((state)=>{
        return(
            <MenuItem value={state.code}>{state.name}</MenuItem>
        )
    })
)

export const capitalizeFirstLetterOfEachWord = (s) => {
    if (typeof s !== 'string') return '';
    return s.replace(/\b\w/g, l => l.toUpperCase());
}

export const removeSpace = (str) => {
    return str.replace(/\s/g, '')
}

export const showDefaultClientValues = (option, clientObj) => {
    const itemObj = option.default_value;
    //console.log('*******************', itemObj)
    switch (itemObj) {
        case '{{client.name}}':
            return clientObj?.name;

        case '{{client.address}}':
            return clientObj?.address;

        case '{{client.country}}':
            return clientObj?.country;
        
        case '{{client.state}}':
            return clientObj?.state;
            
        case '{{client.tax_id}}':
            return clientObj?.tax_id
    
        default:
            break;
    }
}

export const AccessLevel = {
    OWNER: 3,
    EDITOR: 2,
    VIEWER: 1,
    getUserAccessLevelValue: function (access_level) {
        switch (access_level) {
            case "owner": return 3;
            case "editor": return 2;
            case "viewer": return 1;

            default:
                break;
        }
    }
};

export const shouldDisable = (user_access, actual_permission) => {
    return !(user_access >= actual_permission);
}

//input: 'abc' output: 'Abc'
export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

// phone validation check, key pressed when entering telephone number can only be numbers
export const phoneNumberValidate = (e) => {
    const re = /[0-9 ()+]+$/g;
    if (!re.test(e.key) || e.target.value.length > 13) {
        e?.preventDefault();
    }
}

export const currencyFormatter = new Intl.NumberFormat('en-IN', {
    style:'currency',
    currency: 'INR',
});

export const InvoiceStatus = {
    PARTIAL : "partial",
    PAID: "paid",
    VIEWED: "viewed",
    OPEN: "open",
    SENT: "sent",
    DOWNLOADED: "downloaded",
    CANCELLED: "cancelled"
}

export const InoviceTypes = {
    PROFORMA : "proforma",
    TAX: "tax",
    NONE: "none",
}

export const DiscountTypes = {
    PERCENT : "percent",
    FLAT: "flat",
}

export const otherTaxesTypes = (taxesArr) => {
    return taxesArr.filter((tax) => tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'GST' && 
    tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'CGST' && 
    tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'SGST' && tax.name.replace(/[0-9.]/g, '').toUpperCase() !== 'IGST');
}
export const intraStateTaxesArr = (taxesArr) => {
    return taxesArr.filter((tax) => tax.name.replace(/[0-9.]/g, '').toUpperCase() === "GST" || tax.name.replace(/[0-9.]/g, '').toUpperCase() === "CGST"
                            || tax.name.replace(/[0-9.]/g, '').toUpperCase() === "SGST");
}

export const interStateTaxesArr = (taxesArr) => {
    return taxesArr.filter((tax) => tax.name.replace(/[0-9.]/g, '').toUpperCase() === "IGST")
}

export const OverlayMessage = {
    IMPORT_MSG:"Please wait. We are setting up clients.",
    EXPORT_MSG: "Please wait. We are exporting into spreadsheet!",
    DOWNLOAD_MESSAGE: "Please wait. We are exporting into zip!",
}

export const onlyNumberValidate = (e) => {
    const re = /[0-9 ()+]+$/g;
    if (!re.test(e.key)) {
        e?.preventDefault();
    }
}

export const fetchAccessToken = async (service) => {
    try {
        const response = await authenticateApi(service);
        const data = await response.data;
        return data;
    } catch (error) {
        console.error('Error fetching access token:', error);
        throw error;
    }
};

export const InvoicePlans = {
    FREE: 'free',
    MONTHLY: process.env.REACT_APP_MONTHLY_PLAN,
    YEARLY: process.env.REACT_APP_YEARLY_PLAN
}

export const onUpgradeNowClick = (props, navigate) => {
    navigate(AppRoutes.BILLING);
    props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
}

export const setHeightForComponent = (isInvoiceAccountPlanFree, entityLimitExceeded) => {
    if(isInvoiceAccountPlanFree || entityLimitExceeded) {
        return 'calc(100vh - 85px)';
    } else if(!entityLimitExceeded && !isInvoiceAccountPlanFree) {
        return 'calc(100vh - 48px)';
    }
}

export const isInvoiceAccountOnFreePlan = (plan) => {
    return plan?.toLowerCase() === InvoicePlans.FREE ? true : false;
}

export const isRecurringInvoiceActive = (recurring_invoice_obj) => {
    return recurring_invoice_obj && recurring_invoice_obj.status === 'active' ? true : false;
}

export const InvoiceEntityApi = {
    ACTIVE: 'entity_list',
    ARCHIVED: 'archived_entities'
}

const {countries_currency} = currencyCountries;
export const invoiceAccountCountryObj = (selectedAccountCountry) => {
    const countryObj = countries_currency.find(country => country.code === selectedAccountCountry);
    return countryObj
}

export const extractFlagCode = (str) => {
    return str?.split(':')[1]?.split('-')[1]?.toUpperCase()
}

export const isInvoiceAccountCountryIndia = (invoiceAccountCountry) => {
    return invoiceAccountCountry === "IN" ? true : false
}

export const getCountryObj = (countries, code) => {
    return countries.find((country) => country.code === code);
}

export const getStateObj = (states, stateCode, countryCode) => {
    return stateCode ? states.find((state) => state.state_code === stateCode && state.country_code === countryCode) : undefined;
}

export const AppRoutes = {
    DASHBOARD: '/dashboard',
    INVOICES: '/invoices',
    TRANSACTIONS: '/transactions',
    MEMBERS: '/members',
    CLIENTS: '/clients',
    ITEMS: '/items',
    SETTINGS: '/settings',
    PROFILE: '/profile',
    ONBOARDING: '/onboarding',
    SETUP: '/setup',
    CLIENT_REPORTS: '/report/client',
    ITEM_REPORTS: '/report/items',
    ESTIMATES: '/estimates',
    BILLING: '/settings/billing',
    PROPOSALS: '/proposals',
    PAYMENT_GATEWAY: '/settings/payment_gateway',
    VENDORS: '/vendors',
    BILL_CATEGORIES: '/bill_categories',
    EMPLOYEES: '/settings/employees',
    DOCUMENTS: '/documents',
    BILLS: '/bills',
    PAYMENTS: '/payments',
    EXPENSES: '/expenses'
}

export const getUrlAccordingToSelectedComponent = (drawerState) => {
    if(drawerState === SelectedDrawerState.SHOW_DASHBOARD) {
        return AppRoutes.DASHBOARD;
    } else if(drawerState === SelectedDrawerState.SHOW_INVOICES) {
        return AppRoutes.INVOICES;
    } else if(drawerState === SelectedDrawerState.SHOW_TRANSACTIONS) {
        return AppRoutes.TRANSACTIONS;
    } else if(drawerState === SelectedDrawerState.SHOW_CLIENTS) {
        return AppRoutes.CLIENTS;
    } else if(drawerState === SelectedDrawerState.SHOW_ITEMS) {
        return AppRoutes.ITEMS;
    } else if(drawerState === SelectedDrawerState.SHOW_MEMBERS) {
        return AppRoutes.MEMBERS;
    } else if(drawerState === SelectedDrawerState.SHOW_SETTINGS) {
        return AppRoutes.SETTINGS;
    } else if(drawerState === SelectedDrawerState.SHOW_PROFILE) {
        return AppRoutes.PROFILE;
    } else if(drawerState === SelectedDrawerState.SHOW_ONBOARDING) {
        return AppRoutes.ONBOARDING;
    } else if(drawerState === SelectedDrawerState.SHOW_REPORTS) {
        return AppRoutes.REPORTS;
    } else if(drawerState === SelectedDrawerState.SHOW_ESTIMATES) {
        return AppRoutes.ESTIMATES;
    } else if(drawerState === SelectedDrawerState.SHOW_PROPOSALS) {
        return AppRoutes.PROPOSALS;
    }  else if(drawerState === SelectedDrawerState.SHOW_DOCUMENTS) {
        return AppRoutes.DOCUMENTS;
    } else if(drawerState === SelectedDrawerState.SHOW_BILLS) {
        return AppRoutes.BILLS;
    }
    else if(drawerState === SelectedDrawerState.SHOW_PAYMENTS) {
        return AppRoutes.PAYMENTS;
    }
    else if(drawerState === SelectedDrawerState.SHOW_VENDORS) {
        return AppRoutes.VENDORS;
    }
    else if(drawerState === SelectedDrawerState.SHOW_EXPENSES) {
        return AppRoutes.EXPENSES;
    }
}

export const isInvoiceStatusCancelled = (status) => {
    return status === InvoiceStatus.CANCELLED ? true : false
}

export const displayInvoiceTypeLabel = (type) => {
    if(type === InoviceTypes.TAX){
        return 'INV'
    }
    else {
        return 'PRO' 
    }
}

export const extractBasePath = (fromBaseComponent) => {
    let pathname = window.location.pathname;
    const parts = pathname.split('/');
    if (parts.length > 2) {
        return `/${parts[1]}`;
    }
    return pathname;
};

export const storeComponentState = (navigate, fromEdit, fromCreate, itemList, items, page, total, componentObj, saveState, str, props, showSendEmail) => {
    const stateObj = {
        itemList,
        items,
        page,
        total,
        ...(props.fromClientDetails && {
            clientListState: {...props.clientComponentObj},
            clientInfo: {...props.showClientInfo},
            tabValue: props.tabValue,
            clientScrollPosition: props.scrollContainer?.scrollTop
        }),
    };
    saveState(stateObj);
    if(!fromCreate) {
    return fromEdit 
        ? navigate(`/${str}/${componentObj.slug}/edit`) 
        : navigate(`/${str}/${componentObj.slug}/preview`,  {state: {showSendEmail: showSendEmail}});
    }
}

export const showValidityMessage = (validity) => {
    return (    
        <Grid size={12} sx={{marginTop:'16px', padding:'0px 24px 16px 24px' }}>
            <Typography className='validitySyle'>
                {`This estimate is valid for ${validity} days from the date issued. After this period, 
                the terms and pricing may be subject to change. If you need more time or have any questions, 
                please let us know before the validity period expires.`}
            </Typography>
        </Grid>
    )
}

export const EstimateStatus = {
    OPEN: 'open',
    SENT: 'sent',
    VIEWED: 'viewed',
    DOWNLOADED: 'downloaded',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    CLOSED: 'closed',
    CANCELLED: 'cancelled'
}

export const EstimateStatusColor = (status) => {   
    if(status === 'draft') {
        return '#828c98';
    } else if(status === 'open') {
        return '#4c51bf';
    } else if(status === 'viewed') {
        return '#cca20e';
    } else if(status === 'downloaded') {
        return '#007bc3';
    } else if(status === 'approved') {
        return 'green';
    } else if(status === 'rejected') {
        return '#8b0000';
    } else if(status === 'closed') {
        return '#828c98';
    } else if(status === 'cancelled') {
        return '#f44336';
    } else if(status === 'pending_approval') {
        return '#FF5733';
    }
}

export const BillStatusColor = (status) => {   
    if(status === 'none') {
        return '#cccccc'
    } else if(status === 'partial') {
        return '#ff9800'
    } else if(status === 'invoiced') {
        return '#4caf50'
    } 
}

export const displayStatus = (status) => {
    if(status === 'none') return 'Not Invoiced';
    else if(status === 'partial') return 'Partially Invoiced';
    else if(status === 'invoiced') return 'Invoiced';
    //else return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
}

export const invoicePrefixOptions = [
    {name: `{{prefix}}-{{number}}`, value: `{{prefix}}-{{number}}`},
    {name: `{{prefix}}-{{FY}}-{{number}}`, value: `{{prefix}}-{{FY}}-{{number}}`},
    {name: `{{prefix}}-{{MMM-YY}}-{{number}}`, value: `{{prefix}}-{{MMM-YY}}-{{number}}`}
];

export const startMonthOptions = [{name: "January", value:1}, {name: "February", value: 2}, {name: "March", value:3}, 
    {name: "April", value: 4}, {name: "May", value: 5}, {name: "June", value: 6},
    {name: "July", value: 7}, {name: "August", value:8}, {name: "September", value: '9'}, 
    {name: "October", value: 10}, {name: "November", value: 11}, {name: "December", value: 12}
];

export const showPrefixExampleValue = (formatValue, prefix) => {
    switch(formatValue) {
        case `{{prefix}}-{{number}}`:
            return `(eg: ${prefix}-045)`

        // case `{{prefix}}-INV-{{number}}`:
        //     return '(eg: GT-INV-045)'

        case `{{prefix}}-{{FY}}-{{number}}`:
            return `(eg: ${prefix}-2223-045)`

        case `{{prefix}}-{{MMM-YY}}-{{number}}`: 
            return `(eg: ${prefix}-APR-22-045)`
    }
}

export const modifyTaxObj = (id, name, rate) => {
    return {
        tax_preset_id:id, 
        name:name, 
        rate:rate
    }
}

export const PeriodicityArr = [
    {name: 'Not Recurring', value: 'none', display_value: ''},
    {name: 'Hourly', value: 'hourly', display_value: 'per hour'},
    {name: 'Weekly', value: 'weekly', display_value: 'per week'},
    {name: 'Monthly', value: 'monthly', display_value: 'per month'},
    {name: 'Half Yearly', value: 'half_yearly', display_value: 'per half year'},
    {name: 'Yearly', value: 'yearly' , display_value: 'per year'},
]

export const lightenColor = (hex, percent) => {
    // Remove the hash symbol if present
    hex = hex.replace(/^#/, '');

    // Convert hex to RGB values
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Apply lightening based on the percentage
    r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
    g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
    b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

    // Convert back to hex and return the result
    const newHex = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
    return newHex;
};

export const ProposalStatus = {
    DRAFT: 'draft',
    SENT: 'sent',
    VIEWED: 'viewed',
    DOWNLOADED: 'downloaded',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    CANCELLED: 'cancelled',
    PENDING_APPROVAL: 'pending_approval'
}

export const SocialMediaList = [
    {name: "Facebook", type: "facebook"},
    {name: "X (Twitter)", type: 'x'},
    {name: "LinkedIn", type: "linkedin"},
    {name: "Instagram", type: "instagram"},
    {name: "Youtube", type: "youtube"},
    {name: "Other", type:"other"}
];

export const showSocialMediaImages = (type) => {
    switch (type) {
        case 'facebook':
            return '/images/facebook_image.svg'

        case 'x':
            return '/images/x_image.svg'

        case 'linkedin':
            return '/images/linkedin_image.svg'

        case 'instagram':
            return '/images/instagram_image.svg'

        case 'youtube':
            return '/images/youtube_image.svg'

        case 'other':
            return '/images/globe_image.svg'

        default:
            return '/images/globe_image.svg'
            break;
    }
}

export const generateMd5ForApi = (url) => {
    let api_url = url
    if(api_url.indexOf("https://") !== -1) {
        api_url = api_url.replace('https://','http://');
    }
    return md5(api_url);
}

export function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

export function getDominantColor(imgElement, callback) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    // Set a larger canvas size for better color accuracy
    const targetWidth = 800; // Change this as needed
    const scaleFactor = targetWidth / imgElement.naturalWidth;
    canvas.width = targetWidth;
    canvas.height = imgElement.naturalHeight * scaleFactor;

    ctx.drawImage(imgElement, 0, 0, canvas.width, canvas.height);

    try {
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const { data } = imageData;
    
        const colorMap = {};
        let dominantColor = { color: [0, 0, 0], count: 0 };

        for (let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            const alpha = data[i + 3];
    
            // Skip fully transparent pixels
            if (alpha === 0) continue;
    
            // Skip pixels that are close to white
            if (r > 240 && g > 240 && b > 240) continue;
    
            const color = `${r},${g},${b}`;
            colorMap[color] = (colorMap[color] || 0) + 1;
    
            if (colorMap[color] > dominantColor.count) {
            dominantColor = { color: [r, g, b], count: colorMap[color] };
            }
        }
    
        callback(dominantColor.color);
    } catch (error) {
        console.error("Error processing image data:", error);
    }
}

export const parseMarkdownToHtml = (text) => {
    // Function to parse Markdown to HTML
      // Replace headers
    if(text) {
    text = text.replace(/^###### (.*$)/gim, '<h6>$1</h6>');
    text = text.replace(/^##### (.*$)/gim, '<h5>$1</h5>');
    text = text.replace(/^#### (.*$)/gim, '<h4>$1</h4>');
    text = text.replace(/^### (.*$)/gim, '<h3>$1</h3>');
    text = text.replace(/^## (.*$)/gim, '<h2>$1</h2>');
    text = text.replace(/^# (.*$)/gim, '<h1>$1</h1>');

    // Replace bold and italic
    text = text.replace(/\*\*\*(.*?)\*\*\*/gim, '<b><i>$1</i></b>'); // Bold Italic
    text = text.replace(/\*\*(.*?)\*\*/gim, '<b>$1</b>'); // Bold
    text = text.replace(/\*(.*?)\*/gim, '<i>$1</i>'); // Italic

    // Replace links
    text = text.replace(/\[(.*?)\]\((.*?)\)/gim, '<a href="$2">$1</a>');

    // Replace unordered lists
    text = text.replace(/^\* (.*$)/gim, '<ul><li>$1</li></ul>');
    text = text.replace(/<\/ul>\s*<ul>/gim, ''); // Merge consecutive lists

    // Replace ordered lists
    text = text.replace(/^\d+\. (.*$)/gim, '<ol><li>$1</li></ol>');
    text = text.replace(/<\/ol>\s*<ol>/gim, ''); // Merge consecutive lists

    // Replace line breaks
    text = text.replace(/\n/gim, '<br>');

    
    return text.trim();

    }
};

/*
 * Synchronizes the order of navItems with editProposal array while preserving custom sections
 */
export const syncNavItemsOrder = (navItems, editProposal) => {
    if (!navItems?.length || !editProposal?.length) return navItems;

    // Create a map of original nav items for easy lookup
    const navItemsMap = new Map(navItems.map(item => [item.type, item]));
    
    // Filter out custom sections from editProposal

    const standardSections = editProposal.filter(item => item.type !== 'custom');
    
    // Create new array maintaining the order from editProposal
    const updatedNavItems = standardSections.map(section => {
        const navItem = navItemsMap.get(section.type);
        return navItem ? { ...navItem, order_number: section.order_number } : null;
    }).filter(Boolean);

    // Add back custom sections in their original positions
    const customNavItems = navItems.filter(item => {
        const proposalItem = editProposal.find(p => p.type === item.type);
        return proposalItem?.type === 'custom';
    });
    //console.log('reordered nav array', [...updatedNavItems, ...customNavItems])
    return [...updatedNavItems, ...customNavItems];
};

export const AttachmentTypes = {
    PROPOSAL: 'proposal',
    ENTITY: 'entity'
}

export const AttachmentSubTypes = {
    PROPOSAL_TEAM: 'proposal_team',
    PROPOSAL_PROFILE_PIC: 'proposal_profile_pic',
    PROPOSAL_PDF: 'proposal_pdf',
    ENTITY_SIGNATURE: 'entity_signature'
}

export const EntityInvoiceEstimate = {
    ENTITY_INVOICE_PREFIX: 'entity_invoice_prefix',
    ENTITY_ESTIMATE_PREFIX: 'entity_estimate_prefix'
}

// Drag Types
export const DroppedItemTypes = {
    TEXT: "text_block",
    SIGNATURE: "signature",
    DATE: "date",
    DROPPED_ITEM: 'dropped-item'
};

export const dataURLtoFile = (dataURL, fileName) => {
    // Step 1: Parse the Data URL
    const [, base64String] = dataURL.split(';base64,');

    // Step 2: Convert to ArrayBuffer
    const binaryString = atob(base64String);
    const length = binaryString.length;
    const arrayBuffer = new ArrayBuffer(length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
    }

    // Step 3: Create a Blob
    const blob = new Blob([arrayBuffer], { type: 'image/png' }); // Specify the appropriate MIME type

    // Step 4: Create a File (optional)
    const file = new File([blob], fileName, { type: 'image/png' }); // Specify the appropriate MIME type

    return file;
}

// export const transformArray = (inputArray, isUpdate) => {
// // Group items by component_type and component_label
//     const groupedItems = inputArray.reduce((acc, item) => {
//         const key = `${item.component_type}-${item.component_label}`;
//         if (!acc[key]) {
//         acc[key] = [];
//         }
//         acc[key].push(item);
//         return acc;
//     }, {});

//     // Transform grouped items into desired structure
//     const result = Object.values(groupedItems).map((group) => {
//         const coordinatesJsonArray = group.map((item) => ({
//         topLeft: item.coordinates.topLeft,
//         bottomRight: item.coordinates.bottomRight,
//         page: item.page,
//     }));

//     // Use the last item in the group for other details
//     const lastItem = group[group.length - 1];

//     // Remove dummy IDs for both cases
//     let id = lastItem.id;
//     if (!isUpdate || id === null || id === undefined || String(id).startsWith("dummy")) {
//         id = undefined; // Remove the id for dummy items
//     }
//         return {
//             component_type: lastItem.component_type,
//             id,
//             coordinates_json_array: coordinatesJsonArray,
//             component_label: lastItem.component_label,
//             value_json: lastItem.value_json,
//         };
//     });

//     return result;
// };

export const transformArray = (inputArray) => {
    // Group items by component_type and component_label
    const groupedItems = inputArray.reduce((acc, item) => {
        const key = `${item.component_type}-${item.component_label}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});
    //console.log('grouped item', groupedItems);
    // Transform grouped items into desired structure
    const result = Object.values(groupedItems).map((group) => {
        const coordinatesJsonArray = group.map((item) => {
            const { canvasWidth, canvasHeight} = item.coordinates;
            return convertAbsoluteToPercent(
                {
                    topLeft: item.coordinates.topLeft,
                    bottomRight: item.coordinates.bottomRight,
                    page: item.page,
                },
                canvasWidth,
                canvasHeight
            )
        }
        );

        // Use the last item in the group for other details
        const lastItem = group[group.length - 1];

        let id = lastItem.id;
        
        return {
            component_type: lastItem.component_type,
            id,
            item_id: undefined,
            coordinates_json_array: coordinatesJsonArray,
            component_label: lastItem.component_label,
            value_json: lastItem.value_json,
        };
    });
    //console.log('result', result);
    return result;
};

const setValueJsonIfNotPresent = (item) => {
    if(item.value_json) {
        return item.value_json
    } else {
        if(item.component_type === DroppedItemTypes.TEXT) {
            return item.value_json =  { text: '' }
        }

        if(item.component_type === DroppedItemTypes.SIGNATURE) {
            return item.value_json = {
                is_member: true,
                signature_image_path: '',
                signed_date: '',
                signed_ip_address: '',
                name: '',
                email: '',
                member_id:''
            }
        }

        if(item.component_type === DroppedItemTypes.DATE) {
            return item.value_json = { date: '' }
        }
    }

}

// export const splitArray = (inputArray) => {
//     const result = [];
//         inputArray.forEach((item) => {
//             item.coordinates_json_array.forEach((coordinates) => {
//                 result.push({
//                     component_type: item.component_type,
//                     position: coordinates.topLeft, // Use topLeft for position
//                     id: item.id, // Optionally assign a unique id if required
//                     coordinates: {
//                         topLeft: coordinates.topLeft,
//                         bottomRight: coordinates.bottomRight,
//                         page: coordinates.page,
//                     },
//                     page: coordinates.page,
//                     component_label: item.component_label,
//                     value_json: setValueJsonIfNotPresent(item),
//                 });
//             });
//         });
//     return result;
// }; 

export const splitArray = (inputArray, canvasWidth, canvasHeight) => {
    const result = [];
    inputArray.forEach((item) => {
        item.coordinates_json_array.forEach((coordinates) => {
            const absoluteCoordinates = convertPercentToAbsolute(
                coordinates,
                canvasWidth,
                canvasHeight
            );

            // console.log('absolute values', {
            //     topLeft: absoluteCoordinates.topLeft,
            //     bottomRight: absoluteCoordinates.bottomRight,
            //     page: absoluteCoordinates.page,
            // });

            result.push({
                component_type: item.component_type,
                position: absoluteCoordinates.topLeft, // Use topLeft for position
                id: item.id, // Optionally assign a unique id if required
                item_id: randomString(8),
                coordinates: {
                    topLeft: absoluteCoordinates.topLeft,
                    bottomRight: absoluteCoordinates.bottomRight,
                    page: absoluteCoordinates.page,
                    canvasWidth: absoluteCoordinates.canvasWidth,
                    canvasHeight: absoluteCoordinates.canvasHeight
                },
                page: coordinates.page,
                component_label: item.component_label,
                value_json: setValueJsonIfNotPresent(item),
            });
        });
    });
    return result;
};

export const getDroppedComponentWidthAndHeight = (component_width, component_height, new_pdf_width, new_pdf_height, originalDimension) => {
    if (!originalDimension?.width || !new_pdf_width || !new_pdf_height) {
        return {
            new_component_width: component_width,
            new_component_height: component_height
        };
    }

    // Calculate scale factors for both dimensions
    const widthScale = new_pdf_width / originalDimension.width;
    const heightScale = new_pdf_height / originalDimension.height;

    // Use the same scale factor to maintain aspect ratio
    const scaleFactor = Math.min(widthScale, heightScale);

    // Calculate new dimensions
    const new_component_width = Math.round(component_width * scaleFactor);
    const new_component_height = Math.round(component_height * scaleFactor);

    // console.log('Component scaling:', {
    //     original: { width: component_width, height: component_height },
    //     new: { width: new_component_width, height: new_component_height },
    //     scale: scaleFactor,
    //     pdfDimensions: { width: new_pdf_width, height: new_pdf_height },
    //     originalPdfDimensions: originalDimension
    // });
    // let new_component_width = (component_width * new_pdf_width) / originalDimension?.width 
    // let new_component_height = (component_height * new_pdf_height) / originalDimension?.height 

    return { new_component_width, new_component_height };
}

export const getResponsiveFontSize = (baseFontSize, pdfWidth, pdfHeight, originalDimension) => {
    if (!originalDimension?.width || !pdfWidth || !pdfHeight) return baseFontSize;
    
    // Calculate scale factor based on width ratio
    const scaleFactor = pdfWidth / originalDimension.width;
    //console.log('scaleFactor', scaleFactor);
    
    // Calculate new font size
    const newFontSize = Math.round(baseFontSize * scaleFactor);
    //console.log('newFontSize', scaleFactor);
    
    // Set minimum and maximum bounds for font size
    const minFontSize = 8;
    const maxFontSize = 24;
    //console.log('fontSize', Math.min(Math.max(newFontSize, minFontSize), maxFontSize))

    //console.log('final newFontSize', Math.min(Math.max(newFontSize, minFontSize), maxFontSize));
    
    return Math.min(Math.max(newFontSize, minFontSize), maxFontSize);
};


export const DocumentStatus =  {
    DRAFT:'draft',
    OPEN:'open',
    PENDING_SIGNTAURE:'pending_signature',
    VOID: 'void',
    COMPLETED: 'completed'
}

export const DocumentStatusColor = (status) => {   
    if(status === DocumentStatus.OPEN || status === DocumentStatus.DRAFT) {
        return '#828c98';
    } else if(status === DocumentStatus.PENDING_SIGNTAURE) {
        return '#8b0000';
    } else if(status === DocumentStatus.VOID) {
        return '#140126';
    } else if(status === DocumentStatus.COMPLETED) {
        return 'green';
    }
}

export const DocumentStatusName = (status) => {   
    switch (status) {
        case DocumentStatus.DRAFT:
            return 'Draft';

        case DocumentStatus.PENDING_SIGNTAURE:
            return 'Pending Signature';

        case DocumentStatus.VOID:
            return 'Void';

        case DocumentStatus.COMPLETED:
            return 'Completed';
    
        default:
            break;
    }
}


export const ItemTypes = {
    ITEM: "ITEM", 
    UNIT_PRICE: "UNIT_PRICE", 
    PRICE: "PRICE", 
    QUANTITY: "QUANTITY", 
    PRODUCT_CODE: "PRODUCT_CODE", 
    EXPENSE_ROW: "EXPENSE_ROW", 
    TAGS: "TAGS",
    VENDOR_NAME: "VENDOR_NAME",
    INVOICE_RECEIPT_DATE: "INVOICE_RECEIPT_DATE",
    DUE_DATE: "DUE_DATE",
    INVOICE_RECEIPT_ID: "INVOICE_RECEIPT_ID",
    TAX: "TAX",
    TOTAL: "TOTAL",
    SUBTOTAL: "SUBTOTAL",
}

export const convertAbsoluteToPercent = (absoluteCoordinates, canvasWidth, canvasHeight) => {
    const { topLeft, bottomRight } = absoluteCoordinates;
    return {
        topLeft: {
            x: `${(topLeft.x / canvasWidth) * 100}%`,
            y: `${(topLeft.y / canvasHeight) * 100}%`,
        },
        bottomRight: {
            x: `${(bottomRight.x / canvasWidth) * 100}%`,
            y: `${(bottomRight.y / canvasHeight) * 100}%`,
        },
        page: absoluteCoordinates.page,
        canvasWidth,
        canvasHeight
    };
};

// Converts percentage coordinates back to absolute values based on the canvas size
export const convertPercentToAbsolute = (percentCoordinates, canvasWidth, canvasHeight) => {
    const { topLeft, bottomRight } = percentCoordinates;
    
    //console.log('absolute', percentCoordinates, canvasWidth, canvasHeight);
    
    return {
        topLeft: {
            x: (Number(topLeft.x.replace('%', '')) / 100) * canvasWidth,
            y: (Number(topLeft.y.replace('%', '')) / 100) * canvasHeight,
        },
        bottomRight: {
            x: (Number(bottomRight.x.replace('%', '')) / 100) * canvasWidth,
            y: (Number(bottomRight.y.replace('%', '')) / 100) * canvasHeight,
        },
        page: percentCoordinates.page,
        canvasWidth,
        canvasHeight
    };
};

export const showActivityIcons = (status) => {
    switch (status) {
        case 'draft':
        case 'none':
            return '/images/activity-not-sent.png';
        case 'sent':
        case 'cancelled':    
        case 'closed':
            return '/images/activity-icon-sent.png';
        case 'viewed':
        case 'paid':
        case 'approved':
        case 'rejected':
            return '/images/activity-icon-viewed.png';
        case 'downloaded':
        case 'partial':
            return '/images/activity-icon-downloaded.png';
    
        default:
            break;
    }
}

export const statusNameAndColor = (status) => {
    switch (status) {
        case 'draft':
            return {background: 'rgba(130, 140, 152, 0.4)', name: 'Draft', color: 'rgb(130, 140, 152)'};
        case 'none':
            return {background: 'rgba(130, 140, 152, 0.4)', name: 'Not Sent', color: 'rgb(130, 140, 152)'};
        case 'sent':
            return {background: 'rgba(76, 81, 191, 0.4)', name: 'Sent', color: 'rgb(76, 81, 191)'};
        case 'open':
            return {background: 'rgba(76, 81, 191, 0.4)', name: 'Open', color: 'rgb(76, 81, 191)'};
        case 'viewed':
            return {background: 'rgba(204, 162, 14, 0.4)', name: 'Viewed', color: 'rgb(204, 162, 14)'};
        case 'downloaded':
            return {background: 'rgba(74, 85, 104, 0.4)', name: 'Downloaded', color: 'rgb(74, 85, 104)'};
        case 'partial':
            return {background: 'rgba(0, 102, 68, 0.4)', name: 'Partial', color: 'rgb(0, 102, 68)'};
        case 'cancelled':
            return {background: 'rgba(244, 67, 54, 0.4)', name: 'Cancelled', color: 'rgb(244, 67, 54)'};
        case 'paid':
            return {background: 'rgba(0, 100, 0, 0.4)', name: 'Paid', color: '#006400'};
        case 'approved':
            return {background: 'rgba(0, 100, 0, 0.4)', name: 'Approved', color: 'rgb(0, 100, 0)'};
        case 'rejected':
            return {background: 'rgba(139, 0, 0, 0.4)', name: 'Rejected', color: 'rgb(139, 0, 0)'};
        case 'completed':
            return {background: 'rgba(0, 100, 0, 0.4)', name: 'Completed', color: 'rgb(0, 100, 0)'};
        case 'pending_signature':
            return {background: 'rgba(139, 0, 0, 0.4)', name: 'Pending Signature', color: 'rgb(139, 0, 0)'};
        case 'void':
            return {background: 'rgba(20, 1, 38, 0.4)', name: 'Void', color: 'rgb(20, 1, 38)'};
        case 'closed':
            return {background:'rgba(130, 140, 152, 0.4)', name: 'Closed', color: 'rgb(130, 140, 152)'};
        case 'pending_approval':
            return {background:'rgba(255, 87, 51, 0.4)', name: 'Pending Approval', color: 'rgb(255, 87, 51)'};
        // case 'cancelled':
        // return {color: '#f44336', name: 'Cancelled'};
        default:
            break;
    }
}

export const setActivityIconsColors = (status) => {
    switch (status) {
        case 'none':
            return {filter: 'invert(63%) sepia(10%) saturate(441%) hue-rotate(173deg) brightness(86%) contrast(84%)'};

        case 'sent':
            return {filter: 'invert(36%) sepia(12%) saturate(5453%) hue-rotate(210deg) brightness(89%) contrast(94%)'};

        case 'viewed':
            return {filter: 'invert(64%) sepia(79%) saturate(481%) hue-rotate(6deg) brightness(86%) contrast(97%)'};

        case 'downloaded':
            return {filter: 'invert(15%) sepia(93%) saturate(3464%) hue-rotate(94deg) brightness(94%) contrast(103%)'};
    
        default:
            break;
    }
}

export const CreateItemButton = ({ children, onClickFunction, text, ...other }) => {
    return (
    <Paper {...other} 
        sx={{
            marginTop:'6px',
            boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' 
        }}>
        <Grid container justifyContent="center">
            <Button fullWidth
                className={'create_item_button'}
                variant="outlined"
                color="primary"
                onMouseDown={onClickFunction}>
                <AddCircleOutlineIcon fontSize='small' className={'create_item_icon'}/>  
                    {text}
            </Button>
        </Grid>
        {children}
    </Paper>
    )
}

export const onCopyClientUrl = (client_url, enqueueSnackbar) => {
    navigator.clipboard.writeText(client_url);
    enqueueSnackbar('URL copied successfully!!!', { variant:'success'})
}

export const ExpensesTabValues = {
    BILLS:0,
    EXPENSES: 1,
    REIMBURSEMENT: 2
};

export const ExpenseType = {
    VENDOR_BILL: 'vendor_bill',
    EXPENSE: 'expense',
    REIMBURSEMENT: 'reimbursement'
}

export const numberValidate = (e) => {
    const re = /[0-9 ()+-.]+$/g;
    if (!re.test(e.key) || e.target.value.length > 13) {
        e?.preventDefault();
    }
}

export const ITCValues = {
    ELIGIBLE: 'eligible',
    INELIGIBLE_SECTION_17: 'ineligible_section17',
    INELIGIBLE_OTHERS: 'ineligible_others'
}
