import React, {useState, useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import {updateEntityNextInvoiceNumberApi} from '../../services/authService';
import {connect} from 'react-redux';
import { consoleToLog } from '../../util/AppUtil';
import { useSnackbar } from 'notistack';
import { setSelectedInvoiceAccount, setInvoiceAccountsList } from '../../actions/invoiceAccount';
import {getEntityNextInvoiceEstimateNumberApi} from '../../services/authService';
import Box from '@mui/material/Box';

const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};
const paperStyle = {
    position: 'absolute',
    backgroundColor: '#f7f7f7',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
    width: '530px',
    outline: 'none'
};
const modalHeadingStyle = {
    fontSize: '16px',
    fontWeight: 500,
    color: "#212121"
};
const inputTextHeadingStyle = {
    fontSize: '15px',
    color: '#212121',
    fontWeight: 500
};
const headingContainerStyle = {
    background: '#f7f7f7',
    padding: '8px 16px',
    border: '1px solid rgba(0,0,0,0.1)'
};
const contentContainerStyle = {
    padding: '16px',
};

const footerStyle = {
    borderTop: '1px solid rgba(0,0,0,0.1)',
    padding: '16px'
};


const UpdateNumberModal = (props) => {
    const {enqueueSnackbar} = useSnackbar();

    let entityObj = props.entityObj !== -1 ? props.entityObj : props.invoiceObj.entity;
    let selectedEntityObj = props.selectedAccount?.entities.find((entity) => entity.id === entityObj.id);
    let next_invoice_number = selectedEntityObj?.next_invoice_number;

    const [invoiceNumber, setInvoiceNumber] = useState(0);
    

    useEffect(() => {
        getEntityNextInvoiceOrEstimateNumber();
    }, [])


    const getEntityNextInvoiceOrEstimateNumber = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const entity_id = selectedEntityObj?.id;
        const apiStr = props.showEstimateScreen ? `entity/${entity_id}/estimate/entity_next_estimate_number` : `${entity_id}/entity_next_invoice_number`;

        getEntityNextInvoiceEstimateNumberApi(invoice_account_id, apiStr)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getEntityNextInvoiceEstimateNumberApi: ', res);
                let next_number = props.showEstimateScreen ? res.next_estimate_number : res.next_invoice_number;
                setInvoiceNumber(next_number);
                props.setEntityNextInvoiceNumber(next_number);

            })
            .catch((e) => {
                consoleToLog('Error getEntityNextInvoiceEstimateNumberApi', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
        });
    }

    function addLeadingZeros(number) {
        // Check the magnitude of the number
        if (number < 10) {
            return '00' + number;
        } else if (number < 100) {
            return '0' + number;
        } else {
            return number.toString(); // No need to add leading zeros
        }
    }

    const updateInvoiceNextNumber = () => {
        const invoice_account_id = props.selectedAccount.id;
        const entity_id = selectedEntityObj?.id;
        const update_next_invoice_number =  !props.showEstimateScreen ? invoiceNumber : undefined;
        const update_next_estimate_number = props.showEstimateScreen ?  invoiceNumber : undefined;
        const apiStr = props.showEstimateScreen ? `entity/${entity_id}/estimate/update_next_estimate_number` : `entity/${entity_id}/update_next_invoice_number`;

        updateEntityNextInvoiceNumberApi(invoice_account_id, update_next_invoice_number, update_next_estimate_number, apiStr)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response updateEntityNextInvoiceNumberApi:", res);

                enqueueSnackbar('Next invoice number updated successfully', {variant:'success'});

                let account = {
                    ...props.selectedAccount,
                    entities: props.selectedAccount?.entities.map((item) => {
                        if(item.id === selectedEntityObj.id) {
                            item = res;
                        }
                        return item
                    })
                };

                let accountList = props.invoiceAccountsList.map((ia) => {
                if (ia.id === account.id) return account;
                    else return ia;
                });
                props.setDontCallApi(true);
                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);

                if(props.showCreateInvoice) {
                    props.setEntityObj(res);
                    props.setInvoiceNumber(addLeadingZeros(props.showEstimateScreen ? res.next_estimate_number : res.next_invoice_number));
                } else {
                    const obj = {
                        ...props.invoiceObj,
                        entity: res
                    };
                    props.setEditInvoiceObj(obj);
                }
                props.handleUpdateNumberDialogClose();

            })
            .catch((e) => {
                consoleToLog('Error updateEntityNextInvoiceNumberApi: ', e);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    return (
        <Modal
            open={props.openUpdateNumberDialog}
            onClose={props.handleUpdateNumberDialogClose}
            aria-labelledby="Update Number Modal"
            sx={modalStyle}
        >
            <Box component='div' sx={paperStyle}>
                <Grid size={12}>
                    <Grid container justifyContent='space-between' alignItems='center' 
                        sx={headingContainerStyle}>
                        <Grid>
                            <Typography variant='body1' sx={modalHeadingStyle}>
                                {`Configure ${props.showEstimateScreen ? props.selectedAccount.estimate_terminology : 'Invoice'} Number Preferences`}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Button 
                                onClick={props.handleUpdateNumberDialogClose}
                                style={{minWidth:"20px"}}>
                                <ClearIcon sx={{color:"#212121"}}/>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid size={12} sx={contentContainerStyle}>
                        <Grid size={12}>
                            <Typography sx={inputTextHeadingStyle}>
                                {`Continue auto-generating ${props.showEstimateScreen ? props.selectedAccount.estimate_terminology.toLowerCase() : 'invoice'} numbers`}
                            </Typography>
                        </Grid>
                        <Grid container alignItems='center'
                            style={{margin:'12px 0px 8px 0px'}}>
                            <Grid size={6}>
                                <Typography variant='subtitle1'>
                                    Prefix
                                </Typography>
                                <TextField 
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    value={props.invoiceNumberString}
                                    style={{width:'90%'}}
                                    size='small'
                                />
                            </Grid>
                            <Grid size={4}>
                                <Typography variant='subtitle1'>
                                    Next Number
                                </Typography>
                                <TextField 
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    value={invoiceNumber}
                                    onChange={(e) => setInvoiceNumber(e.target.value)}
                                    size='small'
                                />
                            </Grid>
                        </Grid>
                                
                    </Grid>
                    <Grid container alignItems='center'
                        sx={footerStyle}>
                        <Grid>
                            <Button variant='contained' 
                                onClick={updateInvoiceNextNumber}
                                color='primary'>
                                Save
                            </Button>
                        </Grid>

                        <Grid>
                            <Button variant='outlined' 
                                style={{marginLeft:'16px'}}
                                onClick={props.handleUpdateNumberDialogClose}
                                color='primary'>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateNumberModal);