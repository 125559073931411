import React from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import moment from 'moment';
import {formatAmount} from '../util/currencyUtil';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import {downloadReceiptApi, clientDownloadReceiptApi} from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';

const itemStyle = {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)', 
    paddingTop: '16px',
    paddingBottom: '8px'
};

const downloadIcon = {
    fontSize: '18px',
    cursor: 'pointer',
    '&:hover': {
        color: "#595d68"
    }
};

const PaymentReceipt = (props) => {
    const {enqueueSnackbar} = useSnackbar();

    const transactionArr = props.invoiceTransactions;
    const invoice_account_id = props.fromClientPreview ? props.invoice_account_id : props.selectedAccount?.id; 
    const invoice_id = props.invoiceObj?.id;

    const downloadReceipt = (transactionObj) => {
        const transaction_id = transactionObj?.id;
        const details = {};

        props.fromClientPreview ? callDownloadClientReceiptApi(transaction_id) : callUserDownloadReceiptApi(transaction_id, details)
    }

    const callUserDownloadReceiptApi = async(transaction_id, details) => {
        try {
            const response = await downloadReceiptApi(invoice_account_id, invoice_id, transaction_id, details)
            const res = response.data;
            consoleToLog("Response downloadReceiptApi: ", res);
            document.getElementById('dowload_receipt').href = res.document_signed_url;
            document.getElementById('dowload_receipt').click();

        } catch (e) {
            consoleToLog("Response downloadReceiptApi: ", e);
            e.response.data && e.response.data.message && enqueueSnackbar(e.response.data.message, {variant:'error'});
        }
    }

    const callDownloadClientReceiptApi = async(transaction_id) => {
        console.log('transaction_id', transaction_id)
        const md5_hash = props.generateMd5ForApi(`${process.env.REACT_APP_BASE_URL}/client/invoice/${invoice_id}/transaction/${transaction_id}/download_receipt`)
        try {
            const response = await clientDownloadReceiptApi(md5_hash, invoice_id, transaction_id)
            const res = response.data;
            consoleToLog("Response downloadReceiptApi: ", res);
            document.getElementById('dowload_receipt').href = res.document_signed_url;
            document.getElementById('dowload_receipt').click();

        } catch (e) {
            consoleToLog("Response downloadReceiptApi: ", e);
            e.response.data && e.response.data.message && enqueueSnackbar(e.response.data.message, {variant:'error'});
        }
    }

    return (
        <Grid size={12} className="download_invoice" sx={{marginTop:'16px'}}> 
            
            <Typography variant='body1' sx={{textAlign:'center'}}>
                Receipts:
            </Typography>
            
            {
                transactionArr && transactionArr.length > 0 &&
                transactionArr.map((transaction) => {
                    const currenciesObj = transaction.currencies;
                    return <Grid container 
                                justifyContent='space-between'
                                alignItems='center'
                                sx={{...itemStyle, color:'#6B7280'}}
                                key={transaction?.id}>
                                <Grid>
                                    <Typography variant='body2'>
                                        {moment(transaction.payment_date).format('MM-DD-YYYY')}
                                    </Typography>
                                </Grid>

                                <Grid>

                                    <Grid container alignItems='center'>
                                        <Grid>
                                            <Typography variant='body2'>
                                                {formatAmount(transaction.amount, currenciesObj)}
                                            </Typography>
                                        </Grid>

                                        <Grid id='dowload-receipt'>
                                            <Tooltip title='Download' arrow>
                                                <Button sx={{padding:0, minWidth:'20px', position:'relative', top:'-3px',}}>
                                                    <CloudDownloadOutlinedIcon 
                                                        onClick={() => downloadReceipt(transaction)}
                                                        fontSize='small'
                                                        sx={{...downloadIcon, marginLeft:'4px'}} />
                                                    <Link
                                                        style={{ display: "none" }}
                                                        id='dowload_receipt'
                                                        target="_blank"
                                                        onClick={(e) => e.stopPropagation()}
                                                        download
                                                    ></Link>
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                })
            }

        </Grid>
    );
}

export const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
})

export default connect(mapStateToProps)(PaymentReceipt);