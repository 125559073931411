import React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';

const modalContainer = {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
}
    
const modalBody = {
    backgroundColor: 'white', 
    borderRadius: '10px', 
    maxWidth: '460px',
}

const ShowConfirmationModal = ({ showConfirmModal, handleShowConfirmModalClose, itemObj, updatedItemObj, callUpdateSectionApi, setDirtyFlag, handleDrawerClose}) => {

    const handleSave = () => {
        callUpdateSectionApi(itemObj, updatedItemObj);
        handleShowConfirmModalClose();
    };

    const handleDiscard = () => {
         // Close the modal without saving
        handleShowConfirmModalClose();
        setDirtyFlag(false);
        handleDrawerClose();
    };

    return (
        <Modal open={showConfirmModal} 
            onClose={handleShowConfirmModalClose} 
            sx={modalContainer}>
            <Box component='div' sx={modalBody}>
                <div style={{padding:'16px'}}>
                    <Typography variant="h6" component="h2">
                        You have not saved your changes. Do you want to save?
                    </Typography>
                </div>

                <div style={{padding:'16px', borderTop:'1px solid #ccc'}}>
                    <Grid container spacing={1}>
                        <Grid>
                            <Button variant="contained" color="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </Grid>

                        <Grid>
                            <Button variant="outlined" color="secondary" onClick={handleDiscard}>
                                Discard
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </Modal>
    );
};

export default ShowConfirmationModal;
