import React, {useState} from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import "@mdxeditor/editor/style.css";
import {
    InsertTable,
    linkDialogPlugin,
    MDXEditor,
    tablePlugin,
    headingsPlugin,
    listsPlugin,
    quotePlugin,
    thematicBreakPlugin,
    linkPlugin,
    toolbarPlugin,
    UndoRedo,
    BoldItalicUnderlineToggles,
    ListsToggle
} from "@mdxeditor/editor";
import {BaseEditor} from './BaseEditor';
import BaseDrawer from './BaseDrawer';
import { parseMarkdownToHtml } from '../../../util/AppUtil';

const customMargintop = {};

const addNewButton = {
    position: 'relative',
    left: '86%',
    top: '16px'
};

const listContainer = {
    cursor: 'pointer',
    '&:hover': {
        background: '#f7f7f7'
    }
};

function Separator() {
    return (
    <div
        data-orientation="vertical"
        aria-orientation="vertical"
        role="separator"
    ></div>
    );
}

const IntroductionDrawer = ({itemObj, drawerOpen, handleDrawerClose, apiLoading, callUpdateSectionApi, setDirtyFlag}) => {
    const {data} = itemObj;
    const {description, title, bullet_points} = data;

    const [introTitle, setIntroTitle] = useState(title);
    const [shortDescription, setShortDescription] = useState(description);
    const [editDescription, setEditDescription] = useState(false);
    const [bulletPoints, setBulletPoints] = useState([...bullet_points]);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedBulletPoint, setSelectedBulletPoint] = useState(undefined);


    const handleEditClick = (index, bulletObj) => {
        setSelectedBulletPoint(bulletObj);
        setSelectedItemIndex(index);
    };
    
    const handleBulletPointTitleChange = (index, value) => {
        setBulletPoints((prevState) =>
            prevState.map((state, i) =>
            i === index ? { ...state, title: value } : state
            )
        );
        setDirtyFlag(true);
    };

    
    const handleDescriptionChange = (index, text) => {
        setBulletPoints((prevState) =>
            prevState.map((state, i) =>
            i === index ? { ...state, description: text } : state
            )
        );
        setDirtyFlag(true);
    };

    const onShortDescriptionSaveClick = () => {
        setEditDescription(false)
    }

    const onShortDescriptionCancelClick = () => {
        setEditDescription(false);
        setShortDescription(description);
    }
    
    const saveChanges = () => {
        setSelectedItemIndex(null);
        setSelectedBulletPoint(undefined); 
    };

    const cancelEdit = () => {
        setSelectedItemIndex(null);
        const updatedArray = bulletPoints;
        if((updatedArray[selectedItemIndex].title === '' && updatedArray[selectedItemIndex].description === '') || !selectedBulletPoint){
            setBulletPoints([...updatedArray].filter((_, i) => i !== selectedItemIndex));
        } else {
            updatedArray[selectedItemIndex] = selectedBulletPoint;
            setBulletPoints([...updatedArray]);
            setSelectedBulletPoint(undefined); 
        }
    };

    const addBulletPoint = () => {
        setBulletPoints([{ title: '', description: ''}, ...bulletPoints]);
        setSelectedItemIndex(0);
        setDirtyFlag(true);
    };

    const onDeleteIconClick = (index) => {
        const newItems = bulletPoints.filter((_, i) => i !== index);
        setBulletPoints(newItems);
        setDirtyFlag(true);
    }

    const setUpdatedItemObj = () => {
        const dataObj = {
            title: introTitle,
            description: shortDescription,
            bullet_points: bulletPoints,
        };
        const updated_obj = {
            ...itemObj,
            data: {...dataObj}
        }

        return updated_obj;
    }

    const handleTitleChange = (e) => {
        setIntroTitle(e.target.value);
        setDirtyFlag(true);
    }
    
    const onSaveClick = () => {
        callUpdateSectionApi(itemObj, setUpdatedItemObj());
    }

    const onDrawerClose = () => {
        handleDrawerClose(setUpdatedItemObj());
    }

    return (
        <BaseDrawer open={drawerOpen} 
            onClose={onDrawerClose}
            title='Why Us'
        >
            <div className='drawer_content_container' style={{padding:'16px 0px'}}>

                <Grid size={12} className='drawer_element_padding'>
                    <Typography className={'drawer_label_style'}>
                        Page Title
                    </Typography>

                    <TextField 
                        className={'drawer_textfield_margintop'}
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        value={introTitle}
                        onChange={handleTitleChange}
                    
                    />
                </Grid>

                <Grid size={12} className='drawer_element_padding'>

                    <Typography className={'drawer_label_style'}>
                        <b>Description</b>
                    </Typography>

                    {editDescription ?
                        <div className='drawer_edit_item_container'>
                            <BaseEditor editorValue={shortDescription}
                            setEditorValue={setShortDescription}
                            showTable={true}/>
                            <Grid container alignItems='center' spacing={1}>
                                <Grid>
                                    <Button onClick={onShortDescriptionSaveClick} className='drawer_list_save_btn'>
                                        Save
                                    </Button>
                                </Grid>

                                <Grid>
                                    <Button onClick={onShortDescriptionCancelClick} variant='outlined'>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        :
                        <Typography className={'drawer_label_style drawer_descp_cursor'}
                            onClick={() => setEditDescription(true)}
                            dangerouslySetInnerHTML={{ __html: shortDescription }} />
                    }
                </Grid>

                <Grid size={12} className='drawer_element_padding'>

                    {bulletPoints.length === 3 ?
                    <Tooltip title={"You cannot add more than 3 bullet points"} arrow>
                        <span>
                        <Button fullWidth variant='outlined' 
                            disabled={bulletPoints.length === 3}
                            className='edit_drawer_add_btn'>
                            <img src='/images/drawer_add.svg' style={{marginRight: '5px'}}/>
                            Add Bullet Point
                        </Button>
                        </span>
                    </Tooltip>
                    :
                    <Button fullWidth variant='outlined' 
                        onClick={addBulletPoint}
                        disabled={bulletPoints.length === 3}
                        className='edit_drawer_add_btn'>
                        <img src='/images/drawer_add.svg' style={{marginRight: '5px'}}/>
                        Add Bullet Point
                    </Button>}
                </Grid>

                <Grid size={12} className='drawer_list_items'>
                    {
                        bulletPoints && bulletPoints.length > 0 &&
                        bulletPoints.map((bulletObj, index) => {
                            return (
                                <Grid size={12} className='itemList' key={index}>
                                    {index === selectedItemIndex ? (
                                    <div className='drawer_edit_item_container edit_list_width'>
                                        <Grid size={12}>
                                            <TextField variant='outlined'
                                                fullWidth
                                                value={bulletPoints[index]?.title}
                                                onChange={(e) => handleBulletPointTitleChange(index, e.target.value)}
                                                placeholder="Title"
                                                autoFocus
                                                style={{padding:0}}
                                                className='edit_drawer_textfield'
                                            />
                                        </Grid>

                                        <Grid>
                                            <MDXEditor markdown={bulletPoints[index]?.description} 
                                                className='MarkDownEditors'
                                                onChange={(value) => handleDescriptionChange(index, value)}
                                                plugins={[
                                                    headingsPlugin(),
                                                    listsPlugin(),
                                                    quotePlugin(),
                                                    thematicBreakPlugin(),
                                                    linkPlugin(),
                                                    linkDialogPlugin({}),
                                                    tablePlugin(),
                                                    toolbarPlugin({
                                                        toolbarContents: () => (
                                                        <div className="MarkDownToolBar">
                                                            <UndoRedo />
                                                            <Separator />
                                                            <BoldItalicUnderlineToggles />
                                                            <Separator />
                                                            {/* <InsertTable />
                                                            <Separator /> */}
                                                            <ListsToggle options={['bullet', 'number']}/>
                                                        </div>
                                                        ),
                                                    }),
                                                ]}
                                                placeholder='Type here'
                                                autoFocus
                                            />
                                        </Grid>

                                        <Grid container alignItems='center' spacing={1}>
                                            <Grid>
                                                <Button onClick={saveChanges} className='drawer_list_save_btn'>
                                                    Save
                                                </Button>
                                            </Grid>

                                            <Grid>
                                                <Button onClick={cancelEdit} variant='outlined'>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        
                                        
                                    </div>
                                ) : (
                                    <div onClick={() => handleEditClick(index, bulletObj)} className='drawer_edit_item_container'>
                                        <Grid>
                                            <Typography className='drawer_list_items_heading'>
                                                {bulletObj?.title}
                                            </Typography>
                                        </Grid>

                                        <Grid>
                                            <Typography dangerouslySetInnerHTML={{ __html: parseMarkdownToHtml(bulletObj?.description) }}
                                                className='drawer_list_items_descp' />
                                        </Grid>
                                    </div>
                                )}

                                <IconButton 
                                    onClick={() => onDeleteIconClick(index)}
                                    className={'edit_drawer_delete_icon'}>
                                    <img src='/images/drawer_delete.svg' />
                                </IconButton>
                                </Grid>
                            )
                        })
                    }
                </Grid>

            </div>

            <div className={'drawer_footer'}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid>
                        <Button
                            onClick={onSaveClick} 
                            className={`next_btn_style drawer_btn_width`}>
                            {apiLoading && <CircularProgress size={15} className='drawer_loading_margin'/>}  Save
                        </Button>
                    </Grid>

                    <Grid>
                        <Button variant='outlined' color='primary'
                            onClick={onDrawerClose}
                            className={'drawer_btn_width'}>
                            Cancel 
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </BaseDrawer>
    );
}

export default IntroductionDrawer;