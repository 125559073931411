import React, {useState} from 'react';
import Modal from '@mui/material/Modal';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import {connect} from 'react-redux';
import {downloadInvoicePrintApi} from '../../services/authService';
import { consoleToLog, trackGAEvent } from '../../util/AppUtil';
import { logout } from '../../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../../actions/invoiceFilters';
import { setSelectedDrawerState } from '../../actions/drawer';
import { clearSelectedTransactionFilters } from "../../actions/transactionFilters";
import { setClearInvoiceAccounts } from '../../actions/invoiceAccount';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const modalStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const paperStyles = {
    position: 'absolute',
    top:'50%',
    bottom:'50%',
    left:'50%',
    right:'50%',
    width: '350px',
    zIndex:101
};

const modalHeadingStyles = {
    fontSize: '18px',
    fontWeight: 600,
    color: "#212121"
};

const headingContainerStyles = {
    background: '#f7f7f7',
    padding: '4px 8px 4px 16px'
};

const PrintInvoiceModal = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();

    const {original_print, transport_print, customer_print} = props.selectedAccount.print_settings || {};
    const [loading, setLoading] = useState(false);
    const [originalPrintCopy, setOriginalPrintCopy] = useState(original_print ? original_print : true);
    const [transportationPrintCopy, setTransportationPrintCopy] = useState(transport_print ? transport_print : false);
    const [customerPrintCopy, setCustomerPrintCopy] = useState(customer_print ? customer_print : false);

    const onPrintInvoiceClick = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const invoice_id = props.invoiceObj?.id;
        const entity_id = props.invoiceObj?.entity.id;
        const client_id = props.invoiceObj?.client.id;

        setLoading(true);
        downloadInvoicePrintApi(invoice_account_id, entity_id, client_id, invoice_id, originalPrintCopy, transportationPrintCopy, customerPrintCopy)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response downloadInvoicePrintApi: ', res);
                setLoading(false);

                const downloadableURL = res.document_signed_url;

                props.setDownloadInvoiceURL(downloadableURL);

                trackGAEvent(props.selectedAccount.name, 'Invoice Print Api', `${props.user.firstname} ${props.user.lastname}`);
            
                props.handlePrintInvoiceModalClose();
            })
            .catch((e) => {
                consoleToLog('Error downloadInvoicePrintApi: ', e);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
                props.handlePrintInvoiceModalClose();
            })
    }

    return (
        <Modal
            open={props.openPrintModal}
            onClose={props.handlePrintInvoiceModalClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            sx={modalStyles}
        >
            <Box component='div' sx={{...paperStyles,
                backgroundColor: 'red',
                boxShadow: theme.shadows[5],
                zIndex: 200
            }}>
                <Grid size={12}>
                    
                    <Grid size={12}>
                        <Grid container justifyContent='space-between' alignItems='center'
                            sx={headingContainerStyles}>
                            <Grid>
                                <Typography variant='body1' sx={modalHeadingStyles}>
                                    Invoice Print
                                </Typography>
                            </Grid>
                            <Grid>
                                <Button 
                                    onClick={props.handlePrintInvoiceModalClose}
                                    style={{minWidth:"20px"}}>
                                    <ClearIcon style={{color:"#212121"}}/>
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid size={12} sx={{padding:'16px'}}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox  
                                        onChange = {(e)=>{
                                            setOriginalPrintCopy(e.target.checked);
                                            //updateInvoiceDisplaySettings(e.target.checked, paymentTerms, clientTerms);
                                        }}
                                        checked={originalPrintCopy}
                                    />
                                    } 
                                    label="Original Copy" />

                                    <FormControlLabel control={
                                        <Checkbox   
                                            onChange = {(e)=>{
                                                setTransportationPrintCopy(e.target.checked);
                                                //updateInvoiceDisplaySettings(paymentStub, e.target.checked, clientTerms );
                                            }}
                                            checked={transportationPrintCopy}
                                    />
                                    } 
                                    label="Transporter Copy" />

                                    <FormControlLabel control={
                                        <Checkbox   
                                            onChange = {(e)=>{
                                                setCustomerPrintCopy(e.target.checked);
                                                //updateInvoiceDisplaySettings(paymentStub, paymentTerms, e.target.checked);
                                            }}
                                            checked={customerPrintCopy}
                                    />
                                    } 
                                    label="Customer Copy" />
                            </FormGroup>
                            
                            <Grid container justifyContent='center' alignItems='center' sx={{marginTop:"16px"}}>
                                <Button variant='contained' 
                                    disabled={!originalPrintCopy && !transportationPrintCopy && !customerPrintCopy}
                                    onClick={onPrintInvoiceClick}
                                    color='primary'>
                                    {loading && <CircularProgress size={20} sx={{marginRight:'8px', color:'#fff'}}/>}Print
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    drawerState: state.drawerState.setDrawerState,
    user: state.invoiceAccounts.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters()),
});


export default connect(mapStateToProps, mapDispatchToProps)(PrintInvoiceModal);