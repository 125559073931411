import React from 'react';
import BackgroundPreview from './BackgroundPreview';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { showSocialMediaImages } from '../../../util/AppUtil';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

const componentStyles = (theme) => {
    const titleStyle = {
        fontSize: '69px',
        lineHeight: '95px',
        fontWeight: 800,
        [theme.breakpoints.down('sm')]: {
            fontSize: '40px',
            fontWeight: 600,
            lineHeight: '55px',
        },
        [theme.breakpoints.down(400)]: {
            fontSize: '30px',
            fontWeight: 600,
            lineHeight: '45px',
        },
    };

    const titleStyle1 = {
        fontSize: '60px',
        fontWeight: 700,
        lineHeight: '75px',
        [theme.breakpoints.down(1450)]: {
            fontSize: '50px',
            fontWeight: 600,
            lineHeight: '70px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '30px',
            fontWeight: 600,
            lineHeight: '45px',
        },
    };

    const footerStyle = {
        position: 'absolute',
        bottom: '200px',
        left: '80px',
        right: '80px',
        [theme.breakpoints.down('sm')]: {
            bottom: '150px',
            left: '20px',
            right: '20px',
        },
    };

    const footerStyle1 = {
        position: 'absolute',
        bottom: '200px',
        left: '60px',
        right: '60px',
        [theme.breakpoints.down('sm')]: {
            bottom: '150px',
            left: '20px',
            right: '20px',
        },
    };

    const preparedLabelText = {
        fontSize: '24px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    };

    const imageStyle = {
        position: 'relative',
        left: '8px',
        opacity: '30%',
        [theme.breakpoints.down('sm')]: {
            width: '100px',
        },
    };

    const dateStyle = {
        fontSize: '42px',
        fontWeight: 700,
        position: 'relative',
        left: '8px',
        top: '16px',
        marginBottom: '8px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
            top: '4px',
        },
    };

    const dateStyle1 = {
        fontSize: '32px',
        fontWeight: 700,
        position: 'relative',
        left: '8px',
        top: '16px',
        marginBottom: '8px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
            top: '4px',
        },
    };

    const clientNameText = {
        fontSize: '24px',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    };

    const socialMediaImageStyle = {
        width: '32px',
        height: '32px',
        [theme.breakpoints.down('sm')]: {
            width: '24px',
            height: '24px',
        },
    };

    const imageContainer = {
        width: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f9f9f9',
        [theme.breakpoints.down('sm')]: {
            width: '10%',
        },
    };

    const imageContainer1 = {
        width: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f9f9f9',
        [theme.breakpoints.down('sm')]: {
            width: '35px',
            height: '35px',
        },
    };

    const logoImage = {
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'cover',
    };

    const companyNameStyle = {
        fontSize: '24px',
        fontWeight: 600,
        position: 'relative',
        top: '-1px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    };

    const companyNameStyle1 = {
        fontSize: '18px',
        fontWeight: 600,
        position: 'relative',
        top: '-1px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    };

    const customClass = {
        flexBasis: '12%',
        maxWidth: '12%',
        [theme.breakpoints.down('sm')]: {
            flexBasis: '20%',
            maxWidth: '20%',
        },
    };

    const customClass1 = {
        flexBasis: '11%',
        maxWidth: '11%',
    };

    return {
        titleStyle,
        titleStyle1,
        footerStyle,
        footerStyle1,
        preparedLabelText,
        imageStyle,
        dateStyle,
        dateStyle1,
        clientNameText,
        socialMediaImageStyle,
        imageContainer,
        imageContainer1,
        logoImage,
        companyNameStyle,
        companyNameStyle1,
        customClass,
        customClass1,
    };
};

const CoverPagePreview = ({item, index, brand_color, entity_name, entity_logo, normalUserPreview}) => {
    const theme = useTheme();

    const {titleStyle,
        titleStyle1,
        footerStyle,
        footerStyle1,
        preparedLabelText,
        imageStyle,
        dateStyle,
        dateStyle1,
        clientNameText,
        socialMediaImageStyle,
        imageContainer,
        imageContainer1,
        logoImage,
        companyNameStyle,
        companyNameStyle1,
        customClass,
        customClass1,} = componentStyles(theme);

    const {data} = item;
    const {client_company_name, title, client_first_name, client_last_name, show_company_logo, show_company_name, website, social_links} = data;
    const todayDate = new Date();
    const year = todayDate.getFullYear();

    const titleClass = normalUserPreview ? titleStyle1 : titleStyle;
    const dateClass = normalUserPreview ? dateStyle1 : dateStyle;
    const companyLogo = normalUserPreview ? customClass1 : customClass;
    const companyName = normalUserPreview ? companyNameStyle1 : companyNameStyle;
    const footStyle = normalUserPreview ? footerStyle1 : footerStyle;

    const onWebsiteUrlClick = () => {
        window.open(website, '_blank');
    }

    return (
        <BackgroundPreview item={item} title={''} 
            brand_color={brand_color}
            index={index}
            normalUserPreview={normalUserPreview}
        >
                    <Grid size={12}>
                        <Grid container alignItems='center'>
                            {show_company_logo &&
                                <Grid sx={companyLogo}>
                                    {show_company_logo && <img src={entity_logo ? entity_logo : '/images/entity_business-and-trade.png'} style={{...logoImage}} />}
                                </Grid>
                            }

                            <Grid>
                                <Typography sx={companyName} style={{marginLeft:show_company_logo ? '6px' : '0px'}}>
                                    {show_company_name && entity_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                <Grid size={12} sx={{marginTop:'122px'}}>
                    <Box component='img' src='/images/dotted_image.png' sx={{...imageStyle}}/>
                    <Typography sx={{...dateClass, color: brand_color}}>
                        {year}
                    </Typography>
                    <Typography sx={{...titleClass, color: brand_color}}>
                        {title}
                    </Typography>
                </Grid>

                <Grid size={12} sx={footStyle}>
                    <Grid container alignItems='center' justifyContent='space-between'>
                        <Grid>
                            <Typography sx={preparedLabelText}>
                                Prepared for:
                            </Typography>

                            {client_first_name && client_last_name &&
                            <Typography sx={clientNameText}>
                                {`${client_first_name} ${client_last_name}`}
                            </Typography>}

                            <Typography sx={clientNameText}>
                                {client_company_name}
                            </Typography>
                        </Grid>

                        <Grid>
                                {website &&
                                <Typography 
                                    onClick={onWebsiteUrlClick}
                                    className='drawer_link_style'>
                                    {website}
                                </Typography>}
                            <Grid container alignItems='center' spacing={1}>
                                
                            {
                                social_links && social_links.length > 0 &&
                                social_links.map((item) => {
                                    return <Grid key={item.type}>
                                                <Link href={item.link} target='blank'>
                                                    <img src={(showSocialMediaImages(item.type))} style={{...socialMediaImageStyle}}/>
                                                </Link>
                                            </Grid>
                                })
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
        </BackgroundPreview>
    );
}

export default CoverPagePreview;