import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useState } from "react";
import { consoleToLog } from "../util/AppUtil";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { connect } from "react-redux";
import ApplyTax from "./ApplyTax";
import AddEditBillCategoriesDrawer from "./AddEditBillCategoriesDrawer";
import CreateTaxPresetDrawer from "./CreateTaxPresetDrawer";
import { useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography"; 
import Tooltip from "@mui/material/Tooltip";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import {ITCValues} from '../util/AppUtil';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const MuiFormControlroot = {
    width:'100%',
    background:'#fff'
};

const itemOptionStyle = {
    width: "100%", 
    height:'100%',
    color:'#777f8e',
    margin:'5px 0px'
};

const MuiAutocompleteinputRoot = {
    '& .MuiOutlinedInput-root' : {
    padding:'0.5px 1px 0.5px 1px',
    borderRadius: '4px',
    }
};

const customTextField = {
    "& input::placeholder": {
        paddingLeft: "8px",
    }
};

const taxItemStyle = {
    padding: "8px 8px", 
    cursor: 'pointer',
    '&:hover': {
        background: '#f5f5f5'
    }
};

const MuiTextFieldroot = {
    background:'#fff',
    // '& .MuiOutlinedInput-input': {
    //     paddingTop: '8px',
    //     paddingBottom: '8px'
    // }
};
const dateBtn = {
    background:'#fff',
    padding:'5px 8px'
};

const editIconStyle = {
    color: "#007bfb",
    fontSize:'16px',
    position:"relative",
    top:'2px',
    marginLeft:'4px',
    cursor: 'pointer'
}

const checkboxIcon = {
    color: "#007bfb",
}

const radioLabelText = {
    '& .MuiTypography-root' : {
        fontSize:'14px'
    }
}

const tagSaveBtn = {
    background:'#1A73E8',
    color:'#fff',
    width:'70px',
    '&:hover':{
        background:'blue',
    }
}

const tagIconStyle = {
    position:'relative',
    top:'2px',
    fontSize:'16px',
    transform:'scaleX(-1)'
}

const downArrowIconStyle = {
    position:'relative',
    top:'2px',
    fontSize:'21px',
    color:"#555"
}

const tagCancelBtn = {
    border:'1px solid #ddd',
    background:'#fff',
    color:'#333'
}

const hsnCodeStyle = {
    background:'#64B693',
    color: '#fff',
    padding: '4px 12px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    borderRadius:'5px'
};

const hsnCodeText = {
    fontSize: '15px',
    fontWeight: 600
}

const lineItemsContainerStyles = {
    position:'relative',
    padding: '15px',
    borderRadius: '5px',
    background: '#fff',
    boxShadow:  '0px 1px 2px 0px rgba(0, 0, 0, 0.23)',
    marginBottom:'16px',
    display:'flex',
    flexDirection:"column",
    '& .labels': {
        color: '#555',
        leadingTrim: 'both',
        textEdge: 'cap',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginBottom:'4px'
    },
    '& .labels1': {
        color: '#555',
        leadingTrim: 'both',
        textEdge: 'cap',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginBottom:'4px'
    },
    '& .iconStyle': {
        fontSize:'12px',
        color:'#fff',
        cursor:'pointer'
    },
};

const tagsContainer = {
    padding:'8px 8px',
    background:'#f9f9fb'
}

const deleteImage = {
    position:'absolute', 
    right:'-8px', 
    top:'-8px',
    width:'23px',
    height:'23px',
    borderRadius:"100%",
    background: '#D24E4E',
    color:"#fff",
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    cursor:"pointer"
}


const BillLineItem=React.memo(({
    billCategoryList, lineItem, index, onDeleteLine, onUpdateLineItemsArr, currencyObj, setBillCategoryList, taxPresetsArr, 
    CreateItemButton, fromExpenseDetailComponent, reverseCharge
})=>{
    const [selectedBillCategory, setSelectedBillCategory] = useState(null);
    const [openBillCategoryDrawer, setOpenBillCategoryDrawer] = useState(false);
    const [description, setDescription]=useState("");
    const [billLineItem, setBillLineItem] = useState({...lineItem});
    const [openDrawer, setOpenDrawer] = useState(false);
    const [taxPresetsArr1, setTaxPresetArray]=useState([taxPresetsArr]);
    const [showITCPopover, setShowITCPopover] = useState(null);
    const [itemId, setItemId] = useState(undefined);
    const [reportingTagsPopover, setReportingTagsPopover] = useState(null);
    const [reportingTags, setReportingTags] = useState([]);

    const [showHSNTextfield, setShowHSNTextfield] = useState(false);
    const [hsn_code, setHSNCode]= useState("");

    consoleToLog("****Rendering InvoiceLineItem Component:", billLineItem);

    useEffect(() => {
        if(reverseCharge) {
            setBillLineItem(lineItem);
        }
    }, [reverseCharge])

    const onBillCategoryChange = (e, newValue, reason) => {
        const newLineItem = { ...billLineItem };
        if(newValue){
            if(newValue.id !=='add_new'){
                newLineItem.category=newValue;
            }
        }
        else{
            newLineItem.category=null;
        }
        setBillLineItem(newLineItem);
        onUpdateLineItemsArr(newLineItem, index);
    }

    const updateLineItem = (localBillItem, i)=>{
        const newLineItem = { ...localBillItem };

        setBillLineItem(newLineItem);
        onUpdateLineItemsArr(newLineItem, i);
    }
    
    const handleCategoryDrawerClose = () => {
        setOpenBillCategoryDrawer(false);
    }

    const onDescriptionChange=(e)=>{
        const newLineItem = { ...billLineItem };
        let input = e.target.value;
        newLineItem.description=input;
        setBillLineItem(newLineItem);
        onUpdateLineItemsArr(newLineItem, index);
    }

    const onAmountChange = (e)=>{
        const newLineItem = { ...billLineItem };
        const input=e.target.value;
        if (input !== "" || /^\d+(\.\d{0,2})?$/.test(input)) {
            newLineItem.amount=parseFloat(input);
            //calculate tax
            newLineItem.tax=newLineItem.tax?.map((t)=>{
                return {...t, amount:(t.rate*parseFloat(input)/100)}
            })
        }
        else{
            newLineItem.amount=""
        }
        setBillLineItem(newLineItem);
        onUpdateLineItemsArr(newLineItem, index);
    }

    const handleCategoryDrawerOpen = () => {
        setOpenBillCategoryDrawer(true);
    }

    const handleDrawerOpen = (e) => {
        setOpenDrawer(true);
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    }

    const onAddBillCategoryClick = (category) => {
        setBillCategoryList(billCategoryList => [...billCategoryList, category]);
    }

    const showITCvalues = (str) => {
        switch (str) {
            case ITCValues.ELIGIBLE:
                return 'Eligible for ITC';
                
            case ITCValues.INELIGIBLE_SECTION_17:
            case ITCValues.INELIGIBLE_OTHERS:
                return 'Ineligible';
        
            default:
                break;
        }
    }

    const handleITCPopoverOpen = (event) => {
        setShowITCPopover(event.currentTarget);
        setItemId(index);
    }

    const handleITCPopoverClose = () => {
        setShowITCPopover(null);
        setItemId(undefined);
    }

    const itcPopover = (lineItem, index) => {

        const onSaveClick = () => {
            handleITCPopoverClose();
        }

        const onCancelClick = () => {
            const newLineItem = { ...billLineItem };
            newLineItem.itc_eligibility = 'eligible';
            setBillLineItem(newLineItem);
            onUpdateLineItemsArr(newLineItem, index);
            handleITCPopoverClose();
        }

        const handleChange = (event) => {
            const newLineItem = { ...billLineItem };
            newLineItem.itc_eligibility = event.target.value;
            setBillLineItem(newLineItem);
            onUpdateLineItemsArr(newLineItem, index);
        }

        return (
            <Popover open={Boolean(showITCPopover)} 
                anchorEl={showITCPopover}
                onClose={handleITCPopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }} 
                >
                    <Paper sx={{width:'300px'}}>
                        <Grid container justifyContent='space-between' 
                            alignItems='center' sx={{ p: 1, background:'#f9f9fb', fontSize:'14px' }}>
                                <Grid>
                                    <Typography variant="body2">
                                        Input Tax Credit
                                    </Typography>
                                </Grid>

                                <Grid>
                                    <ClearIcon onClick={onCancelClick} sx={{cursor:'pointer', position:'relative', top:'2px'}} fontSize="small"/>
                                </Grid>
                        
                        </Grid>
                        <Box sx={{p:1, display:'flex', flexDirection:'column', gap:'16px', paddingBottom:'16px'}}>
                            <Grid size={12} sx={{px:'8px'}}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={lineItem?.itc_eligibility}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value={ITCValues.ELIGIBLE}
                                            control={<Radio style={checkboxIcon}/>} 
                                            label="Eligible for ITC" sx={radioLabelText}/>

                                        <FormControlLabel value={ITCValues.INELIGIBLE_SECTION_17}
                                            control={<Radio style={checkboxIcon}/>} 
                                            label="Ineligible - As per Section 17 (5)" sx={radioLabelText}/>

                                        <FormControlLabel value={ITCValues.INELIGIBLE_OTHERS}
                                            control={<Radio style={checkboxIcon}/>} 
                                            label="Ineligible - Others" sx={radioLabelText}/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid size={12}>
                                <Grid container alignItems='center' 
                                    style={{padding:'0px 8px'}}
                                    spacing={1}>
                                    <Grid>
                                        <Button sx={tagSaveBtn} onClick={onSaveClick}>
                                            Ok
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
            </Popover>
        )
    }

    const handleTagPopoverOpen = (event) => {
        setReportingTagsPopover(event.currentTarget);
        setItemId(index);
    }

    const handleTagPopoverClose = () => {
        setReportingTagsPopover(null);
        setItemId(undefined);
    }


    const showReportingTags = (lineObj, index) => {
        const newLineItem = { ...lineItem };

        const onReportingTagChanged = (e, selectedTag) => {
            newLineItem.tag = selectedTag;
        } 

        const onSaveClick = () => {
            setBillLineItem(newLineItem);
            handleTagPopoverClose();
            onUpdateLineItemsArr(newLineItem, index);
        }

        return (
            <Popover open={Boolean(reportingTagsPopover)} 
                anchorEl={reportingTagsPopover}
                onClose={handleTagPopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }} 
                >
                    <Paper sx={{width:'300px'}}>
                        <Typography sx={{ p: 1, background:'#f9f9fb', fontSize:'14px' }}>
                            Reporting Tags
                        </Typography>
                        <Box sx={{p:1, display:'flex', flexDirection:'column', gap:'16px', paddingBottom:'16px'}}>
                            <Grid size={12}>
                                <Typography variant="body2" sx={{marginBottom:'-2px'}}>
                                    Cost Center
                                </Typography>
                                <Autocomplete
                                    sx={{width:'91%'}}
                                    options={reportingTags}
                                    value={lineObj.tag || null}
                                    getOptionLabel={(option) => option.name || null}
                                    size="small"
                                    renderInput={(params) => 
                                    <TextField {...params} color="secondary"  
                                        margin="dense"
                                        placeholder="Select Options"
                                    />}
                                    onChange={(e, selectedRT) => onReportingTagChanged(e, selectedRT)}
                                />
                            </Grid>

                            <Grid size={12}>
                                <Grid container alignItems='center' 
                                    style={{padding:'0px 8px'}}
                                    spacing={1}>
                                    <Grid>
                                        <Button sx={tagSaveBtn} onClick={onSaveClick}>
                                            Save
                                        </Button>
                                    </Grid>

                                    <Grid>
                                        <Button 
                                            onClick={handleTagPopoverClose}
                                            sx={tagCancelBtn}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
            </Popover>
        )
    }

    const handleHSNEditClick = (lineObj) => {
        setItemId(index);
        setShowHSNTextfield(true);
        setHSNCode(lineObj.hsn_or_sac);
    };
    

    const onHsnSaveClick = () => {
        setHSNCode("");
        setItemId(undefined);
        setShowHSNTextfield(false);
    }

    const onHsnCloseClick = (liObj, index) => {
        const newLineItem = { ...lineItem };

        newLineItem.hsn_or_sac = hsn_code;
        setBillLineItem(newLineItem);
        setHSNCode("");
        setItemId(undefined);
        setShowHSNTextfield(false);
        onUpdateLineItemsArr(newLineItem, index);
    }

    const onHSNCodeChange = useCallback((e, liObj, index) => {
        const newLineItem = { ...lineItem };
        newLineItem.hsn_or_sac = e.target.value;
        setBillLineItem(newLineItem);
        onUpdateLineItemsArr(newLineItem, index);
    }, [lineItem, index, onUpdateLineItemsArr]);

    return (
        <Grid size={12} sx={lineItemsContainerStyles}>

            <Grid sx={deleteImage} onClick={() => onDeleteLine(index)}>
                <ClearIcon fontSize="small" sx={{color:'#fff'}}/>
                {/* // <img src="/images/close.svg" width="10px" /> */}
            </Grid>
            <Grid container spacing={1}>
                <Grid size={3}>
                    <TextField
                        size="small"
                        placeholder="Amount"
                        variant="outlined"
                        type="number"
                        fullWidth
                        value={lineItem.amount}
                        onChange={onAmountChange}
                        sx={MuiTextFieldroot}
                    />
                </Grid>
                <Grid size={4}>
                    <Autocomplete
                        size="small"
                        id="bill_category"
                        getOptionLabel={(option) => option.name || ''}
                        value={lineItem?.category}
                        options={billCategoryList}
                        onChange={(e, newValue, reason) => onBillCategoryChange(e, newValue, reason)}
                        style={MuiFormControlroot}
                        renderInput={(params) => 
                            <TextField {...params} color="secondary" variant="outlined"  placeholder="Select Bill Category"
                            />}
                        slots={{
                            paper: (props) => <CreateItemButton {...props} text='Category' onClick={handleCategoryDrawerOpen} />
                        }}
                        //PaperComponent={(props) => <CreateItemButton {...props} text='Category' onClick={handleCategoryDrawerOpen} />}
                        // renderOption={(option) => 
                        //     option.id === "add_new" ? (
                        //         <Button variant="text" color="primary" style={{ width: '100%' }}
                        //             onClick={()=>handleCategoryDrawerOpen()}>
                        //             <AddOutlinedIcon fontSize='small'
                        //                 style={{
                        //                     marginRight:'8px',
                        //                         fontSize: '16px'
                        //                         }}
                        //             />
                        //             Add New
                        //         </Button>
                        //     ) : (
                        //         option.name
                        //     )
                        // }
                        getOptionSelected={(option, value) => option?.id === value?.id}
                    />
                </Grid>
                <Grid size={5}>
                    <TextField
                        size="small"
                        placeholder="Description"
                        variant="outlined"
                        fullWidth
                        value={lineItem?.description}
                        onChange={onDescriptionChange}
                        sx={MuiTextFieldroot}
                    />
                </Grid>
                {/* <Grid size={1}
                    sx={{
                        flexBasis:'5%',
                        maxWidth:'5%',
                        textAlign:'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <DeleteOutlineIcon fontSize='small'
                            style={{ color: 'red', cursor: "pointer" }}
                            onClick={() => onDeleteLine(index)}/>
                </Grid> */}
            </Grid>

            <Grid size={12} sx={{marginTop:"5px"}}>
                <Grid container alignItems='flex-start' justifyContent='space-between'>
                    <Grid size={8}>
                    {billLineItem && !fromExpenseDetailComponent && 
                    <ApplyTax billLineItem={billLineItem}
                        currencyObj={currencyObj}
                        index={index}
                        updateLineItem={updateLineItem}
                        handleDrawerOpen={handleDrawerOpen}
                        taxPresetsArr={taxPresetsArr}
                        reverseCharge={reverseCharge}
                        />
                    }
                    </Grid>

                    {billLineItem?.tax && billLineItem?.tax.length > 0 &&
                    <Grid size={2} 
                        onClick={(event) => handleITCPopoverOpen(event)}
                        sx={{cursor:'pointer'}}>
                        <Grid container alignItems='center'>
                            <Grid>
                                <Tooltip title={showITCvalues(billLineItem?.itc_eligibility)} arrow>
                                    <Typography 
                                        style={{maxWidth:'110px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}
                                        variant="subtitle1" sx={{fontSize:"12px"}}>
                                        {showITCvalues(billLineItem?.itc_eligibility)}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid>
                                <EditIcon fontSize="small" sx={editIconStyle}/>
                            </Grid>
                        </Grid>
                    </Grid>}

                    {showITCPopover && itemId === index && itcPopover(billLineItem, index)}
                </Grid>
            </Grid>

            <Grid size={12} sx={{...hsnCodeStyle, marginTop:'16px'}}>
                <Grid size={6}>
                    {showHSNTextfield  && index === itemId ?
                        <TextField id="standard-basic" 
                            placeholder="HSN code" 
                            color="secondary"
                            value={billLineItem.hsn_or_sac}
                            style={{background:'#fff'}}
                            onChange={(e) => onHSNCodeChange(e, lineItem, index)}
                            sx={MuiFormControlroot}
                            size="small"
                        />
                        :
                        <Typography sx={hsnCodeText}>
                            { `Goods HSN/SAC Code`}: {lineItem.hsn_or_sac}
                        </Typography>}
                </Grid>

                <Grid>
                    {showHSNTextfield && index === itemId ?
                    <Grid container alignItems='center'>
                        <Grid>
                            <Tooltip title='Save' arrow>
                                <Button style={{minWidth:'20px'}}
                                    onClick={(e) => onHsnSaveClick(lineItem.hsn_or_sac)}
                                    >
                                    <CheckIcon style={{color:'#fff'}}/>
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid>
                            <Tooltip title='Close' arrow>
                                <Button style={{minWidth:'20px'}}
                                    onClick={(e) => onHsnCloseClick(lineItem, index)}
                                    >
                                    <HighlightOffOutlinedIcon style={{color:'#fff'}}/>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    :
                    <Tooltip title='Edit HSN code' arrow>
                        <Button style={{minWidth:'20px'}}
                            onClick={(e)=>handleHSNEditClick(lineItem)}
                            >
                            <EditOutlinedIcon style={{color:'#fff'}}/>
                        </Button>
                    </Tooltip>
                    }
                </Grid>
            </Grid>

            <Grid size={12} sx={{...tagsContainer, marginTop:'16px'}}>
                <Grid container alignItems='center' spacing={1} 
                    sx={{cursor:'pointer'}}
                    onClick={(event) => handleTagPopoverOpen(event, billLineItem)}> 
                    <Grid>
                        <LocalOfferIcon sx={tagIconStyle}/>
                    </Grid>
                    <Grid>
                        <Typography variant="body2">
                            Reporting Tags
                        </Typography>
                    </Grid>
                    {billLineItem.tag?.length > 0 &&
                    <Grid>
                        <Typography variant="body2">
                            {billLineItem.tag?.length} out of {reportingTags.length} selected
                        </Typography>
                    </Grid>}
                    <Grid item>
                        <ArrowDropDownIcon sx={downArrowIconStyle}/>
                    </Grid>

                </Grid>
                {reportingTagsPopover && itemId === index && showReportingTags(billLineItem, index)}
            </Grid>

            {openDrawer && <CreateTaxPresetDrawer openDrawer={openDrawer}
                handleDrawerClose={handleDrawerClose}
            />
        }
            {openBillCategoryDrawer && <AddEditBillCategoriesDrawer
                openDrawer={openBillCategoryDrawer} 
                handleDrawerClose={handleCategoryDrawerClose}
                onAddItemsClick={onAddBillCategoryClick}
            />
            }
        </Grid>
    )
})


const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    tdsTypeList: state.invoiceAccounts.tdsTypeList,
});

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(BillLineItem);