import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import {lightenColor} from '../../../util/AppUtil';
import { useTheme } from '@mui/material/styles';

const componentStyles = (theme) => {

    const backgroundContainer = {
        position: "relative",
        minHeight: "1250px",
        height: '100%',
        background: "#fff",
        boxShadow: '0px 0px 25px 0px #00000026',
        overflow: 'hidden',
        padding: '80px',
        [theme.breakpoints.down('sm')]: {
            padding: "20px",
            minHeight: "800px",
            height: '100%',
        },
    };

    const backgroundContainer1 = {
        position: "relative",
        padding: "60px",
        minHeight: "1000px",
        height: '100%',
        background: "#fff",
        boxShadow: '0px 0px 25px 0px #00000026',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            padding: "20px",
            minHeight: "800px",
            height: '100%',
        },
    };

    const bookmarkImage = {
        position: "relative",
        top: '0px',
        width: '168px',
        height: '281px',
        [theme.breakpoints.down('sm')]: {
            width: '80px',
            height: '120px',
            top: '0px',
        },
    };

    const titleStyles = {
        marginTop: "32px",
        fontSize: '38px',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
            fontWeight: 600
        },
    };

    const titleStyles1 = {
        marginTop: "32px",
        fontSize: '28px',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
            fontWeight: 600
        },
    };

    const dottedImageStyle = {
        position: 'absolute',
        bottom: 140,
        right: 120,
        opacity: '30%',
        [theme.breakpoints.down('sm')]: {
            width: '100px',
            bottom: 60,
            right: 40,
        },
    };

    const dottedImageStyle1 = {
        width: '151px',
        height: '151px',
        position: 'absolute',
        bottom: 140,
        right: 120,
        opacity: '30%',
        [theme.breakpoints.down('sm')]: {
            width: '100px',
            bottom: 60,
            right: 40,
        },
    };

    const descriptionStyle = {
        width: '95%',
        [theme.breakpoints.down('sm')]: {
            width: '86%',
        },
    };

    const pageNoStyle = {
        position: 'absolute',
        right: '90px',
        bottom: '90px',
        [theme.breakpoints.down('sm')]: {
            right: '30px',
            bottom: '30px',
        },
    };

    const pageNoCircle = {
        width: "20px",
        height: "20px",
        borderRadius: "25px",
        [theme.breakpoints.down('sm')]: {
            width: "15px",
            height: "15px",
        },
    };

    const pageNoText = {
        fontSize: '18px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    };

    return {backgroundContainer, backgroundContainer1, bookmarkImage, titleStyles, titleStyles1, dottedImageStyle, dottedImageStyle1, descriptionStyle,
        pageNoStyle, pageNoCircle, pageNoText
    }
}

const BackgroundPreview = ({item, index, title, normalUserPreview, brand_color, children}) => {
    const theme = useTheme();
    const {backgroundContainer, backgroundContainer1, titleStyles, titleStyles1, dottedImageStyle, dottedImageStyle1, descriptionStyle,
        pageNoStyle, pageNoCircle, pageNoText} =  componentStyles(theme);
    
    const lighterColor = lightenColor(brand_color, 95);
    document.documentElement.style.setProperty("--original-brand-color", brand_color);
    document.documentElement.style.setProperty("--lighter-color", lighterColor);

    const backgroundClass = normalUserPreview ? backgroundContainer1 : backgroundContainer;
    const titleClass = normalUserPreview ? titleStyles1 : titleStyles;
    const dottedImage = normalUserPreview ? dottedImageStyle1 : dottedImageStyle;
    const bottomArcClass = normalUserPreview ? 'preview_bottom_arc1' : 'preview_bottom_arc';

    return (
        <Box boxSizing='content-box' sx={{ ...backgroundClass, background: lighterColor }}>
            <Box className='preview_top_arc' style={{ background: brand_color }}></Box>
            
            {item.type !== 'team' && (
                <Typography sx={{ ...titleClass, color: brand_color }}>
                    {title}
                </Typography>
            )}

            <Box mt={4} mb={4} sx={descriptionStyle}>        
                {children}
            </Box>
            {item.type !== 'cover_page' && (
                <Box component='img' src={'/images/dotted_image.png'} alt="Bookmark" sx={{ ...dottedImage }} />
            )}
            <Box  className={bottomArcClass} style={{ background: brand_color }}></Box>
            <Box sx={pageNoStyle}>
                {item.type !== 'cover_page' && (
                    <Grid container spacing={1} alignItems='center'>
                        <Grid>
                            <Box component='div' sx={{ ...pageNoCircle, background: brand_color }} />
                        </Grid>

                        <Grid>
                            <Typography sx={{ ...pageNoText, color: brand_color }}>
                                {index}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Box>
    );
}

export default BackgroundPreview;