import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {
    DeleteOutlineOutlined as DeleteIcon,
    CancelOutlined as CancelOutlinedIcon,
    PlaylistAddCheckOutlined as PlaylistAddCheckOutlinedIcon,
    SendOutlined as SendOutlinedIcon,
    CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
} from '@mui/icons-material';
import { connect } from 'react-redux';

    const MuiDialogpaper = { scrollbarWidth: 'none !important' };
    const dialogActionImg = { width: '60px', marginBottom: '8px', display: 'flex', justifyContent: 'center' };
    const dailogHeading = { fontSize: '21px', fontWeight: '600', marginBottom: '8px', textAlign: 'center' };
    const yesButtonStyle = { borderRadius: '5px', background: '#318415', color: '#fff', width: '110px', '&:hover': { background: '#318415' } };
    const noButtonStyle = { borderRadius: '5px', background: '#888', color: '#fff', width: '110px', '&:hover': { background: 'rgba(0,0,0,0.3)' } };
    const redButtonStyle = {
        fontSize: '45px', 
        color: 'red'
    };
    const greenButtonStyle = {
        fontSize: '45px', 
        color: 'green'
    };
    const greyButtonStyle = {
        fontSize: '45px', 
        color: '#828c98'
    };


const ShowConfirmModal = (props) => {

    const invoice_account_id = props.selectedAccount?.id;

    // Configuration maps to reduce repeated conditions
    const dialogConfig = {
        delete: { heading: 'Delete Prposal?', message: 'Are you sure you want to delete this proposal?', icon: <DeleteIcon sx={redButtonStyle} /> },
        mark_approved: {heading: 'Mark Approved?', message: 'Are you sure you want to mark this proposal as approved?', icon: <PlaylistAddCheckOutlinedIcon sx={greenButtonStyle}/>},
        mark_rejected: {heading: 'Mark Rejected?', message: 'Are you sure you want to mark this proposal as rejected?', icon: <CancelOutlinedIcon sx={redButtonStyle}/>},
        mark_sent: {heading: 'Mark Sent?', message: 'Are you sure you want to mark this proposal as sent?', icon: <SendOutlinedIcon sx={greyButtonStyle}/>},
        approve: {heading: 'Approve Proposal?', message: 'Are you sure you want to approve this proposal?', icon: <CheckCircleOutlineOutlinedIcon sx={greenButtonStyle}/>},
        reject: {heading: 'Reject Proposal?', message: 'Are you sure you want to reject this proposal?', icon: <CancelOutlinedIcon sx={redButtonStyle}/>},
    };

    const getDialogData = () => {
        // Define logic to choose appropriate dialog data based on props
        if (props.fromProposalListComponent && props.proposalItem?.delete_proposal_clicked) return dialogConfig.delete;
        if (props.fromProposalDetails && props.proposalItem?.mark_approved_clicked) return dialogConfig.mark_approved;
        if (props.fromProposalDetails && props.proposalItem?.mark_rejected_clicked) return dialogConfig.mark_rejected;
        if (props.fromProposalDetails && props.proposalItem?.mark_sent_clicked) return dialogConfig.mark_sent;
        if (props.fromClientProposalPreview && props.proposalItem?.approve_proposal_clicked) return dialogConfig.approve;
        if (props.fromClientProposalPreview && props.proposalItem?.reject_proposal_clicked) return dialogConfig.reject;
    };

    const dialogData = getDialogData();

    const apiRouteStr = () => {

        if(props.proposalItem?.mark_approved_clicked) {
            return 'mark_approved';
        }

        if(props.proposalItem?.mark_rejected_clicked) {
            return 'mark_rejected';
        }

        if(props.proposalItem?.mark_sent_clicked) {
            return 'mark_sent';
        }

        if(props.proposalItem?.approve_proposal_clicked) {
            return 'approve';
        }

        if(props.proposalItem?.reject_proposal_clicked) {
            return 'reject';
        }
    }

    const handleYesClick = () => {
        const apiStr = apiRouteStr();
        const proposal_id = props.proposalItem?.id;
        if (props.fromProposalListComponent) {
            
            if (props.proposalItem?.delete_proposal_clicked) props.callDeleteProposalApi(invoice_account_id, proposal_id);
        }

        if(props.fromProposalDetails) {
            if (props.proposalItem?.mark_approved_clicked || props.proposalItem?.mark_rejected_clicked || props.proposalItem?.mark_sent_clicked) 
                props.callActionApis(apiStr);
        }

        if(props.fromClientProposalPreview) {
            if (props.proposalItem?.approve_proposal_clicked || props.proposalItem?.reject_proposal_clicked) 
                props.onApproveOrRejectClick(apiStr);
        }
    };

    return (
        <Dialog open={props.openConfirmDialog} onClose={props.handleConfirmDialogClose} 
            sx={{ '& .MuiDialog-paper': {
                ...MuiDialogpaper
            } }} maxWidth="sm" fullWidth>
            <DialogContent style={{ padding: '16px' }}>
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid sx={dialogActionImg}>{dialogData?.icon}</Grid>
                    <Grid>
                        <Typography sx={dailogHeading}>{dialogData?.heading}</Typography>
                        <Typography variant="body1">{dialogData?.message}</Typography>
                    </Grid>
                    
                    <Grid style={{ marginTop: '16px' }}>
                        <Grid container spacing={1}>
                            <Grid>
                                <Button onClick={handleYesClick} sx={yesButtonStyle}>
                                    Yes
                                    {props.apiLoading && <CircularProgress size={15} sx={{ color: "white", marginLeft: '8px' }} />}
                                </Button>
                            </Grid>
                            <Grid>
                                <Button onClick={props.handleConfirmDialogClose} sx={noButtonStyle}>No</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
});

export default connect(mapStateToProps)(ShowConfirmModal);
