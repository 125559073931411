import React from 'react';
import BaseSection from './BaseSection';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {showSocialMediaImages} from '../../../util/AppUtil';
import Box from '@mui/material/Box';

const coverPageContainer = {
    display: 'flex',
    flexDirection: 'column',
    gap: '140px'
};

const logoImage = {
    width: '98%',
    objectFit: 'cover',
    objectPosition: 'cover'
};

const companyName = {
    fontSize: '16px',
    fontWeight: 600,
    position: 'relative',
    top: '-1px'
};

const titleStyles = {
    fontSize: '52px',
    fontWeight: 700
};

const preparedLabelText = {
    fontSize: '14px',
    fontWeight: 600
};

const customClass = {
    flexBasis: '9%',
    maxWidth: '9%'
};

const CoverPage = ({item, handleDrawerOpen, onShowOrHidePage, proposalObj}) => {

    
    const {data} = item;
    const {client_company_name, title, client_first_name, client_last_name, show_company_logo, show_company_name, website, social_links} = data;
    const entity_name = proposalObj?.entity.name;
    const entity_logo = proposalObj?.entity.logo_url;

    const onWebsiteUrlClick = () => {
        window.open(website, '_blank');
    }

    return (
        <BaseSection title="Cover Page" 
            item={item}
            handleDrawerOpen={handleDrawerOpen}
            onShowOrHidePage={onShowOrHidePage}>

                <Box component='div' sx={coverPageContainer}>
                    <Grid size={12}>
                        <Grid container alignItems='center'>
                        {show_company_logo && 
                            <Grid size={1} sx={customClass}>
                                    {show_company_logo && <img style={{...logoImage}} src={entity_logo ? entity_logo : '/images/entity_business-and-trade.png'} alt="Company logo" />}
                            </Grid>
                        }

                            <Grid>
                                <Typography sx={companyName} style={{marginLeft:show_company_logo ? '6px' : '0px'}}>
                                    {show_company_name && entity_name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid size={12}>
                        <Typography sx={titleStyles}>
                            {title}
                        </Typography>
                    </Grid>

                    <Grid size={12}>
                        <Grid container alignItems='flex-end' justifyContent='space-between'>
                            <Grid>
                                <Typography sx={preparedLabelText}>
                                    Prepared for:
                                </Typography>

                                {(client_first_name || client_last_name) &&
                                <Typography className='drawer_list_items_descp'>
                                    {`${client_first_name} ${client_last_name}`}
                                </Typography>}

                                <Typography>
                                    {client_company_name}
                                </Typography>
                            </Grid>

                            <Grid style={{position:"relative", top:"-4px"}}>
                                {website &&
                                <Typography 
                                    onClick={onWebsiteUrlClick}
                                    className='drawer_link_style'>
                                    {website}
                                </Typography>}
                                <Grid container alignItems='center' justifyContent='flex-end' spacing={1}>
                                    
                                        {
                                            social_links && social_links.length > 0 &&
                                            social_links.map((item) => {
                                                return <Grid key={item.type}>
                                                            <Link href={item.link} target='blank'>
                                                                <img src={(showSocialMediaImages(item.type))} width={'24px'} height={'24px'}/>
                                                            </Link>
                                                        </Grid>
                                            })
                                        }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

        </BaseSection>
    );
};

export default CoverPage;
