import React from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import {statusNameAndColor} from '../util/AppUtil';

const TopStatus = ({status, fromInvoicePreview, fromDocumentPreview, showSendEmail}) => {
    const {name, color, background} = statusNameAndColor(status);

    const showBoxShadow = () => {
        if(fromDocumentPreview) {
            
        } else {
            if(fromInvoicePreview) {
                return 'none';
            } else {
                return '0px 0px 25px 0px #00000026';
            }
        }

    };

    const setWidthForStatus = () => {
        if(fromDocumentPreview) {
            const canvasElement = document.querySelectorAll('.react-pdf__Page__canvas')[0];
            const pageRect = canvasElement?.getBoundingClientRect();
            return pageRect?.width
        } else {
            return '100%'
        }
    }

    return (
        <Grid size={12} sx={{
            width: setWidthForStatus(),
            background: background,
            display:'flex',
            justifyContent:'center',
            alignItems:'center', 
            marginBottom: fromInvoicePreview ? '10px' : '0px',
            borderTopLeftRadius:'5px', 
            borderTopRightRadius: '5px',
            boxShadow: showBoxShadow(),
            padding:'2px 0px'
        }}>
            <div>
                <Grid container alignItems='center'>
                    <span className={`circle`} style={{background:color}}></span>
                    <Typography variant='body2' sx={{textAlign:'center', color: color}}>
                        {name}
                    </Typography>
                </Grid>
            </div>
        </Grid>
    );
}

export default TopStatus;