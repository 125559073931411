import React from 'react';
import MuiAlert from '@mui/material/Alert';
import {connect} from 'react-redux';
import {verifyInvoiceAccountEmailApi} from '../services/authService';
import { AppRoutes, consoleToLog } from '../util/AppUtil';
import {useSnackbar} from 'notistack';
import { setSelectedDrawerState, SelectedDrawerState } from '../actions/drawer';
import {  onUpgradeNowClick } from '../util/AppUtil';
import { setGoToBilling } from '../actions/invoiceAccount';
import { isInvoiceAccountOnFreePlan } from '../util/AppUtil';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

    // mainContent : {
    //     backgroundColor: theme.palette.primary.light, 
    //     transition: ".5s", 
    //     flexGrow: 1,
    //     },
    
const MuiAlertroot = {
        borderRadius:'0px',
}
    
const MuiAlertfilledError = {
        color: '#c75877',
        fontWeight: 500,
        backgroundColor: '#fdd4cd',
}
    
const MuiAlertPlanUpgrade = {
        color: '#000',
        fontWeight: 500,
        backgroundColor: '#B0DAFF',
        textAlign:'center',
        padding:0,
        display:'flex',
        justifyContent:'center',
}

function Alert(props) {
    return <MuiAlert elevation={0} variant="filled" {...props}  />;
}


const SnackbarComponent = (props) => {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();

    const entityNames = props.unVerifiedEntityEmails?.map((item) => item.name).join(', ');
    const invoiceAccountPlan = props.selectedAccount?.plan;
    const entityLimitExceeded = props.selectedAccount?.entity_limit_exceeded;

    // const onEmailVerifyClick = () => {
    //     const invoice_account_id = props.selectedAccount?.id;

    //     verifyInvoiceAccountEmailApi(invoice_account_id)
    //     .then((response) => {
    //         const res = response.data;
    //         consoleToLog("Response verifyInvoiceAccountEmailApi: ", res)
    //         enqueueSnackbar(res.message, {
    //             variant:'success'
    //         });
    //       //trackGAEvent(props.selectedOrganization.organization.name, 'Verify Email From Settings', `${props.auth.user.firstname} ${props.auth.user.lastname} clicked email verification link from settings`);
    //     })
    //     .catch((e) => {
    //         consoleToLog("verifyInvoiceAccountEmailApi error", e);
    
    //         if (e && e.response.data && e.response.data.message) {
    //             enqueueSnackbar(e.response.data.message, {
    //                 variant:'success'
    //             });
    //             return false;
    //         }
    //     });
    // }

    const goToSettingsComponent = () => {
        navigate(AppRoutes.SETTINGS, {state: {verifyEmail: true}});
        props.setSelectedDrawerState(SelectedDrawerState.SHOW_SETTINGS);
    }
    
    const onVerifyEmailClick = () => {
        goToSettingsComponent();
    }

    return (
            <div>

                {props.fromBaseComponent && isInvoiceAccountOnFreePlan(invoiceAccountPlan) &&
                <Alert severity="info" 
                        icon={false}
                        sx={{
                            ...MuiAlertroot,
                            ...MuiAlertPlanUpgrade
                    }}>You are on free plan, please upgrade to use all invoicing features.
                        {<a onClick={() => onUpgradeNowClick(props, navigate)} className='verify_link'> Upgrade Now </a> }
                </Alert>}

                {props.fromBaseComponent && !isInvoiceAccountOnFreePlan(invoiceAccountPlan) && entityLimitExceeded &&
                <Alert severity="info" 
                        icon={false}
                        sx={{
                            ...MuiAlertroot,
                            ...MuiAlertPlanUpgrade
                    }}>You account have limit of {props.selectedAccount?.entity_count} entities. Do you want more entities?
                        {<a onClick={() => onUpgradeNowClick(props, navigate)} className='verify_link'> Purchase Now </a> }
                </Alert>}
                
                {!props.fromBaseComponent &&
                <Alert severity="error" 
                        sx={{
                            ...MuiAlertroot,
                            ...MuiAlertPlanUpgrade
                    }}>Payment Reminders for entity <b>{entityNames}</b> is disabled. 
                        Please verify email in order to enable the reminders.
                        {<a onClick={onVerifyEmailClick} className='verify_link'> Verify </a> }
                </Alert>}

            </div>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    setGoToBilling: (goToBilling) => dispatch(setGoToBilling(goToBilling))
})

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarComponent);
