import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Popover from '@mui/material/Popover';
import Switch from '@mui/material/Switch';
import { consoleToLog } from '../../../util/AppUtil';
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';
import { getItemListApi } from '../../../services/authService';
import TableItemRow from '../TableItemRow';
import AutocompleteCurrencies from '../../AutocompleteCurrencies';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BaseDrawer from './BaseDrawer';
import {useIsMount} from '../../useIsMount';
import {updateQuantitySettingsApi, deleteProposalItemsApi} from '../../../services/proposalService';
import Box from '@mui/material/Box';

const switchContainer = {
    padding: '6px 8px',
    background: '#E9ECEF',
};

const textFieldContainer = {
    padding: '8px',
};

const AntSwitch = (props) => {
    return (
        <Switch
            {...props}
            sx={{
                width: 28,
                height: 16,
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                '& .MuiSwitch-switchBase': {
                    padding: 2,
                    color: (theme) => theme.palette.grey[500],
                    '&.Mui-checked': {
                        transform: 'translateX(12px)',
                        color: (theme) => theme.palette.common.white,
                        '& + .MuiSwitch-track': {
                            opacity: 1,
                            backgroundColor: '#007bfb',
                            borderColor: '#007bfb',
                        },
                    },
                },
                '& .MuiSwitch-thumb': {
                    width: 12,
                    height: 12,
                    boxShadow: 'none',
                },
                '& .MuiSwitch-track': {
                    border: (theme) => `1px solid ${theme.palette.grey[500]}`,
                    borderRadius: 8,
                    opacity: 1,
                    backgroundColor: (theme) => theme.palette.common.white,
                },
            }}
        />
    );
};

const BillingsDrawer = ({ itemObj, drawerOpen, handleDrawerClose, apiLoading, setDirtyFlag, proposalObj, setProposalObj, 
    callUpdateSectionApi, editProposalArr, setEditProposalArr, setItemObj, ...props }) => {

    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();

    const {selectedAccount} = props;
    const {data} = itemObj;
    const {quantity_terminology, show_quantity, title, items} = data;

    const [billingTitle, setBillingtitle] = useState(title);
    const [quantityTerminology, setQuantityTerminology] = useState(quantity_terminology);
    const [showQuantity, setShowQuantity] = useState(show_quantity);
    const [quantityApiLoading, setQuantityApiLoading] = useState(false);
    
    const [billingItems, setBillingItems] = useState([...items]);
    const [editingIndex, setEditingIndex] = useState(billingItems && billingItems.length > 1 ? null : 0);
    const [errorType, setErrorType] = useState(0);
    const [invoicesItems, setInvoiceItems] = useState([]);

    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        getInvoiceItems();
    }, []);

    useEffect(() => {
        if(!isMount) {
            if(!showQuantity) {
                const updatedArray = billingItems.map((item) => {
                    return {
                        ...item,
                        quantity: parseFloat(1),
                        amount: parseFloat(item.price)
                    }
                })
                setBillingItems([...updatedArray]);
            }

        }
    }, [showQuantity]);


    const currenciesObj = selectedAccount?.currencies;
    const invoice_account_id = selectedAccount?.id;
    const open = Boolean(anchorEl);
    const id = open ? 'settings-popover' : undefined;
    const proposalCurrency = proposalObj.currency;


    const getInvoiceItems = async() => {
        try {
            const response = await getItemListApi(invoice_account_id);
            const res = response.data;
            consoleToLog('Response getItemListApi: ', res);
            setInvoiceItems(res);
        } catch(e) {
            consoleToLog('Error getItemListApi', e.response);
            if(e.response.data && e.response.data.message) {
                return;
            }
        }
    }


    const handleSave = (index, editedItem) => {
        const newItems = [...billingItems];
        newItems[index] = editedItem;
        setBillingItems(newItems);
        setEditingIndex(null);
        setDirtyFlag(true);
    };

    const handleEdit = (index) => {
        setEditingIndex(index);
    };

    const handleDelete = (index) => {
        const newItems = billingItems.filter((_, i) => i !== index);
        billingItems[index].id && deleteProposalItem(billingItems[index].id);
        setBillingItems(newItems);
        setDirtyFlag(true);
    };

    const validationForTableRow = () => {
        if (billingItems[editingIndex]?.name === '') {
            setErrorType(3);
            return;
        } else if (billingItems[editingIndex]?.quantity === '') {
            setErrorType(4);
            return;
        } else if (billingItems[editingIndex]?.price === '') {
            setErrorType(5);
            return;
        } else if (billingItems[editingIndex]?.amount === '') {
            setErrorType(6);
            return;
        } else if (billingItems[editingIndex]?.periodicity === '') {
            setErrorType(7);
            return;
        } else {
            setErrorType(0);
            return true;
        }
    };

    const addBillingItem = () => {
        if(validationForTableRow()) {
            setBillingItems([...billingItems, { name: '', quantity: 1, price: '', amount:'', periodicity: 'none' }]);
            setEditingIndex(billingItems.length);
            setDirtyFlag(true);
        }
    };

    const handleSettingsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const showQuantitySettingsPopover = () => {
        return (<Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div>
                    <Typography component='div' sx={switchContainer}>
                        <Grid container alignItems='center' justifyContent='space-between'>
                            <Grid>
                                <Typography>Show Quantity</Typography>
                            </Grid>
                            
                            <Grid>
                                <AntSwitch
                                    checked={showQuantity}
                                    onChange={() => setShowQuantity(!showQuantity)}
                                    name='quantity unit'
                                    color='primary'
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </Grid>
                        </Grid>
                    </Typography>
                    <Box component='div' sx={textFieldContainer}>
                        <TextField
                            variant='outlined'
                            margin='dense'
                            value={quantityTerminology}
                            onChange={(e) => setQuantityTerminology(e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Box component='div' sx={textFieldContainer}>
                        <Button variant='contained' color='secondary'
                            onClick={updateQuantitySettings}>
                            {quantityApiLoading && <CircularProgress size={15} style={{marginRight:'8px', color:'#fff' }}/>} Save
                        </Button>
                    </Box>
                </div>
            </Popover>
        )
    }

    const updateQuantitySettings = async() => {
        setQuantityApiLoading(true);
        try {
            const response = await updateQuantitySettingsApi(invoice_account_id, proposalObj?.id, quantityTerminology, showQuantity);
            const res = response.data;
            consoleToLog("Response updateQuantitySettingsApi", res);

            const section_obj = {
                ...itemObj,
                data: {
                    quantity_terminology: quantityTerminology,
                    show_quantity: showQuantity,
                    title: billingTitle,
                    items: billingItems
                }
            }
            setItemObj({...section_obj});

            const updatedArray = editProposalArr.map((proposal) => {
                if(proposal.id === section_obj.id) {
                    proposal = section_obj;
                }
                return proposal;
            });
            setEditProposalArr([...updatedArray]);

            let obj ={
                ...proposalObj,
                quantity_terminology: quantityTerminology,
                quantity_enable: showQuantity,
                sections: updatedArray
            }
            setProposalObj({...obj});

            enqueueSnackbar("Proposal quantity settings updated", {variant: 'success'});

            setQuantityApiLoading(false);
        } catch (e) {
            consoleToLog("Error updateQuantitySettingsApi", e.response);
            setQuantityApiLoading(false);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }

    const deleteProposalItem = async(proposal_item_id) => {
        try {
            const response = await deleteProposalItemsApi(invoice_account_id, proposalObj?.id, proposal_item_id);
            const res = response.data;
            consoleToLog("Response deleteProposalItemsApi", res);
        } catch (e) {
            consoleToLog("Error deleteProposalItemsApi", e.response);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant:'error'});
                return;
            }
        }
    }


    const setUpdatedItemObj = () => {
        const updated_obj = {
            ...itemObj,
            section: {title: billingTitle},
            currency_id: selectedCurrency?.id,
            proposal_items: billingItems,
        }

        return updated_obj;
    }

    const onSaveClick = () => {
        if(billingItems.length === 0) {
            enqueueSnackbar('Please add atleast one item to continue', {variant:'error'});
            return;
        } else {
            if(validationForTableRow()) {
                if(editingIndex) {
                    enqueueSnackbar('Please save you changes to continue', {variant:'error'});
                    return;
                } else {
                    callUpdateSectionApi(itemObj, setUpdatedItemObj());
                }
            }
        }
    }

    const onCancelClick = () => {
        const updatedArray = [...billingItems];
        if(!updatedArray[editingIndex]?.id || !updatedArray[editingIndex]?.item_id) {
            setBillingItems([...updatedArray].filter((_, i) => i !== editingIndex));
        }
        setEditingIndex(null);
    }

    const onDrawerClose = () => {
        handleDrawerClose(setUpdatedItemObj());
    }

    return (
        <BaseDrawer open={drawerOpen} 
            onClose={onDrawerClose}
            title='Billing Schedule'
        >
            <div className='drawer_content_container'>

                <Grid size={12}>
                    <Typography className={'drawer_label_style'}>
                        Page Title
                    </Typography>

                    <TextField 
                        className={'drawer_textfield_margintop'}
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        value={billingTitle}
                        onChange={(e) => setBillingtitle(e.target.value)}
                        size='small'
                    />
                </Grid>

                <Grid size={12}>

                    <Typography className={'drawer_label_style'}>
                        Currency
                    </Typography>

                    <AutocompleteCurrencies selectedCurrency={selectedCurrency}
                        setSelectedCurrency={setSelectedCurrency}
                        proposalCurrency={proposalCurrency}
                        fromBillingsDrawer={true}
                        setDirtyFlag={setDirtyFlag}
                        />
                </Grid>

                <Grid size={12}>
                    <TableContainer component={Paper} variant='outlined' >
                        <Table>
                            <TableHead style={{padding:0, background:'#f4f4f4'}}>
                                <TableRow>
                                    <TableCell className="item_name_width table_cell_style1 add_item_table_heading">Item name</TableCell>
                                    <TableCell className="quantity_width table_cell_style1 add_item_table_heading">
                                        <span className={!show_quantity && 'hide_page_style'}>{`${quantityTerminology}`}</span>
                                        <img src='/images/settings.svg' className='add_item_tabel_settings_icon settings_icon_color' onClick={handleSettingsClick}/>
                                        {/* <SettingsOutlinedIcon className='add_item_tabel_settings_icon' onClick={handleSettingsClick} /> */}
                                    </TableCell>
                                    <TableCell className="price_width table_cell_style1 add_item_table_heading">Price</TableCell>
                                    <TableCell className="price_width table_cell_style1 add_item_table_heading">Amount</TableCell>
                                    <TableCell className="periodicity_width table_cell_style1 add_item_table_heading">Recurring</TableCell>
                                    <TableCell className="action_items table_cell_style1"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {billingItems.map((item, index) => (
                                    <TableItemRow
                                        key={index}
                                        item={item}
                                        index={index}
                                        onSave={handleSave}
                                        onEdit={handleEdit}
                                        onDelete={handleDelete}
                                        isEditing={editingIndex === index}
                                        invoiceItems={invoicesItems}
                                        currenciesObj={currenciesObj}
                                        selectedCurrency={selectedCurrency}
                                        errorType={errorType}
                                        setErrorType={setErrorType}
                                        showQuantity={showQuantity}
                                        fromBillingsDrawer={true}
                                        show_quantity={show_quantity}
                                        setEditingIndex={setEditingIndex}
                                        onCancelClick={onCancelClick}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Grid container justifyContent='flex-end' style={{marginTop:'16px'}}>
                        <Button variant='outlined'
                            onClick={addBillingItem}
                            className='btn_width'
                            style={{marginLeft:'auto'}}>
                            <AddCircleOutlineIcon fontSize='small' style={{marginRight:'4px'}}/> 
                            Add Item
                        </Button>
                    </Grid>
                </Grid>
                
                {showQuantitySettingsPopover()}
            </div>

            <div className={'drawer_footer'}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid>
                        <Button className={`next_btn_style drawer_btn_width`}
                            onClick={onSaveClick}>
                        {apiLoading && <CircularProgress size={15} className='drawer_loading_margin'/>} Save
                        </Button>
                    </Grid>

                    <Grid>
                        <Button variant='outlined' color='primary'
                            onClick={onDrawerClose}
                            className={'drawer_btn_width'}>
                            Cancel 
                        </Button>
                    </Grid>
                </Grid>
            </div>

    </BaseDrawer>
    );
}

export const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
})

export default connect(mapStateToProps)(BillingsDrawer);