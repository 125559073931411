import React from 'react';
import BaseSection from './BaseSection';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { ReadOnlyEditor } from './BaseEditor';

const dividerStyle = {
        background:'#555', 
        width:'2px', 
        height:'90px',
        position:'relative',
        top:'8px'
    }

const Reviews = ({item, handleDrawerOpen, onShowOrHidePage}) => {
    const {data} = item;
    const {title, description, reviews} = data;

    return (
        <BaseSection title="Reviews" item={item}
            handleDrawerOpen={handleDrawerOpen}
            onShowOrHidePage={onShowOrHidePage}>

            <Grid size={12} className='edit_section_container'>

                <Grid size={12}>
                    <Typography className='edit_section_heading' 
                        dangerouslySetInnerHTML={{__html: title}} />
                </Grid>

                <Grid size={12}>
                    <ReadOnlyEditor editorValue={description}/>
                    
                </Grid>

                <Grid size={12}>
                    <div className='edit_section_item_container'>
                        {
                            reviews && reviews.length > 0 &&
                            reviews.map((item) => {
                                const {reviewed_by, review_company_name} = item;
                                const reviewtext = `${item.review_text}`;
                                return <Grid container alignItems='center' style={{borderLeft: '2px solid #555'}}>
                                            <Grid size={1} style={{flexBasis:'3%', maxWidth:'3%'}}>
                                            </Grid>
                                            <Grid size={11}  style={{flexBasis:'96%', maxWidth:'96%', position:'relative', top:'-10px'}}>
                                                <ReadOnlyEditor editorValue={reviewtext ? reviewtext : ' '}/>
                                            
                                                <Typography>
                                                    <b><span style={{position:'relative', top:'-4px'}}>____</span> {`${reviewed_by}, ${review_company_name}`}</b>
                                                </Typography>


                                            </Grid>
                                            
                                        </Grid>
                            })
                        }
                    </div>
                </Grid>
                
            </Grid>

        </BaseSection>
    );
};

export default Reviews;
