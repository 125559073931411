import React, {useEffect, useState} from 'react';
import {getCurrencyObjApi} from '../services/authService';
import { consoleToLog, extractFlagCode } from '../util/AppUtil';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Flags from "country-flag-icons/react/3x2";
import {connect} from 'react-redux';
import { useSnackbar } from 'notistack';

const itemOptionStyle = {
    width: "100%", 
    height:'100%',
    color:'#777f8e',
    margin:'5px 0px'
}
    

const AutocompleteCurrencies = ({selectedCurrency, setSelectedCurrency, fromBillingsDrawer, proposalCurrency, setDirtyFlag, ...props}) => {
    const {enqueueSnackbar} = useSnackbar();

    const {selectedAccount} = props;

    const [currencies, setCurrencies] = useState([]);
    const [inputText, setInputText] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        getCurrencies(fromBillingsDrawer ? proposalCurrency?.name : selectedAccount.currencies?.name, true);
    }, [])

    const invoice_account_id = selectedAccount.id;
    const currenciesObj = fromBillingsDrawer ? proposalCurrency : selectedAccount.currencies;


    const getCurrencies = (query, setCurrencyObj) => {
        getCurrencyObjApi(invoice_account_id, query)
        .then((respose) => {
            const res = respose.data;
            consoleToLog('Response getCurrencyObjApi: ', res);

            setCurrencies(res);
            setCurrencyObj && setSelectedCurrency(currenciesObj);
        })
        .catch((e) => {
            consoleToLog('Error getCurrencyObjApi', e);
            if(e.response.data && e.response.data.message) {
                enqueueSnackbar(e.response.data.message, {variant: 'error'});
                return;
            }
        })
    }

    const renderCurrencyOptions = (props, option) => {
        const flagCode =  extractFlagCode(option.flag);
        const Flag = Flags[flagCode];
        return (
            <Grid size={12} sx={{...itemOptionStyle}} {...props}>
                <Grid container alignItems='center'> 
                    <Grid>
                        <Flag width='28px' height='22px'/>
                    </Grid>
                    <Grid>
                        <Typography variant='body1' sx={{marginLeft:'8px'}}>
                            {`${option.name}`}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            );
    }

    const onSelectedCurrencyChange = (e, newValue) => {
        setSelectedCurrency(newValue);
        fromBillingsDrawer && setDirtyFlag(true);
        //console.log('selected currency', newValue);
    }

    const showFlagStartAdornment = () => {
        const flagCode =  extractFlagCode(selectedCurrency?.flag);
        const Flag = Flags[flagCode];

        if (!Flag) {
            //console.error(`Flag component for code ${selectedCurrency?.code} not found.`);
            return null; // Return null if the Flag is undefined
        }
    

        return ( 
                <InputAdornment position="start">
                    <Grid container alignItems='center'>
                        <Grid style={{paddingLeft:'6px', position:"relative", top:'3px'}}>
                            <Flag width='25px'/>
                        </Grid>
                    </Grid>
                </InputAdornment>
            )
    }

    const handleInputChangeForCurrency = (e, newValue) => {
        const value = e?.target.value;
        value && getCurrencies(newValue, false);

        setInputText(newValue);
            if (!newValue) {
                setShowDropdown(false);
            }
    }

    return (
        <Autocomplete
            id="combo-box-demo"
            options={currencies}
            open={showDropdown}
            onOpen={() =>  {
                if(inputText) {
                    setShowDropdown(true)
                }
            }}
            fullWidth
            onClose={() => setShowDropdown(false)}
            value={selectedCurrency}
            getOptionLabel={(option) => option.name || ''}
            renderOption={(props, option) => renderCurrencyOptions(props, option)}
            onChange={(e, newValue) => onSelectedCurrencyChange(e, newValue)}
            onInputChange = {(e, newValue) => handleInputChangeForCurrency(e, newValue)}
            renderInput={params => (
                <TextField {...params} variant="outlined" 
                    placeholder='Search Currency' 
                    margin='dense'
                    size='small'
                
                slotProps={{
                    input: {
                        ...params.InputProps,
                        startAdornment: (
                            <>
                            {showFlagStartAdornment()}
                            {params.InputProps.startAdornment}
                            </>
                        ),
                    }
                }}
                    />
            )}
            sx={{width:'50%', '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall' : {padding: '5px'}}}
            size='small'
            
        />
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
});

export default connect(mapStateToProps)(AutocompleteCurrencies);