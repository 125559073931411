import React,{useState} from 'react';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';


const sectionContainer = {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 0px 20px 0px #0000000D',
};

const header = {
    backgroundColor: '#f0f0f0',
    padding: '8px 0px 8px 16px',
    borderBottom: `1px solid rgba(0,0,0,0.1)`,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
};

const titleStyles = {
    flexGrow: 1,
    fontSize: '18px',
    fontWeight: 600,
};

// const customColumn2 = {
//     flexBasis: '65%',
//     maxWidth: '65%',
// };

const BaseSection = ({ title, children, item, handleDrawerOpen, onShowOrHidePage, callDeleteCustomSectionApi }) => {

    const [startOnNewPage, setStartOnNewPage] = useState(true);

    const handleChange = (event) => {
        setStartOnNewPage(event.target.checked);
    };

    return (
        <Paper variant='outlined' className={`${!item.show_page ? 'hide_page_style' : ''}`} sx={sectionContainer}>
            <Grid container alignItems="center" justifyContent='space-between' sx={header}>
                <Grid>
                    <Typography sx={titleStyles}>{title}</Typography>
                </Grid>

                <Grid>
                    <Grid container alignItems='center' spacing={3}>
                        <Grid size={item.type === 'custom' ? 3 : 4}>
                            {item.show_page
                            ?
                            <Tooltip title='Hide Page' arrow>
                                <IconButton 
                                    onClick={() => onShowOrHidePage(item, false)}
                                    className='edit_header_icon_btn'>
                                    <img src={'/images/visibility_off.svg'} />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title='Show Page' arrow>
                                <IconButton 
                                    onClick={() => onShowOrHidePage(item, true)}
                                    className='edit_header_icon_btn'>
                                    <img src={'/images/visibility.svg'} />
                                </IconButton>
                            </Tooltip>
                            }
                        </Grid>

                        <Grid size={item.type === 'custom' ? 3 : 4}>
                            <IconButton 
                                onClick={() => handleDrawerOpen(item)}
                                className='edit_header_icon_btn'>
                                <img src={'/images/stylus.svg'} />
                            </IconButton>
                        </Grid>

                        {item.type === 'custom' &&
                        <Grid size={3}>
                            <IconButton 
                                onClick={() => callDeleteCustomSectionApi(item.id)}
                                className='edit_header_icon_btn'>
                                <img src={'/images/drawer_delete.svg'} />
                            </IconButton>
                        </Grid>}

                        {/* {item?.type !== 'cover_page' &&
                        <Grid item md={7} className={classes.customColumn2}>
                            
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={startOnNewPage}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="secondary"
                                    />
                                    }
                                    label="Start on new page"
                                />
                        </Grid>
                        } */}
                    </Grid>
                </Grid>
            </Grid>
            <div style={{
                padding: '24px'
            }}>
                {children}
            </div>
        </Paper>
    );
};

export default BaseSection;
