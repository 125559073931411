import React, {memo} from 'react';
import { useDrag } from "react-dnd";
import { DroppedItemTypes } from "../../util/AppUtil";
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import Tooltip from '@mui/material/Tooltip';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';

// Draggable Text Component
export const DraggableText = () => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: DroppedItemTypes.TEXT,
        item: { type: DroppedItemTypes.TEXT },
        collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    }));

    return (
        <div
            ref={drag}
            className="drag_toolbox_item"
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >   
            <Tooltip title={'Add Text'} arrow>
                <TextFieldsOutlinedIcon className='drag_toolbox_item_icon'/>
            </Tooltip>
        </div>
    );
};


// // Draggable Signature Component
export const DraggableSignature = () => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: DroppedItemTypes.SIGNATURE,
        item: { type: DroppedItemTypes.SIGNATURE },
        collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    }));

    return (
        <div
        ref={drag}
        className="drag_toolbox_item"
        style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            <Tooltip title={'Add Signature'} arrow>
                <img src={'/images/digital_signature.png'} className='drag_toolbox_item_image'/>
            </Tooltip>
        </div>
    );
}

export const DraggableDateSelector = () => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: DroppedItemTypes.DATE,
        item: { type: DroppedItemTypes.DATE },
        collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    }));

    return (
        <div
        ref={drag}
        className="drag_toolbox_item"
        style={{ opacity: isDragging ? 0.5 : 1, marginTop:'10px' }}
        >
            <Tooltip title={'Add Date'} arrow>
                <DateRangeOutlinedIcon className='drag_toolbox_item_icon'/>
            </Tooltip>
        </div>
    );
}