import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import CreateUpdateBillDetailComponent from "../CreateUpdateBillDetailComponent";
import {
    PdfLoader,
    PdfHighlighter,
    Tip,
    Highlight,
    Popup,
    AreaHighlight,
} from "react-pdf-highlighter";
import moment from "moment";
import { useIsMount } from "../useIsMount";
import { useSnackbar } from "notistack";
import { getConvertedBoundingBox } from "../../util/pdfUtil";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { consoleToLog, getUrlAccordingToSelectedComponent } from "../../util/AppUtil";
import { getBillDetailsApi } from "../../services/authService";
import { connect } from "react-redux";
import PreviewBillComponent from "../PreviewBillComponent";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import CreateUpdateExpenseBillDetailComponent from "../CreateUpdateExpenseBillDetailComponent";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";

const zoomContainerStyle = {
    width:'100px',
    position:"absolute", 
    bottom:12, 
    left:12, 
    zIndex:999, 
    padding:'8px',
    
};

const zoomContainerStyle1 = {
    width:'100px',
    position:"absolute", 
    bottom:18, 
    left:12, 
    zIndex:999, 
    padding:'8px',
    
};

const backIconContainerStyle = {
    position:"absolute", 
    top:12, 
    left: 12, 
    zIndex:999,
    background:'#fff'
};

const leftComponent = {
    height: "100vh",
    position: "relative",
    overflowY: "auto",
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BillDetailModalComponent=(props)=>{
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [billObj, setBillObj]=useState({});
    const [selectedEntity, setSelectedEntity]=useState(props.selectedEntity);

    const isMount = useIsMount();
    //const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    
    const [pdfUrl, setPdfUrl] = useState("");
    const [pageDimensions, setPageDimensions] = useState([]);
    //const [docArr, setDocArr] = useState([]);
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [ExpenseDocuments, setExpenseDocumentsArr] = useState([]);
    const [zoomVal, setZoomVal] = useState(0.9);
    const [count, setCount] = useState(0);
    const [numPages, setNumPages] = useState();

    const pdfHighlighterRef = useRef(null);
    const highlightArr = useRef([]);
    

    const urlParts = window.location.pathname?.split('/');
    const pathname = urlParts[1];
    //const showEstimateScreen = pathname === 'estimate' ? true : false;
    const slug = urlParts[2];

    //let type = urlParts.length > 3 ? urlParts[3] : '';
    let type;
    //if bill is viewed from unclaimed bill component
    //then irrespective of in url type is edit or preview 
    //always have type=''preview, as we want to show bill only
    if(props.isFromUnclaimedBill){
        type='preview';
    }else{
        type = urlParts.length > 3 ? urlParts[3] : '';
    }

    const onDocumentLoadSuccess = (pdfObj) => {
        const {numPages} = pdfObj
        setNumPages(numPages);
    }
    const HighlightPopup = ({
        comment
    }) =>
        comment.text ? (
            <div className="Highlight__popup">
                {comment.emoji} {comment.text}
            </div>
        ) : null;
    

    const [isDialogOpen, setIsDialogOpen] = useState(props.openBillDetailDialog); // Local state for dialog

    useEffect(() => {
        console.log("UseEffect open dialog");
        // Update local dialog state based on prop changes
        setIsDialogOpen(props.openBillDetailDialog);
    }, [props.openBillDetailDialog]);


    const handleClose = () => {
        // Close dialog and call parent handler
        setIsDialogOpen(false);
        if(!props.isFromUnclaimedBill){
            navigate(getUrlAccordingToSelectedComponent(props.drawerState));
        }
        props.handleBillDetailClickDialogClose(props.editPreview);
    };

    useEffect(() => {
        //if from bill list edit clicked 
        if(slug && (type === 'edit' || type === 'preview')) {
            console.log("In Edit/preview");
            getBillDetails();
        }
        else if(props.billObj){
            console.log("In Create", billObj);
            console.log("Left side***bill obj", props.billObj);
            console.log("Left side****In class", props.billObj?.purchase_invoice?.ocr_json?.ExpenseDocuments);
            setBillObj(props.billObj);
            setExpenseDocumentsArr(props.billObj?.purchase_invoice?.ocr_json?.ExpenseDocuments || [])
            setPdfUrl(props.billObj?.purchase_invoice?.attachment_url);
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if(!isMount){
            console.log("UseEffect previewBillObj", props.previewBillObj);
            if(props.previewBillObj && Object.keys(props.previewBillObj).length>0){
                setBillObj(props.previewBillObj);
            }
        }
    }, [props.previewBillObj]);


    const getBillDetails = async () => {
        try {
            const response = await getBillDetailsApi(props.selectedAccount.id, slug, props.isFromUnclaimedBill, props.billId);
            const res = response.data;
            consoleToLog('Response getBillDetailsApi22: ', res);
            setLoading(false);
            if (type === 'preview') {
                let billObjLocal
                if(props.isFromUnclaimedBill){
                    billObjLocal={purchase_invoice: res}
                }
                else{
                    billObjLocal={purchase_invoice: res};
                }
                consoleToLog("***Preview Left component got bill obj", billObjLocal);
                //setExpenseDocumentsArr(billObjLocal?.ocr_json?.ExpenseDocuments || [])
                setBillObj(billObjLocal);
                setPdfUrl(billObjLocal?.purchase_invoice?.attachment_url);
            } else {
                let billObjLocal;
                if(props.isFromUnclaimedBill){
                    billObjLocal={purchase_invoice: res}
                }
                else{
                    billObjLocal={purchase_invoice: res};
                }
                consoleToLog("***Left component got bill obj", billObjLocal);
                setExpenseDocumentsArr(billObjLocal?.purchase_invoice?.ocr_json?.ExpenseDocuments || [])
                setBillObj(billObjLocal);
                setPdfUrl(billObjLocal?.purchase_invoice?.attachment_url);
            }

        } catch (error) {
            consoleToLog("Error getBillDetailsApi22", error);
        }
    }



    const isTypeAlreadtExistInArray = (localSF, type) => {
        return localSF.some( sf => sf['Type'].Text === type )
    }

    const onZoomChange = (val) => {
        if(val === 'in') {
            //let zoomIn = zoomVal + 0.3
            //setZoomVal(zoomIn);
            setZoomVal((zoomIn) => Math.min(zoomIn + 0.2, 3.0))
            setCount(count => count + 1);
            //setPdfUrl(billObj?.purchase_invoice?.attachment_url);
        } else {
            Math.max(zoomVal - 0.2, 0.5)
            setZoomVal((zoomIn) =>  Math.max(zoomVal - 0.2, 0.5))
            // let zoomOut = zoomVal - 0.3;
            // if(zoomOut < 0.6) {
            //     setZoomVal(0.6);
            //     setCount(count => count + 1);
            // } else {
            //     setZoomVal(zoomOut);
            //     setCount(count => count + 1);
            // }
            // if(zoomVal !== 0.6) {
            //     //setPdfUrl(billObj?.purchase_invoice?.attachment_url);
            // }
        }
    }
    const hasPdfExtension=(url)=> {
        return /\.pdf(?:$|\?)/i.test(url);
    }

    return (
        <Dialog
            fullScreen
            open={isDialogOpen}
            onClose={handleClose}
            //TransitionComponent={Transition}
            slots={{
                transition: Transition 
            }}
            sx={props.isFromUnclaimedBill ? {zIndex:'100', margin: '10px'} : {zIndex:'100'}}
            /* onClose={()=>{
                navigate(getUrlAccordingToSelectedComponent(props.drawerState));
                props.handleBillDetailClickDialogClose(props.editPreview);
            }} */>

            <div style={{ display: "flex", height:'100vh', overflow: "hidden", background:'#fff' }}>
                <Box component='div' style={{ width: (type === 'preview')? "45vw" : "50vw"}}
                    sx={leftComponent}
                >   
                    <Box component='div' sx={backIconContainerStyle}>
                        <ArrowLeftIcon className={'preview_invoice_back_btn'}
                            onClick={handleClose}
                        />
                    </Box>
                    {
                    <Grid container
                        sx={{...(props.editPreview ? zoomContainerStyle1 : zoomContainerStyle)}}>
                        <Grid>
                            <Button variant='contained' color='secondary' 
                                sx={{minWidth:"22px", padding:'4px'}}
                                onClick={() => onZoomChange('in')}>
                                <Tooltip title='Zoom In' arrow>
                                    <AddIcon fontSize='small'/>
                                </Tooltip>
                            </Button>
                        </Grid>

                        <Grid>
                            <Button variant='contained' color='secondary'  
                                sx={{minWidth:"22px", padding:'4px', marginLeft:'8px'}}
                                onClick={() => onZoomChange('out')}>
                                <Tooltip title='Zoom Out' arrow>
                                    <RemoveIcon fontSize='small'/>
                                </Tooltip>
                            </Button>
                        </Grid>
                    </Grid>
                    }
                    {pdfUrl ?
                    !hasPdfExtension(pdfUrl)? 
                    <img src={pdfUrl} alt="description" 
                        style={{ transform: `scale(${zoomVal})` }}/>
                    : 
                    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                    {numPages > 0 && Array.from(new Array(numPages), (el, index) => (
                        <div 
                            key={`page_${index + 1}`}
                            //ref={el => pagesRef.current[index + 1] = el}
                            style={{ position: 'relative' }}
                        >
                            <Page
                                pageNumber={index + 1}
                                renderTextLayer={false}
                                scale={zoomVal}
                            />
                        </div>
                    ))}
                    </Document>
                    :
                    !loading &&
                    <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center' height='100vh'>
                        <img src="/images/no_pdf_fallback_image.svg" width="60%"/>
                        <Typography variant="h6" style={{marginTop:'8px'}}>
                            No PDF Uploaded
                        </Typography>
                    </Box>
                }
                </Box>
                {billObj && 
                <div style={{ width: (type === 'preview')? "55vw" : "50vw", position:'relative'}}
                >
                    {type === 'preview'? 
                    <PreviewBillComponent billObj={billObj}
                        selectedEntity={selectedEntity}
                        setBillObj={setBillObj}
                        handleBillDetailClickDialogClose={()=>handleClose()}
                        loading={loading}
                        setOpenAddPamentBillDrawer={props.setOpenAddPamentBillDrawer}
                        handleCloseAddPaymentBillDrawer={props.handleCloseAddPaymentBillDrawer}
                        updateBillObj={props.updateBillObj}
                        setFromPreviewScreen={props.setFromPreviewScreen}
                        editPreview={props.editPreview}
                        setUpdatedBillObj={props.setUpdatedBillObj}
                        isFromUnclaimedBill={props.isFromUnclaimedBill}
                        expenseStr={urlParts[1]}
                    />
                    :
                    <CreateUpdateBillDetailComponent
                        highlights={highlightArr.current}
                        billObj={billObj}
                        selectedEntity={selectedEntity}
                        /* handleBillDetailClickDialogClose={()=>{
                            navigate(getUrlAccordingToSelectedComponent(props.drawerState));
                            props.handleBillDetailClickDialogClose(props.editPreview);
                        }} */
                        handleBillDetailClickDialogClose={()=>handleClose()}
                        loading={loading}
                        setUpdatedBillObj={props.setUpdatedBillObj}
                        newExpense={props.newExpense}
                        //pageDimensions={pageDimensions}
                        />
                    }
                </div>}
            </div>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    drawerState: state.drawerState.setDrawerState
});

export default connect(mapStateToProps)(BillDetailModalComponent);