import React from 'react';
import BaseSection from './BaseSection';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

    const leaderItemContainer = {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'center'
    };

    const centerAlignText = {
        textAlign: "center"
    };

const Team = ({item, handleDrawerOpen, onShowOrHidePage}) => {

    const {data} = item;
    const {team_image_title, show_team_image_title, team_image, team_image_signed_url, show_team_image, leadership_title, show_leadership_title, leaders} = data;

    return (
        <BaseSection title="Team" item={item}
            handleDrawerOpen={handleDrawerOpen}
            onShowOrHidePage={onShowOrHidePage}>
                
                <Grid size={12} className='edit_section_container'>

                <Grid size={12}>
                    {show_team_image_title &&
                    <Grid size={12}>
                        <Typography className='edit_section_heading' 
                            dangerouslySetInnerHTML={{__html: team_image_title}} />
                    </Grid>}

                    {show_team_image &&
                        <Grid size={12} sx={{marginTop: show_team_image_title ? '16px' : '0px'}}>
                            <div className='team_image_container'>
                                <img src={team_image_signed_url ? team_image_signed_url : '/images/team_photo.png'} 
                                    className='image_style'/>
                            </div>
                        </Grid>
                    }
                </Grid>
                
                <Grid size={12} style={{marginTop: show_team_image_title && show_team_image ? '24px' : '0px'}}>
                    {show_leadership_title &&
                    <Grid size={12}>
                        <Typography className='edit_section_heading' 
                            dangerouslySetInnerHTML={{__html: leadership_title}} />
                    </Grid>}

                    <Grid size={12} style={{marginTop:show_leadership_title ? '16px' : '0px'}}>
                        <Grid container alignItems='center' justifyContent='center'>
                            {
                                leaders && leaders.length > 0 &&
                                leaders.map((item) => {
                                    const {name, title, bio, profile_pic, profile_pic_signed_url} = item;
                                    return  item &&
                                            <Grid size={4} sx={leaderItemContainer}>
                                                <div className='profile_pic_container'>
                                                    <img src={profile_pic_signed_url ? profile_pic_signed_url : '/images/profile_pic.png'} 
                                                        className='image_style' />
                                                </div>

                                                <Grid>
                                                    <Typography>
                                                        <b>{name}</b>
                                                    </Typography>
                                                    <Typography sx={centerAlignText}>
                                                        {title}
                                                    </Typography>
                                                </Grid>
                                                {/* <Typography>
                                                    {bio}
                                                </Typography> */}
                                            </Grid>
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>

        </BaseSection>
    );
};

export default Team;
