import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';

const BusinessInformation = ({businessInfo, setBusinessInfo, errorType, errorMessage, setErrorMessage, setErrorType}) => {
    return (
        <div>
            <Box className='component_container'>
                <Grid size={12}>
                    <Typography className='component_heading'>
                        Business Information 
                    </Typography>

                    <Typography className='component_description'>
                        Describe your business and its offerings. This information will be used to personalize the proposal content, ensuring a professional and relevant tone.                   
                    </Typography>
                </Grid>

                <Grid size={12}>
                    <Typography className='add_item_table_heading'>
                        What you business do?
                    </Typography>

                    <TextField multiline
                        variant='outlined'
                        placeholder='eg. eProcessify provides invocing software that helps to create and manage invoices.'
                        value={businessInfo}
                        onChange={(e) => {
                            setBusinessInfo(e.target.value);
                            setErrorType(0);
                            setErrorMessage('');
                        }}
                        fullWidth
                        rows={5}
                        maxRows={6}
                        style={{marginTop:'8px'}}
                        error={errorType === 8}
                        helperText={errorType === 8 && `*${errorMessage}`}
                        size='small'
                    />
                </Grid>

            </Box>
        </div>
    );
}

export default BusinessInformation;