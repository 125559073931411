import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";

import moment from "moment";
import { getIcon } from "../util/iconUtil";

const ActivityFeed = (props) => {
    return(
        <Grid className="esign_style">
           {!props.hideShowActivities && <Typography variant="h6" style={{color:'#4a5568', fontSize:'15px', fontWeight:500, margin:'-8px 0px 10px 0px'}}>
                Activity
            </Typography>}
            <Timeline>
                {props.activityFeeds.map((item, index)=>{
                    const time = moment(item.created_at).fromNow();
                    return(
                            <TimelineItem key={item.id}>
                                <TimelineSeparator>
                                    <TimelineDot style={{background: item.color}}>
                                    {getIcon(item.icon)}
                                    </TimelineDot>
                                    {(props.activityFeeds.length !== (index+1)) && <TimelineConnector />}
                                </TimelineSeparator>
                                <Grid>
                                    <TimelineContent style={{fontSize:"13px",padding: "0px 16px 4px 10px", marginTop: "6px"}}>{item.title}</TimelineContent>
                                    <TimelineContent style={{padding: "0px 16px 4px 10px", fontSize:"11px", color: "#a0a0a1"}}>{time}</TimelineContent>
                                    {item.note && <TimelineContent style={{padding: "0px 16px 4px 10px", fontSize:"11px", color: "#a0a0a1"}}>{item.note}</TimelineContent>}
                                </Grid>
                            </TimelineItem>
                    )    
                })
                }
            </Timeline>
            {!props.hideShowActivities && props.activityFeeds && 
                props.activityFeeds.length === 5 && 
                <Typography 
                    variant="body1" 
                    style={{ fontWeight: "bold", color: "#4c51bf", marginTop: "-10px",paddingBottom: "10px", textDecoration: "underline", cursor: "pointer", textAlign: "center" }}
                    onClick={()=>{props.onShowMoreActivities()}}>
                    Show More Activities
                </Typography>
            }
        </Grid>
    )
}

export default ActivityFeed;