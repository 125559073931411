import React, { useState, useEffect, memo, useRef } from 'react';
import Typography from '@mui/material/Typography';
import SetLabelPopover from './SetLabelPopover';
import CloseIcon from '@mui/icons-material/Close';
import { getResponsiveFontSize, getDroppedComponentWidthAndHeight } from '../../util/AppUtil';
import Box from '@mui/material/Box';

const signContainer = {
    position:'relative',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    background:'yellow'
};

const setLabelStyle = {
    fontSize: '9px',
    position: 'absolute',
    top: 5,
    right: '8px',
    color: '#007bfb',
    cursor: 'pointer',
    zIndex: 100,
    fontWeight: 600
};

const signImageContainer = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};

const DroppedSignatureComponent = memo(({ 
    item, 
    index, 
    droppedItems, 
    setDroppedItems, 
    removeDroppedItem, 
    pdfWidth,
    pdfHeight, 
    originalDimension, 
    showCreateEditTemplateScreen,
    drag
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [fontSize, setFontSize] = useState(12);
    const [labelFontSize, setLabelFontSize] = useState(9);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    let img_path = item.value_json?.signature_image_path_url;

    const localRef = useRef(null);

    useEffect(() => {
        // Get the target element by ID
        const textEl = document.getElementById(`${index}`);

        if (textEl &&  item.showLabelPopover) {
            // Simulate the element triggering a popover opening
            setAnchorEl(textEl);
            item.showLabelPopover = undefined;
        } 
    }, []);

    useEffect(() => {
        if (pdfHeight > 0 && originalDimension?.width) {
            // Calculate scaled dimensions
            const baseWidth = 100; // Base width in pixels
            const baseHeight = 40; // Base height in pixels
            const canvasRectArr = document.querySelectorAll('.react-pdf__Page__canvas');
            const canvasEl = canvasRectArr[item.page - 1];
            const pageRect = canvasEl.getBoundingClientRect();

            const scaledDimensions = getDroppedComponentWidthAndHeight(
                baseWidth,
                baseHeight,
                pageRect.width,
                pageRect.height,
                originalDimension
            );
        
            setDimensions({
                width: scaledDimensions.new_component_width,
                height: scaledDimensions.new_component_height
            });

            setFontSize(getResponsiveFontSize(11, pageRect.width, pageRect.height, originalDimension));
            setLabelFontSize(getResponsiveFontSize(10, pdfWidth.width, pageRect.height, originalDimension));
        }
    }, [pdfWidth, pdfHeight, originalDimension]);

    useEffect(() => {
        const updateCoordinates = () => {
            const canvasRectArr = document.querySelectorAll('.react-pdf__Page__canvas');
            const canvasEl = canvasRectArr[item.page - 1]; // Adjust for 1-indexed page numbers
            if (!localRef.current || !canvasEl || !showCreateEditTemplateScreen) return;

            const pageRect = canvasEl.getBoundingClientRect();
            const itemRect = localRef.current.getBoundingClientRect();

            // Calculate absolute positions
             //const left = itemRect.left - pageRect.left;
            //const top = itemRect.top - pageRect.top;
            const left = item.position.x;
            const top = item.position.y;
            const width = itemRect.width;
            const height = itemRect.height;

            item.coordinates = {
                topLeft: { x: left, y: top },
                bottomRight: { x: left + width, y: top + height },
                page: item.page,
                canvasWidth: pageRect.width,
                canvasHeight: pageRect.height
            };
        };

        const timeoutId = setTimeout(updateCoordinates, 0);
        return () => clearTimeout(timeoutId);
    }, [item.position]);

    const handleLabelPopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLabelPopoverClose = () => {
        setAnchorEl(null);
    };

    const handleChangeLabel = (e) => {
        const updatedFormElements = droppedItems;
        const newLabel = e.target.value;

        // Check for existing labels with the same component_type
        const existingItem = droppedItems.find((item, i) => 
            i !== index && item.component_label === newLabel && item.component_type === droppedItems[index].component_type
        );

        // If a matching item is found, copy its value_json.text to the current item
        if (existingItem) {
            updatedFormElements[index].component_label = newLabel;
            updatedFormElements[index].value_json = {...existingItem.value_json};
            setDroppedItems([...updatedFormElements]);
        } else {
            updatedFormElements[index].component_label = newLabel;
            setDroppedItems([...updatedFormElements]);
        }
    }

    const showLabelPopover = () => {
        return (
            <SetLabelPopover 
                anchorEl={anchorEl}
                index={index}
                handleLabelPopoverClose={handleLabelPopoverClose}
                label={droppedItems[index]?.component_label}
                handleChangeLabel={handleChangeLabel}
                droppedItems={droppedItems}
                component_type={droppedItems[index]?.component_type}
            />
        );
    };

    return (
        <Box component='div' 
            ref={(el) => {
                localRef.current = el;
                drag(el);
            }}
            sx={{...signContainer,
                width: dimensions.width,
                height: dimensions.height,
            }}
        >
            {!showCreateEditTemplateScreen && img_path && 
                <Box component='div'  sx={signImageContainer}>
                    <img
                        src={img_path}
                        alt="signature"
                        style={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            objectFit: 'contain'
                        }}
                    />
                </Box>
            }
            
            {
            <>
                {
                <Typography style={{ fontSize: `${fontSize}px` }}>
                    {showCreateEditTemplateScreen ? 'Sign' : img_path ? '' : 'Sign'}
                </Typography>}

                {(showCreateEditTemplateScreen || !img_path) &&
                <div id={`${index}`} onClick={handleLabelPopoverOpen}>
                    <Typography sx={setLabelStyle}
                        style={{ fontSize: `${labelFontSize}px` }}>
                        {item.component_label || 'Set Label'}
                    </Typography>
                </div>
                }
            </>}
            

            {showCreateEditTemplateScreen && showLabelPopover()}
            
            {showCreateEditTemplateScreen && (
                <div 
                    onClick={() => removeDroppedItem(index)}
                    className="remove_dropped_item sign_item_item"
                >
                    <CloseIcon className="dropped_item_close_icon" />
                </div>
            )}
        </Box>
    );
});

export default DroppedSignatureComponent;