import React from 'react';
import BackgroundPreview from './BackgroundPreview';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { ReadOnlyEditor } from '../edit/BaseEditor';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

const componentStyles = (theme) => {
    const dividerStyle = {
        background: '#555', 
        width: '2px', 
        height: '100%',
        position: 'relative',
        top: '8px'
    };

    const listContainer = {
        display: 'flex',
        flexDirection: 'column',
        gap: '56px',
        paddingBottom: '72px',
        [theme.breakpoints.down('sm')]: {
            gap: '26px',
        }
    };

    const quotesImage = {
        width: '42px',
        height: '32.36px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            width: '30px',
            height: '20px',
        }
    };

    const quotesImage1 = {
        width: '30px',
        height: '20px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            width: '20px',
            height: '20px',
        }
    };

    const reviewerStyle = {
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: '32px',
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
            fontWeight: 600,
            lineHeight: '26px',
        }
    };

    const reviewerStyle1 = {
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: '30px',
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px",
            fontWeight: 600,
            lineHeight: '26px',
        }
    };

    return { dividerStyle, listContainer, quotesImage, quotesImage1, reviewerStyle, reviewerStyle1 };
}

const ReviewsPreview = ({item, index, normalUserPreview, brand_color}) => {
    const theme = useTheme();
    const {dividerStyle, listContainer, quotesImage, quotesImage1, reviewerStyle, reviewerStyle1} = componentStyles(theme);

    const {data} = item;
    const {title, description, reviews} = data;

    const descpStyle = normalUserPreview ? 'preview_description_style1' : 'preview_description_style';
    const quotesImageStyle = normalUserPreview ? quotesImage1 : quotesImage;
    const revStyle = normalUserPreview ? reviewerStyle1 : reviewerStyle;

    return (
        <BackgroundPreview item={item} 
            title={title}
            brand_color={brand_color}
            index={index}
            normalUserPreview={normalUserPreview}
        >
                
                <Grid size={12} className={descpStyle}>
                    <ReadOnlyEditor editorValue={description}/>
                </Grid>

                <Grid size={12}>
                    <Box className={descpStyle} sx={listContainer}>
                        {
                            reviews && reviews.length > 0 &&
                            reviews.map((item) => {
                                const {reviewed_by, review_company_name, review_text} = item;
                                return <Grid container alignItems='center' style={{borderLeft: '2px solid #555'}}>
                                            <Grid size={1} sx={{flexBasis:'4%', maxWidth:'4%'}}>
                                            </Grid>
                                            <Grid size={11}  sx={{...descpStyle, flexBasis:'95%', maxWidth:'95%'}}>
                                                <img src='/images/quotes_image.png' style={{...quotesImageStyle}}/>
                                                <ReadOnlyEditor editorValue={review_text}/>
                                            
                                                <Typography sx={revStyle}>
                                                    <span style={{position:"relative", top:"-4px", marginRight:"8px"}}><b>___</b></span>{`${reviewed_by}`}
                                                </Typography>

                                                <Typography sx={revStyle}>
                                                    {review_company_name}
                                                </Typography>


                                            </Grid>
                                            
                                        </Grid>
                            })
                        }
                    </Box>
                </Grid>

        </BackgroundPreview>
    );
}

export default ReviewsPreview;