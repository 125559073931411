import React from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import BackgroundPreview from './BackgroundPreview';
import { useTheme } from '@mui/material/styles';

const componentStyles = (theme) => {
    const leaderItemContainer = {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'center',
    };

    const leaderShipTitle = {
        fontSize: '32px',
        fontWeight: 600,
        margin: '32px 0px 16px 0px'
    };

    const leaderShipTitle1 = {
        fontSize: '24px',
        fontWeight: 600,
        margin: '32px 0px'
    };

    const titleStyles = {
        marginTop: "32px",
        fontSize: '32px',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: '24px',
            fontWeight: 600
        },
    };

    const titleStyles1 = {
        marginTop: "32px",
        fontSize: '24px',
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: '21px',
            fontWeight: 600
        },
    };

    const centerAlignText = {
        textAlign: "center"
    };

    return {
        leaderItemContainer,
        leaderShipTitle,
        leaderShipTitle1,
        titleStyles,
        titleStyles1,
        centerAlignText
    };
}

const TeamPreview = ({item, index, normalUserPreview, brand_color}) => {
    const theme = useTheme();
    const {leaderItemContainer, leaderShipTitle, leaderShipTitle1, titleStyles, titleStyles1, centerAlignText} =  componentStyles(theme);

    const {data} = item;
    const {title, team_image_title, team_image, show_team_image, team_image_signed_url, show_leadership_title, show_team_image_title, leaders} = data;

    const titleClass = normalUserPreview ? titleStyles1 : titleStyles;
    const leadershipTitleClass = normalUserPreview ? leaderShipTitle1 : leaderShipTitle;

    return (
        <BackgroundPreview item={item} title={title} 
            brand_color={brand_color}
            index={index}
            normalUserPreview={normalUserPreview}
            >

                <Grid size={12}>
                    {show_team_image_title &&
                    <Grid size={12}>
                        <Typography sx={titleClass} style={{color:brand_color}}>
                            {team_image_title}
                        </Typography>
                    </Grid>}

                    {show_team_image &&
                        <Grid size={12} sx={{marginTop:show_team_image_title ? '16px' : '0px'}}>
                            <div className='team_image_container'>
                                <img src={team_image_signed_url ? team_image_signed_url : '/images/team_photo.png'} 
                                    className='image_style'/>
                            </div>
                        </Grid>
                        
                    }
                </Grid>


            <Grid size={12}>
                {show_leadership_title &&
                <Typography sx={leadershipTitleClass} style={{color:brand_color}}
                    dangerouslySetInnerHTML={{__html: 'Leadership Team'}} />
                }
                <Grid container alignItems='center' justifyContent='center'>
                    {
                        leaders && leaders.length > 0 &&
                        leaders.map((item) => {
                            const {name, title, bio, profile_pic, profile_pic_signed_url} = item;
                            return  item &&
                                    <Grid size={{xs:12, md:4}} sx={leaderItemContainer}>
                                        <div className='profile_pic_container'>
                                            <img src={profile_pic_signed_url ? profile_pic_signed_url : '/images/profile_pic.png'} 
                                                className='image_style'/>
                                        </div>

                                        <div>
                                            <Typography>
                                                <b>{name}</b>
                                            </Typography>
                                            <Typography sx={centerAlignText}>
                                                {title}
                                            </Typography>
                                        </div>
                                        {/* <Typography>
                                            {bio}
                                        </Typography> */}
                                    </Grid>
                        })
                    }
                </Grid>
            </Grid>
        </BackgroundPreview>
    );
}

export default TeamPreview;