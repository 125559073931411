import React, { memo } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const drawerPaper = {
    '& .MuiDrawer-paper': {
        width: '54vw',
        overflowX: 'hidden',
        '@media (max-width: 1420px)': {
            width: '60vw',
        }
    }
};

const header = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '6px 16px',
    borderBottom: `1px solid rgba(0,0,0,0.1)`,
    background: '#F9F9F9',
};

const headingTitle = {
    fontSize: '16px',
    fontWeight: 600
};

const BaseDrawer = memo(({ open, onClose, title, children }) => {

    return (
        <Drawer
            anchor='right'
            open={open}
            onClose={onClose}
            sx={drawerPaper}
            disableEnforceFocus
            disableAutoFocus
        >
            <Box component='div' sx={header}>
                <Typography variant="h6" sx={headingTitle}>
                    {title}
                </Typography>

                <IconButton onClick={onClose}>
                    <img src='/images/close_black.svg' />
                </IconButton>
            </Box>

            <div>
                {children}
            </div>

        </Drawer>
    );
});

export default memo(BaseDrawer);
