import React, {useState, useRef, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import SignatureCanvas from "react-signature-canvas";
import {connect} from 'react-redux';
import { consoleToLog } from '../../util/AppUtil';
import {updateEntityEsignApi} from '../../services/authService';
import { setSelectedInvoiceAccount, setInvoiceAccountsList } from '../../actions/invoiceAccount';
import { useSnackbar } from 'notistack';
import { uploadAttachmentApi } from '../../services/uploadService';
import Box from '@mui/material/Box';

const MuiDialogpaper = {
    width:'700px',
    position:'relative',
    scrollbarWidth: 'none',
    "&::-webkit-scrollbar": {
        display: "none"
    }   
}
    
const dialogTitle = {
    borderBottom:'1px solid #ccc', 
    padding:'14px 16px', 
    fontSize:'16px',
    fontWeight:'600',
}
    
const modalBottom = {
    width:'100%',
    position:'absolute',
    bottom:0,
    padding:'14px 16px',
    borderTop:'1px solid rgba(0,0,0,0.1)',
    display:'flex',
    justifyContent:'flex-end'
}
    
const signPadColors = {
    marginBottom: '10px',
    textAlign:'center',
    '& .signPadText': {
        display: 'inline-block',
        marginRight: '5px'
    },
    '& .signPadPixels': {
        padding: '0px 9px',
        borderRadius: '100%',
        marginRight: '5px'
    },
}

const EnableInvoiceESignModal = (props) => {

    const signCanvas  = useRef();
    const {enqueueSnackbar} = useSnackbar();

    const [penColor, setPenColor] = useState("black");
    const colors = ["black", "blue", "red"];
    const [imageURL, setImageURL] = useState(null);
    const [loading, setLoading] = useState(false);

    const invoice_account_id = props.selectedAccount?.id;
    const entity_slug = props.entityObj?.slug;
    const entity_id = props.entityObj?.id;

    useEffect(() => {
        setImageURL(props.entityObj?.signature_url ? props.entityObj?.signature_url : null);
    }, [])

    const dataURLtoFile = (dataURL, fileName) => {
        // Step 1: Parse the Data URL
        const [, base64String] = dataURL.split(';base64,');
    
        // Step 2: Convert to ArrayBuffer
        const binaryString = atob(base64String);
        const length = binaryString.length;
        const arrayBuffer = new ArrayBuffer(length);
        const uint8Array = new Uint8Array(arrayBuffer);
    
        for (let i = 0; i < length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
        }
    
        // Step 3: Create a Blob
        const blob = new Blob([arrayBuffer], { type: 'image/png' }); // Specify the appropriate MIME type
    
        // Step 4: Create a File (optional)
        const file = new File([blob], fileName, { type: 'image/png' }); // Specify the appropriate MIME type
    
        return file;
    }

    const createImageOfSign = async() => {
        const imageDataURL = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        const fileName = `${props.entityObj?.name}-esign.png`;
        const imageFile = dataURLtoFile(imageDataURL, fileName);
        const attachment_type = 'entity';
        const attachment_subtype = 'entity_signature';
        
        setLoading(true);
        try {
            const response = await uploadAttachmentApi(invoice_account_id, attachment_type, attachment_subtype, fileName, imageFile, entity_slug)
            const res = response;
            const enable_esign = true;
            updateEntityESign(res.upload_url, enable_esign);
            setLoading(false);
        } catch(e) {
            consoleToLog("Error uploadAttachmentApi: ", e.response);
            setLoading(false);
            if(e.response.data && e.response.data.message) {
                return;
            }
        }   

        setImageURL(imageDataURL);
    };

    const updateEntityESign = (signature, enable_esign) => {
        
        updateEntityEsignApi(invoice_account_id, entity_id, signature, enable_esign)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateEntityEsignApi', res);
                setLoading(false);
                enqueueSnackbar('Invoice e-sign updated successfully', {variant:'success'});

                let account = {
                    ...props.selectedAccount,
                    entities: props.selectedAccount?.entities.map((item) => {
                        if(item.id === entity_id) {
                            item.signature = res.signature;
                            item.signature_url = res.signature_url;
                            item.enable_esign = res.enable_esign;
                        }
                        return item
                    })
                };

                let accountList = props.invoiceAccountsList.map((ia) => {
                if (ia.id === account.id) return account;
                    else return ia;
                });

                if(props.fromCreateUpdateInvoiceComponent || props.fromPreviewInvoiceScreen) {
                    let obj = {
                        ...props.selectedEntityObj,
                        signature: res.signature,
                        signature_url: res.signature_url,
                        enable_esign: res.enable_esign
                    }

                    props.setSelectedEntityObj(obj);
                }

                if(props.fromOnboardingComponent) {
                    let obj = {
                        ...props.entityObj,
                        signature: res.signature,
                        signature_url: res.signature_url,
                        enable_esign: res.enable_esign
                    }
                    props.setEntityObj(obj);
                }
 
                props.fromPreviewInvoiceScreen && !props.showEstimateScreen && props.updateInvoiceEntityEsign(res.enable_esign);
                
                props.setInvoiceAccountsList(accountList);
                props.setSelectedInvoiceAccount(account);

                if(props.invoiceAccountInfoComponent) {
                    props.handleEntityDrawerClose();
                    props.onEntityUpdate();
                }
                props.handleEnableInvoiceESignModalClose();
            })
            .catch((e) => {
                consoleToLog("Error uploadESignImageApi: ", e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    return (
        <Dialog
            open={props.openESignDialog}
            //TransitionComponent={Transition}
            scroll="paper"
            sx={{ '& .MuiDialog-paper' : {
                ...MuiDialogpaper
                },
                scrollbarWidth:'none !important'
            }}
            maxWidth="md"
            fullWidth
            onClose={props.handleEnableInvoiceESignModalClose}>

                <Grid container   
                        justifyContent='space-between'
                        alignItems='center'sx={dialogTitle}>

                    <Grid>        
                        <Typography style={{fontSize:'16px', fontWeight:500}}>
                            E Signature
                        </Typography>
                    </Grid>

                    <Grid>
                        <ClearIcon fontSize='small' onClick={props.handleEnableInvoiceESignModalClose}
                            style={{cursor:'pointer', verticalAlign:'inherit', position:"relative", top:"4px"}}/>
                    </Grid>
                </Grid>

                <div style={{padding:'16px'}}>
                    <Grid size={12} sx={signPadColors}>
                        <Typography variant='body1' className='signPadText'>Pen Color:</Typography>
                        {colors.map((color) => (
                            <span 
                                key={color}
                                style={{
                                    backgroundColor: color,
                                    border: `${color === penColor ? `2px solid ${color}` : ""}`,
                                }}
                                onClick={() => setPenColor(color)}
                                className='signPadPixels'
                            ></span>
                        ))}
                    </Grid>
                    <Grid size={12}>
                        <SignatureCanvas
                            penColor={penColor}
                            canvasProps={{ style: { width: '100%', height: '300px' } }}
                            ref={signCanvas}
                        />
                    </Grid>
                </div>
                
                <div>
                {
                    imageURL && (
                        <>
                            <img src={imageURL} alt="signature" className='signature_style' />
                        </>
                    ) 
                        
                }
                </div>
                
                <Box component='div' sx={modalBottom}>
                    <Button variant='contained' color='secondary'
                        style={{marginRight:'16px'}}
                        onClick={createImageOfSign}>
                        {loading && <CircularProgress size={20} style={{marginRight:"10px", color:"#fff"}} />}Save
                    </Button>
                    <Button variant='outlined' color='secondary'
                        style={{marginRight:'32px'}}
                        onClick={() => signCanvas.current.clear()}>
                        Clear
                    </Button>
                </Box>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    invoiceAccountsList: state.invoiceAccounts.invoiceAccountsList,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedInvoiceAccount: (account) => dispatch(setSelectedInvoiceAccount(account)),
    setInvoiceAccountsList: (list) => dispatch(setInvoiceAccountsList(list)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EnableInvoiceESignModal);