 import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const style={width: "6px", height: "6px"};
export const getIcon = (icon) => {
    if(icon === 'AddOutlinedIcon') return <AddOutlinedIcon style={style}></AddOutlinedIcon>;
    if(icon === 'EmailOutlinedIcon') return <EmailOutlinedIcon style={style}></EmailOutlinedIcon>;
    if(icon === 'EditOutlinedIcon') return <EditOutlinedIcon style={style}></EditOutlinedIcon>;
    if(icon === 'VisibilityOutlinedIcon') return <VisibilityOutlinedIcon style={style}></VisibilityOutlinedIcon>;
    if(icon === 'CloudDownloadOutlinedIcon') return <CloudDownloadOutlinedIcon style={style}></CloudDownloadOutlinedIcon>;
    if(icon === 'AddAlertOutlinedIcon')return <AddAlertOutlinedIcon style={style}></AddAlertOutlinedIcon>;
    if(icon === 'ClearOutlinedIcon') return <ClearOutlinedIcon style={style}></ClearOutlinedIcon>;
    if(icon === 'ThumbUpOutlinedIcon') return <ThumbUpOutlinedIcon style={style}></ThumbUpOutlinedIcon>;
    if(icon === 'ThumbDownOutlinedIcon') return <ThumbDownOutlinedIcon style={style}></ThumbDownOutlinedIcon>;
    if(icon === 'SettingsBackupRestoreOutlinedIcon') return <SettingsBackupRestoreOutlinedIcon style={style}></SettingsBackupRestoreOutlinedIcon>;
    if(icon === 'DescriptionOutlinedIcon') return <DescriptionOutlinedIcon style={style}></DescriptionOutlinedIcon>;

    return null;
}

