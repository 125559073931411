import React, {Fragment} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid2';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const itemOptionStyle = {
    width: "100%",
    height: '100%',
    color: '#777f8e',
    margin: '5px 0px'
};

const chip = {
    borderRadius: '4px !important',
    backgroundColor: '#f9f9f9 !important',
    border: '1px solid #ececec !important',
}
    
const MuiAutocompleteinputRoot = {
    '& .MuiOutlinedInput-root' : {
    padding:'1px',
    borderRadius: '5px',
    }
}
    
const customTextField = {
    "& input::placeholder": {
        fontSize: "13px",
    }
}

const AutocompleteTaxRates = (props) => {

    const renderOptions = (props, option) => {
        return (
                <Box component='div' key={option.id}  {...props} sx={{width:'100%'}}>
                    <Grid container alignItems='center' justifyContent="space-between" sx={itemOptionStyle}>
                        <Grid>
                            <Typography>
                                {option.name}
                            </Typography>
                        </Grid>

                        <Grid>
                            <Typography>
                                {option.rate}%
                            </Typography>
                        </Grid>
                    </Grid>  
                </Box>
            // props.fromOtherCountry ?
            // <div style={{display: "flex", width: "100%"}}>
            //     {`${option.name} ${option.rate}`}
            // </div>
            // :
            // <div style={{display: "flex", width: "100%"}}>
            //     {option.name}
            // </div>
            // );
        )
    }

    const onDeleteTaxPreset = (option) => {
        //console.log('option', option);

        if(props.fromOtherCountry) {
            props.setSelectedOtherCountryTaxes(props.selectedOtherCountryTaxes.filter(entry => entry !== option));
        } else {
            if(props.fromInterState) {
                props.setSelectedInterStateTaxes(props.selectedInterStateTaxes.filter(entry => entry !== option)) 
            } else {
                props.setSelectedIntraStateTaxes(props.selectedIntraStateTaxes.filter(entry => entry !== option));
            }
        }
        
    }

    const renderTags = (options) => {
        return (
            options.map(option =>
                <Fragment key={option.id}>
                <Chip
                    sx={chip}
                    label={`${option.name} (${option.rate}%)`}
                    onDelete={() => onDeleteTaxPreset(option)}
                    variant="outlined"
                    style={{margin: "2px 2px"}}
                    deleteIcon={<ClearIcon  style={{width: "18px"}}/>}
                />
                </Fragment>))
    }

    const taxOptions = () => {
        if(props.fromOtherCountry) {
            return props.otherCountryTaxes.filter(option => !props.selectedOtherCountryTaxes?.some(selected => selected.tax_preset_id === option.id));
        } else {
            if(props.fromInterState) {
                return props.interStateTaxes.filter((option) => !props.selectedInterStateTaxes?.some(selected => selected.tax_preset_id === option.id)); 
            } else {
                return props.intraStateTaxes.filter((option) => !props.selectedIntraStateTaxes?.some(selected => selected.tax_preset_id === option.id));
            }
        }
    }

    const selectedTaxes = () => {
        if(props.fromOtherCountry) {
            return props.selectedOtherCountryTaxes;
        } else {
            if(props.fromInterState) {
                return props.selectedInterStateTaxes; 
            } else {
                return props.selectedIntraStateTaxes;
            }
        }
    }

    const onTaxPresetsChange = (e, newValue) => {

        if(props.fromOtherCountry) {
            props.setSelectedOtherCountryTaxes(newValue);
        } else {
            if(props.fromInterState) {
                props.setSelectedInterStateTaxes(newValue); 
            } else {
                props.setSelectedIntraStateTaxes(newValue);
            }
        }
    }

    const showPlaceholder = () => {
        if(props.fromOtherCountry) {
            return "Type here";
        } else {
            if(props.fromInterState) {
                return "Type IGST18, CGST28. You can select multiple taxes."; 
            } else {
                return "Type SGST9, CGST9. You can select multiple taxes.";
            }
        }
    }

    const getTaxLabel = (option) => {
        // if(props.fromOtherCountry) {
        //     return `${option.name} ${option.rate}`
        // } else {
        //     return option.name
        // }
        return `${option.name} (${option.rate}%)`;
    }

    return (
        <div>
            <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="small"
                options={taxOptions()}
                getOptionLabel={(option) => getTaxLabel(option)}
                disableClearable
                renderOption={(props, option) => renderOptions(props, option)}
                value={selectedTaxes()}
                onChange={(e, newValue) => onTaxPresetsChange(e, newValue)}
                sx={MuiAutocompleteinputRoot}
                filterSelectedOptions
                renderTags={(options) => renderTags(options)}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" 
                        placeholder={showPlaceholder()} 
                        fullWidth
                        sx={customTextField}
                    />
                )}
            />
        </div>
    );
}

export default AutocompleteTaxRates;