import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import {connect} from 'react-redux';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import {setClearInvoiceAccounts} from '../actions/invoiceAccount';
import {addItemApi, updateItemApi} from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import { logout } from '../actions/invoiceAuth';
import { clearSelectedInvoiceFilters } from '../actions/invoiceFilters';
import { setSelectedDrawerState } from '../actions/drawer';
import { clearSelectedTransactionFilters } from "../actions/transactionFilters";
import {trackGAEvent} from '../util/AppUtil';
import AutocompleteTaxRates from './AutocompleteTaxRates';
import {intraStateTaxesArr, interStateTaxesArr} from '../util/AppUtil';
import { isInvoiceAccountCountryIndia } from '../util/AppUtil';

const labelTextStyles = {
    fontSize: '14px',
    marginTop: '8px',
    marginBottom: '-6px'
};

const customMarginTop = {
    marginTop: '8px'
};

const customColumn1 = {
    flexBasis: '46.5%',
    maxWidth: '46.5%'
};

const AddEditInvoiceItemsDrawer = (props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [itemName, setItemName] = useState("");
    const [itemDescription, setItemDescription] = useState("");
    const [itemPrice, setItemPrice] = useState(0);
    const [hsnCode, setHSNCode] = useState("");
    const [taxes, setTaxes] = useState([]);
    const [errorType, setErrorType] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [taxPreferenceList, setTaxPreferenceList] = useState([
        {
            name:'Taxable',
            value:'taxable'
        },
        {
            name:'Non Taxable',
            value:'non_taxable'
        },

    ]);

    let inter_state_taxes = interStateTaxesArr(props.selectedAccount.tax_presets);
    let intra_state_taxes = intraStateTaxesArr(props.selectedAccount.tax_presets);
    let other_type_taxes = props.selectedAccount.tax_presets;

    const [selectedTaxPreference, setSelectedTaxPreference] = useState(-1);
    const [interStateTaxes, setInterStateTaxes] = useState(inter_state_taxes);
    const [intraStateTaxes, setIntraStateTaxes] = useState(intra_state_taxes);
    const [selectedInterStateTaxes, setSelectedInterStateTaxes] = useState([]);
    const [selectedIntraStateTaxes, setSelectedIntraStateTaxes] = useState([]);
    const [otherCountryTaxes, setOtherCountryTaxes] = useState(other_type_taxes);
    const [selectedOtherCountryTaxes, setSelectedOtherCountryTaxes] = useState([]);

    useEffect(() => {
        if(Object.keys(props.itemObj).length === 0) {
            if(props.selectedAccount.tax_preference) {
                let taxPreference = props.selectedAccount.tax_preference;
                if(isInvoiceAccountCountryIndia(props.selectedAccount?.country)) {
                    setSelectedInterStateTaxes(taxPreference?.inter_state);
                    setSelectedIntraStateTaxes(taxPreference?.intra_state);
                } else {
                    setSelectedOtherCountryTaxes(taxPreference?.states);
                }
            }
        } 

    }, [props.itemObj])

    useEffect(() => {
        if(!props.fromCreateEditInvoice && Object.keys(props.itemObj).length !== 0) {
            setItemName(props.itemObj?.title);
            setItemDescription(props.itemObj?.description);
            setItemPrice(props.itemObj?.cost);
            setHSNCode(props.itemObj?.hsn_code);
            setSelectedTaxPreference(props.itemObj?.tax_preference);
            if(isInvoiceAccountCountryIndia(props.selectedAccount?.country)) {
                setSelectedInterStateTaxes(props.itemObj.tax?.inter_state);
                setSelectedIntraStateTaxes(props.itemObj.tax?.intra_state);
            } else {
                setSelectedOtherCountryTaxes(props.itemObj.tax?.states);
            }
        } 

    }, [props.itemObj]);

    const invoiceAccountCountry = props.selectedAccount?.country;

    const clearForm = ( ) => {
        setItemName('');
        setItemDescription('');
        setItemPrice(0);
        setHSNCode('');
        setErrorType(undefined);
        setErrorMessage('');
        setSelectedTaxPreference(-1);
        Object.keys(props.itemObj).length !== 0 && props.setItemObj({})
    }

    const closeDrawer = () => {
        props.handleDrawerClose();
        clearForm();
    }

    const onAddEditItemClick = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const title = itemName;
        if(title === '') {
            setErrorType(0);
            setErrorMessage('Item name cannot be empty!');
            return;
        }

        const description = itemDescription;
        const cost = parseFloat(itemPrice);
        if(!cost) {
            setErrorType(1);
            setErrorMessage('Price cannot be empty!');
            return; 
        }
        const tax_preference = selectedTaxPreference;
        if(tax_preference === -1) {
            setErrorType(2);
            setErrorMessage('Please select tax preference');
            return; 
        }

        let obj = {};

        if(isInvoiceAccountCountryIndia(invoiceAccountCountry)) {
            obj = {
                inter_state: selectedInterStateTaxes && selectedInterStateTaxes.length > 0 ? selectedInterStateTaxes.map(({id, name, rate, tax_preset_id}) => ({tax_preset_id:tax_preset_id ? tax_preset_id : id, name:name, rate:rate})) : [],
                intra_state: selectedIntraStateTaxes && selectedIntraStateTaxes.length > 0 ? selectedIntraStateTaxes.map(({id, name, rate, tax_preset_id}) => ({tax_preset_id:tax_preset_id ? tax_preset_id : id, name:name, rate:rate})) : []
            }
        } else {
            obj = {
                states: selectedOtherCountryTaxes && selectedOtherCountryTaxes.length > 0 ? selectedOtherCountryTaxes.map(({id, name, rate, tax_preset_id}) => ({tax_preset_id:tax_preset_id ? tax_preset_id : id, name:name, rate:rate})) : [],
            }
        }

        const tax = tax_preference === 'taxable' ? obj : isInvoiceAccountCountryIndia(invoiceAccountCountry) ? {inter_state: [], intra_state: []} : {states: []};
        if(tax_preference === 'taxable') {
            if(isInvoiceAccountCountryIndia(invoiceAccountCountry) && tax.inter_state.length === 0 && tax.intra_state.length === 0) {
                enqueueSnackbar('Please select atleast one tax', {variant:'error'})
                return;
            } else {
                if(!isInvoiceAccountCountryIndia(invoiceAccountCountry) && tax.states.length === 0) {
                    enqueueSnackbar('Please select atleast one tax', {variant:'error'})
                    return;
                }
            }
        }

        const hsn_code = hsnCode;

        setLoading(true);
        if(!props.fromCreateEditInvoice && Object.keys(props.itemObj).length !== 0) {
            const item_id = props.itemObj?.id;
            callUpdateApi(invoice_account_id, item_id, title, description, cost, tax_preference, tax, hsn_code)

        } else {

        addItemApi(invoice_account_id, title, description, cost, tax_preference, tax, hsn_code)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response addItemApi: ', res);
                setLoading(false);
                
                enqueueSnackbar('Item Added SuccessFully', {variant: 'success'});
                props.onAddItemsClick(res);
                
                
                props.fromCreateEditInvoice && props.onItemClick(res, props.itemIndex)
                props.handleDrawerClose();
                clearForm();

                trackGAEvent(props.selectedAccount.name, 'Invoice Items item created', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog('Error addItemApi', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
        }
    }


    const callUpdateApi = (invoice_account_id, item_id, title, description, cost, tax_preference, tax, hsn_code) => {
        updateItemApi(invoice_account_id, item_id, title, description, cost, tax_preference, tax, hsn_code)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response updateItemApi: ', res);
                setLoading(false);
                
                enqueueSnackbar('Item Updated SuccessFully', {variant: 'success'});
                props.onUpdateLocalList(res);
                props.handleDrawerClose();
                clearForm();

                trackGAEvent(props.selectedAccount.name, 'Invoice Items item updated', `${props.user.firstname} ${props.user.lastname}`);
            })
            .catch((e) => {
                consoleToLog('Error updateItemApi', e);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
        }

    const onTaxPreferenceChange = (e) => {
        if(e.target.value !== -1) {
            setSelectedTaxPreference(e.target.value);
            setErrorMessage('');
            setErrorType(undefined);
        }
    }

    const showTaxSelectorForItem = () => {
        if(isInvoiceAccountCountryIndia(invoiceAccountCountry)) {
            return <Grid size={12} style={{marginTop:'16px'}}>
                        <Typography style={{fontSize:'15px', fontWeight:500, marginBottom:'8px'}}>
                            Tax Rates
                        </Typography>
                        <Grid item md={12}>
                            <Typography>
                                Intra State Tax Rate
                            </Typography>
                            <AutocompleteTaxRates fromInterState={false}
                                intraStateTaxes={intraStateTaxes}
                                selectedIntraStateTaxes={selectedIntraStateTaxes}
                                setSelectedIntraStateTaxes={setSelectedIntraStateTaxes}/>
                        </Grid>


                        <Grid size={12}>
                            <Typography style={{marginTop:'16px'}}>
                                Inter State Tax Rate
                            </Typography>
                            <AutocompleteTaxRates fromInterState={true}
                                interStateTaxes={interStateTaxes}
                                selectedInterStateTaxes={selectedInterStateTaxes}
                                setSelectedInterStateTaxes={setSelectedInterStateTaxes}/>
                        </Grid>

                    </Grid>
        } else {
            return <Grid size={12}>
                        <Typography style={{marginTop:'16px'}}>
                            Tax
                        </Typography>
                        <AutocompleteTaxRates fromOtherCountry={true}
                            otherCountryTaxes={otherCountryTaxes}
                            selectedOtherCountryTaxes={selectedOtherCountryTaxes}
                            setSelectedOtherCountryTaxes={setSelectedOtherCountryTaxes}/>
                    </Grid>
        }
    }

    return (
        <RightToLeftSideDrawer title={Object.keys(props.itemObj).length !== 0  ? 'Update Item' : "Add Item"} 
            openDrawer={props.openDrawer}
            closeDrawer={closeDrawer}>

            <div style={{padding:'0px 20px 24px 20px', height:'calc(100vh - 180px)', overflow:'auto'}}>
                <Grid size={12}>
                    <Typography sx={labelTextStyles}>
                        Name
                    </Typography>

                    <TextField fullWidth 
                        id="item_name" 
                        variant="outlined"
                        type="text"
                        margin="dense"
                        value={itemName}
                        autoComplete="off"
                        placeholder="Enter item name"
                        slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={(e) => {
                            setItemName(e.target.value)
                            setErrorType(undefined);
                            setErrorMessage('');
                        }}
                        error={errorType === 0}
                        helperText={errorType === 0 ? errorMessage : ''}
                        size='small'
                    />
                </Grid>    


                <Grid size={12} sx={customMarginTop}>
                    <Typography sx={labelTextStyles}>
                        Description
                    </Typography>   

                    <TextField fullWidth 
                        id="item_description" 
                        variant="outlined"
                        type="text"
                        multiline
                        rows={4}
                        margin="dense"
                        autoComplete="off"
                        value={itemDescription}
                        placeholder="Enter item description"
                        slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                        onChange={(e) => {
                            setItemDescription(e.target.value);
                            //setErrorNo(undefined);
                            //setErrorMsg('');
                        }}
                        //error={errorNo === 1}
                        //helperText={errorNo === 1 ? errorMsg : ''}
                        size='small'
                    />
                </Grid> 

                <Grid size={12} sx={customMarginTop}>
                    <Grid item container justifyContent='space-between'>
                        <Grid item size={isInvoiceAccountCountryIndia(invoiceAccountCountry) ? 6 : 12} 
                            sx={isInvoiceAccountCountryIndia(invoiceAccountCountry) && customColumn1}>
                            <Typography sx={labelTextStyles}>
                                Price/Rate
                            </Typography>   

                            <TextField fullWidth 
                                id="item_price" 
                                variant="outlined"
                                type="number"
                                margin="dense"
                                autoComplete="off"
                                value={itemPrice === 0 ? '' : itemPrice}
                                placeholder="Enter item price"
                                slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                                onChange={(e) => {
                                    let input = e.target.value;
                                    // Regular expression to allow only numbers with up to 2 decimal places
                                    if (input === "" || /^\d+(\.\d{0,2})?$/.test(input)) {
                                        setItemPrice(input);
                                        setErrorType(undefined);
                                        setErrorMessage('');
                                    }
                                }}
                                error={errorType === 1}
                                helperText={errorType === 1 ? errorMessage : ''}
                                size='small'
                            />

                        </Grid>
                    
                        {isInvoiceAccountCountryIndia(invoiceAccountCountry) && 
                        <Grid size={6} sx={customColumn1}>
                            <Typography sx={labelTextStyles}>
                            HSN/SAC code
                            </Typography>   

                            <TextField fullWidth 
                                id="item_price" 
                                variant="outlined"
                                type="text"
                                margin="dense"
                                autoComplete="off"
                                value={hsnCode}
                                placeholder="Enter HSN/SAC code"
                                slotProps={{style: {fontSize: 13, color: "#ccc"}}}
                                onChange={(e) => {
                                    setHSNCode(e.target.value);
                                    //setErrorNo(undefined);
                                    //setErrorMsg('');
                                }}
                                //error={errorNo === 1}
                                //helperText={errorNo === 1 ? errorMsg : ''}
                                size='small'
                            />

                        </Grid>}
                    </Grid>

                    
                </Grid> 

                <Grid size={12} style={{marginTop:'16px'}}>
                    <Typography style={{fontSize:'14px', marginBottom:'-4px'}}>
                        Tax Preferences          
                    </Typography>

                    <TextField 
                        variant='outlined'
                        select
                        fullWidth
                        margin='dense'
                        value={selectedTaxPreference}
                        onChange={onTaxPreferenceChange}
                        error={errorType === 2}
                        helperText={errorType === 2 ? errorMessage : ''}
                        size='small'
                    >
                        <MenuItem value={-1}>Please select tax preference</MenuItem>
                        {
                            taxPreferenceList && taxPreferenceList.length > 0 &&
                            taxPreferenceList.map((taxItem) => {
                                return <MenuItem key={taxItem.value} value={taxItem.value}>
                                            {taxItem.name}
                                        </MenuItem>
                            })
                        }

                    </TextField>

                    {/* <AutoCompleteTaxPreset setTaxes={setTaxes}
                        taxes={taxes}
                        taxPresetObj={props.taxPresetObj}
                        setTaxPresetObj={props.setTaxPresetObj}
                        handleTaxPresetDrawerOpen={props.handleTaxPresetDrawerOpen}
                     /> */}
                </Grid>

                {
                    selectedTaxPreference === 'taxable' && showTaxSelectorForItem()
                    // <Grid item md={12} lg={12} style={{marginTop:'16px'}}>
                    //     <Typography style={{fontSize:'15px', fontWeight:500, marginBottom:'8px'}}>
                    //         Tax Rates
                    //     </Typography>
                    //     <Grid item md={12}>
                    //         <Typography>
                    //             Intra State Tax Rate
                    //         </Typography>
                    //         <AutocompleteTaxRates fromInterState={false}
                    //             intraStateTaxes={intraStateTaxes}
                    //             selectedIntraStateTaxes={selectedIntraStateTaxes}
                    //             setSelectedIntraStateTaxes={setSelectedIntraStateTaxes}/>
                    //     </Grid>


                    //     <Grid item md={12}>
                    //         <Typography style={{marginTop:'16px'}}>
                    //             Inter State Tax Rate
                    //         </Typography>
                    //         <AutocompleteTaxRates fromInterState={true}
                    //             interStateTaxes={interStateTaxes}
                    //             selectedInterStateTaxes={selectedInterStateTaxes}
                    //             setSelectedInterStateTaxes={setSelectedInterStateTaxes}/>
                    //     </Grid>

                    // </Grid>
                }
                <div className="modal__footer">
                    <Button variant="contained" color="primary" 
                        onClick={onAddEditItemClick}
                        className="modal__button">
                        {loading && <CircularProgress size={15} className='loading__style'/>}
                        {Object?.keys(props.itemObj)?.length !== 0 ? 'Update' : 'Save'}    
                    </Button>
                    <Button variant="outlined" style={{
                        minWidth: '130px',
                    }} onClick={closeDrawer}>
                        Cancel
                    </Button>
                </div>
            </div>
        </RightToLeftSideDrawer>
    );
}


const mapStateToProps = (state) => ({
    accessToken: state.invoiceAuth,
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user

});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearSelectedInvoiceFilters: () => dispatch(clearSelectedInvoiceFilters()),
    setClearInvoiceAccounts: () => dispatch(setClearInvoiceAccounts()),
    setSelectedDrawerState: (drawerState) => dispatch(setSelectedDrawerState(drawerState)),
    clearSelectedTransactionFilters: () => dispatch(clearSelectedTransactionFilters())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddEditInvoiceItemsDrawer);