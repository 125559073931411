import React, {useState, memo, useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {connect} from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { autoCompleteClientListApi } from '../../../services/clientService';
import { consoleToLog } from '../../../util/AppUtil';
import  AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const MuiTextFieldRoot = {
    border: 'none'
};

const avatarStyle = {
    width: '15px !important',
    height: '15px !important',
    fontSize: '12px !important',
    background: 'none !important',
    color: 'rgba(0, 0, 0, 0.87) !important',
    border: '1px solid rgba(0, 0, 0, 0.26) !important'
};

const autoStyle = {
    "&::-webkit-scrollbar": {
        display: "none"
    }
};

const inputRoot = {
    padding: '10px', // Ensure padding is applied here
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)', // Default border color
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.87)', // Hover border color
    }
};

const inputStyle = {
    color: 'black', // Text color
    '&::placeholder': {
        color: 'black', // Placeholder text color
        opacity: 1, // Ensure placeholder is visible
    },
};

const SelectClient = memo(({selectedEntity, selectedClient, setSelectedEntity, setSelectedClient, 
    errorMessage, errorType, handleClientDrawerOpen, onCloseProposalDialog, newClientObj, setNewClientObj, ...props}) => {

    const [clientList, setClientList] = useState([]);
    const [inputText, setInputText] = useState('')
    const [open, setOpen] = useState(false);

    const {selectedAccount, resetErrorMessageAndErrorType} = props;
    const entities = selectedAccount.entities;

    useEffect(() => {
            if (newClientObj) {
                setClientList([newClientObj]);
                setSelectedClient({...newClientObj});
                //setNewClientObj(undefined);
            }
    }, [newClientObj]);

    useEffect(() => {
        if (newClientObj && clientList.length > 0) {
        }
}, [clientList]);

    const onSelectEntityClick = (e) => {
        const value = e.target.value;
        setSelectedEntity(value);
        resetErrorMessageAndErrorType();
    }

    const getClientsList = async (text) => {
        const invoice_account_id = selectedAccount.id;

        if(!text) return;
        try {
            const response = await  autoCompleteClientListApi(invoice_account_id, text);
            const res = response.data;
            consoleToLog('Response autoCompleteClientListApi: ', res);
            setClientList(res.companies);

        } catch(e) {
            consoleToLog('Response autoCompleteClientListApi ', e);
            if (e.response.data && e.response.data.message) {
                return false;
            }
        }
        
    } 

    const onAddClient = (option) => {
        setSelectedClient(option);
        resetErrorMessageAndErrorType();
    }

    const renderOptions = (props, option) => {
        return (
            <Box  sx={{display: "flex", width:"100%", ...autoStyle}} 
                onClick={() => onAddClient(option)}   
                {...props}  
            >
                <Avatar sx={{marginRight: "6px", marginTop: "4px", ...avatarStyle}}  
                >
                    {option.name?.charAt(0)}
                </Avatar>
                {option.name}
            </Box>
        );
    }

    const handleInputChange = (e, newValue) => {
        getClientsList(newValue);
            
        setInputText(newValue);
            if (!newValue) {
                setOpen(false);
            }
    }

    const CreateItemButton = ({ children, ...other }) => {
        return (
        <Paper {...other} 
            style={{
                marginTop:'6px',
                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' 
            }}>
            <Grid container justifyContent="center">
                <Button fullWidth
                    className={'create_item_button'}
                    variant="outlined"
                    color="primary"
                    onMouseDown={() => {
                        handleClientDrawerOpen();
                    }}
                    >
                    <AddCircleOutlineIcon fontSize='small' className={'create_item_icon'}/>  
                        Add Client
                </Button>
            </Grid>
            {children}
        </Paper>
        )
    }

    return (
        <div>
            <Box className='component_container'>
                <Grid size={12}>
                    <Typography className='component_heading'>
                        Select Client
                    </Typography>

                    <Typography className='component_description'>
                        Choose the entity and the client for whom this proposal will be created. This ensures the proposal is tailored to the specific client's requirements.
                    </Typography>
                </Grid>

                <Grid size={12}>
                    <Grid container alignItems='center' spacing={1}>
                        
                        <Grid size={6}>
                            <TextField fullWidth
                                variant='outlined'
                                margin='dense'
                                sx={MuiTextFieldRoot}
                                select
                                value={selectedEntity}
                                onChange={onSelectEntityClick}
                                size='small'
                            >
                                <MenuItem value={-1}>Select Entity</MenuItem>
                                {
                                    entities.map((entityItem) => {
                                        return <MenuItem key={entityItem?.id} value={entityItem}>
                                                    {entityItem.name}
                                                </MenuItem>
                                    })
                                }
                            </TextField>
                        </Grid>
                        
                        <Grid size={6}>
                            <Autocomplete
                                fullWidth
                                id="size-small-outlined-multi"
                                size="small"
                                open={open}
                                onOpen={() =>  {
                                    if(inputText) {
                                        setOpen(true)
                                    }
                                }}
                                onClose={() => setOpen(false)}
                                options={clientList}
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => option.name || ''}
                                renderOption={(props, option) => renderOptions(props, option)}
                                value={selectedClient}
                                onChange={(e, newValue) => setSelectedClient(newValue ? newValue : {})}
                                onInputChange={(e, newValue) => handleInputChange(e, newValue)}
                                sx={inputRoot}
                                slots={{
                                    paper: CreateItemButton
                                }}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        variant="outlined" 
                                        placeholder="Select Client"
                                        margin='dense'
                                        slotProps={{
                                            input: {
                                                ...params.InputProps,
                                            }
                                        }}
                                        
                                    />
                                )}
                            />
                        </Grid>

                    </Grid>

                    <Grid container alignItems='center'>
                        <Grid size={6}>
                            {errorType === 1 && errorMessage &&
                            <Typography className='error_message_text'>
                                *{errorMessage}
                            </Typography>}
                        </Grid>

                        <Grid size={6}>
                            {errorType === 2 && errorMessage &&
                            <Typography className='error_message_text'>
                                *{errorMessage}
                            </Typography>}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
});

export const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount
})

export default memo(connect(mapStateToProps)(SelectClient));
