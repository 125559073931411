import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Slide from "@mui/material/Slide";
import { connect } from 'react-redux';
import CreateUpdateInvoiceComponent from '../CreateUpdateInvoiceComponent';
import PreviewAndSendInvoiceComponent from '../PreviewAndSendInvoiceComponent';
import { getUrlAccordingToSelectedComponent } from '../../util/AppUtil';
import { useIsMount } from '../useIsMount';
import { useLocation } from 'react-router-dom';
import {getBillDetailsApi} from '../../services/authService';
import { consoleToLog } from '../../util/AppUtil';
import {getUndeliveredEstimateItemsApi} from '../../services/authService';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateEditPreviewBillModal = (props) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    
    const [showCreateInvoice, setShowCreateInvoice] = useState(false);
    const [customerObj, setCustomerObj] = useState({});
    const [entityObj, setEntityObj] = useState(undefined);
    const [estimateObjForCreateInvoice, setEstimateObjForCreateInvoice] = useState({});

    const [editInvoiceObj, setEditInvoiceObj] = useState({});

    const [previewInvoiceObj, setPreviewInvoiceObj] = useState({});
    const [showSendEmail, setShowSendEmail] = useState(false);
    const [sendEmailInvoiceType, setSendEmailInvoiceType] = useState(undefined);

    const location = useLocation();
    const isMount = useIsMount();

    const urlParts = window.location.pathname?.split('/');
    const pathname = urlParts[1];
    //const showEstimateScreen = pathname === 'estimate' ? true : false;
    const slug = urlParts[2];
    const type = urlParts.length > 3 ? urlParts[3] : '';
    //const estimateObj =  location.state?.estimateObj;
    const invoice_account_id = props.selectedAccount.id;
    //const client_obj =  location.state?.clientObj;
    //const entity_obj =  location.state?.entityObj;
    //const show_send_email =  location.state?.showSendEmail;
    //const send_email_invoice_type =  location.state?.invoiceType;

    useEffect(() => {
        if(slug) {
            getBillDetails();
        }
    }, []);

    /* useEffect(() => {
        if(!isMount) {
            setShowSendEmail(show_send_email);
            setSendEmailInvoiceType(send_email_invoice_type);
        }
    }, [show_send_email, send_email_invoice_type]); */

    /* useEffect(() => {
        if(!isMount) {
            if(Object.keys(editInvoiceObj).length > 0) {
                const obj = {
                    ...editInvoiceObj,
                    client: client_obj ? client_obj : editInvoiceObj.client
                }
                setEditInvoiceObj(obj);
            }
        }
    }, [client_obj]); */

    /* useEffect(() => {
        if(!isMount) {
            if(Object.keys(editInvoiceObj).length > 0) {
                const obj = {
                    ...editInvoiceObj,
                    entity: entity_obj ? entity_obj : editInvoiceObj.entity
                }
                setEditInvoiceObj(obj);
            }
        }
    }, [entity_obj]); */


    const getBillDetails = async () => {
            setApiLoading(true);
            try {
                const response = await getBillDetailsApi(invoice_account_id, slug);
                const res = response.data;
                consoleToLog('Response getBillDetailsApi11: ', res);

                if (type === 'preview') {
                    const billObj={...res}
                    setPreviewInvoiceObj({purchase_invoice: billObj, vendors: []});
                } else {
                    const billObj={...res}
                    setEditInvoiceObj({purchase_invoice: billObj, vendors: []});
                }

                showError && setShowError(false);
            } catch (error) {
                consoleToLog("Error getBillDetailsApi11", error);
                setShowError(true);
            } finally {
                setLoading(false);
            }
    }

    const getUndeliveredEstimateItems = async(tryAgain, slug) => {

        try {

            const response = await getUndeliveredEstimateItemsApi(invoice_account_id, slug);
            const res = response.data;
            consoleToLog('Response getUndeliveredEstimateItemsApi: ', res);
            // Save data in the store based on which object is being fetched
            setEstimateObjForCreateInvoice(res);
            setCustomerObj(res.client);
            setEntityObj(res.entity);
            setShowCreateInvoice(true);
            showError && setShowError(false);
            tryAgain && setApiLoading(false);
        } catch (error) {
            consoleToLog("Error getUndeliveredEstimateItemsApi", error);
            setShowError(true);
            tryAgain && setApiLoading(false);
        } finally {
            setLoading(false);
        }
    }


    const closeDialog = () => {
        navigate(getUrlAccordingToSelectedComponent(props.drawerState));
        props.handleInvoiceDialogClose();
    }

    return (
        <Dialog
            open={props.openInvoiceDialog}
            slots={{
                transition: Transition
            }}
            scroll="paper"
            fullScreen
            style={{scrollbarWidth:'none !important', zIndex:'100'}}
            onClose={closeDialog}>
                {  <Grid size={12} style={{background:'#f7f7f7'}}>

                    
                    {loading ? 
                    <Grid container justifyContent="center">
                        <CircularProgress size={25} style={{marginTop:'32px'}}/>
                    </Grid> 
                    :
                    !showError
                    ?
                    <>
                        {
                            Object.keys(previewInvoiceObj).length > 0 
                            ?
                            <PreviewAndSendInvoiceComponent /* invoiceObj={previewInvoiceObj}
                                editInvoiceObj={editInvoiceObj}
                                handleInvoiceDialogClose={props.handleInvoiceDialogClose}
                                setUpdatedInvoiceObj={props.setUpdatedInvoiceObj}
                                setAddTransaction={props.setAddTransaction}
                                updateTranscItemInTranscList={props.updateTranscItemInTranscList}
                                closeDialog={closeDialog}
                                showEstimateScreen={showEstimateScreen}
                                setEstimateObjForCreateInvoice={setEstimateObjForCreateInvoice}
                                setLoading={setLoading}
                                getUndeliveredEstimateItems={getUndeliveredEstimateItems}
                                setPreviewInvoiceObj={setPreviewInvoiceObj}
                                setEditInvoiceObj={setEditInvoiceObj}
                                showSendEmail={showSendEmail}
                                sendEmailInvoiceType={sendEmailInvoiceType} */
                                /> 
                            :
                            (Object.keys(editInvoiceObj).length > 0) &&
                            <CreateUpdateInvoiceComponent /* entityObj={entityObj}
                                setEntityObj={setEntityObj} */
                                editInvoiceObj={editInvoiceObj}
                                setPreviewInvoiceObj={setPreviewInvoiceObj}
                                setEditInvoiceObj={setEditInvoiceObj}
                                />
                            
                        }
                    </>
                    :
                    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                            <img src={'/images/details_error.png'} width={'50%'} style={{marginTop:'70px'}} />

                            <Typography variant='h5' style={{textAlign:'center'}}>
                                Something went wrong!
                            </Typography>

                            <Box display='flex' justifyContent='center' marginTop={2}>
                                <Button variant='contained' 
                                    //onClick={() => getEstimateOrInvoiceDetails(true)}
                                    style={{ width: '120px' }} // Set width for the button
                                    color='secondary'>
                                    Try again
                                    {apiLoading && <CircularProgress size={18} style={{marginLeft:'8px', color:'white'}}/>}
                                </Button>
                                <Button variant='outlined'
                                    onClick={closeDialog}
                                    style={{ width: '120px', marginLeft: '8px' }} // Set width and margin for the button
                                    color='secondary'>
                                    Cancel
                                </Button>
                            </Box>
                    </Box>
                    }

                </Grid>}
            
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user,
    drawerState: state.drawerState.setDrawerState
});

export default connect(mapStateToProps)(CreateEditPreviewBillModal);
