import React, {useEffect, useState} from 'react';
import RightToLeftSideDrawer from './RightToLeftSideDrawer';
import { connect } from 'react-redux';
import { getDueInvoiceListApi } from '../services/authService';
import { consoleToLog, showDate, InoviceTypes } from '../util/AppUtil';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {AccessLevel} from '../util/AppUtil';
import { formatAmount } from '../util/currencyUtil';
import {displayInvoiceTypeLabel} from '../util/AppUtil';
import Box from '@mui/material/Box';
import { useIsMount } from './useIsMount';
import { useNavigate } from 'react-router-dom';

const dueInvoiceListContainer = {
    cursor: 'pointer',
    boxSizing: "border-box",
    border: "1px solid #ccc",
    padding: "16px 16px 0px 16px",
    borderRadius: "4px",
    marginBottom: "16px",
};
const dueDate = {
    color: "#8d96a2",
    fontWeight: "500",
    marginLeft: "16px",
};
const divider = {
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    margin: "8px 0px 10px 0px",
};
const headingText = {
    color: "#8d96a2",
};
const plusIcon = {
    marginRight: "14px",
};
const clientName = {
    color: "#4a5568",
    fontWeight: 500,
    marginTop: '8px'
};

const ShowInvoicesDrawer = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const isMount = useIsMount();
    const navigate = useNavigate();

    const [dueInvoicesList, setDueInvoicesList] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [total, setTotal] = useState();
    const [items, setItems] = useState([]);

    const { access_level } = props.selectedAccount || {};
    const accessLevelViewer = AccessLevel.getUserAccessLevelValue(access_level) === AccessLevel.VIEWER ? true : false;

    useEffect(() => {
        getDueInvoices();
    }, []);

    useEffect(() => {
        if (!isMount) {
            if (isMoreLoading) {
                getDueInvoices();
            }
        }
    }, [isMoreLoading]);

    const getDueInvoices = () => {
        const invoice_account_id = props.selectedAccount?.id;
        const entity_id = props.dueInvoiceObj?.entity_id;
        const daysParam = props.dueInvoiceObj?.data_for.replaceAll(/\s/g,'').replace('Days', '');
        const days_param = daysParam === '90+' ? '90-120' : daysParam;

        getDueInvoiceListApi(invoice_account_id, entity_id, days_param, page)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response getDueInvoiceListApi: ", res);
                setLoading(false);
                setItems(res.invoices);

                if(isMoreLoading) {
                    const newItems = dueInvoicesList.concat(res.invoices);
                    setDueInvoicesList(newItems);
                    setPage(page + 1);
                    setIsMoreLoading(!isMoreLoading);
                } else {
                    setPage(page + 1);
                    setDueInvoicesList(res.invoices);
                    setTotal(res.total);
                }
            })
            .catch((e) => {
                consoleToLog("Error getDueInvoiceListApi: ", e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant: 'error'});
                    return;
                }
            })
    }

    const onViewInvoiceClick = async (e, invoice) => {
            e.stopPropagation();
            navigate(`/invoice/${invoice.slug}/preview`, {state: {showSendEmail: false, invoice}});
            props.handleInvoiceDialogOpen();
    }

    const loadMoreLogic = () => {
        return (
            <Grid container>
                <Grid size={12}>
                {items.length === 20 && !isMoreLoading &&
                    <div style={{textAlign: "center", margin:'16px 0px'}}>
                        <Button type="button"
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsMoreLoading(!isMoreLoading);
                            }}>Load More
                        </Button>
                    </div>}

                { isMoreLoading &&
                    <CircularProgress size={35} style={{position:'relative', left:'50%', right:'50%', margin:'16px 0px'}}/>
                }
                </Grid>
            </Grid>
        )
    }

    return (
        <RightToLeftSideDrawer title={'Invoices'}
            openDrawer={props.openInvoicesDrawer}
            closeDrawer={props.handleInvoicesDrawerClose}
            fromDueInvoicesDrawer={true}>
                
                <div style={{ padding: "16px", marginBottom: "18px" }}>
                    {
                        loading ?
                        <Grid container justifyContent='center' alignItems='center'>
                            <CircularProgress size={25} style={{marginTop:'24px'}}/>
                        </Grid>
                        :
                        <>
                        {
                        dueInvoicesList &&
                        dueInvoicesList.length > 0 ?
                        dueInvoicesList.map((invoice) => {
                            return (
                            <Grid  container
                                direction='column'
                                sx={dueInvoiceListContainer}
                                key={invoice.id}
                            >
                                <Grid container alignItems="center" justifyContent='space-between'>
                                    <Grid>
                                        <Typography
                                        variant="subtitle1"
                                        >
                                        {<span className='invoice_type_style'>
                                            {displayInvoiceTypeLabel(invoice.invoice_type)}
                                        </span>} 
                                        <span style={{fontSize:'13px', position:'relative', top:'1px'}}>
                                            {invoice.invoice_type === InoviceTypes.PROFORMA ? invoice.proforma_invoice_number :  invoice.invoice_number}
                                        </span>
                                        </Typography>
                                    </Grid>

                                    <Grid>
                                        <Typography variant="body2" sx={dueDate}>
                                        {showDate(invoice.issue_date)}
                                        </Typography>
                                    </Grid>

                                </Grid>
                                <Grid>
                                    <Typography variant="body1" sx={clientName}>
                                        {invoice.client_name}
                                    </Typography>
                                </Grid>
                                <Box component='div' sx={divider}></Box>

                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                <Grid size={4}>
                                    <Typography variant="body2" sx={headingText}>
                                    Invoice Total
                                    </Typography>

                                    <Typography variant="body2">
                                    {formatAmount(invoice.total, invoice.currency)}
                                    </Typography>
                                </Grid>

                                {/* <Grid item md={4} style={{ textAlign: "center" }}>
                                    <Typography variant="body2" className={classes.headingText}>
                                    TDS Amount
                                    </Typography>

                                    <Typography variant="body2">
                                    {currencyFormatter.format(invoice.tds_amount)}
                                    </Typography>
                                </Grid> */}

                                    <Grid size={4} style={{ textAlign: "right" }}>
                                        <Typography variant="body2" sx={headingText}>
                                        Amount due
                                        </Typography>

                                        <Typography variant="body2" style={{ color: "red" }}>
                                        {formatAmount(invoice.due, invoice.currency)}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid>
                                    <Grid container
                                        spacing={1}
                                        style={{ marginTop: "16px" }}
                                    >

                                        <Grid size={6}>
                                            <Button
                                                variant="outlined"
                                                onClick={(e) => onViewInvoiceClick(e, invoice)}
                                                fullWidth
                                                style={{ color: "green" }}
                                            >
                                                <VisibilityOutlinedIcon
                                                    fontSize="small"
                                                    sx={plusIcon}
                                                />
                                                View 
                                            </Button>
                                        </Grid>

                                        <Grid size={6}>
                                            <Button
                                                variant="outlined"
                                                onClick={(e) => props.handleOpenAddPaymentDrawer(e, invoice)}
                                                fullWidth
                                                style={{ color: "green" }}
                                                disabled={accessLevelViewer}
                                            >
                                                <AddCircleOutlineIcon
                                                    fontSize="small"
                                                    sx={plusIcon}
                                                />
                                                Add Payment
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            );
                        }) : <Grid container justifyContent='center'
                                style={{background:'#f7f7f7', padding:'16px', borderRadius:'5px'}}>
                                <Typography variant='body1'>
                                    No Due Invoices Found
                                </Typography>
                            </Grid>
                    }
                    </>
                    }

                    {loadMoreLogic()}
                </div>

        </RightToLeftSideDrawer>
    );
}

const mapStateToProps = (state) => ({
    selectedAccount: state.invoiceAccounts.selectedAccount,
    user: state.invoiceAccounts.user
});

export default connect(mapStateToProps)(ShowInvoicesDrawer);
