import React from 'react';
import BaseSection from './BaseSection';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { ReadOnlyEditor } from './BaseEditor';

const Introduction = ({item, handleDrawerOpen, onShowOrHidePage}) => {

    const {data} = item;
    const {title, description} = data;

    return (
        <BaseSection title="Introduction" 
            item={item}
            handleDrawerOpen={handleDrawerOpen}
            onShowOrHidePage={onShowOrHidePage}>

                <Grid size={12} className='edit_section_container'>

                    <Grid isize={12}>
                        <Typography className='edit_section_heading' 
                            dangerouslySetInnerHTML={{__html: title}} />
                    </Grid>

                    <Grid size={12}>
                    <ReadOnlyEditor editorValue={description}/>
                        {/* <Typography className='edit_section_descp'  >
                            {section_description}
                        </Typography> */}
                    </Grid>
                        
                </Grid>

        </BaseSection>
    );
};

export default Introduction;
