import './App.css';
import React from 'react';
import AppRouter from './routers/AppRouter';
import './styles/styles.scss';
import './css/flaticon.css';
import { SnackbarProvider } from 'notistack';
import Slide from '@mui/material/Slide';
import "react-datepicker/dist/react-datepicker.css";
import OverlayComponent from './components/OverlayComponent';

function App() {
  return (
    <div className="App">
      <SnackbarProvider maxSnack={3}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        TransitionComponent={Slide}>
          <AppRouter /> 
          <OverlayComponent />
      </SnackbarProvider>
    </div>
  );
}

export default App;
