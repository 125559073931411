import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';
import {updateEstimateSettingsApi} from '../../services/authService';
import { consoleToLog } from '../../util/AppUtil';

const MuiDialogpaper = {
    '& .MuiDialog-paper': {
        scrollbarWidth: 'none',
        "&::-webkit-scrollbar": {
            display: "none"
        }
    }
};

const dialogTitle = {
    borderBottom:'1px solid #ccc', 
    padding:'8px 16px', 
    fontSize:'16px',
    fontWeight:'600',
};

const dialogBody = {
    padding: '16px'
};

const customMarginTop = {
    marginTop:'16px'
};

const MuiFormControlLabelroot = {
    fontSize:'10px'
};

const EstimateSettingsModal = ({openEstimatesSettingsModal, handleEstimatesSettingsModalClose, selectedAccount, invoiceAccountsList, setInvoiceAccountsList, setSelectedInvoiceAccount}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [labelValue, setLabelValue] = useState(selectedAccount.estimate_terminology);
    const [estimateApprovalRequired, setEstimateApprovalRequired] = useState(selectedAccount.estimate_approval_required);

    const saveEstimateSettings = async () => {
        setIsLoading(true);
        try {   
            const response = await updateEstimateSettingsApi(selectedAccount.id, estimateApprovalRequired, labelValue);
            const res = response.data;
            consoleToLog("updateEstimateSettingsApi: ", res);
            setIsLoading(false);

            const obj = {
                ...selectedAccount,
                estimate_terminology: res.estimate_terminology,
                estimate_approval_required: res.estimate_approval_required
            }

            let accountList = invoiceAccountsList.map((ia) => {
                if (ia.id === obj.id) return obj;
                    else return ia;
            });

            setSelectedInvoiceAccount(obj);
            setInvoiceAccountsList(accountList);

            handleEstimatesSettingsModalClose();
        } catch (error) {
            consoleToLog("updateEstimateSettingsApi: ", error);
            setIsLoading(false);
        }
    }
    return (
        <div>
            <Dialog
                open={openEstimatesSettingsModal}
                //TransitionComponent={Transition}
                scroll="paper"
                sx={MuiDialogpaper}
                maxWidth="sm"
                fullWidth
                style={{scrollbarWidth:'none !important'}}
                onClose={handleEstimatesSettingsModalClose}
                >
                        <Grid container justifyContent='space-between'
                            alignItems='center'sx={dialogTitle}>

                            <Grid>        
                                <Typography>
                                    Estimate Settings
                                </Typography>
                            </Grid>

                            <Grid>
                                <IconButton onClick={handleEstimatesSettingsModalClose}>
                                    <ClearIcon fontSize='medium' />
                                </IconButton>
                            </Grid>

                        </Grid>

                        <Grid container flexDirection='column' sx={dialogBody}>

                            <Grid size={12}>
                                <Typography variant='subtitle1' sx={{marginBottom:'-8px'}}>
                                    Terminology
                                </Typography>

                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    color='secondary'
                                    placeholder='Terminology'
                                    margin='dense'
                                    value={labelValue}
                                    onChange={(e)=>{
                                        setLabelValue(e.target.value)
                                    }}
                                    style={{marginBottom:'-8px'}}
                                    size='small'
                                />
                            </Grid>

                            <Grid size={12} sx={customMarginTop}>
                                <FormControlLabel 
                                    sx={MuiFormControlLabelroot}
                                    control={
                                        <Checkbox  
                                            onChange = {(e)=>{
                                                setEstimateApprovalRequired(e.target.checked)
                                            
                                            }}
                                            checked={estimateApprovalRequired}
                                        />
                                } 
                                style={{fontSize:'14px'}}
                                label="Estimate Approval Required" />
                        </Grid>
                        
                            <Grid size={12} sx={customMarginTop}>
                                <Grid container spacing={1}>
                                    <Grid>
                                        <Button variant='contained' color='primary'
                                            onClick={saveEstimateSettings}
                                        >
                                            {isLoading && <CircularProgress size={18} style={{marginRight:'8px', color:'white'}}/>}
                                            Save
                                        </Button>
                                    </Grid>
                                    <Grid>
                                        <Button variant='outlined' color='secondary'
                                            onClick={handleEstimatesSettingsModalClose}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                    </Grid>
                </Dialog>
        </div>
    );
}

export default EstimateSettingsModal;